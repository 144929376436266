import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const TDAmeritradeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 21</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#54B948"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Forms-/-zz-build-/-Elements-/-Banks-/-TD"
              transform="translate(11.829114, 6.268837)"
              fill="#FFFFFF"
            >
              <g
                id="g10"
                transform="translate(9.087647, 6.403160) scale(-1, 1) rotate(-180.000000) translate(-9.087647, -6.403160) translate(0.002204, 0.043908)"
              >
                <g
                  id="g3261"
                  transform="translate(0.029442, 0.000000)"
                >
                  <g
                    id="g62"
                    transform="translate(0.025316, 0.043907)"
                  >
                    <path
                      d="M12.7068116,0 L7.36011136,0 L7.36011136,10.1925587 L10.0456269,10.1925587 L10.0456269,1.8055022 L12.6284968,1.8055022 C14.4114905,1.8055022 15.1604239,3.11256027 15.1604239,6.4785667 C15.1604239,9.86468791 14.3096052,10.8487027 12.4996194,10.8487027 L6.60889693,10.8487027 L6.60889693,0 L3.94961305,0 L3.94961305,10.8487027 L0.047175746,10.8487027 L0.047175746,12.6582278 L13.3280082,12.6582278 C16.5313142,12.6582278 18.0531317,10.9042195 18.0531317,6.5055205 C18.0531317,0.71045385 15.7808601,0 12.7068116,0"
                      id="path64"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default TDAmeritradeIcon;
