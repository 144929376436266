import type PaymentApi from '../../services/payment/PaymentApi';

import authorizePayment from './authorizePayment';

const authorizeAndConfirmPayment = async ({
  paymentId,
  vendorPlatformKey,
  vendorMerchantId,
  vendorPaymentSecret,
  paymentApi,
}: {
  paymentId: string;
  vendorPlatformKey: string;
  vendorMerchantId: string;
  vendorPaymentSecret: string;
  paymentApi: typeof PaymentApi;
}) => {
  const { paymentIntent } = await authorizePayment({
    vendorPlatformKey,
    vendorMerchantId,
    vendorPaymentSecret,
  });

  if (paymentIntent?.status === 'requires_confirmation') {
    // UI to confirm the payment, based off requires_confirmation status from stripe
    return paymentApi.confirmPayment({
      paymentId,
    });
  } else {
    // for any other status or error, UI to poll and get the response
    return paymentApi.verifyPaymentSubmission({
      paymentId,
      isPaymentConfirmed: true,
    });
  }
};

export default authorizeAndConfirmPayment;
