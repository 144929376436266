import { createContext } from 'react';

export type BeforeCloseTaskFunc = () => Promise<void> | void;

export type SecondaryContainerCloseContextActions = {
  registerBeforeCloseTask: (task: BeforeCloseTaskFunc) => void;
  clearBeforeCloseTask: () => void;
  runBeforeCloseTask: () => Promise<void>;
};

export type SecondaryContainerCloseLocalStateContextType =
  SecondaryContainerCloseContextActions;

export type SecondaryContainerCloseContextProviderType =
  SecondaryContainerCloseLocalStateContextType;

const SecondaryContainerCloseContext = createContext<
  SecondaryContainerCloseContextProviderType | undefined
>(undefined);

export default SecondaryContainerCloseContext;
