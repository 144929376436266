import React from 'react';
import { Box } from '@mui/material';

import ErrorCard from '../../components/ErrorCard/ErrorCard';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { CONTAINER_IDS } from '../../utils/element-ids';

import WalletManagementEntry from './WalletManagementEntry';

const WalletManagementRouteContainer = () => {
  const { containerConfig } = useEnvironmentContextProvider();

  if (containerConfig?.type !== 'inline') {
    return <ErrorCard />;
  }

  return (
    <Box
      data-testid={
        CONTAINER_IDS.WALLET_MANAGEMENT_ROUTE_CONTAINER_WRAPPER
      }
    >
      <WalletManagementEntry />
    </Box>
  );
};

export default WalletManagementRouteContainer;
