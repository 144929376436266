import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const PNCIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 18</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Rectangle"
            fill="#FF5400"
            fillRule="nonzero"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="g4"
            transform="translate(13.800000, 4.800000)"
            fill="#FFFFFF"
          >
            <path
              d="M10.7429365,8.18329412 C10.0695812,8.18329412 9.12655059,8.18329412 9.12655059,8.18329412 C9.19383529,8.01509647 9.16048941,7.77961412 9.02563765,7.50993882 C9.02563765,7.50993882 8.11626353,5.99477647 8.11626353,5.99477647 C8.11626353,5.99477647 6.60081882,3.33393882 6.60081882,3.33393882 C6.60081882,3.33393882 6.02840471,2.25634165 5.18656941,1.61663435 C5.72535529,1.81874824 12.5280282,5.79238588 12.5280282,5.79238588 C13.6395106,6.46599529 14.6158871,8.14963765 10.7429365,8.18329412 Z M5.62416,12.4604894 C4.51267765,13.1001882 2.52573459,13.1001882 4.44539294,9.73290353 C4.78207059,9.15992471 5.25357176,8.3184 5.25357176,8.3184 C5.38842353,8.48659765 5.59053176,8.58751059 5.89355294,8.58751059 C5.89355294,8.58751059 7.67839059,8.58751059 7.67839059,8.58751059 C7.67839059,8.58751059 10.7429365,8.58751059 10.7429365,8.58751059 C10.7429365,8.58751059 11.9556141,8.62113882 12.9322729,8.21748706 C12.4944,8.55388235 5.62416,12.4604894 5.62416,12.4604894 Z M3.30056471,3.16545882 C3.30056471,1.88602729 4.31085176,0.168198494 6.26414118,3.50239059 C6.60081882,4.10874353 7.07234824,4.91692235 7.07234824,4.91692235 C6.87021176,4.95029647 6.70176,5.08540235 6.53353412,5.35479529 C6.53353412,5.35479529 5.65781647,6.90386824 5.65781647,6.90386824 C5.65781647,6.90386824 4.10874353,9.53051294 4.10874353,9.53051294 C4.10874353,9.53051294 3.46876235,10.5744565 3.33419294,11.6525929 C3.26662588,11.0459576 3.30056471,3.16545882 3.30056471,3.16545882 Z M7.17326118,0 C3.19936941,0 0,3.19936941 0,7.17326118 C0,11.1468988 3.19936941,14.3801506 7.17326118,14.3801506 C11.1474353,14.3801506 14.3804329,11.1468988 14.3804329,7.17326118 C14.3804329,3.19936941 11.1474353,0 7.17326118,0 Z"
              id="path6"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default PNCIcon;
