/**
 * The /app route really just means "session-router",
 * However, /app is more secure+user-friendly than /session-router
 */
export const SESSION_ROUTER_PATH = '/app';

export const ROOT_PATHS = {
  WALLET: '/wallet-management',
  PAYMENT_WITH_WALLET: '/payment-with-wallet',
  PAYMENT: '/one-time-payment',
  PAYMENT_METHOD_ENTRY: '/add-payment-method-v2',
  PAYMENT_METHOD_SELECT: '/payment-method-selector',
} as const;

export const WALLET_MANAGEMENT_PATHS = {
  ADD_PAYMENT_METHOD: '/wallet-management/add-payment-method',
  EDIT_PAYMENT_METHOD_TEMPLATE:
    '/wallet-management/edit-payment-method/:paymentMethodId',
  REMOVE_PAYMENT_METHOD_TEMPLATE:
    '/wallet-management/remove-payment-method/:paymentMethodId',
} as const;

export const SECONDARY_CCG_PATHS = {
  PAYMENT_METHOD_DISPLAY:
    '/component-library/payment-method-display',
} as const;
