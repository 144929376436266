import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import Checkbox from '../../ui/Checkbox/Checkbox';
import InputError from '../../ui/InputError/InputError';
import RichTextBox from '../../ui/RichTextBox/RichTextBox';
import {
  TEXT_CONTENT_IDS,
  TRANSLATION_CLASS_NAMES,
} from '../../utils/element-ids';
import ContentSpacer from '../../ui/ContentSpacer/ContentSpacer';

type AchAuthorizationConsentProps = {
  title: string;
  consentText?: string;
  consentLabel: string;
  consentValue: boolean;
  onChange: (hasConsented: boolean) => void;
  hasNotConsented: boolean;
  errorMessage?: string;
};

const AchAuthorizationConsent = ({
  title,
  consentText,
  consentLabel,
  consentValue,
  onChange,
  hasNotConsented,
  errorMessage,
}: AchAuthorizationConsentProps) => {
  return (
    <Grid>
      <Typography
        style={{ fontWeight: 700 }}
        data-testid={
          TEXT_CONTENT_IDS.ACH_AUTHORIZATION_AGREEMENT_LABEL
        }
      >
        {title}
      </Typography>

      <ContentSpacer bottom={4} />
      <div
        className={
          TRANSLATION_CLASS_NAMES.NO_TRANSLATION_CLASS_NAME
        }
      >
        <RichTextBox richText={consentText} />
      </div>

      <ContentSpacer bottom={4} />

      <Checkbox
        id="achAuthorization"
        value={consentValue}
        label={consentLabel}
        testId={TEXT_CONTENT_IDS.ACH_AUTHORIZATION_CHECKBOX}
        onChange={(value) => {
          onChange(value);
        }}
        error={hasNotConsented}
      />
      {hasNotConsented ? (
        <Box style={{ margin: '0 0 8px' }}>
          <InputError
            id="achAuthorizationErrorLabel"
            errorMessage={errorMessage}
          />
        </Box>
      ) : null}
    </Grid>
  );
};

export default AchAuthorizationConsent;
