/* eslint no-void: ["error", { "allowAsStatement": true }] */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { FC } from 'react';

import { AppContext } from '../../../contextStore/AppContext';
import { getCheckoutSessionStatusFromCommonCheckoutApi } from '../../../../services/commonCheckout/getCheckoutSessionStatusFromCommonCheckoutApi';
import NotificationAlertWrapper from '../../../../ui/NotificationAlertWrapper/NotificationAlertWrapper';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import type {
  CheckoutSessionsResponse,
  DeterminedSessionResponse,
} from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { HttpErrorResponse } from '../../../../services/commonCheckout/types/HttpErrorResponse';
import transformSuccessObject from '../../utils/transform-success-object';
import type { ErrorCallbackArgs } from '../../types';
import CancelSessionHandler from '../../../../components/cancel-session-handler/CancelSessionHandler';
import { SessionOutcome } from '../../../../components/SessionOutcome/SessionOutcome';
import useEnvironmentContextProvider from '../../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import shouldShowWidget from '../../../../utils/environment/shouldShowWidget';
import { SESSION_ALREADY_PROCESSED_HTTP_STATUS } from '../../../../utils/constants';
import emitEvent from '../../../../utils/widget/emitEvent';
import emitError from '../../../../utils/widget/emitError';
import PrimarySecondaryConditionalAppContentVisibility from '../../../../components/PrimarySecondaryConditionalAppContentVisibility/PrimarySecondaryConditionalAppContentVisibility';

import { isSessionComplete } from './checkout-sessions-polling/utils/isSessionComplete';
import type { CheckoutContainerProps } from './checkout-container/types/CheckoutContainerProps';
import { isConcludedSessionStatus } from './checkout-sessions-polling/utils/isConcludedSessionStatus';
import type { CheckoutContainersSessionStatus } from './checkout-container/types/CheckoutContainersSessionStatus';
import { isOngoingSessionStatus } from './checkout-sessions-polling/utils/isOngoingSessionStatus';
import ContainerContent from './ContainerContent';

const CheckoutContainer: FC<CheckoutContainerProps> = ({
  checkoutSessionId,
  showPaymentContainer,
  postMessage,
  children,
}) => {
  const {
    isAuthorized,
    setData,
    originalCheckoutSessionResponse,
    isChildWaitingOnGlobalSession,
    isSessionOutcomeShowingDeadEndScreen,
    initialSessionData,
    hasInitialSessionDataLoaded,
    isPrimaryRouteInFocus,
  } = useContext(AppContext);
  const { closeNotification } = useContext(NotificationContext);

  const { setEnvironmentState } =
    useEnvironmentContextProvider();

  const [componentDataIsLoaded, setComponentDataIsLoaded] =
    useState(false);
  const [sessionStatus, setSessionStatus] =
    useState<CheckoutContainersSessionStatus>('NOT_READY');
  const stopPollingRef = useRef(false);

  const [shouldStartPolling, setShouldStartPolling] =
    useState<boolean>(false);

  const handleCancelClick = useCallback(() => {
    stopPollingRef.current = true;
    postMessage({ type: 'hideModal' });
    setSessionStatus(() => 'STALE');
    emitError(postMessage, {
      title: 'SESSION_CANCELED',
      status: 410,
      detail: 'Your session has been canceled',
    });
  }, []);

  const onHttpErrorResponseCallback = useCallback(
    (
      _errorResponse: HttpErrorResponse,
      latestCheckoutSession: CheckoutSessionsResponse,
    ) => {
      postMessage({ type: 'hideModal' });

      emitError(postMessage, {
        checkoutSessionResponse: latestCheckoutSession,
        title:
          _errorResponse.title as ErrorCallbackArgs['title'],
        detail: _errorResponse.detail,
        status: _errorResponse.status,
        errorDetails: _errorResponse.errorDetails,
      });
      setSessionStatus(() => 'PROCESSING_FAILED');
    },
    // Shouldn't need to add anything here, setSessionStatus uses setState callback,
    // and other functions shouldn't change
    [],
  );

  const onNewSessionData = useCallback(
    (data: CheckoutSessionsResponse): void => {
      // Maybe update more properties, besides just `originalCheckoutSessionResponse`?
      setData({ originalCheckoutSessionResponse: data });
    },
    [],
  );

  const onSuccessCallback = useCallback(
    (data: DeterminedSessionResponse<'COMPLETED'>) => {
      postMessage({ type: 'hideModal' });
      postMessage({
        type: 'onSuccess',
        data: transformSuccessObject({
          checkoutSessionResponse: data,
        }),
      });
      setSessionStatus(() => 'PROCESSING_SUCCEEDED');
    },
    [],
  );

  const onExceptionCallback = useCallback(
    (
      e: Error,
      latestCheckoutSession: CheckoutSessionsResponse,
    ) => {
      reportError(e);
      postMessage({ type: 'hideModal' });
      emitError(postMessage, {
        checkoutSessionResponse: latestCheckoutSession,
        title: 'POLLING_EXCEPTION',
        detail: e.message,
      });
      setSessionStatus(() => 'PROCESSING_FAILED');
    },
    [],
  );

  useEffect(() => {
    if (
      !hasInitialSessionDataLoaded ||
      !Object.keys(initialSessionData).length
    ) {
      return;
    }

    if (isSessionComplete(initialSessionData)) {
      setSessionStatus('ALREADY_PROCESSED');
      emitError(postMessage, {
        title: 'SESSION_ALREADY_PROCESSED',
        detail: 'session is already processed',
      });
      return;
    }

    setSessionStatus('READY_FOR_PROCESSING');
    setComponentDataIsLoaded(true);
  }, [hasInitialSessionDataLoaded, initialSessionData]);

  useEffect(() => {
    setEnvironmentState({
      showWidgetContainer: shouldShowWidget(
        showPaymentContainer,
        () => isOngoingSessionStatus(sessionStatus),
      ),
    });
  }, [sessionStatus]);

  /**
   * This use effect performs basic checkoutSessionId validation and obtains session details
   */
  useEffect(() => {
    const hasValidCheckoutSessionId =
      typeof checkoutSessionId === 'string' &&
      checkoutSessionId.trim().length > 0;
    if (!hasValidCheckoutSessionId) {
      setSessionStatus('ERROR');
      emitError(postMessage, {
        title: 'INVALID_SESSION',
        detail: 'session id is invalid',
      });
      return () => {
        stopPollingRef.current = true;
      };
    }
    closeNotification();

    return () => {
      stopPollingRef.current = true;
    };
  }, [checkoutSessionId]);

  /**
   * This use effect starts polling when:
   * 1. modal is supposed to be visible (showPaymentContainer = true)
   * 2. initial loading is complete (headers are set, app context data is set)
   * 3. checkout session status is not complete
   */
  useEffect(() => {
    (async () => {
      if (showPaymentContainer) {
        // already processed
        if (isConcludedSessionStatus(sessionStatus)) {
          postMessage({ type: 'hideModal' });
          emitError(postMessage, {
            title: 'SESSION_ALREADY_PROCESSED',
            detail: 'session is already processed',
          });
        }
        // fetch new checkout session status
        else if (sessionStatus === 'STALE') {
          setData({ overlayLoaderConfig: { show: true } });
          try {
            const { response: currentCheckoutSessionResponse } =
              await getCheckoutSessionStatusFromCommonCheckoutApi(
                {
                  updateStatusUrl: `/checkout-sessions/${checkoutSessionId}`,
                },
              );

            if (!currentCheckoutSessionResponse) {
              throw new Error(
                'getCheckoutSessions().data is undefined',
              );
            }

            const { checkoutSessionStatus } =
              currentCheckoutSessionResponse.checkoutSession;

            if (checkoutSessionStatus === 'COMPLETED') {
              setSessionStatus('PROCESSING_SUCCEEDED');
            } else {
              setSessionStatus('READY_FOR_PROCESSING');
            }
          } catch (e) {
            reportError(e);
            setSessionStatus('PROCESSING_FAILED');
          }
        }
        // continue to process
        if (
          sessionStatus === 'READY_FOR_PROCESSING' &&
          componentDataIsLoaded
        ) {
          if (stopPollingRef.current) {
            // Seen this happen on http://localhost:7468/testHarnessVanillaJS.html?appEnv=dev
            console.warn(
              'stopPollingRef.current was set to true, but its being flipped to false. Not sure why it was set to true.',
            );
          }
          stopPollingRef.current = false;
          if (
            isSessionComplete(originalCheckoutSessionResponse)
          ) {
            throw new Error(
              'Session is already complete. This should never happen if `sessionStatus` is `READY_FOR_PROCESSING`!',
            );
          }
          setShouldStartPolling(true);
          emitEvent(postMessage, { title: 'WIDGET_OPEN' });
        }
      }
    })().catch((error) => {
      setSessionStatus('ERROR');
      if (
        error?.response?.status ===
        SESSION_ALREADY_PROCESSED_HTTP_STATUS
      ) {
        emitError(postMessage, {
          title: 'SESSION_ALREADY_PROCESSED',
          detail: 'session is already processed',
        });
      } else {
        emitError(postMessage, {
          title: error?.response?.data?.title,
          detail:
            error?.response?.data?.detail ??
            (error.message as string),
        });
      }
    });
  }, [
    sessionStatus,
    showPaymentContainer,
    componentDataIsLoaded,
  ]);

  return (
    <div
      style={{
        textAlign: 'left', // textAlign: left was added because a client had textAlign: center and it was centering most of the elements inside the widget.
        minWidth: '320px',
      }}
    >
      {componentDataIsLoaded ? (
        <PrimarySecondaryConditionalAppContentVisibility
          isPrimaryRouteInFocus={isPrimaryRouteInFocus}
        >
          <CancelSessionHandler
            sessionId={checkoutSessionId}
            onCancelEnvironmentCallback={handleCancelClick}
          >
            {isAuthorized &&
            !isChildWaitingOnGlobalSession &&
            !isSessionOutcomeShowingDeadEndScreen &&
            isPrimaryRouteInFocus ? (
              <NotificationAlertWrapper />
            ) : null}
            <ContainerContent>
              <SessionOutcome
                onNewSessionData={onNewSessionData}
                onSuccessCallback={onSuccessCallback}
                onHttpErrorResponseCallback={
                  onHttpErrorResponseCallback
                }
                onExceptionCallback={onExceptionCallback}
                shouldStartPolling={shouldStartPolling}
                stopPollingRef={stopPollingRef}
              >
                {children}
              </SessionOutcome>
            </ContainerContent>
          </CancelSessionHandler>
        </PrimarySecondaryConditionalAppContentVisibility>
      ) : null}
    </div>
  );
};

export default CheckoutContainer;
