import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const EasyPayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Group 17</title>
      <g
        id="Optum-Review-&amp;-Pay"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop---RP---Split-Order---Easy-Pay"
          transform="translate(-116.000000, -1530.000000)"
        >
          <g
            id="Group-17"
            transform="translate(116.000000, 1530.000000)"
          >
            <polygon
              id="Fill-14"
              fill="#021F3C"
              transform="translate(27.043550, 33.687278) rotate(-75.000000) translate(-27.043550, -33.687278) "
              points="0.900109565 47.3266781 53.1763275 47.3062555 53.1869909 20.0478778 0.910772914 20.0683003"
            />
            <g
              id="Bill"
              transform="translate(21.760825, 30.241345) rotate(-285.000000) translate(-21.760825, -30.241345) translate(-5.689175, 16.494866)"
            >
              <polygon
                id="Clip-15"
                points="0 0 54.511041 0 54.511041 27.2981739 0 27.2981739"
              />
              <g id="Group-9">
                <polygon
                  id="Fill-14"
                  fill="#6FC1B1"
                  points="0 27.2981739 54.511041 27.2981739 54.511041 0 0 0"
                />
                <path
                  d="M51.785489,2.04736304 L2.72555205,2.04736304 C2.34923184,2.04736304 2.04416404,2.35290826 2.04416404,2.72981739 L2.04416404,24.5683565 C2.04416404,24.9452656 2.34923184,25.2508109 2.72555205,25.2508109 L51.785489,25.2508109 C52.1618092,25.2508109 52.466877,24.9452656 52.466877,24.5683565 L52.466877,2.72981739 C52.466877,2.35290826 52.1618092,2.04736304 51.785489,2.04736304 Z M51.1038,3.41183099 L51.1038,23.8855211 L3.4065,23.8855211 L3.4065,3.41183099 L51.1038,3.41183099 Z"
                  id="Stroke-16"
                  fill="#007C89"
                  fillRule="nonzero"
                />
                <g
                  id="Group-14"
                  transform="translate(19.078864, 5.459635)"
                >
                  <path
                    d="M16.2542013,8.13994751 C16.2542013,12.635495 12.6156946,16.2796383 8.12710066,16.2796383 C3.63850671,16.2796383 -5.28169421e-15,12.635495 -5.28169421e-15,8.13994751 C-5.28169421e-15,3.64440005 3.63850671,-2.2217831e-14 8.12710066,-2.2217831e-14 C12.6156946,-2.2217831e-14 16.2542013,3.64440005 16.2542013,8.13994751"
                    id="Fill-20"
                    fill="#F9F9F9"
                  />
                  <path
                    d="M8.17143476,1.09192696 C8.50830335,1.09192696 8.78428851,1.33489394 8.80868206,1.64307341 L8.81043476,1.68752348 L8.81054167,3.16630863 C9.69386105,3.38009504 10.4087057,3.96856786 10.8061063,4.82076229 L10.8193514,4.85083587 L10.829088,4.85826943 C10.8966851,4.91394033 10.9527685,4.98519985 10.9912279,5.0692213 L11.0089606,5.11228243 C11.1170106,5.40627211 10.9789544,5.73079983 10.6993205,5.85919847 L10.6563267,5.87695896 L10.5826976,5.9041046 C10.2701497,6.0193351 9.92405826,5.85503402 9.81524297,5.5397684 C9.53800954,4.73655257 8.88854856,4.26844174 8.04911718,4.26844174 C7.11061942,4.26844174 6.47431737,4.81092039 6.46077346,5.53545394 C6.46077346,6.19816882 6.83858162,6.6777969 7.69523614,7.014797 L8.90565822,7.47384846 C10.5022892,8.06873761 11.2986521,8.92569398 11.2986521,10.1904096 C11.2986521,11.6919048 10.2183782,12.736557 8.81007869,12.9593971 L8.81043476,14.3935826 C8.81043476,14.7225215 8.52434471,14.9891791 8.17143476,14.9891791 C7.83456616,14.9891791 7.558581,14.7462121 7.53418745,14.4380327 L7.53243476,14.3935826 L7.53131768,12.9411658 C6.27790033,12.7127888 5.33952537,11.8715736 4.97847042,10.6071 C4.89800182,10.3252858 5.03433004,10.0294689 5.29441894,9.90562736 L5.33889707,9.88661137 L5.39510851,9.86520807 C5.70209748,9.74831771 6.04557152,9.90281255 6.16227923,10.2102819 C6.1956243,10.2981303 6.20685008,10.3889618 6.19871403,10.4766267 L6.1955267,10.4988777 L6.20065531,10.5124231 C6.51476841,11.3093792 7.19131499,11.7730389 8.11815913,11.8101858 L8.24203063,11.8126643 C9.30827149,11.8126643 10.1093203,11.1676273 10.1093203,10.1904096 C10.1093203,9.52240169 9.69274285,9.055572 8.58449125,8.62584209 L7.36662927,8.16400645 C6.01698393,7.66661985 5.27144165,6.76233846 5.27154428,5.52438928 C5.29496216,4.26207992 6.23810044,3.31605807 7.53206286,3.11635303 L7.53243476,1.68752348 C7.53243476,1.3585846 7.8185248,1.09192696 8.17143476,1.09192696 Z"
                    id="Combined-Shape"
                    fill="#E87722"
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle"
              fill="#E87722"
              x="0"
              y="25.0801973"
              width="51.615142"
              height="38.6809438"
              rx="4.54258675"
            />
            <path
              d="M36.975963,37.5961256 L50.9716088,37.5961256 C52.6441431,37.5961256 54,38.9519826 54,40.6245168 L54,48.2168214 C54,49.8893557 52.6441431,51.2452126 50.9716088,51.2452126 L36.975963,51.2452126 C33.2068717,51.2452126 30.1514196,48.1897604 30.1514196,44.4206691 C30.1514196,40.6515778 33.2068717,37.5961256 36.975963,37.5961256 Z"
              id="Rectangle"
              fill="#FFAD83"
            />
            <ellipse
              id="Oval"
              fill="#F9F9F9"
              cx="36.9652997"
              cy="44.4206691"
              rx="2.38485804"
              ry="2.38859022"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default EasyPayIcon;
