import { useContext, useEffect, useState } from 'react';

import { getWalletFromCommonCheckoutApi } from '../../../services/commonCheckout/getWalletFromCommonCheckoutApi';
import { AppContext } from '../../contextStore/AppContext';
import type { PaymentMethodType } from '../../../services/customer/types';
import type { FormattedPaymentMethod } from '../../checkout/components/Wallet/types';
import { formatPaymentMethods } from '../../checkout/components/Wallet/utils';

const usePaymentSelectorComponentData = () => {
  const {
    customerId,
    isPrimaryRouteInFocus,
    paymentMethods,
    setData,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [formattedPaymentMethods, setFormattedPaymentMethods] =
    useState<FormattedPaymentMethod<PaymentMethodType>[]>([]);

  const hideLoader = () => {
    setIsLoading(false);
    setData({ overlayLoaderConfig: { show: false } });
  };

  useEffect(() => {
    if (!customerId || !isPrimaryRouteInFocus) {
      return;
    }

    setIsLoading(true);
    setData({ overlayLoaderConfig: { show: true } });

    (async () => {
      const { paymentMethods: paymentMethodsFromResponse } =
        await getWalletFromCommonCheckoutApi({
          customerId,
        });
      setFormattedPaymentMethods(
        formatPaymentMethods(paymentMethodsFromResponse),
      );

      setData({ paymentMethods: paymentMethodsFromResponse });
      hideLoader();
    })().catch(() => {
      hideLoader();
    });
  }, [isPrimaryRouteInFocus]);

  return {
    isLoading,
    formattedPaymentMethods,
    paymentMethods,
  };
};

export default usePaymentSelectorComponentData;
