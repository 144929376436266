import type {
  StripeElements,
  PaymentMethod,
} from '@stripe/stripe-js';

import type {
  CreateNewPaymentRequest,
  CreatePaymentMethodResponse,
  PaymentMethodError,
} from './types';

const StripeApi = {
  validatePaymentMethod: async ({
    elements,
    errorMessage = 'Form validation error',
  }: {
    elements: StripeElements;
    errorMessage?: string;
  }): Promise<{
    validationError?: PaymentMethodError;
  }> => {
    let error: PaymentMethodError | undefined;
    try {
      const stripeValidationResult = await elements.submit();

      if (stripeValidationResult?.error) {
        const { type, message = errorMessage } =
          stripeValidationResult.error;
        error = {
          errorType: type,
          message,
        };
      }
    } catch (exception) {
      error = {
        errorType: 'api_error',
        message: errorMessage,
      };
    }
    return { validationError: error };
  },
  createPaymentMethod: async ({
    elements,
    stripe,
    name,
    errorMessage = 'Error creating payment method',
  }: CreateNewPaymentRequest & {
    errorMessage?: string;
  }): Promise<CreatePaymentMethodResponse> => {
    let paymentMethod: PaymentMethod | undefined;
    let paymentMethodError: PaymentMethodError | undefined;

    if (!stripe || !elements) {
      throw new Error('Stripe API not available');
    }

    try {
      const paymentMethodResult =
        await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: {
              name,
            },
          },
        });

      paymentMethod = paymentMethodResult.paymentMethod;

      if (paymentMethodResult.error) {
        paymentMethodError = {
          errorType: paymentMethodResult.error.type,
          message:
            paymentMethodResult.error.message ?? errorMessage,
        };
      }
    } catch (exception) {
      paymentMethodError = {
        errorType: 'api_error',
        message: errorMessage,
      };
    }
    return {
      paymentMethod,
      paymentMethodError,
    };
  },
};

export default StripeApi;
