import { httpClientService } from '../settingsApi/httpClientService';
import poll from '../../utils/function/poll';
import { isPollingResponse } from '../commonCheckout/helpers/isPollingResponse';
import type { PaymentMethodType } from '../commonCheckout/types/PaymentMethod';

import type {
  PaymentMethodResponse,
  EditPaymentMethodParams,
  EditPaymentMethodRequest,
  PaymentMethodDetailsParams,
  PaymentMethodDetailsResponse,
  CreatePaymentMethodReq,
  SetupPaymentMethodResponse,
} from './types';

const CustomerApi = {
  updatePaymentMethod: async (
    params: EditPaymentMethodParams,
    request: EditPaymentMethodRequest<PaymentMethodType>,
  ) => {
    const client = httpClientService.getHttpClient({
      api: 'CUSTOMER',
    });
    const response = await client.request<PaymentMethodResponse>(
      {
        url: `/payment-methods/${params.paymentMethodId}`,
        method: 'PATCH',
        data: request,
      },
    );
    if (!isPollingResponse(response)) {
      return response.data;
    }
    const polledResponse = await poll({
      fn: async () =>
        CustomerApi.updatePaymentMethodStatus(params),
      until: ({ status }) => status === 200,
    });
    return polledResponse.data;
  },
  updatePaymentMethodStatus: async (
    params: PaymentMethodDetailsParams,
  ) => {
    const client = httpClientService.getHttpClient({
      api: 'CUSTOMER',
    });
    return client
      .request<PaymentMethodDetailsResponse>({
        // url: `/customers/${params.customerId}/payment-methods/${params.paymentMethodId}`,
        url: `/payment-methods/${params.paymentMethodId}`,
        method: 'GET',
      })
      .then((response) => response);
  },
  createPaymentMethod: async (
    customerId: string,
    payload: Record<'paymentMethod', CreatePaymentMethodReq>,
  ) => {
    const client = httpClientService.getHttpClient({
      api: 'CUSTOMER',
    });
    const response =
      await client.request<SetupPaymentMethodResponse>({
        url: `/customers/${customerId}/setup-payment-methods`,
        method: 'POST',
        data: payload,
      });
    if (isPollingResponse(response) && response.data?.data.id) {
      return CustomerApi.confirmPaymentMethodCreation({
        url: response.data?.url,
      });
    }
    return response.data;
  },
  getCreatePaymentMethodStatus: async (url: string) => {
    const client = httpClientService.getHttpClient({
      api: 'CUSTOMER',
    });
    return client.request<SetupPaymentMethodResponse>({
      url,
      method: 'GET',
    });
  },
  confirmPaymentMethodCreation: async ({
    url,
    isPaymentMethodAuthorized = false,
  }: {
    url: string;
    isPaymentMethodAuthorized?: boolean;
  }) => {
    const polledResponse = await poll({
      fn: async () =>
        CustomerApi.getCreatePaymentMethodStatus(url),
      until: ({ status, data }) => {
        if (isPaymentMethodAuthorized) {
          return status === 200;
        }
        return (
          status === 200 || data?.data.status === 'AUTH_REQUIRED'
        );
      },
    });

    return polledResponse.data;
  },
};

export default CustomerApi;
