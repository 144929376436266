import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import BackButton from '../../../../ui/BackButton/BackButton';
import PageTitle from '../../../../ui/PageTitle/PageTitle';
import {
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../../utils/element-ids';
import type { DisclaimerProps } from '../../../../components/Disclaimer/Disclaimer';
import {
  ACCOUNT_NUMBER,
  ACCOUNT_NUMBER_HELPER_TEXT,
  ALL_FIELDS_REQUIRED,
  BANK_ACCOUNT_NICKNAME_HELPER_TEXT,
  CANCEL_BUTTON,
  CHECKING,
  NAME_ON_ACCOUNT,
  PAYMENT_METHOD_SETTINGS,
  ROUTING_NUMBER,
  ROUTING_NUMBER_HELPER_TEXT,
  SAVE_BUTTON,
  SAVINGS,
} from '../../../../shared/strings';
import RadioGroup from '../../../../ui/RadioGroup/RadioGroup';
import FormField from '../../../../ui/FormField/FormField';
import Checkbox from '../../../../ui/Checkbox/Checkbox';
import Disclaimer from '../../../../components/Disclaimer/Disclaimer';
import CheckDiagram from '../../../../media/payment/CheckDiagram';
import Button from '../../../../ui/Button/Button';
import type AddPaymentMethodAchFormData from '../../types/AddPaymentMethodAchFormData';
import validateAchForm from '../../../../utils/capabilities/validateAchForm';
import type AddPaymentMethodAchFormErrors from '../../types/AddPaymentMethodAchFormErrors';
import type {
  AddPaymentMethodAchFormKeys,
  AddPaymentMethodAchFormValues,
} from '../../types/AddPaymentMethodAchForm';
import GenericAchBankIcon from '../../../../icons/ach/generic_ach_bank';
import useFormConfigManager from '../../../../hooks/useFormConfigManager/useFormConfigManager';

export type AddPaymentMethodAchFormProps = {
  formTitle: string;
  bankName?: string;
  actionType: DisclaimerProps['actionType'];
  isInFocus?: boolean;
  onSubmit: (data: AddPaymentMethodAchFormData) => void;
  onBackClick?: () => void;
  onCancel: () => void;
  onRoutingNumberInput: (routingNumber: string) => Promise<void>;
};

const parseNumericCharacters = (value: string) => {
  return value ? value.replace(/\D/g, '') : value;
};

const AddPaymentMethodAchForm = ({
  formTitle,
  bankName,
  actionType,
  isInFocus,
  onSubmit,
  onBackClick,
  onCancel,
  onRoutingNumberInput,
}: AddPaymentMethodAchFormProps) => {
  const [formData, setFormData] =
    useState<AddPaymentMethodAchFormData>({
      accountType: 'checking',
      nameOnAccount: '',
      accountNumber: '',
      routingNumber: '',
      default: false,
      nickname: '',
    });
  const [formErrors, setFormErrors] =
    useState<AddPaymentMethodAchFormErrors>({});
  const [fieldVisited, setFieldVisited] = useState<
    Record<AddPaymentMethodAchFormKeys, boolean>
  >({
    accountType: false,
    nameOnAccount: false,
    accountNumber: false,
    routingNumber: false,
    default: false,
    nickname: false,
  });

  const handleOnChange = (
    key: AddPaymentMethodAchFormKeys,
    value: AddPaymentMethodAchFormValues,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOnBlur = (
    key: AddPaymentMethodAchFormKeys,
    value: AddPaymentMethodAchFormValues,
  ) => {
    const newFormValues = { ...formData, [key]: value };
    const validationResult = validateAchForm(newFormValues);
    setFormErrors(validationResult);
    setFieldVisited((prevState) => ({
      ...prevState,
      [key]: true,
    }));

    if (key === 'routingNumber' && typeof value === 'string') {
      onRoutingNumberInput(value).catch(() => {
        /* ignore error */
      });
    }
    setFormData(newFormValues);
  };

  const handleOnSubmit = useCallback(() => {
    setFieldVisited({
      accountType: true,
      nameOnAccount: true,
      accountNumber: true,
      routingNumber: true,
      default: true,
      nickname: true,
    });
    const validations = validateAchForm(formData);
    setFormErrors(validations);

    if (Object.keys(validations).length) {
      return;
    }

    onSubmit(formData);
  }, [formData]);

  const getErrorMessage = (key: AddPaymentMethodAchFormKeys) => {
    return fieldVisited[key] ? formErrors[key] : '';
  };

  const { title, showActionsOnForm } = useFormConfigManager({
    title: formTitle,
    titleTestId: TITLE_IDS.MAIN_PAGE_TITLE,
    isInFocus,
    processHeaderConfig: false,
    backActionConfig: {
      label: 'Back',
      testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON,
      handler: onBackClick,
    },
    primaryActionConfig: {
      label: SAVE_BUTTON,
      testId: ELEMENT_IDS.PAYMENT_METHOD_ACH_SAVE_BUTTON,
      handler: handleOnSubmit,
    },
    secondaryActionConfig: {
      label: CANCEL_BUTTON,
      testId: ELEMENT_IDS.PAYMENT_METHOD_ACH_CANCEL_BUTTON,
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <Box>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON
          }
        />
      ) : null}

      <PageTitle
        title={title}
        titleId={TITLE_IDS.MAIN_PAGE_TITLE}
      />

      <Grid
        container
        direction="column"
        gap={{ xs: '16px' }}
      >
        <Grid
          container
          direction="column"
        >
          <Typography
            variant="body2"
            data-testid={
              TEXT_CONTENT_IDS.ACH_ADD_PAYMENT_ALL_FIELDS_REQUIRED_TEXT
            }
          >
            {ALL_FIELDS_REQUIRED}
          </Typography>
        </Grid>
        <Grid>
          <RadioGroup
            defaultValue={formData.accountType}
            id="accountType"
            label="Account type"
            isUsedByRadioGroup
            options={[
              {
                id: 'checking',
                label: CHECKING,
              },
              { id: 'savings', label: SAVINGS },
            ]}
            onChange={(value) => {
              handleOnChange('accountType', value);
            }}
          />

          <FormField
            id="nameOnAccount"
            label={NAME_ON_ACCOUNT}
            value={formData.nameOnAccount}
            errorMessage={getErrorMessage('nameOnAccount')}
            onChange={(value) => {
              handleOnChange('nameOnAccount', value);
            }}
            onBlur={(value) => {
              handleOnBlur('nameOnAccount', value);
            }}
            autocomplete="name"
          />

          <FormField
            required
            id="routingNumber"
            label={ROUTING_NUMBER}
            value={formData.routingNumber}
            errorMessage={getErrorMessage('routingNumber')}
            helperText={ROUTING_NUMBER_HELPER_TEXT}
            maxLength={9}
            onChange={(value) => {
              handleOnChange(
                'routingNumber',
                parseNumericCharacters(value),
              );
            }}
            onBlur={(value) => {
              handleOnBlur('routingNumber', value);
            }}
            renderAfterInput={() =>
              bankName ? (
                <GenericAchBankIcon bankName={bankName} />
              ) : null
            }
          />

          <FormField
            required
            id="accountNumber"
            label={ACCOUNT_NUMBER}
            value={formData.accountNumber}
            errorMessage={getErrorMessage('accountNumber')}
            helperText={ACCOUNT_NUMBER_HELPER_TEXT}
            maxLength={17}
            onChange={(value) => {
              handleOnChange(
                'accountNumber',
                parseNumericCharacters(value),
              );
            }}
            onBlur={(value) => {
              handleOnBlur('accountNumber', value);
            }}
          />
        </Grid>
        <Grid item>
          <CheckDiagram />
        </Grid>
        <Grid item>
          <FormControl
            component="fieldset"
            style={{ width: '100%' }}
          >
            <FormLabel component="legend">
              <Typography
                variant="body1"
                data-testid={
                  TEXT_CONTENT_IDS.ACH_PAYMENT_METHOD_SETTINGS_LABEL
                }
              >
                <b>{PAYMENT_METHOD_SETTINGS}</b>
              </Typography>
            </FormLabel>
            <Checkbox
              id="default"
              value={formData.default}
              label="Set as default payment method"
              testId={
                TEXT_CONTENT_IDS.ADD_PAYMENT_SET_AS_DEFAULT_CHECKBOX_ACH
              }
              onChange={(value) => {
                handleOnChange('default', value);
              }}
            />
            <FormField
              id="achNickname"
              aria-label="Bank Account Nickname (Optional)"
              label="Bank account nickname"
              value={formData.nickname ?? ''}
              hintText="(Optional)"
              errorMessage={getErrorMessage('nickname')}
              helperText={BANK_ACCOUNT_NICKNAME_HELPER_TEXT}
              maxLength={30}
              onChange={(value) => {
                handleOnChange('nickname', value);
              }}
              onBlur={(value) => {
                handleOnBlur('nickname', value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            marginTop: '-4px',
            borderTop: 'solid 1px #CBCCCD',
            padding: '24px 0 8px',
          }}
        >
          <Disclaimer
            actionType={actionType}
            privacyUrlTestId="achPrivacyUrl"
            termsAndConditionTestId="achTermsAndCondition"
          />
        </Grid>
        {showActionsOnForm ? (
          <Grid
            container
            direction="column"
            style={{ gap: '16px' }}
          >
            <Button
              id={ELEMENT_IDS.PAYMENT_METHOD_ACH_SAVE_BUTTON}
              isLoading={false}
              fullWidth
              color="primary"
              variant="contained"
              className="loadingButton"
              onClick={handleOnSubmit}
            >
              {SAVE_BUTTON}
            </Button>
            <Button
              id={ELEMENT_IDS.PAYMENT_METHOD_ACH_CANCEL_BUTTON}
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              {CANCEL_BUTTON}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default AddPaymentMethodAchForm;
