import type { PaymentMethodType } from '../../../../../services/commonCheckout/types/PaymentMethod';
import type { FormattedPaymentMethod } from '../types';

import { getCardSvg } from './getCardSvg';
import { isFormattedCardPaymentMethod } from './isFormattedCardPaymentMethod';

const GENERIC_CARD_SVG = 'genericcard';
const GENERIC_BANK_ACCOUNT_SVG = 'genericbank';

const getPaymentMethodLogo = (
  formattedPaymentMethod: FormattedPaymentMethod<PaymentMethodType>,
) => {
  if (isFormattedCardPaymentMethod(formattedPaymentMethod)) {
    return getCardSvg(
      formattedPaymentMethod.cardBrand,
      GENERIC_CARD_SVG,
    );
  }

  return getCardSvg(
    GENERIC_BANK_ACCOUNT_SVG,
    GENERIC_BANK_ACCOUNT_SVG,
  );
};

export default getPaymentMethodLogo;
