import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import { getAgent, getConfigMode } from '../session/selectors';

export default function shouldTriggerReminder(
  originalCheckoutSessionResponse: CheckoutSessionsResponse,
) {
  /**
   * Note: updated the logic to check when the reminder should be triggered.
   * The reminder should be triggered when the user is authenticated and
   * only when the user is doing text or email to add or text or email to pay.
   */
  const isAgent = !!getAgent(originalCheckoutSessionResponse);
  const isPaymentMethodEntryMode =
    getConfigMode(originalCheckoutSessionResponse) ===
    'PAYMENT_METHOD_ENTRY';
  const isPaymentMode =
    getConfigMode(originalCheckoutSessionResponse) === 'PAYMENT';

  const isTextOrEmail2Add = !isAgent && isPaymentMethodEntryMode;
  const isTextOrEmail2Pay = !isAgent && isPaymentMode;

  return isTextOrEmail2Add || isTextOrEmail2Pay;
}
