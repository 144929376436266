import React, { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import axios from 'axios';

import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import { getCheckoutSessionSettingsFromCommonCheckoutApi } from '../../services/commonCheckout/getCheckoutSessionSettingsFromCommonCheckoutApi';
import { CCGThemeProviderContext } from '../../capabilities/contextStore/CCGThemeProviderContext';
import { isSessionComplete } from '../../capabilities/checkout-v2/checkout/components/checkout-sessions-polling/utils/isSessionComplete';
import { ensureIsInstanceOfError } from '../../utils/function/ensureIsInstanceOfError';
import { isHttpErrorResponse } from '../../utils/function/isHttpErrorResponse';
import useParseCheckoutSessionId from '../../shared/CheckoutSessionInitializer/useParseCheckoutSessionId';
import { httpClientService } from '../../services/settingsApi/httpClientService';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import getAppEnvOrDefaultForHosted from '../../utils/getAppEnvOrDefaultForHosted';

import PaymentTermsAndConditions from './PaymentTermsAndConditions';

type PaymentTermsAndConditionsContainerProps = {
  checkoutSessionId?: string;
  onSuccess?: (data: {
    originalCheckoutSessionResponse:
      | CheckoutSessionsResponse
      | undefined;
  }) => void;
  onError?: (error: unknown) => void;
};

/**
 * Note: Using useParseCheckoutSessionId and getAppEnvOrDefaultForHosted prevents this component
 * from being used in an embedded environment. If we relocate the logic relying on the URL data,
 * it can be used in embedded. Originally, there was no requirement for this component to function
 * in an embedded environment.
 */
const PaymentTermsAndConditionsContainer: FC<
  PaymentTermsAndConditionsContainerProps
> = ({ checkoutSessionId: sessionId, onSuccess, onError }) => {
  const appContext = useContext(AppContext);
  const parseCheckoutSessionId = useParseCheckoutSessionId();
  const { setAppearance } = useContext(CCGThemeProviderContext);
  const {
    setData,
    setInitializeSessionException,
    setInitializeSessionError,
  } = appContext;
  const checkoutSessionId = sessionId || parseCheckoutSessionId;
  const [isLoading, setIsLoading] = useState(true);
  const appEnv = getAppEnvOrDefaultForHosted();
  useEffect(() => {
    if (checkoutSessionId && appEnv) {
      httpClientService.init({
        appEnv,
      });
    }
  }, []);
  useEffect(() => {
    // We want both checkoutSessionId and appEnv to be something to make sure we are requesting session
    // data from the correct environment (getAppEnvOrDefaultForHosted can return `stage` as the default environment)
    if (checkoutSessionId && appEnv) {
      getCheckoutSessionSettingsFromCommonCheckoutApi({
        checkoutSessionId,
        fetchConfig: true,
      })
        .then((data) => {
          onSuccess?.(data);
          if (
            isSessionComplete(
              data.originalCheckoutSessionResponse,
            )
          ) {
            setInitializeSessionException(
              new Error('Session already processed'),
            );
            return;
          }

          const { appearance } = data;

          if (appearance) {
            setAppearance(appearance);
          }
          setIsLoading(false);
        })
        .catch((e: unknown) => {
          if (
            axios.isAxiosError(e) &&
            e.response?.data &&
            isHttpErrorResponse(e.response.data)
          ) {
            onError?.(e);
            setInitializeSessionError(e.response.data);
          } else {
            setInitializeSessionException(
              ensureIsInstanceOfError(e),
            );
          }
        })
        .finally(() => {
          setData({
            checkoutSessionId,
            hasInitialSessionDataLoaded: true,
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [checkoutSessionId]);

  if (isLoading) {
    return <OverlayLoader />;
  }

  return <PaymentTermsAndConditions />;
};

export default PaymentTermsAndConditionsContainer;
