import { Typography, Box } from '@mui/material';
import type { SVGProps } from 'react';
import React from 'react';

import {
  ICON_IDS,
  TEXT_CONTENT_IDS,
  TRANSLATION_CLASS_NAMES,
} from '../../utils/element-ids';

type GenericAchBankIconProps = SVGProps<SVGSVGElement> & {
  bankName: string;
};

const GenericAchBankIcon = ({
  bankName,
  ...svgProps
}: GenericAchBankIconProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={ICON_IDS.GENERIC_ACH_BANK_ICON}
        {...svgProps}
      >
        <path
          d="M7 11H5V18H7V11ZM13 11H11V18H13V11ZM21.5 20H2.5V22H21.5V20ZM19 11H17V18H19V11ZM12 4.26L17.21 7H6.79L12 4.26ZM12 2L2.5 7V9H21.5V7L12 2Z"
          fill="#323334"
        />
      </svg>
      <Typography
        className={
          TRANSLATION_CLASS_NAMES.NO_TRANSLATION_CLASS_NAME
        }
        data-testid={TEXT_CONTENT_IDS.GENERIC_ACH_BANK_NAME}
        sx={{
          color: '#222',
          lineHeight: '16px',
          fontSize: '14.22px',
        }}
      >
        {bankName}
      </Typography>
    </Box>
  );
};
export default GenericAchBankIcon;
