import useCcgNavigate from '../../../hooks/useCcgNavigate/useCcgNavigate';

const useManagementWalletClickHandler = () => {
  const { ccgNavigate } = useCcgNavigate();

  return () => {
    ccgNavigate({
      routes: ['WALLET'],
    });
  };
};

export default useManagementWalletClickHandler;
