import type {
  PaymentMethod,
  PaymentMethodType,
} from '../../../services/commonCheckout/types/PaymentMethod';

/** @note: this logic will be a bit confusing because the api response related
 *  to the paymentMethod is a different shape than what we have defined in CheckoutSessionResponse.
 *  But the callback we provide to the merchant that transform PaymentMethod<PaymentMethodType> is
 *  of type PaymentMethodSessionOutcome. But we need to transform the paymentMethod we get from the
 *  API, but keeping in mind that CheckoutSessionResponse thinks it has the shape of PaymentMethodSessionOutcome
 *  even though it's not, so the utility func formatPaymentMethodForDisplay() can convert the
 *  data that we receive from the api in the paymentMethod property to what we need in PaymentMethodDisplay
 *  component. Keep in mind the types that are in several files including the storybook file, fixtures related
 *  to it and unit tests. It's a cluster of a mess.
 */

const mapPaymentMethodSessionOutcomeToPaymentMethod = (
  paymentMethodSessionOutcome: unknown,
): PaymentMethod<PaymentMethodType> | undefined => {
  if (
    typeof paymentMethodSessionOutcome === 'object' &&
    paymentMethodSessionOutcome !== null
  ) {
    const paymentMethod =
      paymentMethodSessionOutcome as any as PaymentMethod<PaymentMethodType>;
    const {
      paymentMethodDetails,
      warning,
      agent,
      card,
      ...rest
    } = paymentMethod;
    const {
      customerId,
      default: isDefault,
      id,
      paymentMethodType,
      vendor,
      vendorPaymentMethodId,
      zipCode,
      nickname,
      status,
    } = rest;

    const commonProperty = {
      id,
      paymentMethodType,
      vendorPaymentMethodId,
      customerId,
      vendor,
      status,
      agent,
      zipCode,
      default: isDefault,
      nickname,
      warning,
    };

    return {
      ...commonProperty,
      card,
      paymentMethodDetails,
    };
  }
  return undefined;
};

export default mapPaymentMethodSessionOutcomeToPaymentMethod;
