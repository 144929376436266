import axios from 'axios';
import type { AsyncReturnType } from 'type-fest';

import poll from '../../utils/function/poll';
import { getCheckoutSessionStatusFromCommonCheckoutApi } from '../../services/commonCheckout/getCheckoutSessionStatusFromCommonCheckoutApi';
import { isSessionComplete } from '../../capabilities/checkout-v2/checkout/components/checkout-sessions-polling/utils/isSessionComplete';
import { POLL_FOR_CHECKOUT_STATUS_CHECK_INTERVAL } from '../../utils/constants';
import type {
  CheckoutSessionsResponse,
  IncompleteSessionResponse,
} from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import { ensureIsInstanceOfError } from '../../utils/function/ensureIsInstanceOfError';
import { isHttpErrorResponse } from '../../utils/function/isHttpErrorResponse';
import { doCheckoutSessionsMatch } from '../../utils/function/doCheckoutSessionsMatch';
import type { ExtendedAxiosError } from '../../services/settingsApi/types';

import type { OnFinallyDone } from './types/OnFinallyDone';
import type { OnNewSessionData } from './types/OnNewSessionData';

/**
 * Because embedded and hosted both make an initial validation call to /checkout-sessions,
 * that initial checkoutSession object is put to use here.
 *   - it creates a guarantee that callbacks will always receive a checkout session.
 *
 * This will have to go away once the initial /checkout-sessions call gets integrated into SessionOutcome code.
 */
interface StartPollingCheckoutSessionArgs {
  initialCheckoutSession: IncompleteSessionResponse;
  updateStatusUrl: string;
  stopPollingRef?: { current: boolean };
  onNewSessionData?: OnNewSessionData;
  isSessionOutcomePolling?: boolean;
  // Polling is totally done.
  onFinallyDone?: OnFinallyDone;
}

export function startPollingCheckoutSession({
  initialCheckoutSession,
  updateStatusUrl,
  stopPollingRef,
  onNewSessionData,
  onFinallyDone,
  isSessionOutcomePolling,
}: StartPollingCheckoutSessionArgs): void {
  // This `as` assertion may need to be re-considered in the future:
  let currentCheckoutSession =
    initialCheckoutSession as CheckoutSessionsResponse;

  poll({
    interval: POLL_FOR_CHECKOUT_STATUS_CHECK_INTERVAL,
    isSessionOutcomePolling,
    fn: async () => {
      let result:
        | AsyncReturnType<
            typeof getCheckoutSessionStatusFromCommonCheckoutApi
          >
        | ExtendedAxiosError;

      try {
        result =
          await getCheckoutSessionStatusFromCommonCheckoutApi({
            updateStatusUrl,
          });
      } catch (error) {
        result = error;
      }

      return result;
    },
    until: (responseOrError) => {
      if ('hasError' in responseOrError) {
        if (responseOrError.hasConnection === false) {
          // if the user doesn't have a connection we keep polling for now.
          // (At the time of this implementation we don't have pause capabilities so for now we just keep polling.)
          return false;
        } else {
          throw responseOrError;
        }
      }

      const data = responseOrError.response;

      if ('checkoutSession' in data) {
        const isComplete = isSessionComplete(data);
        const hasDataChanged = doCheckoutSessionsMatch(
          data,
          currentCheckoutSession,
        );
        if (hasDataChanged) {
          currentCheckoutSession = data;
          // eslint-disable-next-line no-console
          console.debug(
            '/checkout-sessions json data has changed',
            data,
          );
          onNewSessionData?.(data);
        }
        if (stopPollingRef?.current) {
          // Call `onCancel`?
          return true;
        }
        return isComplete;
      } else {
        // Received an unexpected response, stop polling.
        // can't wipe checkoutSessionResponse: needed by `generateErrorObject`
        // In future, if we stop throwing on 4xx/5xx responses, we'll hit this code path.
        return true;
      }
    },
  })
    .then(({ response: data }) => {
      if (!currentCheckoutSession) {
        const error = new Error(
          'Expected `currentCheckoutSession` to be defined.',
        );
        reportError(error);
        throw error;
      }

      if (isSessionComplete(currentCheckoutSession)) {
        onFinallyDone?.({
          didSucceed: true,
          latestCheckoutSession: currentCheckoutSession,
        });
      } else {
        // Polling may have stopped early due to cancelation, etc.
        if (stopPollingRef && !stopPollingRef.current) {
          console.warn(
            'Expected `stopPollingRef.current` to be `true`.',
          );
        }
        onFinallyDone?.({
          didSucceed: false,
          latestCheckoutSession:
            // if 5xx errors start being returned instead of thrown, this assertion would be incorrect:
            currentCheckoutSession as IncompleteSessionResponse,
          pollingStoppedEarly: true,
          errorOrResponse: data as
            | CheckoutSessionsResponse
            | undefined,
        });
      }
    })
    .catch((e: unknown) => {
      if (!currentCheckoutSession) {
        const error = new Error(
          'Expected `currentCheckoutSession` to be defined.',
        );
        reportError(error);
        throw error;
      }

      if (
        // Convert `unknown` to well-typed object,
        // pass to respective callbacks:
        //   I believe we should apply this pattern across all new code,
        //   because throwing and catching causes typescript types to be lost.
        axios.isAxiosError(e) &&
        e.response?.data &&
        isHttpErrorResponse(e.response.data)
      ) {
        onFinallyDone?.({
          didSucceed: false,
          // @ts-expect-error - `currentCheckoutSession.checkoutSessionStatus` should never be `COMPLETED` here...
          latestCheckoutSession: currentCheckoutSession,
          httpErrorResponse: e.response.data,
        });
        // TODO: if an error is an axios error, status code 503, has no response data,
        // and these headers:
        //   Content-Length: 145
        //   Content-Type: text/plain
        //   Server: istio-envoy
        //   X-Envoy-Decorator-Operation: wallet.checkout-domain-dev.svc.cluster.local:80/*
        // Then we should probably keep polling, perhaps with a greater time interval.
        //
        // Implementation:
        //   - Setup some callback mechanism to starting polling again?
        //  OR:
        //   - Create custom `poll` utility that normalizes axios errors into normal "responses" passed to `until`.
        //   - `until` actually already has logic to keep polling if there is no response data.
        //     - This would mean most `catch` logic would be integrated into `until` or `.then`
      } else {
        onFinallyDone?.({
          didSucceed: false,
          // @ts-expect-error - `currentCheckoutSession.checkoutSessionStatus` should never be `COMPLETED` here...
          latestCheckoutSession: currentCheckoutSession,
          exception: ensureIsInstanceOfError(e),
        });
      }
    });
  // Promise.finally doesn't receive any args, so can't be used for our `onFinallyDone` callback.
}
