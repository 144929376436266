import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types/FormattedPaymentMethod';
import {
  formatCardPaymentMethod,
  formatBankAccountPaymentMethod,
} from '../../capabilities/checkout/components/Wallet/utils';
import type {
  PaymentMethod,
  PaymentMethodType,
} from '../../services/commonCheckout/types/PaymentMethod';
import { isBankAccountPaymentMethod } from '../../services/commonCheckout/utils/isBankAccountPaymentMethod';
import { isCardPaymentMethod } from '../../services/commonCheckout/utils/isCardPaymentMethod';

export default function formatPaymentMethodForDisplay(
  paymentMethod: PaymentMethod<PaymentMethodType> | undefined,
): FormattedPaymentMethod<PaymentMethodType> | undefined {
  if (paymentMethod) {
    if (isCardPaymentMethod(paymentMethod)) {
      return formatCardPaymentMethod(paymentMethod);
    }

    if (isBankAccountPaymentMethod(paymentMethod)) {
      return formatBankAccountPaymentMethod(paymentMethod);
    }
  }

  return undefined;
}
