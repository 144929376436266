import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import { getPaymentMethods } from '../session/selectors';

export const getWebFormChannelByType = (
  session: CheckoutSessionsResponse,
  paymentMethodType: PaymentMethodType,
) => {
  const paymentMethodList = getPaymentMethods(session);
  if (!paymentMethodType || !paymentMethodList) {
    return undefined;
  }
  const paymentMethodTypeObj = paymentMethodList.find(
    (paymentMethod) => paymentMethod.type === paymentMethodType,
  );

  if (!paymentMethodTypeObj) {
    return undefined;
  }

  return paymentMethodTypeObj.channels.find(
    (channel) => channel.type === 'WEBFORM',
  );
};
