import React from 'react';
import { MemoryRouter as CcgElementsRouter } from 'react-router';

import type { UiRouteDescriptor } from '../../types/UiRouteDescriptor';
import type { SecondaryCcgPathNameValues } from '../../types/PathNameValues';
import { secondaryRoutes } from '../../secondaryRoutes';

const CcgElementsRouteGroupContainer = ({
  initialUiRoute,
}: {
  initialUiRoute: UiRouteDescriptor<SecondaryCcgPathNameValues>;
}) => {
  return (
    <CcgElementsRouter
      initialEntries={[initialUiRoute]}
      initialIndex={1}
    >
      {secondaryRoutes}
    </CcgElementsRouter>
  );
};

export default CcgElementsRouteGroupContainer;
