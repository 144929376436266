import { ROOT_PATHS } from '../../unified-router/constants';
import type {
  RootPathNameValues,
  SecondaryCcgPathNameValues,
} from '../../unified-router/types/PathNameValues';
import type { UiRouteDescriptor } from '../../unified-router/types/UiRouteDescriptor';

export default function isPrimaryCcgRouteMode(
  initialUiRoute: UiRouteDescriptor<
    RootPathNameValues | SecondaryCcgPathNameValues
  >,
): initialUiRoute is UiRouteDescriptor<RootPathNameValues> {
  return !!Object.values(ROOT_PATHS).find(
    (path) => initialUiRoute && path === initialUiRoute.pathname,
  );
}
