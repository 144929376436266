import type { CheckoutSessionsResponse } from '../../../../../services/commonCheckout/types/CheckoutSessionsResponse';

import { uiModeToSuccessObjectMap } from './constants/uiModeToSuccessObjectMap';
import type { SuccessCode } from './types/SuccessCode';

const fallbackSuccessCode =
  uiModeToSuccessObjectMap['FALLBACK' as const];

export const getSuccessCodeAndDescription = (
  checkoutSessionResponse: CheckoutSessionsResponse,
): SuccessCode => {
  const uiModes =
    checkoutSessionResponse.checkoutSession.checkoutRequest
      .config?.modes ?? [];

  if (uiModes.length === 0) {
    // TODO: convert to `throw`, backend should always be sending a ui mode now.
    console.warn(
      "checkoutRequest.config.modes is undefined or empty, assuming it to be ['FALLBACK'].",
      'This will yield a success object of:',
      fallbackSuccessCode,
    );
    return fallbackSuccessCode;
  }

  let firstSuccessCode: undefined | SuccessCode;
  uiModes.forEach((uiMode) => {
    if (
      uiMode !== 'PAYMENT_METHOD_DISPLAY' &&
      uiMode in uiModeToSuccessObjectMap
    ) {
      if (!firstSuccessCode) {
        firstSuccessCode = uiModeToSuccessObjectMap[uiMode];
      } else {
        console.warn(
          'Found extra ui mode, this will be ignored:',
          uiMode,
          `\nWe are already using ${firstSuccessCode.code} as the success code.`,
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `uiMode doesn't have a success code: ${uiMode}, `,
      );
    }
  });

  if (firstSuccessCode) {
    return firstSuccessCode;
  } else {
    console.warn(
      'No success code found, this will yield a success object of:',
      fallbackSuccessCode,
    );
    return fallbackSuccessCode;
  }
};
