import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { TITLE_IDS } from '../../utils/element-ids';

type LoaderWithTextProps = Record<'description', string>;

const LoaderWithText = ({
  description,
}: LoaderWithTextProps) => {
  return (
    <div className="loader-wrapper">
      <Typography
        component="h1"
        variant="h4"
        data-testid={TITLE_IDS.LOADER_TITLE}
        align="center"
      >
        {description}
      </Typography>
      <Stack
        alignItems="center"
        style={{ marginTop: '20px' }}
      >
        <CircularProgress
          color="secondary"
          sx={{ color: '#FF612B' }}
          size={60}
        />
      </Stack>
    </div>
  );
};

export default LoaderWithText;
