import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { HTTPRetryEvent } from './types';

const EVENT_HTTP_RETRY = 'HTTP_RETRY';

export const logHttpRetryEvent = ({
  url,
  httpStatus,
  checkoutSessionId,
}: HTTPRetryEvent) => {
  appInsights.trackEvent({
    name: EVENT_HTTP_RETRY,
    properties: {
      [telemetryKeyPrefix('url')]: url,
      [telemetryKeyPrefix('httpStatus')]: httpStatus,
      [telemetryKeyPrefix('checkoutSessionId')]:
        checkoutSessionId,
    },
  });
};
