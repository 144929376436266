export const ServerErrorDefaultProps = {
  icon: 'error',
  /**
   * Some server errors include extended text like "Payment could not be authorized. Please try a different payment method."
   * Devin discussed with Phil morning of July 17th, and we agreed to not show subtext for any server errors.
   * Ideally, the server provides explicit subtext for each error, but that's not the case today.
   */
  showDescription: false,
  // title text comes from server's top level "detail" key (see HttpErrorResponse.ts)
} as const;
