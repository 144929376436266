import checkConnectionStatus from '../../../api/utils/checkConnectionStatus';

const ConnectionChecker = () => {
  let interval: NodeJS.Timeout | undefined;

  const stopChecking = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = undefined;
  };

  const checkConnection = ({
    connectionRestoredCallback,
  }: {
    connectionRestoredCallback: () => void;
  }) => {
    if (interval) {
      // another interval is already in progress
      return;
    }

    interval = setInterval(() => {
      (async () => {
        const hasConnection = await checkConnectionStatus();

        if (hasConnection) {
          stopChecking();
          connectionRestoredCallback();
        }
      })();
    }, 5000);
  };

  return {
    checkConnection,
    stopChecking,
  };
};

const connectionChecker = ConnectionChecker();

export default connectionChecker;
