import type { TypographyOptions } from '@mui/material/styles/createTypography';

const mutateTypographyWithUpdates = (
  typography: TypographyOptions,
  { fontFamily }: { fontFamily?: string },
): void => {
  if (fontFamily) {
    // eslint-disable-next-line no-param-reassign
    typography.fontFamily = fontFamily;
  }

  Object.keys(typography).forEach((key) => {
    const value = typography[key as keyof TypographyOptions];
    if (
      fontFamily &&
      typeof value === 'object' &&
      'fontFamily' in value
    ) {
      value.fontFamily = fontFamily;
    }
  });
};

export default mutateTypographyWithUpdates;
