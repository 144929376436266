import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type {
  RootPathNameValues,
  SecondaryCcgPathNameValues,
} from '../types/PathNameValues';
import type { UiRouteDescriptor } from '../types/UiRouteDescriptor';

import { mapUiModesToRoutes } from './mapUiModesToRoutes';
import { normalizeUiModesFromServer } from './normalizeUiModesFromServer';

export const getInitialUiRouteFromSession = ({
  originalCheckoutSessionResponse,
}: {
  originalCheckoutSessionResponse: CheckoutSessionsResponse;
}): UiRouteDescriptor<
  RootPathNameValues | SecondaryCcgPathNameValues
> => {
  const uiRoutes = mapUiModesToRoutes(
    normalizeUiModesFromServer(
      originalCheckoutSessionResponse.checkoutSession
        .checkoutRequest.config?.modes,
    ),
  );
  if (uiRoutes.length > 1) {
    // eslint-disable-next-line no-console
    console.log('Multiple uiRoutes are not yet supported');
  }
  return uiRoutes[0];
};
