import React, { useContext, useEffect } from 'react';
import { useParams, Outlet } from 'react-router';

import PrimaryRouteWrappers from '../../../../../components/PrimarySecondaryRouteWrappers/PrimaryRouteWrappers';
import { AppContext } from '../../../../../capabilities/contextStore/AppContext';
import useEnvironmentContextProvider from '../../../../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import getPrimaryRouteComponent from '../utils/getPrimaryRouteComponent';
import type { PrimarySecondaryRouteConfigMapKey } from '../../../../../shared/types/routing/PrimarySecondaryRouteConfig';
import useRoutingHelper from '../../../../../hooks/useRoutingHelper/useRoutingHelper';

import PrimaryRouteNotImplemented from './PrimaryRouteNotImplemented';

const ParentRouteContainer = () => {
  const { checkoutSessionId, setData } = useContext(AppContext);
  const { containerConfig, showPaymentContainer, postMessage } =
    useEnvironmentContextProvider();
  const { isPrimaryRouteInFocus } = useRoutingHelper();

  const params = useParams();

  const primaryComponentFunc = getPrimaryRouteComponent(
    params?.parentRoute as PrimarySecondaryRouteConfigMapKey,
  );

  useEffect(() => {
    setData({ isPrimaryRouteInFocus });
  }, [isPrimaryRouteInFocus]);

  if (!params?.parentRoute) {
    return null;
  }

  if (
    !containerConfig ||
    !postMessage ||
    !primaryComponentFunc
  ) {
    return <PrimaryRouteNotImplemented />;
  }

  return (
    <PrimaryRouteWrappers
      checkoutSessionId={checkoutSessionId}
      containerConfig={containerConfig}
      showPaymentContainer={showPaymentContainer}
      postMessage={postMessage}
    >
      {primaryComponentFunc()}
      <Outlet />
    </PrimaryRouteWrappers>
  );
};

export default ParentRouteContainer;
