import useCcgNavigate from '../../../hooks/useCcgNavigate/useCcgNavigate';

const useBackButtonHandler = ({
  onBackClickHandlerFromParent,
}: {
  onBackClickHandlerFromParent?: () => void;
} = {}) => {
  const { ccgNavigate } = useCcgNavigate();

  let backButtonHandler: () => void;

  if (onBackClickHandlerFromParent) {
    backButtonHandler = () => {
      onBackClickHandlerFromParent();
    };
  } else {
    backButtonHandler = () => {
      ccgNavigate({ routes: ['WALLET'] });
    };
  }

  return backButtonHandler;
};

export default useBackButtonHandler;
