import { useState } from 'react';

import { getBankNameFromCommonCheckoutApi } from '../../../../services/commonCheckout/getBankNameFromCommonCheckoutApi';
import hasOnlyNumbers from '../../../../utils/function/hasOnlyNumbers';

const bankNameCache = new Map<string, string | undefined>();

const isInvalidRoutingNumber = (routingNumber: string) =>
  routingNumber.length !== 9 || !hasOnlyNumbers(routingNumber);

export default function useFindBankNameWithRoutingNumber() {
  const [bankName, setBankName] = useState<string | undefined>(
    undefined,
  );

  const onRoutingNumberInput = async (routingNumber: string) => {
    if (isInvalidRoutingNumber(routingNumber)) {
      setBankName(undefined);
      return;
    }

    if (bankNameCache.has(routingNumber)) {
      setBankName(bankNameCache.get(routingNumber));
      return;
    }

    if (routingNumber) {
      try {
        const bankNameResponse =
          await getBankNameFromCommonCheckoutApi(routingNumber);

        bankNameCache.set(routingNumber, bankNameResponse);
        setBankName(bankNameResponse);
      } catch (e) {
        // ignore error
      }
    }
  };

  return [bankName, onRoutingNumberInput] as const;
}
