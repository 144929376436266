import React from 'react';

import type { RootPathNameValues } from '../../types/PathNameValues';
import type { UiRouteDescriptor } from '../../types/UiRouteDescriptor';
import CcgPrimaryRouter from '../CcgPrimaryRouter';
import CheckoutContainer from '../../../capabilities/checkout-v2/checkout/components/checkout-container';
import type { CheckoutContainerProps } from '../../../capabilities/checkout-v2/checkout/components/checkout-container/types/CheckoutContainerProps';

type CcgEmbeddedRouteGroupContainerProps = Omit<
  CheckoutContainerProps,
  'children' | 'showPaymentContainer'
> & {
  initialUiRoute: UiRouteDescriptor<RootPathNameValues>;
  showPaymentContainer?: boolean;
};

const CcgEmbeddedRouteGroupContainer = ({
  initialUiRoute,
  checkoutSessionId,
  showPaymentContainer,
  postMessage,
  containerConfig,
}: CcgEmbeddedRouteGroupContainerProps) => {
  return (
    <CheckoutContainer
      checkoutSessionId={checkoutSessionId}
      showPaymentContainer={showPaymentContainer}
      postMessage={postMessage}
      containerConfig={containerConfig}
    >
      <CcgPrimaryRouter initialUiRoute={initialUiRoute} />
    </CheckoutContainer>
  );
};

export default CcgEmbeddedRouteGroupContainer;
