import React, { useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { CONTAINER_IDS } from '../../utils/element-ids';
import shouldShowWidget from '../../utils/environment/shouldShowWidget';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import { isSessionComplete } from '../../capabilities/checkout-v2/checkout/components/checkout-sessions-polling/utils/isSessionComplete';
import { ROOT_PATHS } from '../../unified-router/constants';
import { getConfigMode } from '../../utils/session/selectors';
import useContainerCloseHandler from '../container-types/hooks/useContainerCloseHandler';

const ConditionalWidgetContainerVisibility: FC = ({
  children,
}) => {
  const {
    setEnvironmentState,
    showPaymentContainer,
    showWidgetContainer,
  } = useEnvironmentContextProvider();
  const {
    setData,
    initializeSessionError,
    initializeSessionException,
    checkoutSessionId,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);

  const { shouldCloseContainer } = useContainerCloseHandler();

  const configMode = getConfigMode(
    originalCheckoutSessionResponse,
  );

  const shouldDisplayContainer = useMemo(
    () =>
      !!checkoutSessionId &&
      !initializeSessionError &&
      !initializeSessionException &&
      !(
        isSessionComplete(originalCheckoutSessionResponse) &&
        Object.keys(ROOT_PATHS).includes(configMode)
      ),
    [
      checkoutSessionId,
      configMode,
      initializeSessionError,
      initializeSessionException,
      originalCheckoutSessionResponse,
    ],
  );

  useEffect(() => {
    /** @note: what would make showWidgetContainer true?
     *  - showPaymentContainer is true
     *  - checkout session is valid
     *  - session is not completed or accepted
     *  - session is not expired?
     */
    setEnvironmentState({
      showWidgetContainer: shouldShowWidget(
        showPaymentContainer,
        () => shouldDisplayContainer,
      ),
    });
  }, [showPaymentContainer, shouldDisplayContainer]);

  useEffect(() => {
    // start application wide loading state
    setData({
      overlayLoaderConfig: {
        show: !showWidgetContainer && !shouldCloseContainer,
      },
    });
  }, [showWidgetContainer, shouldCloseContainer]);

  return (
    <div id={CONTAINER_IDS.CHECKOUT_CONTAINER_ID}>
      {children}
    </div>
  );
};

export default ConditionalWidgetContainerVisibility;
