import type { UserJourney } from '../../../shared/types/UserJourney';

const title = 'Send a link via email';

export default function getSendEmailFormHeading(
  userJourney: UserJourney,
) {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    return {
      title,
      subTitle:
        'Send an email with a link for the customer to add their payment method to their wallet.',
    };
  }

  if (userJourney === 'MAKE_PAYMENT') {
    return {
      title,
      subTitle:
        'Send an email with a link for the customer to make a payment.',
    };
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`${userJourney} not handled.`);
}
