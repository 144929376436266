import { logGuestPaymentStatusEvent } from './log-guest-payment-status-event';
import { logLoginPaymentStatusEvent } from './log-login-payment-status-event';
import type { PaymentStatusEvent } from './types';

export const logPaymentStatusEvent = ({
  checkoutType,
  ...eventLog
}: PaymentStatusEvent) => {
  if (checkoutType === 'guest') {
    logGuestPaymentStatusEvent(eventLog);
  }
  if (checkoutType === 'hsid') {
    logLoginPaymentStatusEvent(eventLog);
  }
};
