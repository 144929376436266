import React from 'react';
import type { DialogProps } from '@mui/material';
import { Dialog, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';

import noop from '../../utils/function/noop';

import StyledCloseButton from './styled-components/StyledCloseButton';
import StyledBoxCloseButtonContainer from './styled-components/StyledBoxCloseButtonContainer';
import StyledDialogContent from './styled-components/StyledDialogContent';
import StyledPaperContainer from './styled-components/StyledPaperContainer';

export type ModalProps = {
  open: boolean;
  maxWidth?: DialogProps['maxWidth'];
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  labelledById?: string;
  container?: DialogProps['container'];
  onClose?: () => void;
};

const Modal: FC<ModalProps> = ({
  open = false,
  onClose = noop,
  maxWidth = 'xs',
  disableEscapeKeyDown = true,
  disableBackdropClick = true,
  children,
  labelledById,
  container,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      container={container}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      PaperProps={{ elevation: 0 }}
      PaperComponent={StyledPaperContainer}
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) {
          return;
        }

        onClose();
      }}
      aria-labelledby={labelledById}
      TransitionProps={{ role: undefined } as any}
      role={undefined}
    >
      <StyledBoxCloseButtonContainer>
        {onClose ? (
          <StyledCloseButton
            aria-label="close"
            onClick={() => {
              onClose();
            }}
            size="large"
          >
            <CloseIcon fontSize="medium" />
          </StyledCloseButton>
        ) : null}
      </StyledBoxCloseButtonContainer>
      <StyledDialogContent>{children}</StyledDialogContent>
    </Dialog>
  );
};

export default Modal;
