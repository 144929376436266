import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type PaymentMethodEntryViewStates from '../../../shared/types/capabilities/PaymentMethodEntryViewStates';
import type PaymentMethodEntryLoaderState from '../../../shared/types/capabilities/payment-method-entry/PaymentMethodEntryLoaderState';
import { getFilteredPaymentTypes } from '../getFilteredPaymentTypes';

import {
  includesCardAndAch,
  findCardPaymentMethodType,
  findAchPaymentMethodType,
} from './filterHelpers';

const shouldHideModal = (
  viewState: PaymentMethodEntryViewStates,
  originalCheckoutSessionResponse: CheckoutSessionsResponse,
  newState: PaymentMethodEntryLoaderState,
) => {
  let result = false;
  if (viewState === 'unified_payment_method_form') {
    const filteredPaymentTypes = getFilteredPaymentTypes({
      session: originalCheckoutSessionResponse,
    });

    if (includesCardAndAch(filteredPaymentTypes)) {
      result =
        !!newState.isAchFormReady && !!newState.isCardFormReady;
    } else if (findCardPaymentMethodType(filteredPaymentTypes)) {
      result = !!newState.isCardFormReady;
    } else if (findAchPaymentMethodType(filteredPaymentTypes)) {
      result = !!newState.isAchFormReady;
    }
  } else {
    result = true;
  }

  return result;
};

export default shouldHideModal;
