export declare const CCG_VERSION: string | undefined;
export declare const CCG_COMMIT_ID: string | undefined;
export declare const CCG_DISTRIBUTION_TYPE: string | undefined;

export const getVersion = () => {
  return CCG_VERSION;
};

export const getDistributionType = () => {
  return CCG_DISTRIBUTION_TYPE;
};
