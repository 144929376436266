import { noop } from '@skyline/common';

import type PaymentApi from '../../services/payment/PaymentApi';
import type StripeApi from '../../services/stripe/stripe';
import type { PaymentMethodError } from '../../services/stripe/types';
import {
  getAgent,
  getAssistedByAgent,
  getIIASPaymentDetails,
  getMetadata,
  getPartialAuthorization,
} from '../session/selectors';
import type { PayAndStoreStripeFormSubmitData } from '../../capabilities/pay-and-store/types';
import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import { SUCCESSFUL_PAYMENT_SUBMISSION_STATUSES } from '../constants';

import isAuthRequiredForPayment from './isAuthRequiredForPayment';
import authorizeAndConfirmPayment from './authorizeAndConfirmCardPayment';

const processCardPaymentStripe = async ({
  elements,
  formData: {
    nameOnCard: name,
    default: isDefault,
    manufacturerCard: isManufacturerCard,
    nickname,
    savePaymentMethod,
    cardChannel,
  },
  stripe,
  originalCheckoutSessionResponse,
  paymentDescription,
  amount,
  merchantTransactionId,
  authorizeCard,
  statementDescriptorSuffix,
  vendorPlatformKey,
  stripeApi,
  paymentApi,
  handlePaymentMethodError,
  onProcessingComplete = noop,
  onSuccess = noop,
}: PayAndStoreStripeFormSubmitData & {
  originalCheckoutSessionResponse: CheckoutSessionsResponse;
  paymentDescription: string;
  amount: number;
  merchantTransactionId: string;
  authorizeCard?: boolean;
  statementDescriptorSuffix?: string;
  vendorPlatformKey: string;
  stripeApi: typeof StripeApi;
  paymentApi: typeof PaymentApi;
  handlePaymentMethodError: (args: {
    paymentMethodError: PaymentMethodError;
  }) => void;
  onProcessingComplete?: () => void;
  onSuccess?: () => void;
}) => {
  const agent = getAgent(originalCheckoutSessionResponse);
  const assistedByAgent = getAssistedByAgent(
    originalCheckoutSessionResponse,
  );

  const { validationError } =
    await stripeApi.validatePaymentMethod({
      elements,
    });

  if (validationError) {
    handlePaymentMethodError({
      paymentMethodError: validationError,
    });
    return;
  }

  const { paymentMethod, paymentMethodError } =
    await stripeApi.createPaymentMethod({
      elements,
      stripe,
      name,
    });

  if (paymentMethodError) {
    handlePaymentMethodError({
      paymentMethodError,
    });
    return;
  }

  if (paymentMethod) {
    try {
      let paymentResponse = await paymentApi.payAndStore({
        authRequired: true,
        paymentMethod: {
          type: 'CARD',
          paymentMethodDetails: {
            type: 'CARD',
            manufacturerCard: isManufacturerCard,
            last4: paymentMethod.card?.last4 ?? '',
            cardChannel,
          },
          nameOnCard: name,
          vendorPaymentMethodId: paymentMethod.id,
          vendor: 'STRIPE',
          savePaymentMethod,
          nickname,
          default: isDefault,
        },
        agent,
        assistedByAgent,
        paymentDescription,
        paymentDetails: getIIASPaymentDetails(
          originalCheckoutSessionResponse,
        ),
        amount,
        merchantTransactionId,
        authorizeCard,
        partialAuthorization: getPartialAuthorization(
          originalCheckoutSessionResponse,
        ),
        statementDescriptorSuffix,
        metadata: getMetadata(originalCheckoutSessionResponse),
      });

      if (!paymentResponse) {
        throw new Error('paymentResponse unavailable');
      }

      const {
        status,
        id: paymentId,
        vendorMerchantId,
        vendorPaymentSecret,
      } = paymentResponse.data;

      if (isAuthRequiredForPayment(status)) {
        if (vendorPaymentSecret) {
          paymentResponse = await authorizeAndConfirmPayment({
            paymentId,
            vendorPlatformKey,
            vendorMerchantId,
            vendorPaymentSecret,
            paymentApi,
          });
        } else {
          throw new Error(
            'vendorPaymentSecret is required to perform payment authorization',
          );
        }
      }

      if (
        paymentResponse &&
        SUCCESSFUL_PAYMENT_SUBMISSION_STATUSES.includes(
          paymentResponse?.data.status,
        )
      ) {
        onSuccess();
      }
    } finally {
      onProcessingComplete();
    }
  }
};

export default processCardPaymentStripe;
