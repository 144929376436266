import React from 'react';
import type { FC } from 'react';
import type {
  CssFontSource,
  CustomFontSource,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import type { Theme } from '@mui/material';
import { useTheme } from '@mui/material';

import stripeAppearance from '../../../style/stripeAppearanceObject';
import StripeElementsLoader from '../stripe-elements-loader/StripeElementsLoader';
import useRenderedLanguage from '../../../capabilities/components/LanguageSelector/utils/useRenderedLanguage';

type PaymentElementWrapperProps = {
  vendorPlatformKey?: string;
};

const StripePaymentElementWrapper: FC<
  PaymentElementWrapperProps
> = ({ vendorPlatformKey, children }) => {
  const theme: Theme = useTheme();

  const { renderedLanguage } = useRenderedLanguage();

  const options: StripeElementsOptions = {
    appearance: stripeAppearance(theme),
    fonts: theme.typography.fontSources as (
      | CssFontSource
      | CustomFontSource
    )[],
    paymentMethodCreation: 'manual',
    mode: 'setup',
    currency: 'usd',
    paymentMethodTypes: ['card'],
    locale: renderedLanguage,
    loader: 'never',
  };

  return vendorPlatformKey ? (
    <StripeElementsLoader
      vendorPlatformKey={vendorPlatformKey}
      options={options}
    >
      {children}
    </StripeElementsLoader>
  ) : null;
};

export default StripePaymentElementWrapper;
