import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import PageTitle from '../../ui/PageTitle/PageTitle';
import {
  CONTAINER_IDS,
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../utils/element-ids';
import PaymentMethodList from '../../components/PaymentMethodList/PaymentMethodList';
import CallToActionCard from '../../components/CallToActionCard/CallToActionCard';
import ContentSpacer from '../../ui/ContentSpacer/ContentSpacer';
import { NotificationContext } from '../contextStore/NotificationContext';
import { AppContext } from '../contextStore/AppContext';
import Button from '../../ui/Button/Button';
import BackButton from '../../ui/BackButton/BackButton';
import {
  getConfigMode,
  getWarning,
  getWarningForAgent,
} from '../../utils/session/selectors';
import useCcgNavigate from '../../hooks/useCcgNavigate/useCcgNavigate';
import useFormConfigManager from '../../hooks/useFormConfigManager/useFormConfigManager';
import isSecondaryTypeDrawer from '../../utils/container-config/isSecondaryTypeDrawer';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';

import useWalletManagementComponentData from './hooks/useWalletManagementComponentData';
import useBackButtonForWalletManagement from './hooks/useBackButtonForWalletManagement';
import useDoneButtonForWalletManagement from './hooks/useDoneButtonForWalletManagement';

const MANAGE_WALLET_TITLE = 'Manage wallet';
const DONE_BUTTON_LABEL = 'Done';
const BACK_BUTTON_LABEL = 'Back';

const WalletManagementEntry = () => {
  const { closeNotification, notify } = useContext(
    NotificationContext,
  );
  const {
    addPaymentMethodWarning,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);

  const { showBackButton, backButtonHandler } =
    useBackButtonForWalletManagement();
  const { doneButtonHandler, showDoneButton } =
    useDoneButtonForWalletManagement(backButtonHandler);
  const { ccgNavigate } = useCcgNavigate();

  const { isLoading, formattedPaymentMethods } =
    useWalletManagementComponentData();

  const { containerConfig } = useEnvironmentContextProvider();
  const configMode = getConfigMode(
    originalCheckoutSessionResponse,
  );

  const location = useLocation().state || {};
  const locationStateAfterUpdatePaymentMethod =
    'showUpdateNotificationAfterNav' in location &&
    location.showUpdateNotificationAfterNav
      ? location
      : { showUpdateNotificationAfterNav: false };

  const locationStateAfterAddPaymentMethod =
    'showAddPaymentMethodNotificationAfterNav' in location &&
    location.showAddPaymentMethodNotificationAfterNav
      ? location
      : { showAddPaymentMethodNotificationAfterNav: false };

  const locationStateAfterRemovePaymentMethod =
    'showRemovePaymentMethodNotificationAfterNav' in location &&
    location.showRemovePaymentMethodNotificationAfterNav
      ? location
      : { showRemovePaymentMethodNotificationAfterNav: false };

  const { title, titleTestId, showActionsOnForm } =
    useFormConfigManager({
      title: MANAGE_WALLET_TITLE,
      titleTestId: TITLE_IDS.WALLET_MANAGEMENT_TITLE,
      shouldOverrideFormConfigTitleAndActionsOnForm:
        isSecondaryTypeDrawer(containerConfig) &&
        configMode === 'WALLET',
      backActionConfig: {
        testId: ELEMENT_IDS.WALLET_MANAGEMENT_BACK_BUTTON,
        handler: backButtonHandler,
        label: BACK_BUTTON_LABEL,
      },
      showBackButton,
      primaryActionConfig:
        showDoneButton && doneButtonHandler
          ? {
              testId: ELEMENT_IDS.WALLET_MANAGEMENT_DONE_BUTTON,
              handler: useCallback(() => {
                doneButtonHandler?.();
              }, []),
              label: DONE_BUTTON_LABEL,
            }
          : undefined,
    });

  useEffect(() => {
    return () => {
      closeNotification();
    };
  }, []);

  useEffect(() => {
   
    const warningMessage = getWarning(
      originalCheckoutSessionResponse,
    );
    const warningMessageForAgent = getWarningForAgent(
      originalCheckoutSessionResponse,
    );
    if (
      !addPaymentMethodWarning &&
      !warningMessage &&
      locationStateAfterAddPaymentMethod?.showAddPaymentMethodNotificationAfterNav
    ) {
      notify({
        severity: 'success',
        title: 'Success! Payment method added.',
      });
    }
    if (
      !addPaymentMethodWarning &&
      (warningMessage || warningMessageForAgent) &&
      locationStateAfterAddPaymentMethod?.showAddPaymentMethodNotificationAfterNav
    ) {
      const notificationTitle =
        warningMessage?.title ||
        warningMessageForAgent?.title ||
        '';
      const notificationDescription =
        warningMessage?.description ||
        warningMessageForAgent?.description ||
        '';
      notify({
        severity: 'warning',
        title: notificationTitle,
        message: notificationDescription,
      });
    }
    if (
      locationStateAfterUpdatePaymentMethod.showUpdateNotificationAfterNav
    ) {
      notify({
        severity: 'success',
        title: 'Success! Payment method updated.',
      });
    }
    if (
      locationStateAfterRemovePaymentMethod.showRemovePaymentMethodNotificationAfterNav
    ) {
      notify({
        severity: 'success',
        title: 'Success! Payment method removed.',
      });
    }
  }, [
    locationStateAfterUpdatePaymentMethod.showUpdateNotificationAfterNav,
    locationStateAfterAddPaymentMethod.showAddPaymentMethodNotificationAfterNav,
    locationStateAfterRemovePaymentMethod.showRemovePaymentMethodNotificationAfterNav,
    originalCheckoutSessionResponse,
  ]);

  if (isLoading) {
    return null;
  }

  return (
    <Box id={CONTAINER_IDS.WALLET_MANAGEMENT_CONTAINER}>
      {showActionsOnForm &&
      showBackButton &&
      backButtonHandler ? (
        <BackButton
          onClick={backButtonHandler}
          testId={ELEMENT_IDS.WALLET_MANAGEMENT_BACK_BUTTON}
        />
      ) : null}

      <PageTitle
        title={title}
        titleId={titleTestId}
        subTitleId={TITLE_IDS.WALLET_MANAGEMENT_SUBTITLE}
        subTitle="Add, edit, or remove a payment method."
      />

      <ContentSpacer bottom={24} />

      <PaymentMethodList
        paymentMethodsList={formattedPaymentMethods}
        onPaymentMethodClick={({ selectedPaymentMethod }) => {
          ccgNavigate({
            routes: ['EDIT_PAYMENT_METHOD'],
            options: {
              paymentMethodId: selectedPaymentMethod.id,
            },
          });
        }}
      />

      <ContentSpacer bottom={24} />

      <CallToActionCard
        titleId={TEXT_CONTENT_IDS.CTA_ADD_PAYMENT_METHOD_TITLE}
        buttonId={ELEMENT_IDS.CTA_ADD_PAYMENT_METHOD_BUTTON}
        title="Add a payment method"
        buttonLabel="Add"
        onClick={() => {
          ccgNavigate({ routes: ['ADD_PAYMENT_METHOD'] });
        }}
      />

      {showActionsOnForm &&
      showDoneButton &&
      doneButtonHandler ? (
        <>
          <ContentSpacer bottom={24} />
          <Button
            id={ELEMENT_IDS.WALLET_MANAGEMENT_DONE_BUTTON}
            onClick={doneButtonHandler}
            fullWidth
          >
            Done
          </Button>
        </>
      ) : null}
    </Box>
  );
};

export default WalletManagementEntry;