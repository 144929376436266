import { useContext } from 'react';

import { DrawerContext } from './DrawerContext';

const useDrawerContextProvider = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      'useDrawerContext must be used within an DrawerContext.Provider',
    );
  }

  return context;
};

export default useDrawerContextProvider;
