import React from 'react';

import PaymentApi from '../../services/payment/PaymentApi';

import { Checkout } from './Checkout';
import usePaymentWithWalletComponentData from './hooks/usePaymentWithWalletComponentData';

const PaymentWithWalletRouteContainer = () => {
  const { componentDataReady, formattedPaymentMethods } =
    usePaymentWithWalletComponentData();

  return componentDataReady ? (
    <Checkout
      paymentApi={PaymentApi}
      formattedPaymentMethods={formattedPaymentMethods}
    />
  ) : null;
};

export default PaymentWithWalletRouteContainer;
