import React from 'react';

import noop from '../../utils/function/noop';

import type { NotificationContextType } from './NotificationContextType';

export const NotificationContext =
  React.createContext<NotificationContextType>({
    title: '',
    message: '',
    isShown: false,
    closeable: true,
    notify: noop,
    closeNotification: noop,
  });
