import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../services/customer/types';

const findFormattedPaymentMethod = (
  formattedPaymentMethods: FormattedPaymentMethod<PaymentMethodType>[],
  paymentMethodId: string | null,
) => {
  return paymentMethodId
    ? formattedPaymentMethods.find(
        (paymentMethod) =>
          paymentMethod.paymentMethodId === paymentMethodId,
      ) || null
    : null;
};

export default findFormattedPaymentMethod;
