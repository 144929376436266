import { useEffect, useState, useContext } from 'react';

import useEnvironmentContextProvider from '../../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { AppContext } from '../../../capabilities/contextStore/AppContext';

const useContainerCloseHandler = () => {
  const { showWidgetContainer } =
    useEnvironmentContextProvider();
  const { initializeSessionError, initializeSessionException } =
    useContext(AppContext);

  const [containerVisible, setContainerVisible] = useState<
    boolean | undefined
  >(showWidgetContainer);

  const [hasSessionError, setHasSessionError] = useState<
    boolean | undefined
  >(false);

  const shouldCloseContainer =
    (containerVisible === true &&
      showWidgetContainer === false) ||
    hasSessionError === true;

  useEffect(() => {
    if (initializeSessionError || initializeSessionException) {
      setHasSessionError(true);
    }
  }, [initializeSessionError, initializeSessionException]);

  useEffect(() => {
    if (showWidgetContainer) {
      setContainerVisible(showWidgetContainer);
    }
  }, [showWidgetContainer]);

  return {
    shouldCloseContainer,
  };
};

export default useContainerCloseHandler;
