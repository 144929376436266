import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { getFilteredPaymentTypes } from '../../getFilteredPaymentTypes';

const hasMultiplePaymentMethodTypes = (
  sessionsDetails: CheckoutSessionsResponse,
) => {
  return (
    getFilteredPaymentTypes({ session: sessionsDetails })
      .length > 1
  );
};

export default hasMultiplePaymentMethodTypes;
