const luhnCheck = (cardNumber: string) => {
  let sum = 0;
  let shouldDouble = false;

  // eslint-disable-next-line no-plusplus
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (shouldDouble) {
      // eslint-disable-next-line no-cond-assign
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
};

const fieldHasCardNumber = (value: string) => {
  const parsedValue = value.replace(/\s/g, '');
  let result = false;

  const regex = /^[0-9]{13,19}$/;
  if (!regex.test(parsedValue)) {
    result = false;
  } else {
    result = luhnCheck(parsedValue);
  }

  return result;
};

export default fieldHasCardNumber;
