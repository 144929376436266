import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { SuccessCallbackArgs } from '../../types';

import { getSuccessCodeAndDescription } from './get-success-code-and-description';
import { getData } from './get-data';
import { getPayload } from './get-payload';

const transformSuccessObject = ({
  checkoutSessionResponse,
}: {
  checkoutSessionResponse: CheckoutSessionsResponse;
}): SuccessCallbackArgs => {
  return {
    ...getSuccessCodeAndDescription(checkoutSessionResponse),
    payload: getPayload(checkoutSessionResponse),
    data: getData(checkoutSessionResponse),
  };
};

export default transformSuccessObject;
