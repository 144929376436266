import type { CheckoutSessionStatus } from '../../../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { CheckoutSessionStatuses } from '../../../../../../services/commonCheckout/types/CheckoutSessionsResponse';

/**
 * TODO: Remove this once we are validating all server responses with zod:
 */
export const validateCheckoutSessionStatus = (
  checkoutSessionStatus?: CheckoutSessionStatus,
) => {
  if (
    checkoutSessionStatus &&
    !CheckoutSessionStatuses.includes(checkoutSessionStatus)
  ) {
    reportError(
      new Error(
        `unrecognized checkoutSessionStatus: ${checkoutSessionStatus}`,
      ),
    );
  }
};
