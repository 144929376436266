import type { ContainerConfig } from '../../../components/container-types/types';

const getLoaderDimension = (
  containerConfig?: ContainerConfig,
) => {
  let styles: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    width?: string;
  } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  if (containerConfig && containerConfig.type === 'inline') {
    styles = {
      ...styles,
      left: -6,
      width: 'calc(100% + 12px)',
    };
  }

  return styles;
};

export default getLoaderDimension;
