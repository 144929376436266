import type { ConfigModes } from '../../../services/commonCheckout/types/SessionConfig/Modes';
import type CcgNavigateFunc from '../../../shared/types/routing/CcgNavigateFunc';

const goBackToPrimaryContainer = (
  mode: ConfigModes,
  ccgNavigate: CcgNavigateFunc,
) => {
  ccgNavigate({
    routes: [mode],
  });
};

export default goBackToPrimaryContainer;
