import type { CreateCSSProperties } from '@mui/styles';

import type { PaletteColors } from '../types';

export const transformPrimaryButtonWithTheme: (
  paletteColors: PaletteColors,
) => CreateCSSProperties = (paletteColors) => {
  return {
    color: paletteColors.contrastText,
    backgroundColor: paletteColors.main,
    '&:hover': {
      backgroundColor: paletteColors.light,
    },
    '&:active': {
      color: paletteColors.light,
      backgroundColor: '#FBF9F4',
    },
  };
};

export const transformSecondaryButtonWithTheme: (
  paletteColors: PaletteColors,
) => CreateCSSProperties = (paletteColors) => {
  return {
    color: paletteColors.main,
    '&:hover': {
      color: paletteColors.light,
    },
    '&:active': {
      color: '#FBF9F4',
      backgroundColor: paletteColors.light,
    },
    /** @note: did not include as part of CC-8184
     *  because of assumption that it handles both Global Mui Css
     *  with the prefix and without prefix since both
     *  seems to target the same focusVisible state.
     * */
    '&.Mui-focusVisible, &.ccg-Mui-focusVisible': {
      color: '#FBF9F4',
      backgroundColor: paletteColors.light,
    },
  };
};
