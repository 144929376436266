import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import isDrawerType from '../../utils/container-config/isDrawerType';
import isSecondaryTypeDrawer from '../../utils/container-config/isSecondaryTypeDrawer';

const usePopulateFormConfigForDrawer = () => {
  const { containerConfig } = useEnvironmentContextProvider();

  return (
    isDrawerType(containerConfig) ||
    (containerConfig && isSecondaryTypeDrawer(containerConfig))
  );
};

export default usePopulateFormConfigForDrawer;
