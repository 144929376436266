import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';

import formatPhoneNumber from '../../../utils/capabilities/formatPhoneNumber';
import LoaderWithText from '../../../ui/LoaderWithText/LoaderWithText';
import type { PaymentMethodChannelType } from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import { TEXT_CONTENT_IDS } from '../../../utils/element-ids';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

type AwaitingUserResponseProps = {
  destination: string;
  status: string;
  paymentMethodChannel: PaymentMethodChannelType;
  expiresAtMessage?: string;
  onCancel: () => void;
  description: string;
};

const color = '#323334';

const AwaitingUserResponse = ({
  destination,
  status,
  paymentMethodChannel = 'TEXT',
  expiresAtMessage = '',
  onCancel,
  description,
}: AwaitingUserResponseProps) => {
  // NOTE: any major / global layout changes will probably need changes here
  // NOTE: position absolute is causing the close button in embedded experience
  // to become unclickable

  const { showActionsOnForm } = useFormConfigManager({
    // we don't want to move the heading to the drawer's header
    title: '',
    titleTestId: '',
    secondaryActionConfig: {
      testId:
        TEXT_CONTENT_IDS.AWAITING_PAYMENT_METHOD_CANCEL_BUTTON,
      label: 'Cancel',
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        marginTop: '35%',
        paddingRight: '24px',
        paddingLeft: '24px',
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          gap: '32px',
        }}
      >
        <LoaderWithText description={description} />

        <Grid
          container
          direction="column"
          alignItems="center"
          item
          style={{ gap: '8px' }}
        >
          <Typography
            variant="h6"
            component="h2"
            data-testid={
              TEXT_CONTENT_IDS.AWAITING_PAYMENT_METHOD_DESTINATION
            }
          >
            {paymentMethodChannel === 'TEXT'
              ? formatPhoneNumber(destination)
              : destination}
          </Typography>

          <Typography
            style={{
              fontSize: '18px',
              lineHeight: '32px',
              color,
            }}
            data-testid={
              TEXT_CONTENT_IDS.AWAITING_PAYMENT_METHOD_STATUS
            }
          >
            {status}
          </Typography>

          {expiresAtMessage ? (
            <Typography
              style={{
                fontSize: '18px',
                lineHeight: '32px',
                color,
              }}
              data-testid={
                TEXT_CONTENT_IDS.AWAITING_PAYMENT_METHOD_EXPIRATION_COUNTDOWN
              }
            >
              {expiresAtMessage}
            </Typography>
          ) : null}
        </Grid>

        {showActionsOnForm ? (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onCancel}
            data-testid={
              TEXT_CONTENT_IDS.AWAITING_PAYMENT_METHOD_CANCEL_BUTTON
            }
          >
            Cancel
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AwaitingUserResponse;
