import React from 'react';

import ErrorCard from '../../../../components/ErrorCard/ErrorCard';
import BackButton from '../../../../ui/BackButton/BackButton';
import { TEXT_CONTENT_IDS } from '../../../../utils/element-ids';
import useFormConfigManager from '../../../../hooks/useFormConfigManager/useFormConfigManager';

const AddPaymentMethodEntryErrorHandler = ({
  onBackClick,
}: {
  onBackClick?: () => void;
}) => {
  const { showActionsOnForm } = useFormConfigManager({
    title: '',
    titleTestId: '',
    processFooterConfig: false,
    backActionConfig: {
      label: 'Back',
      handler: onBackClick,
      testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON,
    },
  });

  return (
    <>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON
          }
        />
      ) : null}
      <ErrorCard />
    </>
  );
};

export default AddPaymentMethodEntryErrorHandler;
