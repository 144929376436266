import React from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

import type TabsProps from './types';

const StyledMuiTabs = styled(MuiTabs)({
  margin: 0,
  // The negative margin is to compensate for the outline offset of the tab cards
  marginLeft: '-2px',
  marginRight: '-2px',
  '.ccg-MuiTabs-flexContainer': {
    gap: 16,
  },
  '.ccg-MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledMuiTab = styled(Tab)({
  alignItems: 'start',
  padding: '13px 16px',
  border: '1px solid #B1B2B4',
  borderRadius: '8px',
  color: '#0C55B8',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  '&.ccg-MuiTab-root:hover': {
    background: '#FAFAFA',
  },
  '&.ccg-MuiTab-root.Mui-selected': {
    color: '#002677',
    border: '3px solid #002677',
  },
});

const StyledIcon = styled('div')({
  width: '24px',
  height: '24px',
  margin: 0,
  '& .ccg-MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
  },
});

const Tabs = ({
  tabsConfig,
  onTabSelectionChange,
  selectedIndex,
}: TabsProps) => {
  const handleOnChange = (
    _: React.SyntheticEvent,
    newSelectedIndex: number,
  ) => {
    onTabSelectionChange(newSelectedIndex);
  };

  return (
    <StyledMuiTabs
      value={selectedIndex}
      onChange={handleOnChange}
    >
      {tabsConfig.map((tabConfig) => (
        <StyledMuiTab
          key={tabConfig.id}
          id={tabConfig.id}
          data-testid={tabConfig.id}
          label={tabConfig.label}
          icon={<StyledIcon>{tabConfig.icon}</StyledIcon>}
        />
      ))}
    </StyledMuiTabs>
  );
};

export default Tabs;
