import { FormControlLabel, styled } from '@mui/material';

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    width: '100%',
    padding: '13px 16px',
    minHeight: '74px',
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: 0,
    boxSizing: 'border-box',
    borderRadius: '8px',
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '&:active': {
      backgroundColor: '#83C8FF1A',
    },
  }),
);

export default StyledFormControlLabel;
