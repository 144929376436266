import React, { useEffect } from 'react';

import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { HttpErrorResponse } from '../../services/commonCheckout/types/HttpErrorResponse';
import {
  getConfigMode,
  getWarning,
} from '../../utils/session/selectors';
import ErrorCard from '../ErrorCard/ErrorCard';
import SuccessCard from '../success-card/SuccessCard';
import WarningCard from '../WarningCard/WarningCard';

import { deriveFailureConfigFromHttpError } from './deriveFailureConfigFromHttpError';
import { getFinalUiConfig } from './getFinalUiConfig';
import { mapConfigModeToFallbackErrorScreen } from './mapConfigModeToFallbackSessionOutcome';

export const SessionOutcomeUi = ({
  finalCallback,
  basicSessionStatus,
  originalCheckoutSessionResponse,
  errorResponse,
  exception,
}: {
  finalCallback: (() => void) | undefined;
  basicSessionStatus: 'failure' | 'success';
  originalCheckoutSessionResponse: CheckoutSessionsResponse;
  errorResponse: HttpErrorResponse | undefined;
  exception: Error | undefined;
}): JSX.Element => {
  useEffect(() => {
    if (!finalCallback) return;

    const BASELINE_DELAY = 50;
    const localStorageDelay =
      localStorage.getItem('SessionOutcome-callback-delay-ms') ||
      '0';
    const delay =
      parseInt(localStorageDelay, 10) + BASELINE_DELAY;
    const timer = setTimeout(finalCallback, delay);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, [finalCallback]);

  const currentMode = getConfigMode(
    originalCheckoutSessionResponse,
  );

  if (currentMode === 'PAYMENT_METHOD_DISPLAY') {
    throw new Error(
      `Session Outcome UI is not for ${currentMode} mode.`,
    );
  }

  if (basicSessionStatus === 'failure') {
    const finalFailureConfig = getFinalUiConfig({
      explicitServerConfig:
        deriveFailureConfigFromHttpError(errorResponse),
      modeBasedFallbackConfig:
        mapConfigModeToFallbackErrorScreen[currentMode]?.failure,
    });
    if (!finalFailureConfig && exception) {
      // eslint-disable-next-line no-console
      console.debug('polling exception:', exception);
    }
    return <ErrorCard {...finalFailureConfig} />;
  }

  const warningObject = getWarning(
    originalCheckoutSessionResponse,
  );

  if (warningObject) {
    return <WarningCard {...warningObject} />;
  }

  const finalSuccessConfig = getFinalUiConfig({
    explicitServerConfig: undefined, // We don't currently have an api contract for this
    modeBasedFallbackConfig:
      mapConfigModeToFallbackErrorScreen[currentMode]?.success,
  });

  if (!finalSuccessConfig) {
    console.warn(
      'SessionOutcome has no explicit success message to show, will rely on <SuccessCard defaults',
    );
  }
  return <SuccessCard {...finalSuccessConfig} />;
};
