import { styled } from '@mui/system';
import { FormControlLabel, Radio } from '@mui/material';

export const FormControlLabelRoot = styled(FormControlLabel)(
  ({ theme }) => ({
    width: '100%',
    padding: '13px 16px',
    minHeight: '74px',
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: 0,
    boxSizing: 'border-box',
    borderRadius: '8px',
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '&:active': {
      backgroundColor: '#83C8FF1A',
    },
  }),
);

export const FormControlLabelLabel = styled('span')({
  flex: 1,
  maxWidth: '100%',
});

export const RadioRoot = styled(Radio)(({ theme }) => ({
  marginRight: '12px',
  color: theme.palette.action.active,
  '&.ccg-Mui-checked': {
    color: '#316BBE',
  },
}));
