import type RouteKeys from '../../../shared/types/routing/RouteKeys';
import type RoutePathsWithTemplate from '../../../shared/types/routing/RoutePathsWithTemplate';

const PrimaryRoutePathsMap: Record<
  RouteKeys,
  RoutePathsWithTemplate
> = {
  PAYMENT_WITH_WALLET: 'payment-with-wallet',
  WALLET: 'wallet-management',
  PAYMENT: 'one-time-payment',
  PAYMENT_METHOD_ENTRY: 'add-payment-method-v2',
  PAYMENT_METHOD_SELECT: 'payment-method-selector',
  ADD_PAYMENT_METHOD: 'wallet-management/add-payment-method',
  EDIT_PAYMENT_METHOD: `wallet-management/edit-payment-method/:paymentMethodId`,
  REMOVE_PAYMENT_METHOD:
    'wallet-management/remove-payment-method/:paymentMethodId',
  PAYMENT_METHOD_DISPLAY: 'payment-method-display',
} as const;

export default PrimaryRoutePathsMap;
