import { useState } from 'react';

import type { getThemeArgs } from '../../style/external-theme/types';

import type { CCGThemeProviderContextType } from './CCGThemeProviderContextType';

export const useCCGThemeProviderContext = ({
  appearance,
}: {
  appearance?: getThemeArgs['appearance'];
} = {}) => {
  const [ccgThemeProviderContext, setCcgThemeProviderContext] =
    useState<CCGThemeProviderContextType>({
      appearance,
      setAppearance: (appearanceProp) => {
        setCcgThemeProviderContext((state) => {
          return {
            ...state,
            appearance: appearanceProp,
          };
        });
      },
    });

  return ccgThemeProviderContext;
};
