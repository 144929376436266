import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';

import getChannelsByType from './getChannelsByType';

export const getExpressCheckoutMerchantConfig = (
  session: CheckoutSessionsResponse,
) => {
  const channelsAvailable = getChannelsByType({
    session,
    paymentMethodType: 'CARD',
  }).map((channel) => channel.type);

  return {
    isGooglePayButtonEnabled:
      channelsAvailable.includes('GOOGLE_PAY') || false,
    isApplePayButtonEnabled:
      channelsAvailable.includes('APPLE_PAY') || false,
  };
};
