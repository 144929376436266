import React, { useCallback, useContext, useState } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Grid, Typography } from '@mui/material';

import BackButton from '../../../../../ui/BackButton/BackButton';
import PageTitle from '../../../../../ui/PageTitle/PageTitle';
import type SendEmailFormData from '../../../types/SendEmailFormData';
import type SendEmailFormErrors from '../../../types/SendEmailFormErrors';
import validateSendEmailForm from '../../../../../utils/capabilities/validateSendEmailForm';
import { NotificationContext } from '../../../../contextStore/NotificationContext';
import FormField from '../../../../../ui/FormField/FormField';
import type {
  ChildSessionModes,
  ChildSessionPayload,
} from '../../../../../services/session/types';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../../../utils/element-ids';
import Button from '../../../../../ui/Button/Button';
import type { UserJourney } from '../../../../../shared/types/UserJourney';
import getSendEmailFormHeading from '../../../utils/getSendEmailFormHeading';
import useFormConfigManager from '../../../../../hooks/useFormConfigManager/useFormConfigManager';
import Dropdown from '../../../../../ui/Dropdown/Dropdown';

export type SendEmailFormProps = {
  emailAddress?: string;
  onBackClick?: () => void;
  onCancel: () => void;
  onSubmit: (
    formData: ChildSessionPayload<
      'EMAIL_TO_ADD' | 'EMAIL_TO_PAY'
    >,
  ) => void;
  childSessionMode: Extract<
    ChildSessionModes,
    'EMAIL_TO_ADD' | 'EMAIL_TO_PAY'
  >;
  userJourney: UserJourney;
};

const SendEmailForm = ({
  emailAddress = '',
  onBackClick,
  onCancel,
  onSubmit,
  childSessionMode = 'EMAIL_TO_ADD',
  userJourney,
}: SendEmailFormProps) => {
  const { notify, closeNotification } = useContext(
    NotificationContext,
  );
  const [formData, setFormData] = useState<SendEmailFormData>({
    emailAddress,
  });
  const [formErrors, setFormErrors] =
    useState<SendEmailFormErrors>({});

  const [language, setLanguage] = useState<string>('en');

  const handleOnSubmit = useCallback(() => {
    const errors = validateSendEmailForm(formData);
    setFormErrors(errors);

    closeNotification();

    if (Object.keys(errors).length) {
      notify({
        title: 'You have fields that need to be corrected.',
        message: 'See errors below.',
        severity: 'error',
      });
      return;
    }

    onSubmit({
      lang: language,
      childSessionMode,
      customer: {
        email: formData.emailAddress,
      },
    });
  }, [formData, language]);

  const handleOnChange = (val: string) => {
    if (formErrors) {
      closeNotification();
      setFormErrors({ emailAddress: '' });
    }
    setFormData((prevState) => ({
      ...prevState,
      emailAddress: val,
    }));
  };

  const handleOnBlur = () => {
    const emailError = validateSendEmailForm(formData);
    setFormErrors(emailError);
  };

  const { title, subTitle } =
    getSendEmailFormHeading(userJourney);

  const onLanguageChange = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
  };

  const { title: titleFromConfigManager, showActionsOnForm } =
    useFormConfigManager({
      title,
      titleTestId: TITLE_IDS.SEND_EMAIL_FORM_TITLE,
      backActionConfig: {
        testId: TEXT_CONTENT_IDS.SEND_TEXT_FORM_BACK_BUTTON,
        handler: onBackClick,
      },
      primaryActionConfig: {
        label: 'Send email',
        handler: handleOnSubmit,
        testId: TEXT_CONTENT_IDS.SEND_EMAIL_FORM_SUBMIT,
      },
      secondaryActionConfig: {
        label: 'Cancel',
        handler: useCallback(() => {
          onCancel();
        }, []),
        testId: TEXT_CONTENT_IDS.SEND_EMAIL_FORM_CANCEL,
      },
    });

  return (
    <Grid
      container
      direction="column"
    >
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={TEXT_CONTENT_IDS.SEND_EMAIL_FORM_BACK_BUTTON}
        />
      ) : null}

      <PageTitle
        title={titleFromConfigManager}
        subTitle={subTitle}
        titleId={TITLE_IDS.SEND_EMAIL_FORM_TITLE}
        subTitleId={TITLE_IDS.SEND_EMAIL_FORM_SUBTITLE}
      />
      <Grid
        container
        direction="column"
        style={{ gap: '24px' }}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '24px',
          }}
          data-testid={
            TEXT_CONTENT_IDS.SEND_EMAIL_CUSTOMER_INFORMATION_TITLE
          }
        >
          <b>Customer information</b>
        </Typography>

        <Typography
          variant="body2"
          data-testid={
            TEXT_CONTENT_IDS.SEND_EMAIL_FIELDS_REQUIRED_SUBTITLE
          }
        >
          All fields are required unless marked optional.
        </Typography>

        <FormField
          id="email"
          label="Email address"
          value={formData.emailAddress}
          errorMessage={formErrors.emailAddress}
          onChange={(value) => {
            handleOnChange(value);
          }}
          onBlur={handleOnBlur}
        />

        <Dropdown
          value={language}
          onChange={onLanguageChange}
          testid="sendEmailForm"
          isUsedByChildSession
        />

        <Typography
          data-testid={TEXT_CONTENT_IDS.SEND_EMAIL_FORM_NOTE}
        >
          <b>Note:</b> Link expires after 15 minutes.
        </Typography>

        {showActionsOnForm ? (
          <>
            <Button
              isLoading={false}
              disabled={false}
              fullWidth
              color="primary"
              variant="contained"
              className="loadingButton"
              onClick={handleOnSubmit}
              testId={TEXT_CONTENT_IDS.SEND_EMAIL_FORM_SUBMIT}
            >
              Send email
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={onCancel}
              testId={TEXT_CONTENT_IDS.SEND_EMAIL_FORM_CANCEL}
            >
              Cancel
            </Button>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default SendEmailForm;
