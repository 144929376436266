import type {
  PaymentMethod,
  PaymentMethodType,
} from '../types/PaymentMethod';

import PaymentMethodTypes from './PaymentMethodTypes';

export function isBankAccountPaymentMethod(
  paymentMethod: PaymentMethod<PaymentMethodType>,
): paymentMethod is PaymentMethod<'BANK_ACCOUNT'> {
  return (
    paymentMethod.paymentMethodDetails.type ===
    PaymentMethodTypes.BANK_ACCOUNT
  );
}
