import { createContext } from 'react';

import noop from '../../../utils/function/noop';

export type CancelCallbackContextProps = {
  onCancel: () => void;
};

const CancelCallbackContext =
  createContext<CancelCallbackContextProps>({
    onCancel: noop,
  });

export default CancelCallbackContext;
