import React, { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';

import type CustomerApi from '../../../services/customer/CustomerApi';
import type {
  BankAccountType,
  PaymentMethod,
} from '../../../services/commonCheckout/types/PaymentMethod';
import noop from '../../../utils/function/noop';
import type { PaymentMethodType } from '../../../services/customer/types';
import { NotificationContext } from '../../contextStore/NotificationContext';
import { AppContext } from '../../contextStore/AppContext';
import { getAgent } from '../../../utils/session/selectors';

import EditPaymentMethodAchForm from './EditPaymentMethodAchForm';
import type {
  EditPaymentMethodAchFormData,
  EditPaymentMethodAchFormProps,
} from './EditPaymentMethodAchForm';

export type EditPaymentMethodAchContainerProps = {
  customerApi: typeof CustomerApi;
  paymentMethod: PaymentMethod<'BANK_ACCOUNT'>;
  onUpdateSuccess?: ({
    paymentMethodId,
    isDefault,
  }: {
    paymentMethodId: string;
    isDefault: boolean;
  }) => void;
  onUpdateFailure?: () => void;
  onBackClick?: () => void;
  onRemoveClick?: () => void;
  onCancelClick: () => void;
  showBackButton?: boolean;
};

const EditPaymentMethodAchContainer: FC<
  EditPaymentMethodAchContainerProps
> = ({
  paymentMethod,
  customerApi,
  onUpdateSuccess = noop,
  onUpdateFailure = noop,
  onBackClick = noop,
  onRemoveClick,
  onCancelClick,
  showBackButton,
}) => {
  const { notify } = useContext(NotificationContext);
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);

  const agent = getAgent(originalCheckoutSessionResponse);
  const [isLoading, setIsLoading] = useState(false);

  const updatePaymentMethod = async ({
    nickname,
    isDefault,
    accountType,
    nameOnAccount,
  }: EditPaymentMethodAchFormData) => {
    setIsLoading(true);

    const paymentMethodAchDetails: {
      type: Extract<PaymentMethodType, 'BANK_ACCOUNT'>;
      accountType: BankAccountType;
      nameOnAccount: string;
    } = {
      type: 'BANK_ACCOUNT',
      accountType,
      nameOnAccount,
    };

    const result = await customerApi
      .updatePaymentMethod(
        {
          customerId: paymentMethod.customerId,
          paymentMethodId: paymentMethod.id,
        },
        {
          isDefault,
          nickname,
          paymentMethodDetails: paymentMethodAchDetails,
          agent,
        },
      )
      .finally(() => {
        setIsLoading(false);
      });

    if (
      !result?.errors ||
      (result.errors && result.errors.length === 0)
    ) {
      onUpdateSuccess({
        paymentMethodId: paymentMethod.id,
        isDefault,
      });
    } else {
      onUpdateFailure();
    }
  };

  const handleOnSubmit:
    | EditPaymentMethodAchFormProps['onSubmit'] = (formData) => {
    updatePaymentMethod(formData).catch((error) => {
      console.error(
        'Error while updating payment method: ',
        error,
      );
    });
  };

  useEffect(() => {
    if (paymentMethod.status === 'INVALIDATED') {
      notify({
        title: 'This payment method is no longer valid.',
        message:
          'If payment method details have changed, please remove this payment method and add a new one.',
        severity: 'warning',
      });
    }
  }, []);

  return (
    <EditPaymentMethodAchForm
      nickname={paymentMethod.nickname}
      isDefault={paymentMethod.default}
      isLoading={isLoading}
      onSubmit={handleOnSubmit}
      onBackClick={onBackClick}
      onRemoveClick={onRemoveClick}
      accountType={
        paymentMethod.paymentMethodDetails.accountType
      }
      routingNumber={
        paymentMethod.paymentMethodDetails.routingNumber
      }
      last4={paymentMethod.paymentMethodDetails.last4}
      nameOnAccount={
        paymentMethod.paymentMethodDetails.nameOnAccount
      }
      bankName={paymentMethod.paymentMethodDetails.bankName}
      paymentMethodStatus={paymentMethod.status}
      onCancelClick={onCancelClick}
      showBackButton={showBackButton}
    />
  );
};

export default EditPaymentMethodAchContainer;
