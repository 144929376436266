import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import type { FC } from 'react';

import FormField from '../../../ui/FormField/FormField';
import BackButton from '../../../ui/BackButton/BackButton';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import RadioGroup from '../../../ui/RadioGroup/RadioGroup';
import {
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import { PAYMENT_METHOD_SETTINGS_TITLE } from '../../pay-and-store/constants';
import Button from '../../../ui/Button/Button';
import { CHECKING, SAVINGS } from '../../../shared/strings';
import GenericAchBankIcon from '../../../icons/ach/generic_ach_bank';
import { isInvalidNickname } from '../../../utils/function/nicknameUtils';
import type {
  BankAccountType,
  PaymentMethodStatus,
} from '../../../services/commonCheckout/types/PaymentMethod';
import CallToActionCard from '../../../components/CallToActionCard/CallToActionCard';
import ContentSpacer from '../../../ui/ContentSpacer/ContentSpacer';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';
import PageTitle from '../../../ui/PageTitle/PageTitle';

export type EditPaymentMethodAchFormData = {
  accountType: BankAccountType;
  nameOnAccount: string;
  routingNumber: string;
  last4: string;
  nickname?: string;
  isDefault: boolean;
  bankName: string;
};

export type EditPaymentMethodAchFormProps = {
  isLoading: boolean;
  paymentMethodStatus: PaymentMethodStatus;
  onSubmit: (formData: EditPaymentMethodAchFormData) => void;
  onBackClick: () => void;
  onRemoveClick?: () => void;
  onCancelClick: () => void;
  showBackButton?: boolean;
} & EditPaymentMethodAchFormData;

export type EditPaymentMethodAchFormErrors = Partial<
  Record<keyof EditPaymentMethodAchFormData, string>
>;

export type EditPaymentMethodAchFormKeys =
  keyof EditPaymentMethodAchFormData;

export type EditPaymentMethodAchFormValueTypes =
  EditPaymentMethodAchFormData[keyof EditPaymentMethodAchFormData];

const formLabelMap: Record<
  keyof EditPaymentMethodAchFormData,
  string
> = {
  nameOnAccount: 'Name on account',
  accountType: '',
  last4: '',
  routingNumber: '',
  isDefault: 'Set as default payment method',
  nickname: 'Bank account nickname',
  bankName: '',
};
const validateBankAccount = ({
  nameOnAccount,
  nickname,
}: EditPaymentMethodAchFormData): EditPaymentMethodAchFormErrors => {
  const formErrors: Partial<EditPaymentMethodAchFormErrors> = {};
  if (!nameOnAccount) {
    formErrors.nameOnAccount = `${formLabelMap.nameOnAccount} is required.`;
  }
  if (isInvalidNickname(nickname)) {
    formErrors.nickname = `Enter a valid bank account nickname.`;
  }
  return formErrors;
};

const EditPaymentMethodAchForm: FC<
  EditPaymentMethodAchFormProps
> = ({
  accountType,
  nickname,
  routingNumber,
  last4,
  nameOnAccount,
  isDefault,
  isLoading,
  paymentMethodStatus,
  onSubmit,
  bankName,
  onBackClick,
  onRemoveClick,
  onCancelClick,
  showBackButton = true,
}) => {
  const [formData, setFormData] =
    useState<EditPaymentMethodAchFormData>({
      accountType,
      nickname,
      routingNumber,
      last4: `••••••••${last4}`,
      nameOnAccount,
      isDefault,
      bankName,
    });
  const primaryButtonText = 'Save';
  const secondaryButtonText = 'Cancel';

  const [formErrors, setFormErrors] =
    useState<EditPaymentMethodAchFormErrors>({});

  const setFormFields = (
    fields: Partial<EditPaymentMethodAchFormData>,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      ...fields,
    }));
  };
  const handleOnChange = (
    key: EditPaymentMethodAchFormKeys,
    value: EditPaymentMethodAchFormValueTypes,
  ): void => {
    const transformedValue = value;
    setFormFields({ [key]: transformedValue });
  };

  const handleOnBlur = (
    key: EditPaymentMethodAchFormKeys,
    value: EditPaymentMethodAchFormValueTypes,
  ): void => {
    const newFormValues = { ...formData, [key]: value };
    const validationResult = validateBankAccount(newFormValues);
    setFormErrors(validationResult);
    setFormFields(newFormValues);
  };

  const handleOnSubmit = useCallback(() => {
    const validationResult = validateBankAccount(formData);
    setFormErrors(validationResult);

    if (Object.keys(formErrors).length) {
      return;
    }

    onSubmit({ ...formData });
  }, [formData]);

  useEffect(() => {
    const validationResult = validateBankAccount(formData);
    setFormErrors(validationResult);
  }, []);

  const { title, showActionsOnForm } = useFormConfigManager({
    title: 'Edit bank account',
    titleTestId: TITLE_IDS.EDIT_PAYMENT_METHOD_FORM_TITLE,
    backActionConfig: {
      label: 'Back',
      handler: onBackClick,
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_METHOD_BACK_BUTTON,
    },
    showBackButton,
    primaryActionConfig: {
      label: 'Save',
      handler: handleOnSubmit,
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_SAVE_BUTTON,
    },
    secondaryActionConfig: {
      label: 'Cancel',
      handler: useCallback(() => {
        onCancelClick();
      }, []),
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_CANCEL_BUTTON,
    },
  });

  return (
    <>
      <Box>
        {showBackButton && showActionsOnForm && (
          <BackButton
            onClick={onBackClick}
            label="Back"
            testId={
              TEXT_CONTENT_IDS.EDIT_PAYMENT_METHOD_BACK_BUTTON
            }
          />
        )}

        <PageTitle
          title={title}
          titleId={TITLE_IDS.EDIT_PAYMENT_METHOD_FORM_TITLE}
        />
        <Box sx={{ my: '24px' }}>
          <Typography
            variant="body2"
            data-testid={
              TEXT_CONTENT_IDS.EDIT_PAYMENT_ALL_FIELDS_REQUIRED_TEXT
            }
          >
            All fields are required unless marked optional.
          </Typography>
        </Box>

        <RadioGroup
          defaultValue={formData.accountType}
          id="accountType"
          label="Account type"
          isUsedByRadioGroup
          disabled={paymentMethodStatus === 'INVALIDATED'}
          options={[
            {
              id: 'checking',
              label: CHECKING,
            },
            { id: 'savings', label: SAVINGS },
          ]}
          onChange={(value) => {
            handleOnChange('accountType', value);
          }}
        />
        <FormField
          id="nameOnAccount"
          label="Name on account"
          placeholder="Jane Smith"
          value={formData.nameOnAccount}
          errorMessage={formErrors.nameOnAccount}
          required
          autocomplete="name"
          disabled={paymentMethodStatus === 'INVALIDATED'}
          onChange={(value) => {
            handleOnChange('nameOnAccount', value);
          }}
          onBlur={(value) => {
            handleOnBlur('nameOnAccount', value);
          }}
        />
        <FormField
          id="routingNumber"
          label="Routing number"
          value={formData.routingNumber}
          disabled
          onChange={(value) => {
            handleOnChange('routingNumber', value);
          }}
          onBlur={(value) => {
            handleOnBlur('routingNumber', value);
          }}
          renderAfterInput={() =>
            bankName ? (
              <GenericAchBankIcon bankName={bankName} />
            ) : null
          }
        />
        <FormField
          id="accountNumber"
          label="Account number"
          value={formData.last4}
          disabled
          onChange={(value) => {
            handleOnChange('last4', value);
          }}
          onBlur={(value) => {
            handleOnBlur('last4', value);
          }}
        />
        <Grid
          container
          direction="column"
          sx={{
            gap: '16px',
            marginTop: '16px',
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography
                style={{ fontWeight: 700 }}
                data-testid={
                  TEXT_CONTENT_IDS.PAYMENT_METHOD_SETTINGS_LABEL
                }
              >
                {PAYMENT_METHOD_SETTINGS_TITLE}
              </Typography>
            </FormLabel>
            <Checkbox
              id="isDefault"
              value={formData.isDefault}
              label="Set as default payment method"
              testId={
                TEXT_CONTENT_IDS.EDIT_PAYMENT_SET_AS_DEFAULT_CHECKBOX
              }
              disabled={paymentMethodStatus === 'INVALIDATED'}
              onChange={(value) => {
                handleOnChange('isDefault', value);
              }}
            />
          </FormControl>
          <FormField
            id="nickname"
            label="Bank account nickname"
            hintText="(Optional)"
            helperText="Bank account nickname must be 30 characters or less and can contain letters and numbers only."
            value={formData.nickname || ''}
            errorMessage={formErrors.nickname}
            maxLength={30}
            disabled={paymentMethodStatus === 'INVALIDATED'}
            onChange={(value) => {
              handleOnChange('nickname', value);
            }}
            onBlur={(value) => {
              handleOnBlur('nickname', value);
            }}
          />
        </Grid>
      </Box>

      {onRemoveClick ? (
        <>
          <ContentSpacer top={24} />

          <CallToActionCard
            title="Remove payment method"
            titleId={
              TEXT_CONTENT_IDS.CTA_REMOVE_PAYMENT_METHOD_TITLE
            }
            description="If you remove this payment method, it will no longer be saved to your wallet."
            descriptionId={
              TEXT_CONTENT_IDS.CTA_REMOVE_PAYMENT_METHOD_DESCRIPTION
            }
            buttonLabel="Remove"
            buttonId={
              ELEMENT_IDS.CTA_REMOVE_PAYMENT_METHOD_BUTTON
            }
            onClick={onRemoveClick}
          />
        </>
      ) : null}
      {showActionsOnForm ? (
        <Grid
          container
          sx={{ marginTop: '40px' }}
          direction="column"
          justifyContent="center"
        >
          <Button
            isLoading={isLoading}
            fullWidth
            color="primary"
            variant="contained"
            className="loadingButton"
            onClick={handleOnSubmit}
            id={TEXT_CONTENT_IDS.EDIT_PAYMENT_SAVE_BUTTON}
          >
            {primaryButtonText}
          </Button>
          <Button
            id={TEXT_CONTENT_IDS.EDIT_PAYMENT_CANCEL_BUTTON}
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onCancelClick}
            style={{ marginTop: '16px' }}
          >
            {secondaryButtonText}
          </Button>
        </Grid>
      ) : null}
    </>
  );
};
export default EditPaymentMethodAchForm;
