import React, { useContext } from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

import { AppContext } from '../../../capabilities/contextStore/AppContext';

const InlineContainer: FC = ({ children }) => {
  const { headerTitleId } = useContext(AppContext);

  return (
    <Box
      position="relative"
      aria-labelledby={headerTitleId}
    >
      {children}
    </Box>
  );
};

export default InlineContainer;
