import { useContext, useEffect, useState } from 'react';

import {
  getChildSessionId,
  getChildSessionStatus,
} from '../../../../utils/session/selectors';
import { AppContext } from '../../../contextStore/AppContext';

/** Monitor the child sessions status, and handle some cases when it's completed */
export const useNotificationDeliveryHandler = ({
  childSessionId,
  onError,
}: {
  childSessionId?: string;
  onError: () => void;
}) => {
  const [hasError, setHasError] = useState(false);
  const [hasNotificationDelivered, setHasNotificationDelivered] =
    useState(false);

  const { originalCheckoutSessionResponse, setData } =
    useContext(AppContext);

  const childSessionStatus = getChildSessionStatus(
    originalCheckoutSessionResponse,
  );
  const hasCompleted =
    childSessionStatus &&
    childSessionStatus !== 'CANCELED' &&
    childSessionStatus !== 'PENDING' &&
    childSessionStatus !== 'NOTIFICATION_CREATED';

  const isNegativeScenario =
    childSessionStatus === 'NOTIFICATION_FAILED' ||
    childSessionStatus === 'FAILED';

  const serverChildSessionId = getChildSessionId(
    originalCheckoutSessionResponse,
  );

  useEffect(() => {
    if (
      !childSessionId ||
      childSessionId !== serverChildSessionId
    ) {
      return;
    }

    if (hasCompleted) {
      if (isNegativeScenario) {
        setHasError(true);
        onError();
      }
      setHasNotificationDelivered(true);
      setData({ overlayLoaderConfig: { show: false } });
    }
  }, [
    hasCompleted,
    isNegativeScenario,
    childSessionId,
    serverChildSessionId,
    setHasError,
    onError,
    setHasNotificationDelivered,
    setData,
  ]);

  return {
    hasError,
    hasNotificationDelivered,
  };
};
