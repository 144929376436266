import React from 'react';
import { Typography, styled } from '@mui/material';

import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';

const StyledHintText = styled(Typography)(({ theme }) => ({
  color: '#6E7072',
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  marginLeft: '6px',
  display: 'inline',
  component: 'span',
}));

export type HintTextProps = {
  id: string;
  hintText?: string;
};

const HintText = ({ id, hintText = '' }: HintTextProps) => {
  return (
    <StyledHintText
      data-testid={`${id}_${FORM_FIELD_SUFFIXES_IDS.HINT_TEXT}`}
    >
      {hintText}
    </StyledHintText>
  );
};

export default HintText;
