import { loadStripe } from '@stripe/stripe-js';

import memoizeAsyncFunction from '../../../utils/function/memoizeAsyncFunction';

const getStripeInstance = async ({
  vendorPlatformKey,
  vendorMerchantId,
}: {
  vendorPlatformKey: string;
  vendorMerchantId?: string;
}) =>
  loadStripe(vendorPlatformKey, {
    locale: 'en',
    stripeAccount: vendorMerchantId,
  });

export default memoizeAsyncFunction(getStripeInstance);
