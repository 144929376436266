import type { PaymentMethod } from '../../../../../../services/commonCheckout/types/PaymentMethod';
import {
  getCardBrand,
  getCardLastFour,
  getExpirationMonth,
  getExpirationYear,
  getIsDefault,
  getNickname,
  getIsManufacturerCard,
  getIsExpired,
  getPaymentMethodId,
} from '../../../../../../services/commonCheckout/utils/paymentMethodSelectors';
import PaymentMethodTypes from '../../../../../../services/commonCheckout/utils/PaymentMethodTypes';
import capitalizeFirstLetter from '../../../../../../utils/function/capitalizeFirstLetter';
import type { FormattedPaymentMethod } from '../../types';

export function formatCardPaymentMethod(
  card: PaymentMethod<'CARD'>,
): FormattedPaymentMethod<'CARD'> {
  const isExpired = getIsExpired(card);
  const isDefault = getIsDefault(card);
  const isManufacturerCard = getIsManufacturerCard(card);
  const cardBrand = getCardBrand(card);
  const nickname = getNickname(card);
  const description =
    nickname || capitalizeFirstLetter(cardBrand);
  return {
    type: PaymentMethodTypes.CARD,
    id: getPaymentMethodId(card),
    paymentMethodId: getPaymentMethodId(card),
    isDefault,
    nickname,
    last4: getCardLastFour(card),
    cardBrand,
    expirationMonth: getExpirationMonth(card),
    expirationYear: getExpirationYear(card),
    isExpired,
    isManufacturerCard,
    description,
    ariaDescription: `${capitalizeFirstLetter(
      cardBrand,
    )} ${description}`,
    isAllowedToSetAsDefault:
      !isDefault &&
      !isExpired &&
      !isDefault &&
      !isManufacturerCard,
    isAllowedToEdit: !isExpired,
  };
}
