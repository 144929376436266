import type ConfirmPaymentMethodRequestParams from '../../shared/types/capabilities/paymentMethodSelector/ConfirmPaymentMethodRequestParams';
import { httpClientService } from '../settingsApi/httpClientService';

const PaymentMethodSelectorApi = {
  confirmPaymentMethod: async ({
    sessionId,
    paymentMethod,
  }: ConfirmPaymentMethodRequestParams) => {
    const client = httpClientService.getHttpClient({
      api: 'WALLET',
    });

    const response = await client.request<boolean>({
      url: `/checkout-sessions/${sessionId}/confirm-payment-method`,
      method: 'PATCH',
      data: { ...paymentMethod },
    });

    return response.data;
  },
};

export default PaymentMethodSelectorApi;
