import { useContext } from 'react';

import EnvironmentContext from './EnvironmentContext';

const useEnvironmentContextProvider = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error(
      'useEnvironmentContextProvider must be used within an EnvironmentContextProvider',
    );
  }

  return context;
};

export default useEnvironmentContextProvider;
