import poll from '../../utils/function/poll';
import { isPollingResponse } from '../commonCheckout/helpers/isPollingResponse';
import { httpClientService } from '../settingsApi/httpClientService';

import type {
  PayAndStoreError,
  PayAndStorePaymentMethodType,
  PayAndStoreRequest,
  PayAndStoreResponse,
  PayWithStoredPaymentMethod,
} from './types';
import isPayAndStoreError from './utils/isPayAndStoreError';
import isPayAndStoreResponse from './utils/isPayAndStoreResponse';

const PaymentApi = {
  payAndStore: async <
    TPaymentType extends PayAndStorePaymentMethodType,
  >(
    params:
      | PayAndStoreRequest<TPaymentType>
      | PayWithStoredPaymentMethod<TPaymentType>,
  ) => {
    const client = httpClientService.getHttpClient({
      api: 'PAYMENT',
    });
    const response = await client
      .request<PayAndStoreResponse>({
        url: '/payments',
        method: 'POST',
        data: params,
      })
      .catch<PayAndStoreError>((error) => {
        return {
          status: error.response.data.status,
          data: error.response.data.detail,
        };
      });

    if (isPayAndStoreError(response)) {
      throw new Error(response.data);
    }

    if (isPollingResponse(response) && response.data) {
      return PaymentApi.verifyPaymentSubmission({
        paymentId: response.data.data.id,
      });
    }
    return response.data;
  },

  getPayAndStoreStatus: async (paymentId: string) => {
    const client = httpClientService.getHttpClient({
      api: 'PAYMENT',
    });
    /**
     * API can respond back either with 'PayAndStoreResponse' or 'string' type for 202 status
     * This is due to validation done on the API layer
     */
    return client.request<PayAndStoreResponse | string>({
      url: `/payments/${paymentId}`,
      method: 'GET',
    });
  },
  verifyPaymentSubmission: async ({
    paymentId,
    isPaymentConfirmed = false,
  }: {
    paymentId: string;
    isPaymentConfirmed?: boolean;
  }) => {
    const polledResponse = await poll({
      fn: async () => PaymentApi.getPayAndStoreStatus(paymentId),
      until: ({ status: httpStatusCode, data: response }) => {
        if (isPaymentConfirmed) {
          return httpStatusCode === 200;
        }

        if (isPayAndStoreResponse(response)) {
          return (
            httpStatusCode === 200 ||
            response?.data?.status === 'AUTH_REQUIRED'
          );
        }
        return httpStatusCode === 200;
      },
    });
    return isPayAndStoreResponse(polledResponse.data)
      ? polledResponse.data
      : undefined;
  },
  confirmPayment: async ({
    paymentId,
  }: {
    paymentId: string;
  }) => {
    const client = httpClientService.getHttpClient({
      api: 'PAYMENT',
    });
    const response = await client
      .request<PayAndStoreResponse>({
        url: `/payments/${paymentId}/confirm`,
        method: 'PATCH',
      })
      .catch<PayAndStoreError>((error) => {
        return {
          status: error.response.data.status,
          data: error.response.data.detail,
        };
      });

    if (isPayAndStoreError(response)) {
      throw new Error(response.data);
    }

    if (isPollingResponse(response) && response.data) {
      return PaymentApi.verifyPaymentSubmission({
        paymentId: response?.data.data.id,
        isPaymentConfirmed: true,
      });
    }
    return response.data;
  },
};

export default PaymentApi;
