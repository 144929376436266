import { getHeaders } from '../../api/apis';
import { X_MERCHANT_ID } from '../../utils/constants';
import poll from '../../utils/function/poll';
import { isPollingResponse } from '../commonCheckout/helpers/isPollingResponse';
import type { CheckoutSessionsResponse } from '../commonCheckout/types/CheckoutSessionsResponse';
import { httpClientService } from '../settingsApi/httpClientService';
import { settingsApi } from '../settingsApi/settingsApi';
import type { ApiPollingResponseData } from '../shared/types';

import type {
  ChildSessionModes,
  ChildSessionPayload,
} from './types';

const SessionApi = {
  createChildSession: async <
    TChildSessionModes extends ChildSessionModes,
  >({
    parentSessionId,
    payload,
  }: {
    parentSessionId: string;
    payload: ChildSessionPayload<TChildSessionModes>;
  }) => {
    const client = httpClientService.getHttpClient({
      api: 'WALLET',
    });

    const response = await client.request<
      ApiPollingResponseData<CheckoutSessionsResponse>
    >({
      url: `/checkout-sessions/${parentSessionId}/child-sessions`,
      method: 'POST',
      data: payload,
    });

    if (!response.data) {
      return Promise.reject(
        new Error(
          'No data was returned for the create child session call.',
        ),
      );
    }

    if (!isPollingResponse(response)) {
      return response.data;
    }

    const pollUrl = response.data.url;

    const polledResponse = await poll({
      fn: async () =>
        SessionApi.createChildSessionStatus(pollUrl),
      until: ({ status }) => status === 200,
    });

    return polledResponse.data;
  },
  createChildSessionStatus: async (url: string) => {
    const client = httpClientService.getHttpClient({
      api: 'WALLET',
    });

    return client.request<
      ApiPollingResponseData<CheckoutSessionsResponse>
    >({
      url,
      method: 'GET',
    });
  },
  cancelSession: async (sessionId: string) => {
    const client = httpClientService.getHttpClient({
      api: 'WALLET',
    });

    return client.request<
      ApiPollingResponseData<CheckoutSessionsResponse>
    >({
      url: `/checkout-sessions/${sessionId}/cancel`,
      method: 'POST',
    });
  },
  cancelSessionUsingBeacon: async (sessionId: string) => {
    if (typeof navigator?.sendBeacon === 'function') {
      const walletBaseUrl = await settingsApi.getWalletUrl();

      // navigator.sendBeacon(
      //   `${walletBaseUrl}/checkout-sessions/${sessionId}/cancel`,
      // );

      /**
       * https://www.w3.org/TR/beacon/: The sendBeacon() method does not provide ability to provide custom request headers and apps that require non-default settings for such requests should use the [FETCH] API with keepalive set to true.
       */

      // TODO: revisit beacon vs fetch+keepalive implementation
      fetch(
        `${walletBaseUrl}/checkout-sessions/${sessionId}/cancel`,
        {
          keepalive: true,
          method: 'POST',
          headers: {
            [X_MERCHANT_ID]: getHeaders()[X_MERCHANT_ID],
          },
        },
      ).catch(() => {
        // do nothing
      });
    }
  },
};

export default SessionApi;
