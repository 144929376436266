import React, { useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';

import type { FontSources } from './external-theme/appearance/types';
import { getFontUrl, loadFonts } from './utils/font-loader';

type FontLoaderProps = {
  fontSources?: FontSources;
  children?: ReactNode;
};
export const FontLoader: FC<FontLoaderProps> = ({
  fontSources,
  children,
}) => {
  const fontUrlsLoaded = useRef<string[]>([]);

  useEffect(() => {
    const fontsToBeLoaded: FontSources = [];

    if (fontSources) {
      fontSources.forEach((fontSource) => {
        const fontUrl = getFontUrl(fontSource);
        if (
          fontUrl &&
          !fontUrlsLoaded.current.includes(fontUrl)
        ) {
          fontUrlsLoaded.current.push(fontUrl);
          fontsToBeLoaded.push(fontSource);
        }
      });
      if (fontsToBeLoaded.length) {
        loadFonts(fontsToBeLoaded);
      }
    }
  }, [fontSources]);

  return <>{children}</>;
};
