import type { PayAndStoreError } from '../types';

export default function isPayAndStoreError(
  obj: unknown,
): obj is PayAndStoreError {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'status' in obj &&
    'data' in obj &&
    typeof obj.data === 'string'
  );
}
