import type { LegacyRef, SVGProps } from 'react';
import React, { forwardRef } from 'react';

type ExternalLinkIconProps = SVGProps<SVGSVGElement> & {
  dataTestId: string;
};

const ExternalLinkIcon = forwardRef(
  (
    { dataTestId, ...restProps }: ExternalLinkIconProps,
    ref: LegacyRef<SVGSVGElement>,
  ) => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        style={{ verticalAlign: 'middle' }}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        data-testid={`${dataTestId}-external-link-icon`}
        aria-labelledby="Opens in a new window"
        role="img"
        {...restProps}
      >
        <g id="Icon">
          <path
            id="Vector"
            d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"
            fill="#0C55B8"
          />
        </g>
      </svg>
    );
  },
);

export default ExternalLinkIcon;
