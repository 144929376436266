import type SendTextFormData from '../../capabilities/add-payment-method/types/SendTextFormData';
import type SendTextFormErrors from '../../capabilities/add-payment-method/types/SendTextFormErrors';

const onlyNumbers = /^\d+$/;
const hasInvalidCharacters = (phoneNumber: string) => {
  return !onlyNumbers.test(phoneNumber);
};

const hasInvalidLength = (phoneNumber: string) => {
  return phoneNumber.length !== 10;
};

const validateSendTextForm = (
  formData: SendTextFormData,
): SendTextFormErrors => {
  const errors: SendTextFormErrors = {};

  const { phoneNumber } = formData;

  if (!phoneNumber) {
    errors.phoneNumber = 'Phone number is required.';
  } else if (
    hasInvalidCharacters(phoneNumber) ||
    hasInvalidLength(phoneNumber)
  ) {
    errors.phoneNumber = 'Please enter a valid phone number.';
  }

  return errors;
};

export default validateSendTextForm;
