import type {
  FontSources,
  CssFontSource,
  CustomFontSource,
} from '../external-theme/appearance/types';

const createStylesheetLink = ({ cssSrc }: CssFontSource) => {
  const fontLink = document.createElement('link');
  fontLink.rel = 'stylesheet';
  fontLink.href = cssSrc;
  return fontLink;
};

const createFontFace = ({
  family = '',
  src,
  style,
  weight,
}: {
  family: string;
  src: string;
  style?: string;
  weight?: string;
}) => {
  if (!family || !src) {
    console.warn('FontFace: missing family or src');
    return undefined;
  }
  return new window.FontFace(family, src, {
    style,
    weight,
  });
};

const defineFontSources = (fontSources: FontSources) => {
  const cssFontDefinition: HTMLLinkElement[] = [];
  const customFontDefintion: FontFace[] = [];
  fontSources.forEach((fontSource) => {
    if ('src' in fontSource && fontSource.src) {
      const fontFace = createFontFace(fontSource);
      if (fontFace) {
        customFontDefintion.push(fontFace);
      }
    } else if ('cssSrc' in fontSource && fontSource.cssSrc) {
      cssFontDefinition.push(
        createStylesheetLink({
          cssSrc: fontSource.cssSrc,
        }),
      );
    }
  });
  return [cssFontDefinition, customFontDefintion] as const;
};

export const getFontUrl = (
  fontSource: CssFontSource | CustomFontSource,
): string | undefined => {
  if ('cssSrc' in fontSource && fontSource.cssSrc) {
    return fontSource.cssSrc;
  }
  if ('src' in fontSource && fontSource.src) {
    return fontSource.src;
  }
  return undefined;
};

const loadCustomFonts = (customFonts: FontFace[]) => {
  customFonts.forEach((customFont: FontFace) => {
    document.fonts.add(customFont);
    customFont.load().catch((err) => {
      console.error(err);
    });
  });
};

const loadCssFonts = (cssFonts: HTMLLinkElement[]) => {
  cssFonts.forEach((cssFont) => {
    document.head.appendChild(cssFont);
  });
};

export const loadFonts = (fontSources: FontSources) => {
  const [cssFontDefinition, customFontDefintion] =
    defineFontSources(fontSources);
  loadCustomFonts(customFontDefintion);
  loadCssFonts(cssFontDefinition);
};
