import type {
  CardStatus,
  PaymentMethod,
} from '../../services/commonCheckout/types/PaymentMethod';
import type { PaymentMethodSessionOutcome } from '../../services/commonCheckout/types/SessionOutcome/PaymentMethodSessionOutcome';
import type { PaymentMethodType } from '../../services/customer/types';

export const transformPaymentMethodToPaymentMethodSessionOutcome =
  (
    paymentMethod: PaymentMethod<PaymentMethodType>,
  ): PaymentMethodSessionOutcome | null => {
    const {
      id,
      status,
      card,
      zipCode,
      default: isDefault,
      nickname,
      warning,
    } = paymentMethod;

    let cardStatus: CardStatus | undefined;

    if (paymentMethod.paymentMethodType === 'CARD') {
      const narrowedCardPaymentMethod =
        paymentMethod as PaymentMethod<'CARD'>;

      const {
        vendorPaymentMethodFingerprint: _,
        ...remainingPaymentMethodDetails
      } = narrowedCardPaymentMethod.paymentMethodDetails;

      return {
        id,
        paymentMethodType:
          narrowedCardPaymentMethod.paymentMethodType,
        card: {
          nameOnCard: card?.nameOnCard ?? '',
          cardBrand: card?.cardBrand ?? 'VISA',
          expiryMonth: card?.expiryMonth ?? 1,
          expiryYear: card?.expiryYear ?? 2099,
          last4: card?.last4 ?? '',
          manufacturerCard: card?.manufacturerCard ?? false,
          type: 'CARD',
          zipCode,
          status: cardStatus,
        },
        paymentMethodDetails: remainingPaymentMethodDetails,
        default: isDefault,
        nickname,
        warning,
        zipCode,
        status,
      };
    }

    if (paymentMethod.paymentMethodType === 'BANK_ACCOUNT') {
      const narrowedBankPaymentMethod =
        paymentMethod as PaymentMethod<'BANK_ACCOUNT'>;

      const {
        vendorPaymentMethodFingerprint: _,
        accountNumber: __,
        ...remainingPaymentMethodDetails
      } = narrowedBankPaymentMethod.paymentMethodDetails;

      return {
        id,
        paymentMethodType:
          narrowedBankPaymentMethod.paymentMethodType,
        paymentMethodDetails: remainingPaymentMethodDetails,
        default: isDefault,
        nickname,
        warning,
        zipCode,
        status,
      };
    }

    return null;
  };
