import type {
  WalletPaymentFormData,
  WalletPaymentFormErrors,
  WalletPaymentFormKeys,
} from '../../capabilities/checkout/types';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import PaymentMethodTypes from '../../services/commonCheckout/utils/PaymentMethodTypes';

import { validateAchAuthorization } from './validateAchAuthorization';

const executeValidator = (
  key: WalletPaymentFormKeys,
  value: string | boolean | undefined,
  options: {
    paymentMethodType: PaymentMethodType;
  },
) => {
  if (
    key === 'achAuthorization' &&
    options.paymentMethodType === PaymentMethodTypes.BANK_ACCOUNT
  ) {
    return validateAchAuthorization(value as boolean);
  }
  return null;
};

type ValidateWalletPaymentFormProp = {
  formData: WalletPaymentFormData;
  options: {
    paymentMethodType: PaymentMethodType;
  };
};

const validateWalletPaymentForm = ({
  formData,
  options,
}: ValidateWalletPaymentFormProp): Partial<
  Record<WalletPaymentFormKeys, string>
> => {
  const formErrors: Partial<WalletPaymentFormErrors> = {};

  const keys = Object.keys(formData) as WalletPaymentFormKeys[];
  keys.forEach((key: WalletPaymentFormKeys) => {
    const value = formData[key] as unknown as string | boolean;
    const error = executeValidator(key, value, options);
    if (error) {
      formErrors[key] = error;
    }
  });

  return formErrors;
};

export default validateWalletPaymentForm;
