import type { HttpErrorResponse } from '../../services/commonCheckout/types/HttpErrorResponse';
import { HttpStatus } from '../../utils/constants';
import { SessionInvalidDefaultProps } from '../session-invalid-screen/SessionInvalidDefaultProps';
import { SessionExpiredDefaultProps } from '../session-invalid-screen/SessionExpiredDefaultProps';

import { ServerErrorDefaultProps } from './ServerErrorDefaultProps';
import type { SessionOutcomeConfig } from './types/SessionOutcomeConfig';

const SESSION_TIMEOUT_TITLE = 'SESSION_TIMEOUT';

export const deriveFailureConfigFromHttpError = (
  httpErrorResponse?: HttpErrorResponse,
): SessionOutcomeConfig['failure'] | undefined => {
  if (!httpErrorResponse) return undefined;

  const isUnauthorizedError =
    httpErrorResponse.status.toString().trim() ===
    HttpStatus.UNAUTHORIZED.toString();
  const sessionErrorDefaultProps =
    httpErrorResponse?.title === SESSION_TIMEOUT_TITLE
      ? SessionExpiredDefaultProps
      : SessionInvalidDefaultProps;
  return {
    ...(isUnauthorizedError
      ? sessionErrorDefaultProps
      : ServerErrorDefaultProps),
    title: httpErrorResponse.detail,
  };
};
