import { useEffect, useState, useContext } from 'react';

import {
  getAgent,
  getPreferredLanguage,
} from '../../../../utils/session/selectors';
import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { AppContext } from '../../../contextStore/AppContext';
import { settingsApi } from '../../../../services/settingsApi/settingsApi';
import useEnvironmentContextProvider from '../../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import type { CcgSupportedLocale } from '../types/CcgSupportedLocale';

import { isCcgLocale } from './isCcgLocale';

const useRenderedLanguage = () => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { appEnvironmentType } = useEnvironmentContextProvider();
  const [renderedLanguage, setRenderedLanguage] = useState<
    undefined | CcgSupportedLocale
  >();
  const [translationKey, setTranslationKey] =
    useState<string>('');
  const [isDataFetched, setIsDataFetched] =
    useState<boolean>(false);

  const determineRenderedLanguage = (
    session: CheckoutSessionsResponse,
    tKey: string,
  ): CcgSupportedLocale => {
    const shouldReturnEnglish =
      (appEnvironmentType === 'embedded' &&
        (getAgent(session) || !tKey)) ||
      (appEnvironmentType === 'hosted' && !tKey);

    if (shouldReturnEnglish) {
      return 'en';
    }
    if (appEnvironmentType === 'hosted') {
      const url = new URL(window.location.href);
      const langInUrl =
        url.searchParams.get('lang')?.toLowerCase() ?? null;
      const langInSession = getPreferredLanguage(session);

      if (isCcgLocale(langInUrl)) {
        return langInUrl;
      }

      if (isCcgLocale(langInSession)) {
        return langInSession;
      }
      return 'en';
    }

    if (appEnvironmentType === 'embedded') {
      return getPreferredLanguage(session) === 'es'
        ? 'es'
        : 'en';
    }
    return 'en';
  };

  useEffect(() => {
    const fetchTKeyData = async () => {
      try {
        const tKeyVal: string =
          await settingsApi.getSpanishTranslationKey();
        setTranslationKey(tKeyVal);
        setIsDataFetched(true);
      } catch (error) {
        console.error(error);
      }
    };

    (async () => {
      await fetchTKeyData();
    })();
  }, []);

  useEffect(() => {
    if (isDataFetched) {
      const language = determineRenderedLanguage(
        originalCheckoutSessionResponse,
        translationKey,
      );

      setRenderedLanguage(language);
    }
  }, [
    isDataFetched,
    translationKey,
    originalCheckoutSessionResponse,
    appEnvironmentType,
  ]);

  return { renderedLanguage, translationKey };
};

export default useRenderedLanguage;
