import React, { useContext } from 'react';
import type { FC } from 'react';

import { ModalContainer } from '../modal-container';
import InlineContainer from '../inline-container';
import useEnvironmentContextProvider from '../../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import ConditionalOverlayLoader from '../../../ui/OverlayLoader/ConditionalOverlayLoader';
import { AppContext } from '../../../capabilities/contextStore/AppContext';
import isPrimarySecondaryContainer from '../../../utils/container-config/isPrimarySecondaryContainer';
import DrawerContainer from '../drawer-container';
import ModalDrawerContentContainer from '../../../ui/ModalDrawerContentContainer/ModalDrawerContentContainer';

const ContainerSelector: FC = ({ children }) => {
  const { isPrimaryRouteInFocus } = useContext(AppContext);
  const { containerConfig, setEnvironmentState } =
    useEnvironmentContextProvider();

  const handleModalClose = () => {
    setEnvironmentState({ triggerUnload: true });
  };

  if (!containerConfig) {
    // We need containerConfig to render the container in embedded.
    // It should be available but adding this guard to prevent any issues.
    return null;
  }

  const renderChildrenWithLoader = () => {
    let showLoader = true;

    if (
      isPrimarySecondaryContainer(containerConfig) &&
      !isPrimaryRouteInFocus
    ) {
      showLoader = false;
    }

    return (
      <>
        {showLoader ? <ConditionalOverlayLoader /> : null}
        {children}
      </>
    );
  };

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (containerConfig.type) {
    case 'inline':
      return (
        <InlineContainer>
          {renderChildrenWithLoader()}
        </InlineContainer>
      );
    case 'drawer':
      return (
        <DrawerContainer onClose={handleModalClose}>
          <ModalDrawerContentContainer>
            {renderChildrenWithLoader()}
          </ModalDrawerContentContainer>
        </DrawerContainer>
      );
    default:
      return (
        <ModalContainer onClose={handleModalClose}>
          <ModalDrawerContentContainer>
            {renderChildrenWithLoader()}
          </ModalDrawerContentContainer>
        </ModalContainer>
      );
  }
};

export default ContainerSelector;
