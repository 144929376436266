import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import type { PaymentMethodChannels } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import type { PaymentMethodConfig } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodConfig';

const getChannelsByType = ({
  session,
  paymentMethodType,
}: {
  session: CheckoutSessionsResponse;
  paymentMethodType: PaymentMethodType;
}): PaymentMethodChannels => {
  const { paymentMethod = [] } =
    session.checkoutSession.checkoutRequest.config ?? {};
  const paymentMethodConfig: PaymentMethodConfig | undefined =
    paymentMethod.find(
      (config) => config.type === paymentMethodType,
    );
  return paymentMethodConfig ? paymentMethodConfig.channels : [];
};

export default getChannelsByType;
