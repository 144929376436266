import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';

import GenericAchBankIcon from '../../../icons/ach/generic_ach_bank';
import CheckDiagram from '../../../media/payment/CheckDiagram';
import {
  ALL_FIELDS_REQUIRED,
  CHECKING,
  SAVINGS,
  NAME_ON_ACCOUNT,
  ROUTING_NUMBER,
  ROUTING_NUMBER_HELPER_TEXT,
  ACCOUNT_NUMBER,
  ACCOUNT_NUMBER_HELPER_TEXT,
  PAYMENT_METHOD_SETTINGS,
  BANK_ACCOUNT_NICKNAME_HELPER_TEXT,
  CANCEL_BUTTON,
} from '../../../shared/strings';
import { AppContext } from '../../contextStore/AppContext';
import BackButton from '../../../ui/BackButton/BackButton';
import Button from '../../../ui/Button/Button';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import type { DisclaimerProps } from '../../../components/Disclaimer/Disclaimer';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Divider from '../../../ui/Divider/Divider';
import FormField from '../../../ui/FormField/FormField';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import RadioGroup from '../../../ui/RadioGroup/RadioGroup';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
  ELEMENT_IDS,
} from '../../../utils/element-ids';
import {
  getAgent,
  getMerchantConsentText,
} from '../../../utils/session/selectors';
import validateAchForm from '../../../utils/capabilities/validateAchForm';
import { AmountLine } from '../../components/AmountLine/AmountLine';
import {
  ACH_AUTHORIZATION_AGREEMENT,
  PAYMENT_BUTTON_TEXT,
} from '../constants';
import type {
  PayAndStoreAchFormData,
  PayAndStoreAchFormErrors,
  PayAndStoreAchFormKeys,
  PayAndStoreAchFormValues,
} from '../types';
import AchAuthorizationConsent from '../../../components/AchAuthorizationConsent/AchAuthorizationConsent';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

export type PayAndStoreAchFormProps = {
  formTitle: string;
  onSubmit: (data: PayAndStoreAchFormData) => void;
  onBackClick?: () => void;
  onCancel: () => void;
  bankName?: string;
  onRoutingNumberInput: (routingNumber: string) => Promise<void>;
  showFieldsForAuthenticatedUser?: boolean;
  amount: number;
  actionType: DisclaimerProps['actionType'];
  showSaveFeatureBlock: boolean;
  isInFocus?: boolean;
};

const parseNumericCharacters = (value: string) => {
  return value ? value.replace(/\D/g, '') : value;
};

const PayAndStoreAchForm = ({
  formTitle,
  bankName,
  showFieldsForAuthenticatedUser = false,
  amount,
  actionType,
  showSaveFeatureBlock,
  isInFocus,
  onSubmit,
  onBackClick,
  onCancel,
  onRoutingNumberInput,
}: PayAndStoreAchFormProps) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const [formData, setFormData] =
    useState<PayAndStoreAchFormData>({
      accountType: 'checking',
      nameOnAccount: '',
      accountNumber: '',
      routingNumber: '',
      default: false,
      savePaymentMethod: false,
      nickname: '',
      achAuthorization: false,
    });
  const [formErrors, setFormErrors] =
    useState<PayAndStoreAchFormErrors>({});
  const [fieldVisited, setFieldVisited] = useState<
    Record<PayAndStoreAchFormKeys, boolean>
  >({
    accountType: false,
    nameOnAccount: false,
    accountNumber: false,
    routingNumber: false,
    default: false,
    savePaymentMethod: false,
    nickname: false,
    achAuthorization: false,
  });

  const handleOnChange = (
    key: PayAndStoreAchFormKeys,
    value: PayAndStoreAchFormValues,
  ) => {
    setFormData((prevState) => {
      // If the key is 'savePaymentMethod' and the value is false, update 'default' and 'nickname' as well
      if (key === 'savePaymentMethod' && value === false) {
        return {
          ...prevState,
          [key]: value,
          default: false,
          nickname: '',
        };
      }
      // Otherwise, just update the key with the new value
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleOnBlur = (
    key: PayAndStoreAchFormKeys,
    value: PayAndStoreAchFormValues,
  ) => {
    const newFormValues = { ...formData, [key]: value };
    const validationResult = validateAchForm(newFormValues);
    setFormErrors(validationResult);
    setFieldVisited((prevState) => ({
      ...prevState,
      [key]: true,
    }));

    if (key === 'routingNumber' && typeof value === 'string') {
      onRoutingNumberInput(value).catch(() => {
        /* ignore error */
      });
    }
    setFormData(newFormValues);
  };

  const handleOnSubmit = useCallback(() => {
    setFieldVisited({
      accountType: true,
      nameOnAccount: true,
      accountNumber: true,
      routingNumber: true,
      default: true,
      savePaymentMethod: true,
      nickname: true,
      achAuthorization: true,
    });
    const validations = validateAchForm(formData);
    setFormErrors(validations);

    if (Object.keys(validations).length) {
      return;
    }

    onSubmit(formData);
  }, [formData]);

  const merchantConsentText = getMerchantConsentText(
    originalCheckoutSessionResponse,
  );
  const getErrorMessage = (key: PayAndStoreAchFormKeys) => {
    return fieldVisited[key] ? formErrors[key] : '';
  };

  const { title, showActionsOnForm } = useFormConfigManager({
    title: formTitle,
    titleTestId: TITLE_IDS.MAIN_PAGE_TITLE,
    isInFocus,
    processHeaderConfig: false,
    backActionConfig: {
      label: 'Back',
      testId: TEXT_CONTENT_IDS.PAY_AND_STORE_ACH_BACK_BUTTON,
      handler: onBackClick,
    },
    primaryActionConfig: {
      label: PAYMENT_BUTTON_TEXT,
      testId: ELEMENT_IDS.PAY_AND_STORE_ACH_SUBMIT_BUTTON,
      handler: handleOnSubmit,
    },
    secondaryActionConfig: {
      label: CANCEL_BUTTON,
      testId: ELEMENT_IDS.PAY_AND_STORE_ACH_CANCEL_BUTTON,
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <Box>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={TEXT_CONTENT_IDS.PAY_AND_STORE_ACH_BACK_BUTTON}
        />
      ) : null}

      <PageTitle
        title={title}
        titleId={TITLE_IDS.MAIN_PAGE_TITLE}
      />

      <Grid
        container
        direction="column"
        gap={{ xs: '16px' }}
      >
        <Grid
          container
          direction="column"
        >
          <Typography
            variant="body2"
            data-testid={
              TEXT_CONTENT_IDS.ACH_MAKE_PAYMENT_ALL_FIELDS_REQUIRED_TITLE
            }
          >
            {ALL_FIELDS_REQUIRED}
          </Typography>
        </Grid>
        <Grid>
          <RadioGroup
            defaultValue={formData.accountType}
            id="accountType"
            label="Account type"
            isUsedByRadioGroup
            options={[
              {
                id: 'checking',
                label: CHECKING,
              },
              { id: 'savings', label: SAVINGS },
            ]}
            onChange={(value) => {
              handleOnChange('accountType', value);
            }}
          />

          <FormField
            id="nameOnAccount"
            label={NAME_ON_ACCOUNT}
            value={formData.nameOnAccount}
            errorMessage={getErrorMessage('nameOnAccount')}
            onChange={(value) => {
              handleOnChange('nameOnAccount', value);
            }}
            onBlur={(value) => {
              handleOnBlur('nameOnAccount', value);
            }}
          />

          <FormField
            required
            id="routingNumber"
            label={ROUTING_NUMBER}
            value={formData.routingNumber}
            errorMessage={getErrorMessage('routingNumber')}
            helperText={ROUTING_NUMBER_HELPER_TEXT}
            maxLength={9}
            onChange={(value) => {
              handleOnChange(
                'routingNumber',
                parseNumericCharacters(value),
              );
            }}
            onBlur={(value) => {
              handleOnBlur('routingNumber', value);
            }}
            renderAfterInput={() =>
              bankName ? (
                <GenericAchBankIcon bankName={bankName} />
              ) : null
            }
          />

          <FormField
            required
            id="accountNumber"
            label={ACCOUNT_NUMBER}
            value={formData.accountNumber}
            errorMessage={getErrorMessage('accountNumber')}
            helperText={ACCOUNT_NUMBER_HELPER_TEXT}
            maxLength={17}
            onChange={(value) => {
              handleOnChange(
                'accountNumber',
                parseNumericCharacters(value),
              );
            }}
            onBlur={(value) => {
              handleOnBlur('accountNumber', value);
            }}
          />
        </Grid>
        <Grid item>
          <CheckDiagram />
        </Grid>
        {showFieldsForAuthenticatedUser &&
        showSaveFeatureBlock ? (
          <Grid item>
            <FormControl
              component="fieldset"
              style={{ width: '100%' }}
            >
              <FormLabel component="legend">
                <Typography
                  style={{ fontWeight: 700 }}
                  data-testid={
                    TEXT_CONTENT_IDS.ACH_PAYMENT_METHOD_SETTINGS_LABEL
                  }
                >
                  {PAYMENT_METHOD_SETTINGS}
                </Typography>
              </FormLabel>
              <Checkbox
                id="savePaymentMethod"
                value={formData.savePaymentMethod}
                label="Save for future use"
                testId={
                  TEXT_CONTENT_IDS.MAKE_PAYMENT_SAVE_FOR_FUTURE_CHECKBOX_ACH
                }
                onChange={(value) => {
                  handleOnChange('savePaymentMethod', value);
                }}
              />
              {formData.savePaymentMethod ? (
                <>
                  <Checkbox
                    id="default"
                    value={formData.default}
                    label="Set as default payment method"
                    testId={
                      TEXT_CONTENT_IDS.MAKE_PAYMENT_SET_AS_DEFAULT_CHECKBOX_ACH
                    }
                    onChange={(value) => {
                      handleOnChange('default', value);
                    }}
                  />
                  <FormField
                    id="achNickname"
                    label="Bank account nickname"
                    value={formData.nickname ?? ''}
                    hintText="(Optional)"
                    errorMessage={getErrorMessage('nickname')}
                    helperText={
                      BANK_ACCOUNT_NICKNAME_HELPER_TEXT
                    }
                    maxLength={30}
                    onChange={(value) => {
                      handleOnChange('nickname', value);
                    }}
                    onBlur={(value) => {
                      handleOnBlur('nickname', value);
                    }}
                  />
                </>
              ) : null}
            </FormControl>
          </Grid>
        ) : null}
        <Grid
          container
          direction="column"
        >
          <Grid
            item
            style={{ marginTop: '8px' }}
            gap={{ xs: '8px' }}
          >
            <Divider />
            <AmountLine
              amount={amount}
              testId={
                TEXT_CONTENT_IDS.MAKE_ACH_PAYMENT_AMOUNTLINE_LABEL
              }
            />
          </Grid>
          <AchAuthorizationConsent
            title={ACH_AUTHORIZATION_AGREEMENT}
            consentText={merchantConsentText}
            consentLabel={
              getAgent(originalCheckoutSessionResponse)
                ? 'The customer agrees to the ACH authorization agreement'
                : 'I agree to the ACH authorization agreement'
            }
            consentValue={formData.achAuthorization ?? false}
            onChange={(value) => {
              handleOnChange('achAuthorization', value);
            }}
            hasNotConsented={
              fieldVisited.achAuthorization &&
              !formData.achAuthorization
            }
            errorMessage={formErrors.achAuthorization}
          />

          <Grid
            item
            style={{ margin: '8px 0 0 0' }}
          >
            <Disclaimer
              actionType={actionType}
              privacyUrlTestId="achPrivacyUrl"
              termsAndConditionTestId="achTermsAndCondition"
            />
          </Grid>
        </Grid>
        {showActionsOnForm ? (
          <Grid
            container
            direction="column"
            style={{ gap: '16px' }}
          >
            <Button
              id={ELEMENT_IDS.PAY_AND_STORE_ACH_SUBMIT_BUTTON}
              isLoading={false}
              fullWidth
              color="primary"
              variant="contained"
              className="loadingButton"
              onClick={handleOnSubmit}
            >
              {PAYMENT_BUTTON_TEXT}
            </Button>
            <Button
              id={ELEMENT_IDS.PAY_AND_STORE_ACH_CANCEL_BUTTON}
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              {CANCEL_BUTTON}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default PayAndStoreAchForm;
