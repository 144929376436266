import React from 'react';

import { formatToUSD } from '../../../services/formatToUSD/formatToUSD';

import StyledAmountValue from './styled-components/StyledAmountValue';
import StyledAmountLineLabel from './styled-components/StyledAmountLineLabel';
import StyledAmountLineContainer from './styled-components/StyledAmountLineContainer';

export const AmountLine: React.FC<{
  amount: number;
  testId?: string;
}> = ({ amount, testId = 'defaultAmountLineTestId' }) => {
  return (
    <StyledAmountLineContainer
      container
      justifyContent="space-between"
    >
      <StyledAmountLineLabel data-testid={testId}>
        Amount:
      </StyledAmountLineLabel>
      <StyledAmountValue data-testid={`${testId}-amount`}>
        {formatToUSD(amount)}
      </StyledAmountValue>
    </StyledAmountLineContainer>
  );
};
