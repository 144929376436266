import { useState } from 'react';

import type { DrawerContextType } from './DrawerContextType';

export const useDrawerContext = () => {
  const [drawerContext, setDrawerContext] =
    useState<DrawerContextType>({
      title: '',
      showBackButton: false,

      setHeaderData: ({
        title,
        showBackButton,
        titleTestId,
        onBackButtonClick,
        backButtonTestId,
      }) => {
        setDrawerContext((state) => ({
          ...state,
          title,
          showBackButton,
          titleTestId,
          onBackButtonClick,
          backButtonTestId,
        }));
      },

      setFooterData: ({
        primaryButtonText,
        secondaryButtonText,
        onPrimaryButtonClick,
        onSecondaryButtonClick,
        primaryButtonTestId,
        secondaryButtonTestId,
      }) => {
        setDrawerContext((state) => ({
          ...state,
          primaryButtonText,
          secondaryButtonText,
          onPrimaryButtonClick,
          onSecondaryButtonClick,
          primaryButtonTestId,
          secondaryButtonTestId,
        }));
      },
      resetDrawerConfig: () => {
        setDrawerContext((state) => ({
          ...state,
          title: '',
          titleTestId: '',
          showBackButton: false,
          backButtonTestId: '',
          primaryButtonTestId: '',
          primaryButtonText: '',
          secondaryButtonTestId: '',
          secondaryButtonText: '',
          onPrimaryButtonClick: undefined,
          onSecondaryButtonClick: undefined,
          onBackButtonClick: undefined,
        }));
      },
    });

  return drawerContext;
};
