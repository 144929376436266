import type { ChildSessionStatus } from '../../../services/commonCheckout/types/ChildSessionStatus';
import type { PaymentMethodChannelType } from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import { STATUS_MAPPER } from '../../../utils/constants';

function toTitleCase(selectedChannel: PaymentMethodChannelType) {
  return (
    selectedChannel.charAt(0).toUpperCase() +
    selectedChannel.substring(1).toLowerCase()
  );
}

export default function getStatusMessage(
  selectedChannel: PaymentMethodChannelType,
  status: ChildSessionStatus,
) {
  if (
    status === 'NOTIFICATION_CREATED' ||
    status === 'NOTIFICATION_SENT'
  ) {
    return STATUS_MAPPER[status].replace(
      '{selectedChannel}',
      toTitleCase(selectedChannel),
    );
  }
  return STATUS_MAPPER[status];
}
