import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { PaymentMethodChannelTypes } from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';
import {
  getAgent,
  getConfigMode,
  getCustomerId,
} from '../../session/selectors';
import type PaymentMethodEntryViewStates from '../../../shared/types/capabilities/PaymentMethodEntryViewStates';

import { getFilteredPaymentTypesForViewState } from './utils/getFilteredPaymentTypesForViewState';

// NOTE: The name should not be confused with mode: PAYMENT_METHOD_ENTRY. This view state is going to be used in all the modes.
const paymentMethodEntryViewState = ({
  errorType,
  checkoutSessionDetails,
  componentEntryLocalData,
}: {
  errorType?: 'cancel' | 'error';
  checkoutSessionDetails: CheckoutSessionsResponse;
  componentEntryLocalData: ComponentEntryLocalData;
}): PaymentMethodEntryViewStates => {
  const hasCustomerId = !!getCustomerId(checkoutSessionDetails);
  const hasAgent = !!getAgent(checkoutSessionDetails);
  const isAgentAssistedSession = hasAgent && hasCustomerId;
  const isGuestUserPaymentFlowViewState =
    !isAgentAssistedSession &&
    getConfigMode(checkoutSessionDetails) === 'PAYMENT';
  const isAuthenticatedUserViewState =
    hasCustomerId && !hasAgent;
  const isAgentSessionOnScreenEntryViewState =
    hasAgent &&
    hasCustomerId &&
    componentEntryLocalData.selectedChannelType === 'WEBFORM';

  if (errorType) {
    return 'cancel_child_session';
  }

  if (
    getFilteredPaymentTypesForViewState(checkoutSessionDetails)
      .length === 0
  ) {
    return 'view_state_error';
  }

  if (isAgentAssistedSession) {
    // Agent view states
    if (
      !componentEntryLocalData.selectedChannelType ||
      componentEntryLocalData.isBackToChannelSelection
    ) {
      return 'channel_selector';
    }

    if (
      componentEntryLocalData.selectedChannelType &&
      (['EMAIL', 'TEXT'] as PaymentMethodChannelTypes).includes(
        componentEntryLocalData.selectedChannelType,
      )
    ) {
      if (
        componentEntryLocalData.childSessionId &&
        !componentEntryLocalData.childSessionHistory.includes(
          componentEntryLocalData.childSessionId,
        )
      ) {
        return 'awaiting_user_response';
      }

      return 'notifications_methods';
    }
  }

  // Agent view state for on-screen-entry
  // Authenticated user view states
  // Guest user view states for payment flow only.
  if (
    isGuestUserPaymentFlowViewState ||
    isAuthenticatedUserViewState ||
    isAgentSessionOnScreenEntryViewState
  ) {
    return 'unified_payment_method_form';
  }

  return 'view_state_error';
};

export default paymentMethodEntryViewState;
