import type { UserJourney } from '../../shared/types/UserJourney';

const getPaymentMethodChannelSelectorSubTitle = (
  userJourney: UserJourney,
): string => {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    return 'Please select how you would like to capture the payment method.';
  }
  return 'Please select how you would like to capture the payment.';
};

export default getPaymentMethodChannelSelectorSubTitle;
