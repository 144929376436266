import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const BankOfAmericaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 7</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#E31837"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Bank_of_America_logo"
              transform="translate(10.200000, 6.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M10.7675188,11.8520548 C14.1780451,8.89315068 18.9690226,6.01643836 21.6,4.98082192 C21.193985,4.71780822 20.5606015,4.33972603 19.846015,3.92876712 C16.4517293,5.0630137 12.4890226,7.57808219 8.94857143,10.4219178 C9.54947368,10.8821918 10.1828571,11.3589041 10.7675188,11.8520548 L10.7675188,11.8520548 Z"
                id="Path"
              />
              <path
                d="M9.20842105,3.81369863 C8.70496241,3.56712329 8.15278195,3.3369863 7.73052632,3.13972603 C6.4475188,3.78082192 4.77473684,4.75068493 2.66345865,6.27945205 C3.11819549,6.5260274 3.60541353,6.82191781 4.12511278,7.11780822 C5.74917293,5.90136986 7.38947368,4.75068493 9.20842105,3.81369863 L9.20842105,3.81369863 Z"
                id="Path"
              />
              <path
                d="M12.2454135,2.4 C11.6282707,2.05479452 9.48451128,1.24931507 8.00661654,0.904109589 C7.5681203,1.06849315 6.95097744,1.31506849 6.5287218,1.49589041 C7.06466165,1.64383562 9.06225564,2.18630137 10.7675188,3.05753425 C11.206015,2.84383562 11.8231579,2.58082192 12.2454135,2.4 Z"
                id="Path"
              />
              <path
                d="M4.98586466,2.18630137 C3.08571429,3.0739726 1.0881203,4.29041096 0,4.99726027 C0.389774436,5.17808219 0.779548872,5.3260274 1.31548872,5.5890411 C3.71909774,3.94520548 5.60300752,2.94246575 6.35007519,2.61369863 C5.81413534,2.41643836 5.31067669,2.28493151 4.98586466,2.18630137 Z"
                id="Path"
              />
              <path
                d="M13.6745865,1.89041096 C14.1130827,1.74246575 14.6165414,1.6109589 15.0550376,1.47945205 C13.7882707,0.936986301 12.1966917,0.361643836 10.7675188,0 C10.5401504,0.0657534247 9.85804511,0.246575342 9.38706767,0.394520548 C9.87428571,0.542465753 11.4821053,0.920547945 13.6745865,1.89041096 Z M5.66796992,8.07123288 C6.18766917,8.38356164 6.73984962,8.8109589 7.27578947,9.17260274 C10.8324812,6.37808219 14.3404511,4.22465753 18.1894737,3.00821918 C17.6535338,2.72876712 17.1825564,2.48219178 16.5816541,2.18630137 C14.2754887,2.77808219 10.3290226,4.37260274 5.66796992,8.07123288 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default BankOfAmericaIcon;
