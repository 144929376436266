/**
 * In your catch block, given you have an unknown value,
 * ensure that value is an `Error` instance.
 */
export const ensureIsInstanceOfError = (e: unknown): Error => {
  if (e instanceof Error) {
    return e;
  } else {
    const errorMessage =
      'caught something that doesnt look like an error';
    console.error(`${errorMessage}:`, e);
    return new Error(`${errorMessage}, see console.`);
  }
};
