import React from 'react';
import { Box } from '@mui/material';
import type { FC, ReactNode } from 'react';

import FieldLabel from '../FieldLabel/FieldLabel';
import InputField from '../InputField/InputField';
import InputError from '../InputError/InputError';
import type { FieldLabelProps } from '../FieldLabel/FieldLabel';
import type { InputFieldProps } from '../InputField/InputField';
import type { InputErrorProps } from '../InputError/InputError';
import noop from '../../utils/function/noop';
import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';
import HelperText from '../HelperText/HelperText';

export type FormFieldProps = InputFieldProps &
  FieldLabelProps &
  InputErrorProps & {
    helperText?: string;
    renderAfterInput?: () => ReactNode;
  };

const FormField: FC<FormFieldProps> = ({
  // FieldLabelProps section
  label,
  // End FieldLabelProps section

  // InputFieldProps section
  hintText,
  id: inputId,
  value,
  placeholder,
  type,
  inputMode,
  disabled = false,
  required = false,
  autocomplete,
  onChange = noop,
  onBlur = noop,
  maxLength,
  inputValueTransformers,
  // End InputFieldProps section

  // InputErrorProps section
  errorMessage,
  // End InputErrorProps section

  // MiscellaneousProps section
  helperText,
  renderAfterInput,
  // End MiscellaneousProps section
}) => {
  const errorLabelId = `${inputId}-error`;

  return (
    <Box sx={{ marginBottom: '12px' }}>
      <Box sx={{ mb: 1 }}>
        <FieldLabel
          id={inputId}
          label={label}
          hintText={hintText}
        />
      </Box>
      <Box sx={{ mb: '3px' }}>
        <InputField
          aria-describedby={`${inputId}_${FORM_FIELD_SUFFIXES_IDS.HELPER_TEXT} ${errorLabelId}`}
          type={type}
          inputMode={inputMode}
          id={inputId}
          errorLabelId={errorLabelId}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          error={!!errorMessage}
          required={required}
          autocomplete={autocomplete}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          hintText={hintText}
          inputValueTransformers={inputValueTransformers}
        />
      </Box>
      {errorMessage ? (
        <Box>
          <InputError
            id={errorLabelId}
            errorMessage={errorMessage}
          />
        </Box>
      ) : null}
      {renderAfterInput?.()}
      {helperText ? (
        <Box>
          <HelperText
            id={inputId}
            helperText={helperText}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default FormField;
