import React, { useCallback, useState } from 'react';
import type { FC, Dispatch } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import FormField from '../../../ui/FormField/FormField';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import noop from '../../../utils/function/noop';
import {
  CANCEL_BUTTON_TEXT,
  NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  PAYMENT_BUTTON_TEXT,
  PAYMENT_METHOD_SETTINGS_TITLE,
} from '../constants';
import { AmountLine } from '../../components/AmountLine/AmountLine';
import type {
  PayAndStoreStripeFormData,
  PayAndStoreStripeFormErrors,
  PayAndStoreStripeFormKeys,
  PayAndStoreStripeFormSubmitData,
  PayAndStoreStripeFormValues,
} from '../types';
import Divider from '../../../ui/Divider/Divider';
import { isInvalidNickname } from '../../../utils/function/nicknameUtils';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import Button from '../../../ui/Button/Button';
import fieldHasCardNumber from '../../../utils/function/fieldHasCardNumber';
import type { Agent } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import BackButton from '../../../ui/BackButton/BackButton';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

const formLabelMap: Record<
  keyof Omit<PayAndStoreStripeFormData, 'cardChannel'>,
  string
> = {
  nameOnCard: 'Name on card',
  default: 'Set as default payment method',
  nickname: 'Card nickname',
  savePaymentMethod: '',
  manufacturerCard: 'Is this a manufacturer card?',
};

const validateNickname = (nickname?: string) => {
  const formErrors: PayAndStoreStripeFormErrors = {};

  if (nickname && isInvalidNickname(nickname)) {
    formErrors.nickname = 'Please enter a valid card nickname.';
  } else if (
    typeof nickname === 'string' &&
    fieldHasCardNumber(nickname)
  ) {
    formErrors.nickname =
      NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  return formErrors;
};

const validatePaymentMethod = (
  formData: PayAndStoreStripeFormData,
  isSavePaymentMethod: boolean,
) => {
  const { nickname, nameOnCard } = formData;
  let formErrors: PayAndStoreStripeFormErrors = {};

  if (fieldHasCardNumber(nameOnCard)) {
    formErrors.nameOnCard =
      NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  if (isSavePaymentMethod) {
    formErrors = {
      ...formErrors,
      ...validateNickname(nickname),
    };
  }

  return formErrors;
};

const validate = (formData: PayAndStoreStripeFormData) => {
  const { nameOnCard, savePaymentMethod } = formData;
  let formErrors: PayAndStoreStripeFormErrors = {};

  if (!nameOnCard) {
    formErrors.nameOnCard = `${formLabelMap.nameOnCard} is required.`;
  }

  formErrors = {
    ...formErrors,
    ...validatePaymentMethod(formData, savePaymentMethod),
  };

  return formErrors;
};

type PayAndStoreStripeFormProps = {
  isGuestUser: boolean;
  isMakingPayment: boolean;
  amount: number;
  agent: Agent | null;
  showSaveFeatureBlock: boolean;
  formTitle: string;
  isInFocus?: boolean;
  onLoadComplete?: () => void;
  setIsMakingPayment?: Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data: PayAndStoreStripeFormSubmitData) => void;
  onCancel: () => void;
  onBackClick?: () => void;
};

const PayAndStoreStripeForm: FC<PayAndStoreStripeFormProps> = ({
  isGuestUser,
  isMakingPayment,
  amount,
  agent,
  showSaveFeatureBlock,
  formTitle,
  isInFocus,
  onLoadComplete = noop,
  setIsMakingPayment = noop,
  onSubmit = noop,
  onCancel = noop,
  onBackClick,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [formData, setFormData] =
    useState<PayAndStoreStripeFormData>({
      // This is tied to form input and the default value would be empty string
      nameOnCard: '',
      default: false,
      // savePayementMethod should be false for guest, but should be defaulted to true for authenticated user
      savePaymentMethod: false,
      nickname: '',
      manufacturerCard: false,
    });

  const [formErrors, setFormErrors] =
    useState<PayAndStoreStripeFormErrors>({});

  const handleOnChange = (
    key: PayAndStoreStripeFormKeys,
    value: PayAndStoreStripeFormValues,
  ) => {
    setIsMakingPayment(false);
    const isUpdatedToManufacturerCard =
      key === 'manufacturerCard' && value;

    if (isUpdatedToManufacturerCard) {
      setFormData((prevState) => ({
        ...prevState,
        default: false,
      }));
    }
    setFormData((prevState) => {
      // If the key is 'savePaymentMethod' and the value is false, update 'default' and 'nickname' as well
      if (key === 'savePaymentMethod' && value === false) {
        return {
          ...prevState,
          [key]: value,
          default: false,
          nickname: '',
        };
      }
      // Otherwise, just update the key with the new value
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleOnBlur = (
    key: PayAndStoreStripeFormKeys,
    value: PayAndStoreStripeFormValues,
  ) => {
    const newFormValues = { ...formData, [key]: value };
    const validationResult = validate(newFormValues);
    setFormErrors(validationResult);

    setFormData((prevState) => ({
      ...prevState,
      ...newFormValues,
    }));
  };

  const handleOnSubmit = useCallback(() => {
    const formValidationErrors = validate(formData);
    setFormErrors(formValidationErrors);

    if (
      !elements ||
      !stripe ||
      Object.keys(formValidationErrors).length
    ) {
      return;
    }

    onSubmit({ elements, stripe, formData });
  }, [formData]);

  const { title, showActionsOnForm } = useFormConfigManager({
    title: formTitle,
    titleTestId: TITLE_IDS.PAY_AND_STORE_FORM_TITLE,
    isInFocus,
    processHeaderConfig: false,
    backActionConfig: {
      label: 'Back',
      testId: TEXT_CONTENT_IDS.PAY_AND_STORE_CARD_BACK_BUTTON,
      handler: onBackClick,
    },
    primaryActionConfig: {
      label: PAYMENT_BUTTON_TEXT,
      testId: TEXT_CONTENT_IDS.MAKE_PAYMENT_SUBMIT_BUTTON,
      handler: handleOnSubmit,
    },
    secondaryActionConfig: {
      label: CANCEL_BUTTON_TEXT,
      testId: TEXT_CONTENT_IDS.MAKE_PAYMENT_CANCEL_BUTTON,
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.PAY_AND_STORE_CARD_BACK_BUTTON
          }
        />
      ) : null}

      <PageTitle
        title={title}
        titleId={TITLE_IDS.PAY_AND_STORE_FORM_TITLE}
      />

      <Box sx={{ mb: '24px' }}>
        <Typography
          variant="body2"
          data-testid={
            TEXT_CONTENT_IDS.MAKE_PAYMENT_ALL_FIELDS_REQUIRED_TITLE
          }
        >
          All fields are required unless marked optional.
        </Typography>
      </Box>

      <Box sx={{ mb: '24px' }}>
        <FormField
          id="nameOnCard"
          label="Name on card"
          placeholder="Name on card"
          value={formData.nameOnCard}
          errorMessage={formErrors.nameOnCard}
          required
          autocomplete="name"
          onChange={(value) => {
            handleOnChange('nameOnCard', value);
          }}
          onBlur={(value) => {
            handleOnBlur('nameOnCard', value);
          }}
        />

        <Grid
          container
          direction="column"
          style={{ marginBottom: '12px' }}
        >
          <PaymentElement
            id="paymentElement"
            options={{
              terms: { card: 'never' },
              wallets: { googlePay: 'never', applePay: 'never' },
            }}
            onChange={() => {
              setIsMakingPayment(false);
            }}
            onReady={onLoadComplete}
          />
        </Grid>
      </Box>

      {!isGuestUser && showSaveFeatureBlock ? (
        <Grid
          container
          direction="column"
        >
          {agent ? (
            <Grid>
              <Typography
                id="manufacturerCardLabel"
                style={{
                  fontWeight: 700,
                }}
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_LABEL
                }
              >
                {formLabelMap.manufacturerCard}
              </Typography>
              <RadioGroup
                row
                value={formData.manufacturerCard ? 'Yes' : 'No'}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      data-testid={
                        TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_YES
                      }
                      color="primary"
                      onChange={() => {
                        handleOnChange('manufacturerCard', true);
                      }}
                    />
                  }
                  label="Yes"
                  data-testid={
                    TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_YES
                  }
                />

                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      color="primary"
                      data-testid={
                        TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_NO
                      }
                      onChange={() => {
                        handleOnChange(
                          'manufacturerCard',
                          false,
                        );
                      }}
                    />
                  }
                  label="No"
                  data-testid={
                    TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_NO
                  }
                />
              </RadioGroup>
            </Grid>
          ) : null}
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography
                style={{ fontWeight: 700 }}
                data-testid={
                  TEXT_CONTENT_IDS.PAYMENT_METHOD_SETTINGS_LABEL
                }
              >
                {PAYMENT_METHOD_SETTINGS_TITLE}
              </Typography>
            </FormLabel>
            <Checkbox
              id="savePaymentMethod"
              value={formData.savePaymentMethod}
              label="Save for future use"
              testId={
                TEXT_CONTENT_IDS.MAKE_PAYMENT_SAVE_FOR_FUTURE_CHECKBOX_CARD
              }
              onChange={(value) => {
                handleOnChange('savePaymentMethod', value);
              }}
            />
            {formData.savePaymentMethod ? (
              <>
                {!formData.manufacturerCard ? (
                  <Checkbox
                    id="default"
                    value={formData.default}
                    label="Set as default payment method"
                    testId={
                      TEXT_CONTENT_IDS.MAKE_PAYMENT_SET_AS_DEFAULT_CHECKBOX
                    }
                    onChange={(value) => {
                      handleOnChange('default', value);
                    }}
                  />
                ) : null}
                <FormField
                  id="nickname"
                  label={formLabelMap.nickname}
                  hintText="(Optional)"
                  helperText="Card nickname must be 30 characters or less and can only contain letters and numbers."
                  value={formData.nickname || ''}
                  errorMessage={formErrors.nickname}
                  maxLength={30}
                  onChange={(value) => {
                    handleOnChange('nickname', value);
                  }}
                  onBlur={(value) => {
                    handleOnBlur('nickname', value);
                  }}
                />
              </>
            ) : null}
          </FormControl>
        </Grid>
      ) : null}
      <Grid
        item
        style={{ marginTop: '12px' }}
      >
        <Divider />
      </Grid>
      <AmountLine
        amount={amount}
        testId={TEXT_CONTENT_IDS.MAKE_PAYMENT_AMOUNTLINE_LABEL}
      />

      <Disclaimer actionType="make_payment" />

      {showActionsOnForm ? (
        <Grid
          container
          direction="column"
          style={{ gap: '16px', marginTop: '24px' }}
        >
          <Button
            isLoading={false}
            fullWidth
            color="primary"
            variant="contained"
            className="loadingButton"
            onClick={handleOnSubmit}
            disabled={isMakingPayment}
            testId={TEXT_CONTENT_IDS.MAKE_PAYMENT_SUBMIT_BUTTON}
          >
            {PAYMENT_BUTTON_TEXT}
          </Button>
          <Button
            testId={TEXT_CONTENT_IDS.MAKE_PAYMENT_CANCEL_BUTTON}
            color="secondary"
            variant="contained"
            onClick={onCancel}
          >
            {CANCEL_BUTTON_TEXT}
          </Button>
        </Grid>
      ) : null}
    </>
  );
};

export default PayAndStoreStripeForm;
