import React from 'react';
import { Route, Routes } from 'react-router';

import RemovePaymentMethodRouteContainer from '../capabilities/checkout/components/RemovePaymentMethod/remove-payment-method-route-container';
import AddPaymentMethodRouteContainer from '../capabilities/add-payment-method/AddPaymentMethodRouteContainer';
import EditPaymentMethodRouteContainer from '../capabilities/edit-payment-method/card/EditPaymentMethodRouteContainer';
import AddPaymentMethodStandaloneContainer from '../capabilities/add-payment-method/AddPaymentMethodStandaloneContainer';
import ErrorCard from '../components/ErrorCard/ErrorCard';
import PayAndStoreEntry from '../capabilities/pay-and-store/PayAndStoreEntry';
import PaymentTermsAndConditionsContainer from '../ui/TermsAndConditions/PaymentTermsAndConditionsContainer';
import WalletManagementEntry from '../capabilities/wallet-management/WalletManagementEntry';
import { PaymentMethodSelectorRouteContainer } from '../capabilities/payment-method-selector/PaymentMethodSelectorRouteContainer';
import PaymentWithWalletRouteContainer from '../capabilities/checkout/PaymentWithWalletRouteContainer';
import generatePathForRouter from '../utils/routing/generatePathForRouter/generatePathForRouter';

export const routes = (
  <Routes>
    <Route
      path={generatePathForRouter('PAYMENT_WITH_WALLET')}
      element={<PaymentWithWalletRouteContainer />}
    />
    <Route
      path={generatePathForRouter('PAYMENT_METHOD_SELECT')}
      element={<PaymentMethodSelectorRouteContainer />}
    />
    <Route
      path={generatePathForRouter('WALLET')}
      element={<WalletManagementEntry />}
    />
    <Route
      path={generatePathForRouter('EDIT_PAYMENT_METHOD')}
      element={<EditPaymentMethodRouteContainer />}
    />
    <Route
      //   WALLET_MANAGEMENT_PATHS.REMOVE_PAYMENT_METHOD_TEMPLATE
      // }
      path={generatePathForRouter('REMOVE_PAYMENT_METHOD')}
      element={<RemovePaymentMethodRouteContainer />}
    />
    <Route
      path={generatePathForRouter('ADD_PAYMENT_METHOD')}
      element={<AddPaymentMethodRouteContainer />}
    />
    <Route
      path={generatePathForRouter('PAYMENT_METHOD_ENTRY')}
      element={<AddPaymentMethodStandaloneContainer />}
    />
    <Route
      path={generatePathForRouter('PAYMENT')}
      element={<PayAndStoreEntry />}
    />
    <Route
      path="/payment-terms-and-conditions"
      element={<PaymentTermsAndConditionsContainer />}
    />
    <Route
      path="*"
      element={<ErrorCard />}
    />
  </Routes>
);
