import type { FC } from 'react';
import React from 'react';

import CheckoutContainer from '../../capabilities/checkout-v2/checkout/components/checkout-container';
import type { CheckoutContainerProps } from '../../capabilities/checkout-v2/checkout/components/checkout-container/types/CheckoutContainerProps';

type PrimaryRouteWrappersProps = Omit<
  CheckoutContainerProps,
  'children'
>;

const PrimaryRouteWrappers: FC<PrimaryRouteWrappersProps> = ({
  children,
  checkoutSessionId,
  containerConfig,
  showPaymentContainer,
  postMessage,
}) => {
  return (
    <CheckoutContainer
      checkoutSessionId={checkoutSessionId}
      containerConfig={containerConfig}
      postMessage={postMessage}
      showPaymentContainer={showPaymentContainer}
    >
      <>{children}</>
    </CheckoutContainer>
  );
};

export default PrimaryRouteWrappers;
