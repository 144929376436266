import { useContext } from 'react';

import { AppContext } from '../../contextStore/AppContext';
import useEnvironmentContextProvider from '../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import type { AppEnvironmentType } from '../../../shared/types/app/AppEnvironmentType';
import { getConfigMode } from '../../../utils/session/selectors';
import isModalType from '../../../utils/container-config/isModalType';
import isSecondaryTypeModal from '../../../utils/container-config/isSecondaryTypeModal';
import isPrimarySecondaryContainer from '../../../utils/container-config/isPrimarySecondaryContainer';
import isDrawerType from '../../../utils/container-config/isDrawerType';
import isSecondaryTypeDrawer from '../../../utils/container-config/isSecondaryTypeDrawer';

const hasDoneButtonCallback = (onDoneCallback?: () => void) => {
  return !!onDoneCallback;
};

const isEmbeddedAppEnvironment = (
  appEnvironmentType?: AppEnvironmentType,
) => {
  return appEnvironmentType === 'embedded';
};

const useDoneButtonForWalletManagement = (
  backButtonHandler?: () => void,
) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { containerConfig, appEnvironmentType, onDoneClick } =
    useEnvironmentContextProvider();

  const configMode = getConfigMode(
    originalCheckoutSessionResponse,
  );
  let doneButtonHandler;
  let showDoneButton = false;

  /**
   * We show the done button for the following conditions:
   * 1. mode = WALLET, app environment = embedded, container type = modal,
   * 2. mode != WALLET, app environment = embedded, secondary container type = modal,
   */
  if (
    isEmbeddedAppEnvironment(appEnvironmentType) &&
    hasDoneButtonCallback(onDoneClick)
  ) {
    if (configMode === 'WALLET') {
      showDoneButton =
        isModalType(containerConfig) ||
        isDrawerType(containerConfig);
    } else {
      showDoneButton =
        isSecondaryTypeModal(containerConfig) ||
        isSecondaryTypeDrawer(containerConfig);
    }
  }

  /**
   * We need to use the backButtonHandler for the following conditions:
   * 1. mode != WALLET, app environment = embedded, secondary container type = modal,
   */

  if (
    containerConfig &&
    isPrimarySecondaryContainer(containerConfig)
  ) {
    doneButtonHandler = backButtonHandler;
  } else {
    doneButtonHandler = onDoneClick;
  }

  return {
    showDoneButton,
    doneButtonHandler,
  };
};

export default useDoneButtonForWalletManagement;
