import { httpClientService } from '../settingsApi/httpClientService';

import type {
  PaymentMethod,
  PaymentMethodType,
} from './types/PaymentMethod';
import { commonCheckoutClientWrapper } from './commonCheckoutClientWrapper';

export async function getWalletFromCommonCheckoutApi({
  amount,
  customerId,
  headers,
}: {
  amount?: number;
  customerId: string | undefined;
  headers?: Record<string, string>;
}) {
  const updateStatusUrl = `/customers/${
    customerId ?? ''
  }/payment-methods`;

  const httpClient = httpClientService.getHttpClient({
    api: 'CUSTOMER',
  });

  const statusResponse = await commonCheckoutClientWrapper<
    PaymentMethod<PaymentMethodType>[]
  >({
    httpClient,
    request: {
      headers,
      url: updateStatusUrl,
    },
  });

  return {
    paymentMethods: statusResponse.data ?? [],
    amount,
    customerId,
  };
}
