import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const SunTrustIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 20</title>
      <defs>
        <linearGradient
          x1="-9.00269749%"
          y1="91.2461917%"
          x2="87.3193047%"
          y2="29.459226%"
          id="linearGradient-1"
        >
          <stop
            stopColor="#FDB913"
            offset="34.27%"
          />
          <stop
            stopColor="#FBB415"
            offset="36.56%"
          />
          <stop
            stopColor="#EF941B"
            offset="55.19%"
          />
          <stop
            stopColor="#E8801D"
            offset="72.47%"
          />
          <stop
            stopColor="#E4741E"
            offset="87.76%"
          />
          <stop
            stopColor="#E36F1E"
            offset="99.44%"
          />
        </linearGradient>
        <path
          d="M7.02715385,11.8983333 C6.99948718,11.7868667 7.24352137,11.6827333 7.50174359,11.5456 L15.7733675,7.23433333 C15.9975385,7.10746667 16.1741795,7.172 16.1727607,7.3722 L16.210359,8.32186667 C16.206812,8.5294 15.9954103,8.7846 15.7386068,8.89093333 L7.58545299,11.8235333 C7.2995641,11.9379333 7.0512735,12.0046667 7.02715385,11.8983333"
          id="path-2"
        />
        <linearGradient
          x1="-7.41516998%"
          y1="74.5728214%"
          x2="87.3717697%"
          y2="37.1274033%"
          id="linearGradient-3"
        >
          <stop
            stopColor="#FDB913"
            offset="34.27%"
          />
          <stop
            stopColor="#FBB415"
            offset="36.56%"
          />
          <stop
            stopColor="#EF941B"
            offset="55.19%"
          />
          <stop
            stopColor="#E8801D"
            offset="72.47%"
          />
          <stop
            stopColor="#E4741E"
            offset="87.76%"
          />
          <stop
            stopColor="#E36F1E"
            offset="99.44%"
          />
        </linearGradient>
        <path
          d="M16.7204188,11.0806667 L8.73326496,12.2620667 C8.4204188,12.3119333 8.19057265,12.364 8.16645299,12.2694 C8.13949573,12.155 8.40623077,12.0853333 8.69850427,11.9834 L16.9850256,9.38226667 C17.2517607,9.2906 17.4610342,9.39106667 17.4362051,9.60446667 L17.3184444,10.5849333 C17.292906,10.8108 17.0268803,11.0352 16.7204188,11.0806667"
          id="path-4"
        />
      </defs>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#EF7622"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Group"
              transform="translate(12.000000, 5.400000)"
            >
              <path
                d="M0.358068376,8.4194 L0.254495726,8.7164 C0.165820513,8.965 0.153760684,9.4094 0.219735043,9.64626667 L0.751076923,12.4857333 C0.792931624,12.7321333 0.868128205,12.8348 0.947581197,12.8201333 C1.03412821,12.7974 1.02490598,12.6104 0.974538462,12.1821333 L0.573726496,8.41866667 C0.545350427,8.1818 0.444615385,8.1774 0.358068376,8.4194"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M1.48388889,11.4026 C1.49382051,11.6475333 1.51935897,11.9408667 1.43848718,11.9621333 C1.3632906,11.9782667 1.31717949,11.8829333 1.27816239,11.5668667 L1.10577778,9.89046667 C1.0752735,9.64993333 1.115,9.141 1.20154701,8.9628 C1.28951282,8.7846 1.37038462,8.83006667 1.37960684,9.06913333 L1.48388889,11.4026 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M2.20605983,5.76106667 L1.99891453,10.6458 C1.99536752,10.868 1.98117949,11.1510667 1.90101709,11.132 C1.85632479,11.121 1.80241026,10.9919333 1.79815385,10.769 L1.70735043,6.75913333 C1.70167521,6.55966667 1.77545299,6.2304 1.87902564,6.03093333 L2.02161538,5.75373333 C2.12518803,5.5594 2.22237607,5.56233333 2.20605983,5.76106667"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M2.91546154,6.75986667 L2.59410256,10.4852 C2.56147009,10.7484667 2.52387179,11.0190667 2.44867521,11.0154368 C2.39759829,11.0139333 2.35290598,10.8496667 2.37844444,10.6032667 L2.51181197,7.4052 C2.53451282,7.1742 2.68490598,6.82733333 2.78067521,6.64986667 C2.87786325,6.4768 2.95093162,6.556 2.91546154,6.75986667"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M3.03747863,10.2212 C2.97576068,10.2131333 2.97292308,9.94913333 2.98994872,9.76946667 L3.49504274,4.6134 C3.5227094,4.42273333 3.65678632,4.1294 3.79015385,3.96 L3.83839316,3.8984 C3.9767265,3.72606667 4.07817094,3.7422 4.04057265,3.92773333 L3.1892906,9.77533333 C3.14530769,10.0276 3.11338462,10.2212 3.03747863,10.2212"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M4.54282906,4.78133333 C4.65420513,4.6508 4.83864957,4.72486667 4.79892308,4.90966667 L3.69438462,10.1185333 C3.65465812,10.3048 3.57591453,10.5922667 3.49362393,10.5827333 C3.42552137,10.5776 3.43048718,10.3040667 3.4794359,10.0826 L4.27396581,5.3724 C4.31298291,5.18246667 4.42932479,4.90893333 4.54282906,4.78133333"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M4.65562393,10.0584 C4.57546154,10.2380667 4.4768547,10.4962 4.36476923,10.4470667 C4.28389744,10.4140667 4.44635043,10.0070667 4.44635043,10.0070667 L7.18535043,3.10126667 C7.27331624,2.90033333 7.46911111,2.64293333 7.68264103,2.75073333 C7.87347009,2.84606667 7.66845299,3.23473333 7.59325641,3.41 C5.93254701,7.26953333 5.45937607,8.26613333 4.65562393,10.0584"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M5.27138462,9.71813333 C5.33664957,9.76946667 5.49058974,9.58026667 5.61047863,9.3654 L10.6245299,0.731866667 C10.7132051,0.5764 10.7642821,0.326333333 10.584094,0.2332 C10.4436325,0.1606 10.2010171,0.302133333 10.086094,0.5126 L5.42603419,9.2092 C5.28911966,9.471 5.20824786,9.66606667 5.27138462,9.71813333"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M5.38701709,10.6487333 C5.32175214,10.582 5.43312821,10.3686 5.56720513,10.1537333 L10.9217692,2.12006667 C11.0664872,1.89273333 11.3857179,1.6412 11.5815128,1.75853333 C11.7588632,1.86486667 11.7617009,2.09366667 11.6120171,2.30853333 L5.76725641,10.3062667 C5.62253846,10.5218667 5.45511966,10.7198667 5.38701709,10.6487333"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M6.97111111,9.7174 C6.97111111,9.7174 6.61711966,10.1002 6.54334188,10.0144 C6.4667265,9.93153333 6.61073504,9.77093333 6.76680342,9.56486667 L6.94202564,9.34633333 L14.4247949,0.474466667 C14.5453932,0.321933333 14.7099744,0.3146 14.8681709,0.4356 L15.0944701,0.7238 C15.217906,0.869 15.2093932,1.12053333 15.073188,1.26133333 L6.97111111,9.7174 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M6.36457265,11.1400667 C6.30640171,11.0799333 6.43622222,10.901 6.67387179,10.6846667 L14.6184615,3.13793333 C14.7610513,2.98613333 14.9603932,2.99126667 15.0887949,3.11886667 C15.2555043,3.28313333 15.3122564,3.43566667 15.3392137,3.58086667 C15.383906,3.8192 15.2555043,3.9248 15.0923419,4.07586667 L6.81788034,10.8863333 C6.61357265,11.0726 6.43196581,11.2046 6.36457265,11.1400667"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M8.01109402,10.4646667 L17.2545983,3.63586667 C17.4312393,3.49213333 17.5922735,3.52513333 17.6369658,3.70553333 L17.8512051,4.4704 C17.9001538,4.6596 17.7710427,4.9126 17.5823419,5.04973333 L8.17,10.6876 C7.93873504,10.8401333 7.69824786,10.9743333 7.63865812,10.89 C7.56558974,10.7851333 7.7982735,10.6436 8.01109402,10.4646667"
                id="Path"
                fill="#FFFFFF"
              />
              <g id="Path">
                {/* <use fill="url(#linearGradient-1)" xlink:href="#path-2"></use> */}
                <path
                  fill="url(#linearGradient-1)"
                  d="M7.02715385,11.8983333 C6.99948718,11.7868667 7.24352137,11.6827333 7.50174359,11.5456 L15.7733675,7.23433333 C15.9975385,7.10746667 16.1741795,7.172 16.1727607,7.3722 L16.210359,8.32186667 C16.206812,8.5294 15.9954103,8.7846 15.7386068,8.89093333 L7.58545299,11.8235333 C7.2995641,11.9379333 7.0512735,12.0046667 7.02715385,11.8983333"
                  id="path-2"
                />
                {/* <use fill="#FFFFFF" xlink:href="#path-2"></use> */}
                <path
                  fill="#FFFFFF"
                  d="M7.02715385,11.8983333 C6.99948718,11.7868667 7.24352137,11.6827333 7.50174359,11.5456 L15.7733675,7.23433333 C15.9975385,7.10746667 16.1741795,7.172 16.1727607,7.3722 L16.210359,8.32186667 C16.206812,8.5294 15.9954103,8.7846 15.7386068,8.89093333 L7.58545299,11.8235333 C7.2995641,11.9379333 7.0512735,12.0046667 7.02715385,11.8983333"
                  id="path-2"
                />
              </g>
              <g id="Path">
                {/* <use fill="url(#linearGradient-3)" xlink:href="#path-4"></use> */}
                <path
                  fill="url(#linearGradient-3)"
                  d="M16.7204188,11.0806667 L8.73326496,12.2620667 C8.4204188,12.3119333 8.19057265,12.364 8.16645299,12.2694 C8.13949573,12.155 8.40623077,12.0853333 8.69850427,11.9834 L16.9850256,9.38226667 C17.2517607,9.2906 17.4610342,9.39106667 17.4362051,9.60446667 L17.3184444,10.5849333 C17.292906,10.8108 17.0268803,11.0352 16.7204188,11.0806667"
                  id="path-4"
                />
                {/* <use fill="#FFFFFF" xlink:href="#path-4"></use> */}
                <path
                  fill="#FFFFFF"
                  d="M16.7204188,11.0806667 L8.73326496,12.2620667 C8.4204188,12.3119333 8.19057265,12.364 8.16645299,12.2694 C8.13949573,12.155 8.40623077,12.0853333 8.69850427,11.9834 L16.9850256,9.38226667 C17.2517607,9.2906 17.4610342,9.39106667 17.4362051,9.60446667 L17.3184444,10.5849333 C17.292906,10.8108 17.0268803,11.0352 16.7204188,11.0806667"
                  id="path-4"
                />
              </g>
              <path
                d="M7.63298291,13.0636 C7.61099145,12.9418667 8.00825641,12.8729333 8.31542735,12.8399333 L15.3796496,11.9834 C15.6953333,11.9504 15.954265,12.1242 15.8514017,12.4072667 L15.7081026,12.7922667 C15.6236838,13.0386667 15.3640427,13.2102667 15.0362991,13.1875333 L8.26505983,13.1904667 C7.96852991,13.1948667 7.65568376,13.1853333 7.63298291,13.0636"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M6.65542735,2.23446667 C6.65542735,2.23446667 4.60951282,8.73253333 4.49175214,9.08233333 C4.36618803,9.45706667 4.24417094,9.79073333 4.13705128,9.76213333 C4.06823932,9.74086667 4.12215385,9.50253333 4.19876923,9.21506667 L6.1354359,2.1648 C6.22765812,1.88026667 6.51567521,1.6346 6.62847009,1.65806667 C6.83206838,1.6984 6.71501709,2.0548 6.65542735,2.23446667"
                id="Path"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default SunTrustIcon;
