import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const NoProviderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>
        _Incomplete/Legacy/Spot Illustrations/General/Balloon
      </title>
      <defs>
        <path
          d="M9.2267338,9.21829745 L9.2267338,9.2212091 C-3.07557793,21.5142135 -3.07557793,41.4473987 9.2267338,53.740403 L9.2267338,53.740403 L31.4990895,76 L53.7743588,53.740403 C66.0752137,41.4473987 66.0752137,21.5142135 53.7743588,9.2212091 L53.7743588,9.2212091 L53.7743588,9.21829745 C47.6239313,3.07325109 39.5622388,0 31.4990895,0 L31.4990895,0 C23.437397,0 15.3771613,3.07325109 9.2267338,9.21829745"
          id="path-1"
        />
      </defs>
      <g
        id="Optum-Review-&amp;-Pay"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop---RP---Update-Provider---No-Results"
          transform="translate(-812.000000, -358.000000)"
        >
          <g
            id="Group"
            transform="translate(776.000000, 334.000000)"
          >
            <g transform="translate(24.000000, 24.000000)">
              <g transform="translate(12.500000, 0.000000)">
                <polygon
                  id="Fill-115"
                  fill="#E87722"
                  points="24 88 39 88 39 77 24 77"
                />
                <g id="Fill-119-+-Fill-121-Mask">
                  <mask
                    id="mask-2"
                    fill="white"
                  >
                    {/* <use xlink:href="#path-1"></use> */}
                    <path
                      d="M9.2267338,9.21829745 L9.2267338,9.2212091 C-3.07557793,21.5142135 -3.07557793,41.4473987 9.2267338,53.740403 L9.2267338,53.740403 L31.4990895,76 L53.7743588,53.740403 C66.0752137,41.4473987 66.0752137,21.5142135 53.7743588,9.2212091 L53.7743588,9.2212091 L53.7743588,9.21829745 C47.6239313,3.07325109 39.5622388,0 31.4990895,0 L31.4990895,0 C23.437397,0 15.3771613,3.07325109 9.2267338,9.21829745"
                      id="path-1"
                    />
                  </mask>
                  {/* <use id="Mask" fill="#EEB50F" xlink:href="#path-1"></use> */}
                  <path
                    id="Mask"
                    fill="#EEB50F"
                    d="M9.2267338,9.21829745 L9.2267338,9.2212091 C-3.07557793,21.5142135 -3.07557793,41.4473987 9.2267338,53.740403 L9.2267338,53.740403 L31.4990895,76 L53.7743588,53.740403 C66.0752137,41.4473987 66.0752137,21.5142135 53.7743588,9.2212091 L53.7743588,9.2212091 L53.7743588,9.21829745 C47.6239313,3.07325109 39.5622388,0 31.4990895,0 L31.4990895,0 C23.437397,0 15.3771613,3.07325109 9.2267338,9.21829745"
                  />
                  <polygon
                    id="Fill-119"
                    fill="#E87722"
                    mask="url(#mask-2)"
                    points="-4.34482759 39.0857143 69.5172414 39.0857143 69.5172414 34.7428571 -4.34482759 34.7428571"
                  />
                  <polygon
                    id="Fill-121"
                    fill="#E87722"
                    mask="url(#mask-2)"
                    points="-4.34482759 26.0571429 69.5172414 26.0571429 69.5172414 21.7142857 -4.34482759 21.7142857"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default NoProviderIcon;
