import { useEffect, useState } from 'react';

import type BaseFormConfig from '../../shared/types/forms/BaseFormConfig';
import useDrawerContextProvider from '../../capabilities/contextStore/DrawerContext/useDrawerContextProvider';
import usePopulateFormConfigForDrawer from '../usePopulateFormConfigForDrawer/usePopulateFormConfigForDrawer';
import type BaseFormConfigHookArgs from '../../shared/types/forms/BaseFormConfigHookArgs';
import useDrawerCleanup from '../useDrawerCleanup/useDrawerCleanup';

const useFormConfigManager = ({
  title,
  titleTestId,
  showBackButton = true,
  isInFocus = true,
  processHeaderConfig = true,
  processFooterConfig = true,
  backActionConfig,
  primaryActionConfig,
  secondaryActionConfig,
  shouldOverrideFormConfigTitleAndActionsOnForm = false,
}: BaseFormConfigHookArgs) => {
  const [formConfig, setFormConfig] = useState<BaseFormConfig>({
    title,
    titleTestId,
    showActionsOnForm: true,
  });

  const { setFooterData, setHeaderData } =
    useDrawerContextProvider();

  const shouldPopulateFormConfigForDrawer =
    usePopulateFormConfigForDrawer();

  useEffect(() => {
    if (
      !shouldPopulateFormConfigForDrawer ||
      shouldOverrideFormConfigTitleAndActionsOnForm
    ) {
      setFormConfig({
        title,
        titleTestId,
        showActionsOnForm: true,
      });
    } else {
      if (!isInFocus) {
        return;
      }

      setFormConfig({
        title: '',
        titleTestId: '',
        showActionsOnForm: false,
      });

      if (processHeaderConfig) {
        setHeaderData({
          title,
          titleTestId,
          backButtonTestId: backActionConfig?.testId,
          onBackButtonClick: backActionConfig?.handler,
          showBackButton:
            showBackButton && !!backActionConfig?.handler,
        });
      }

      if (processFooterConfig) {
        setFooterData({
          primaryButtonTestId: primaryActionConfig?.testId,
          primaryButtonText: primaryActionConfig?.label,
          onPrimaryButtonClick: primaryActionConfig?.handler,
          secondaryButtonTestId: secondaryActionConfig?.testId,
          secondaryButtonText: secondaryActionConfig?.label,
          onSecondaryButtonClick: secondaryActionConfig?.handler,
        });
      }
    }
  }, [
    shouldPopulateFormConfigForDrawer,
    title,
    primaryActionConfig?.handler,
    isInFocus,
    shouldOverrideFormConfigTitleAndActionsOnForm,
  ]);

  useDrawerCleanup();

  return formConfig;
};

export default useFormConfigManager;
