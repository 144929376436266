import { ScopedCssBaseline, styled } from '@mui/material';

type StyledScopedCssBaselineContainerCustomProps = {
  fullWidthView: boolean;
  children?: React.ReactNode;
};

const StyledScopedCssBaselineContainer = styled(
  ScopedCssBaseline,
)<StyledScopedCssBaselineContainerCustomProps>((props) => {
  const { fullWidthView } = props;
  if (fullWidthView) {
    return {
      width: '100%',
    };
  } else {
    return {
      maxWidth: '768px',
      width: '100%',
      overflow: 'visible',
      margin: 'auto',
    };
  }
});

export default StyledScopedCssBaselineContainer;
