import React, { useContext, useEffect } from 'react';

import AddPaymentMethodAchForm from '../AddPaymentMethodAch/AddPaymentMethodAchForm';
import type AddPaymentMethodAchFormData from '../../types/AddPaymentMethodAchFormData';
import { AppContext } from '../../../contextStore/AppContext';
import noop from '../../../../utils/function/noop';
import type { AddPaymentMethodBaseProps } from '../../types/AddPaymentMethodBaseProps';
import {
  getAgent,
  getExpiresAtUtc,
} from '../../../../utils/session/selectors';
import useSessionExpirationReminder from '../../../../hooks/useSessionExpirationReminder';
import shouldTriggerReminder from '../../../../utils/capabilities/shouldTriggerReminder';
import { getTimeDifference } from '../../../../utils/date/getTimeDifference';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import type { ApiError } from '../../../checkout-v2/types';
import type { SetupPaymentMethodResponse } from '../../../../services/customer/types';

import useFindBankNameWithRoutingNumber from './useFindBankNameWithRoutingNumber';

export type AddPaymentMethodAchContainerProps = Omit<
  AddPaymentMethodBaseProps,
  'vendorPlatformKey' | 'stripeApi'
> & {
  formTitle: string;
};

const AddPaymentMethodAchContainer = ({
  customerId,
  customerApi,
  formTitle,
  isInFocus,
  onCancel,
  onSuccess,
  onBackClick,
  onBeforeSubmit = noop,
  onLoadComplete = noop,
  onError = noop,
}: AddPaymentMethodAchContainerProps) => {
  const { setData, originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { notify } = useContext(NotificationContext);
  const expiresAt = getExpiresAtUtc(
    originalCheckoutSessionResponse,
  );
  const timeDifference = getTimeDifference(
    new Date(expiresAt),
    new Date(new Date().toISOString()),
  );

  useSessionExpirationReminder({
    expiresAt,
    notify,
    timeDifference,
    shouldTriggerReminder: shouldTriggerReminder(
      originalCheckoutSessionResponse,
    ),
  });
  const [bankName, onRoutingNumberInput] =
    useFindBankNameWithRoutingNumber();

  const agent = getAgent(originalCheckoutSessionResponse);

  const notifyPaymentMethodWarning = (
    setupIntentResponse?: SetupPaymentMethodResponse,
  ) => {
    if (setupIntentResponse?.data.warning) {
      setData({
        addPaymentMethodWarning:
          setupIntentResponse?.data.warning,
      });
    }
  };

  const createPaymentMethod = async (
    data: AddPaymentMethodAchFormData,
  ) => {
    const createPaymentMethodResponse =
      await customerApi.createPaymentMethod(customerId, {
        paymentMethod: {
          default: data.default,
          nickname: data.nickname ?? '',
          vendor: 'STRIPE',
          agent,
          paymentMethodDetails: {
            type: 'BANK_ACCOUNT',
            accountNumber: data.accountNumber,
            routingNumber: data.routingNumber,
            accountType: data.accountType,
            nameOnAccount: data.nameOnAccount,
          },
        },
      });

    if (
      createPaymentMethodResponse?.errors?.length ||
      !createPaymentMethodResponse?.data?.paymentMethodId
    ) {
      throw new Error('Failed to add payment method');
    }

    onSuccess({
      paymentMethodId:
        createPaymentMethodResponse?.data.paymentMethodId,
      showAddPaymentMethodNotificationAfterNav:
        !createPaymentMethodResponse.data.warning,
    });
    notifyPaymentMethodWarning(createPaymentMethodResponse);
  };

  const handleOnSubmit = (data: AddPaymentMethodAchFormData) => {
    setData({ overlayLoaderConfig: { show: true } });
    onBeforeSubmit();
    createPaymentMethod(data)
      .catch((e: any) => {
        // errors are already caught and handled inside createPaymentMethod itself
        onError(e?.response?.data as ApiError);
      })
      .finally(() => {
        setData({ overlayLoaderConfig: { show: false } });
      });
  };

  useEffect(() => {
    onLoadComplete();
  }, []);

  return (
    <AddPaymentMethodAchForm
      formTitle={formTitle}
      onCancel={onCancel}
      onSubmit={handleOnSubmit}
      onBackClick={onBackClick}
      onRoutingNumberInput={onRoutingNumberInput}
      bankName={bankName}
      actionType="add_payment_method"
      isInFocus={isInFocus}
    />
  );
};

export default AddPaymentMethodAchContainer;
