import { useContext } from 'react';

import { AppContext } from '../../../capabilities/contextStore/AppContext';

const usePaymentMethodSelectorActivityTracker = () => {
  const { setData, paymentMethodSelectorActivityStack } =
    useContext(AppContext);

  const removePaymentMethodIdFromStack = (id: string) => {
    setData({
      paymentMethodSelectorActivityStack:
        paymentMethodSelectorActivityStack?.filter(
          (activityId) => activityId !== id,
        ),
    });
  };

  const addPaymentMethodIdToStack = (id: string) => {
    // remove the payment method id from the stack if it already exists
    const filteredList =
      paymentMethodSelectorActivityStack?.filter(
        (activityId) => activityId !== id,
      );

    setData({
      paymentMethodSelectorActivityStack: [
        ...(filteredList || []),
        id,
      ],
    });
  };

  const getSelectedPaymentMethod = () => {
    return (
      paymentMethodSelectorActivityStack?.slice(-1)[0] || null
    );
  };

  return {
    addPaymentMethodIdToStack,
    getSelectedPaymentMethod,
    removePaymentMethodIdFromStack,
    _UNSAFE_getFullActivityStack: () =>
      paymentMethodSelectorActivityStack,
  };
};

export default usePaymentMethodSelectorActivityTracker;
