import type { PostMessageFunc } from '../../../widget-wrapper/types';
import generateEventObject from '../../checkout-v2/utils/generate-event-object';
import type { PaymentMethodSessionOutcome } from '../../../services/commonCheckout/types/SessionOutcome/PaymentMethodSessionOutcome';

const communicateSelectionWithConsumer = (
  paymentMethod: PaymentMethodSessionOutcome | null,
  postMessage?: PostMessageFunc,
) => {
  postMessage?.({
    type: 'onEvent',
    data: generateEventObject({
      title: 'PAYMENT_METHOD_SELECTION',
      paymentMethod,
    }),
  });
};

export default communicateSelectionWithConsumer;
