import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

ClassNameGenerator.configure((componentName) => {
  /**
   * This is being added to fix Solutran issue where their brittle
   * code is relying on the actual classnames of the components to be present.
   * TODO: Remove the following logic in two releases after R26.
   * We probably need to communicate with them to update their code and share the timeline.
   */
  if (['MuiDialog', 'MuiPaper'].includes(componentName)) {
    return `ccg-${componentName} ${componentName}`;
  }

  return `ccg-${componentName}`;
});
