import type { SecondaryCcgMode } from '../../../../../../services/commonCheckout/types/SessionConfig/Modes';
import type { UiModes } from '../../../../../../services/commonCheckout/types/UiModes';
import type { BaseSuccessCode } from '../types/BaseSuccessCode';

const paymentSucceeded = {
  code: 'PAYMENT_SUCCEEDED',
  description: 'Payment was successfully processed',
} as const;

const baseUiModeToSuccessObjectMap = {
  FALLBACK: paymentSucceeded,
  PAYMENT_METHOD_ENTRY: {
    code: 'PAYMENT_METHOD_CREATED',
    description: 'Payment method created successfully',
  },

  PAYMENT_WITH_WALLET: paymentSucceeded,
  PAYMENT: paymentSucceeded,
  // TODO: cc-6797, check if this is correct
  WALLET: {
    code: '',
    description: '',
  },
  PAYMENT_METHOD_SELECT: {
    code: '',
    description: '',
  },
} as const;

export const uiModeToSuccessObjectMap =
  baseUiModeToSuccessObjectMap satisfies Record<
    Exclude<UiModes, SecondaryCcgMode>,
    BaseSuccessCode
  >;
