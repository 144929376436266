import React from 'react';

import ErrorCard from '../ErrorCard/ErrorCard';

import { SessionCanceledDefaultProps } from './SessionCanceledDefaultProps';

const SessionInvalidScreen = () => {
  return <ErrorCard {...SessionCanceledDefaultProps} />;
};

export default SessionInvalidScreen;
