import type { AxiosInstance, AxiosError } from 'axios';

import { appInsights } from '../services/AppInsights/app-insights';
import { logHttpRetryEvent } from '../services/AppInsights/Events/log-http-retry-event';
import { logError } from '../services/AppInsights/Error/log-error';
import type { ExtendedAxiosError } from '../services/settingsApi/types';
import {
  AUTHORIZATION,
  BEARER,
  HttpStatus,
  INTERNET_CONNECTIVITY_MESSAGES,
  X_CHECKOUT_ID,
  X_CUSTOMER_ID,
  X_MERCHANT_ID,
  X_UPSTREAM_ENV,
  X_CCG_WIDGET_VERSION,
} from '../utils/constants';
import { delay } from '../utils/function/delay';
import { getVersion } from '../build-info';

import { getHeaderFromError } from './utils/getHeaderFromError';
import { isRetryAllowed } from './utils/retry';
import checkConnectionStatus from './utils/checkConnectionStatus';

export const createDefaultHeader = ({
  merchantId,
  customerId,
  checkoutSessionId,
  hcpToken,
  upstreamEnv,
}: {
  merchantId: string;
  customerId: string;
  checkoutSessionId: string;
  hcpToken: string;
  upstreamEnv: string;
}) => {
  return {
    [AUTHORIZATION]: `${BEARER} ${hcpToken}`,
    [X_MERCHANT_ID]: merchantId,
    [X_CHECKOUT_ID]: checkoutSessionId,
    [X_CUSTOMER_ID]: customerId,
    [X_UPSTREAM_ENV]: upstreamEnv,
    [X_CCG_WIDGET_VERSION]: getVersion() ?? '',
  };
};

let localHeaders: Record<string, string> = {};
export const setHeaders = (headers: Record<string, string>) => {
  localHeaders = headers;
};
export const getHeaders = () => localHeaders;

export const telemetryInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      const checkoutSessionId =
        getHeaderFromError(error, X_CHECKOUT_ID)?.toString() ??
        '';
      const customerId =
        getHeaderFromError(error, X_CUSTOMER_ID)?.toString() ??
        '';

      logError({
        errorType: 'api_error',
        error,
        metadata: {
          url: error?.config.url,
          httpMethod: error?.config.method,
          checkoutSessionId,
          customerId,
          request: error?.config?.data,
          response: error?.response?.data,
          version: getVersion(),
        },
      });
      appInsights.pushTelemetryData();
      return Promise.reject(error);
    },
  );
};

export const authInterceptor = (
  axios: AxiosInstance,
  callback: () => void,
) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (HttpStatus.UNAUTHORIZED === error?.response?.status) {
        callback();
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export type HttpServiceError = {
  message: string;
};

export const errorInterceptor = (
  axios: AxiosInstance,
  callback: (args: HttpServiceError) => void,
) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: ExtendedAxiosError) => {
      // eslint-disable-next-line no-param-reassign
      error.hasError = true;
      const response = error?.response;

      if (
        response &&
        response.status !== HttpStatus.UNAUTHORIZED &&
        response.status >= HttpStatus.BAD_REQUEST &&
        response.status <=
          HttpStatus.NETWORK_CONNECT_TIMEOUT_ERROR
      ) {
        if (isRetryAllowed(error)) {
          const { config } = error;
          if (config.retryAttempts !== undefined) {
            config.retryAttempts -= 1;
          }
          return delay(config.retryAfter).then(async () => {
            const checkoutSessionId =
              getHeaderFromError(
                error,
                X_CHECKOUT_ID,
              )?.toString() ?? '';

            logHttpRetryEvent({
              url: error.config.url,
              httpStatus: error.response?.status,
              checkoutSessionId,
            });
            return axios.request(config);
          });
        }
        callback({
          message:
            response?.data?.detail ||
            'Something went wrong. Please close and try again.',
        });
      } else if (!response) {
        // check if the user has a connection
        const hasConnection = await checkConnectionStatus();

        // eslint-disable-next-line no-param-reassign
        error.hasConnection = hasConnection;

        if (hasConnection) {
          callback({
            message: 'Something went wrong. Please try again.',
          });
        } else {
          callback({
            message:
              INTERNET_CONNECTIVITY_MESSAGES.NO_CONNECTION,
          });
        }
      }
      return Promise.reject(error);
    },
  );
};
