import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from 'react';
import type { FC } from 'react';

import type { ModalProps } from '../../../ui/Modal/Modal';
import noop from '../../../utils/function/noop';
import { CONTAINER_IDS } from '../../../utils/element-ids';
import Modal from '../../../ui/Modal/Modal';
import useContainerCloseHandler from '../hooks/useContainerCloseHandler';
import { AppContext } from '../../../capabilities/contextStore/AppContext';
import { NotificationContext } from '../../../capabilities/contextStore/NotificationContext';

export type ModalContainerProps = {
  onClose: ModalProps['onClose'];
};

const ModalContainer: FC<ModalContainerProps> = ({
  children,
  onClose = noop,
}) => {
  const { headerTitleId, overlayLoaderConfig } =
    useContext(AppContext);
  const { closeNotification } = useContext(NotificationContext);
  const isLoading = overlayLoaderConfig.show;
  const [container, setContainer] =
    useState<ModalProps['container']>();

  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const { shouldCloseContainer } = useContainerCloseHandler();

  useEffect(() => {
    setModalOpen(!shouldCloseContainer);
  }, [shouldCloseContainer]);
  /**
   * CONTAINER_IDS.CHECKOUT_CONTAINER_ID (#checkoutContainerId) needs to be in the DOM before we show the modal.
   * The change here fixed an issue where we were loading the Modal before our container element (CONTAINER_IDS.CHECKOUT_CONTAINER_ID)
   * was in the DOM so Material UI was applying `overflow: hidden` to the body element.
   */
  useLayoutEffect(() => {
    const matchingElement = document.querySelector(
      `#${CONTAINER_IDS.CHECKOUT_CONTAINER_ID}`,
    );

    setContainer(matchingElement);
  }, []);

  useEffect(() => {
    closeNotification();
  }, []);

  return container ? (
    <Modal
      open={modalOpen}
      onClose={onClose}
      container={container}
      labelledById={
        isLoading ? 'loaderContainer' : headerTitleId
      }
    >
      {children}
    </Modal>
  ) : null;
};

export default ModalContainer;
