import React from 'react';
import { MemoryRouter, Route, Routes } from 'react-router';

import type { RootPathNameValues } from '../../../types/PathNameValues';
import type { UiRouteDescriptor } from '../../../types/UiRouteDescriptor';

import ParentRouteContainer from './components/PrimaryRouteContainer';
import SecondaryRouteContainer from './components/SecondaryRouteContainer';

type CcgEmbeddedRouteGroupContainerProps = {
  initialUiRoute: UiRouteDescriptor<RootPathNameValues>;
};

const CcgPrimarySecondaryRouteGroupContainer = ({
  initialUiRoute,
}: CcgEmbeddedRouteGroupContainerProps) => {
  return (
    <MemoryRouter initialEntries={[initialUiRoute.pathname]}>
      <Routes>
        <Route
          path="/:parentRoute"
          element={<ParentRouteContainer />}
        >
          <Route
            path=":secondary/*"
            element={<SecondaryRouteContainer />}
          />
        </Route>
      </Routes>
    </MemoryRouter>
  );
};

export default CcgPrimarySecondaryRouteGroupContainer;
