type AnyFunction<T extends any[] = any[], R = any> = (
  ...args: T
) => R;

const memoizeAsyncFunction = <T extends AnyFunction>(
  fn: T,
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  const cache: Record<string, Promise<any>> = {};
  return async (
    ...args: Parameters<T>
  ): Promise<ReturnType<T>> => {
    const key = JSON.stringify(args);
    if (!(key in cache)) {
      cache[key] = fn(...args);
    }
    return cache[key];
  };
};

export default memoizeAsyncFunction;
