import { getMinutesRemaining } from '../getMinutesRemaining';
import { getTimeDifference } from '../getTimeDifference';

/**
 * This function generates a string that shows remaining time in minutes by subtracting
 * `utcDateToSubtract` from `futureUtcDate`
 * @param futureUtcDate - serialized UTC date in ISO 8601 standard. e.g., 2020-01-01T13:15:00Z
 * @param options:
 * `utcDateToSubtract` - serialized UTC date in ISO 8601 standard. e.g., 2020-01-01T13:15:00Z. Defaults to "now"
 * @returns serialized string showing minutes remaining. e.g., `Expires in 12 minutes`
 */
const generateCountdownString = (
  futureUtcDate: string,
  {
    utcDateToSubtract: currentDateUtc = new Date().toISOString(),
    expiredMessage,
  }: {
    utcDateToSubtract?: string;
    expiredMessage?: string;
  } = {},
) => {
  const futureDateObj = new Date(futureUtcDate);
  const dateToSubtractObj = new Date(currentDateUtc);

  const timeDifference = getTimeDifference(
    futureDateObj,
    dateToSubtractObj,
  );

  if (timeDifference < 0) {
    return expiredMessage || '';
  }

  const minutesRemaining = getMinutesRemaining(timeDifference);

  return `Expires in ${minutesRemaining} ${
    minutesRemaining === 1 ? 'minute' : 'minutes'
  }`;
};

export default generateCountdownString;
