export async function doPolling<T>({
  httpClient,
  request,
  pollingThrottleMs = 1000,
  pollingTimeoutTrys = 25,
}: PollingRequestWrapperParams): Promise<{
  status: number;
  data?: T;
}> {
  const timeout = async (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  return new Promise((resolve, reject) => {
    (async () => {
      let responseCode: number;
      let cnt = 0;
      do {
        // eslint-disable-next-line no-await-in-loop
        const innerResponse = await httpClient.request<T>(
          request,
        );
        responseCode = innerResponse.status;
        if (responseCode !== 202) {
          resolve(innerResponse);
        } else {
          // eslint-disable-next-line no-await-in-loop
          await timeout(pollingThrottleMs);
        }
        cnt += 1;
      } while (responseCode === 202 && cnt < pollingTimeoutTrys);
      reject(new Error('timeout'));
    })().then(
      () => {
        // success
      },
      (reason) => {
        reject(reason);
      },
    );
  });
}
