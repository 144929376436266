import { Typography, styled } from '@mui/material';

const StyledAmountLineLabel = styled(Typography)(
  ({ theme }) => ({
    color: '#444444',
    fontSize: '18px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      color: '#444444',
      fontSize: '18px',
      fontWeight: 700,
    },
  }),
);

export default StyledAmountLineLabel;
