import ReactDOM from 'react-dom';
import React from 'react';

import { CheckoutContainerWrapper } from '../capabilities/checkout-v2';

import type {
  InitializerProps,
  OptumCCGWidgetInitializerArgs,
} from './types';

const OptumCCGWidgetInitializer = ({
  rootElement,
  /**
   * @deprecated
   * Refer https://docs.healthsafepay.com/api-reference/ on how to pass `appearance` as part of session creation
   */
  appearance,
  checkoutSessionId,
  appEnv,
  onSuccess,
  onError,
  onEvent,
  containerConfig,
}: OptumCCGWidgetInitializerArgs) => {
  let showPaymentContainer = false;

  /**
   * create widget component
   */
  const getComponent = () => {
    return React.createElement(CheckoutContainerWrapper, {
      appearance,
      checkoutSessionId,
      appEnv,
      showPaymentContainer,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      postMessage,
      containerConfig,
    });
  };

  /**
   * attach widget to rootElement
   */
  const updateComponentToScreen = () => {
    // TODO: React 18:
    // NOTE: These changes are optional:
    //   import { createRoot } from 'react-dom/client';
    //   const container = document.getElementById('app');
    //   const root = createRoot(container); // createRoot(container!) if you use TypeScript
    //   root.render(<App tab="home" />);
    // Without these changes, react 18 will run our app in "react 17 mode".
    // That is likely the best of both worlds
    ReactDOM.render(getComponent(), rootElement);
  };

  /**
   * remove widget and perform cleanup
   */
  const unmount = () => {
    // TODO: React 18:
    // NOTE: This change should be optional:
    //   root.unmount();
    // Without these changes, react 18 will run our app in "react 17 mode".
    // That is likely the best of both worlds
    ReactDOM.unmountComponentAtNode(rootElement);
  };

  const postMessage: InitializerProps['postMessage'] = ({
    type,
    data,
  }) => {
    if (type === 'onSuccess') {
      onSuccess?.(data);
    }

    if (type === 'onError') {
      onError?.(data);
    }

    if (type === 'onEvent') {
      onEvent?.(data);
    }

    if (type === 'hideModal') {
      showPaymentContainer = false;
      updateComponentToScreen();
    }

    if (type === 'showModal') {
      showPaymentContainer = true;
      updateComponentToScreen();
    }
  };

  /**
   * displays the widget as a modal popup
   */
  const render = () => {
    postMessage({ type: 'showModal' });
  };

  /**
   * perform initialization and attach widget to DOM
   */
  const init = () => {
    updateComponentToScreen();
  };

  /**
   * start initialization
   * - create widget, attach to DOM, perform initialization
   * - widget will get displayed only if 'render()' gets called
   */
  init();

  return {
    render,
    unmount,
  };
};

export default OptumCCGWidgetInitializer;
