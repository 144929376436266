import type {
  PrimaryCcgMode,
  SecondaryCcgMode,
} from '../../services/commonCheckout/types/SessionConfig/Modes';
import type {
  RootPathNameValues,
  SecondaryCcgPathNameValues,
} from '../types/PathNameValues';
import type { UiRouteDescriptor } from '../types/UiRouteDescriptor';

const baseUiModeToRouteMap: Record<
  PrimaryCcgMode,
  UiRouteDescriptor<RootPathNameValues>
> = {
  PAYMENT_METHOD_ENTRY: {
    pathname: '/add-payment-method-v2',
  },
  PAYMENT_WITH_WALLET: {
    pathname: '/payment-with-wallet',
  },
  PAYMENT: {
    pathname: '/one-time-payment',
  },
  WALLET: {
    pathname: '/wallet-management',
  },
  PAYMENT_METHOD_SELECT: {
    pathname: '/payment-method-selector',
  },
} as const;

export const primitiveComponentsToRouteMap: Record<
  SecondaryCcgMode,
  UiRouteDescriptor<SecondaryCcgPathNameValues>
> = {
  PAYMENT_METHOD_DISPLAY: {
    pathname: '/component-library/payment-method-display',
  },
} as const;

export const combinedUiModeToRouteMap = {
  ...baseUiModeToRouteMap,
  ...primitiveComponentsToRouteMap,
} as const;

export const uiModeToRouteMap = combinedUiModeToRouteMap;
