import { Box } from '@mui/material';
import React from 'react';

type ContentSpacerProps = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  all?: number;
};

const ContentSpacer = ({
  all,
  bottom,
  left,
  right,
  top,
}: ContentSpacerProps) => {
  let normalizedMargin;

  if (all) {
    normalizedMargin = `${all}px`;
  } else if (top || bottom || left || right) {
    normalizedMargin = `${top ?? 0}px ${right ?? 0}px ${
      bottom ?? 0
    }px ${left ?? 0}px`;
  }

  return <Box style={{ margin: normalizedMargin }} />;
};

export default ContentSpacer;
