import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';
import type { FC } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';
import getAppEnvironmentType from '../../utils/getAppEnvironmentType';

type TitleProps = {
  title: string;
  rootElementAs?: 'h1' | 'h2' | 'h3' | 'h4';
  id?: string;
  textAlign?: TypographyProps['align'];
};
const appEnvironmentType =  getAppEnvironmentType();
const Title: FC<TitleProps> = ({
  title,
  id,
 rootElementAs =   appEnvironmentType === "hosted" ? 'h1' : 'h2',
  textAlign = 'inherit',
}) => {
  const { setData } = useContext(AppContext);

  useEffect(() => {
    if (title && id) {
      setData({ headerTitleId: id });
    }
  }, [title, id]);
  return (
    <Typography
      id={id}
      variant="h4"
      component={rootElementAs}
      align={textAlign}
      data-testid={id || 'defaultTitle'}
    >
      {title}
    </Typography>
  );
};

export default Title;

