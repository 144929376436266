import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const CapitalOneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 10</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#004977"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Capital_One_logo"
              transform="translate(6.000000, 6.600000)"
            >
              <path
                d="M20.3787182,7.81074143 C20.3770321,7.81243682 20.3753459,7.81243682 20.3753459,7.8141322 C19.1006228,8.72116351 17.591526,9.67397023 15.9677715,10.6742477 L15.9036981,10.7115462 C15.8817783,10.7268047 15.8767199,10.7573216 15.8918951,10.7793617 C15.9070704,10.8014017 15.937421,10.8064878 15.9593408,10.7912294 L16.0132973,10.7624078 C17.3908751,10.0401735 18.972476,9.1823084 20.5641938,8.30240326 C20.5726245,8.2973171 20.5810552,8.29223095 20.5894859,8.28884018 C20.5018066,8.14303701 20.4293025,7.98367076 20.3787182,7.81074143 L20.3787182,7.81074143 Z M28.7419811,1.00376816 C26.7337863,-1.22396855 13.8095101,0.779977256 8.31437428,1.98709182 L8.18622751,2.01421799 C8.15924925,2.01930415 8.14238783,2.04473493 8.14744625,2.07016571 C8.15250468,2.09729188 8.1777968,2.11424574 8.20308893,2.10915958 L8.32954956,2.0837288 C12.8821322,1.26824831 22.351504,0.139121491 24.3883632,2.17019347 C25.0105495,2.7907046 24.862169,3.58753585 24.1405004,4.53017025 C24.528313,4.78108732 24.8048402,5.17611217 24.9296147,5.66946939 C27.7454714,3.79098212 29.6356362,1.99556875 28.7419811,1.00376816 L28.7419811,1.00376816 Z"
                id="Shape"
                fill="#D03027"
              />
              <g
                id="Group"
                transform="translate(0.000000, 4.467470)"
                fill="#FFFFFF"
              >
                <path
                  d="M21.1846716,2.78686908 C21.2099889,3.57356787 21.7045186,4.21893333 22.347576,4.21893333 C23.5948712,4.21893333 24.1265328,2.67278072 24.0894009,1.64087711 C24.0623959,0.852475502 23.5560514,0.20881144 22.912994,0.20881144 C21.8412316,0.20711004 21.1475397,1.74645141 21.1846716,2.78686908 L21.1846716,2.78686908 Z M20.5264239,2.7442988 C20.4808529,1.40078072 21.5239224,0.0266120482 23.0395801,0.0266120482 C24.052269,0.0266120482 24.7155802,0.707736546 24.7527121,1.75155984 C24.8016588,3.1682988 23.8261018,4.46924659 22.2412437,4.46924659 C21.2251792,4.46924659 20.561868,3.7898249 20.5264239,2.7442988 L20.5264239,2.7442988 Z"
                  id="Shape"
                />
                <path
                  d="M6.17206533,2.39011406 C6.11805526,2.41054779 6.04885485,2.43098153 5.96108848,2.44971245 C5.87500993,2.47014618 5.71635534,2.49739116 5.48681252,2.53485301 C5.32815793,2.56039518 5.21844997,2.59274859 5.15600082,2.6387245 C5.09692731,2.68299759 5.06148319,2.73578474 5.05135631,2.80219438 C5.0395416,2.87200964 5.06148319,2.9264996 5.12055671,2.97077269 C5.18131805,3.01334297 5.28258693,3.03547952 5.42605119,3.03547952 C5.53407134,3.03547952 5.63871586,3.01845141 5.73998474,2.98439518 C5.84294145,2.95033896 5.93070782,2.90436305 5.99653259,2.84646747 C6.04547922,2.80389719 6.08092333,2.74940723 6.10792837,2.68299759 C6.12480652,2.6387245 6.14674811,2.54166426 6.17206533,2.39011406 L6.17206533,2.39011406 Z M7.48856088,1.5642506 C7.48349743,1.68685301 7.46155584,1.79583293 7.42948736,1.98484498 L7.20669581,3.24662811 C7.18812984,3.35901365 7.2286374,3.44585703 7.3315941,3.50715823 L7.32484284,3.55653976 L6.04885485,3.55483695 L6.03704015,3.23470843 C5.85138052,3.34709398 5.62690115,3.44245141 5.42436338,3.49864418 C5.2218256,3.55143133 5.05810756,3.57356787 4.81337442,3.57356787 C4.40154761,3.57356787 4.16525354,3.53099759 4.0015355,3.38625863 C3.83612965,3.23811406 3.75511454,3.139351 3.76355361,2.93501365 C3.77030487,2.81751968 3.8310662,2.66596948 3.91714476,2.56039518 C4.00491113,2.45311807 4.1264338,2.38330281 4.25639553,2.3202988 C4.38804509,2.25729478 4.54332405,2.21131888 4.76780342,2.17555984 C4.9939706,2.13809799 5.30790416,2.10233896 5.72817004,2.06657992 C5.94927378,2.047849 6.08429896,1.99676466 6.14674811,1.97122249 C6.23451448,1.93716627 6.26995859,1.89800161 6.2817733,1.82648353 C6.3054027,1.69706988 6.26489515,1.62214618 6.09273804,1.58808996 C5.63365241,1.49613815 4.77117905,1.64768835 4.32728376,1.75837108 L4.56864127,1.10108594 C5.15431301,1.01424257 5.70285282,0.95975261 6.2817733,0.95975261 C7.20500799,0.95975261 7.49868777,1.22368835 7.48856088,1.5642506 L7.48856088,1.5642506 Z"
                  id="Shape"
                />
                <path
                  d="M11.1882509,3.55483695 L11.6304584,1.03297349 L12.8524363,1.03297349 L12.4102288,3.55483695 L11.1882509,3.55483695 Z M11.697971,0.448909237 C11.7351029,0.241166265 12.0591634,0.0742907631 12.4203557,0.0742907631 C12.7832359,0.0742907631 13.0482228,0.242869076 13.0127787,0.448909237 C12.973959,0.656652209 12.6515864,0.825230522 12.2870184,0.825230522 C11.925826,0.825230522 11.6625269,0.656652209 11.697971,0.448909237 L11.697971,0.448909237 Z"
                  id="Shape"
                />
                <path
                  d="M17.1913018,2.39522249 C17.1356039,2.41395341 17.0647157,2.43438715 16.9786372,2.45482088 C16.8925586,2.47525462 16.733904,2.50079679 16.5043612,2.53825863 C16.3473944,2.5638008 16.2359986,2.59615422 16.1752373,2.64213012 C16.1161638,2.6847004 16.0824075,2.73919036 16.068905,2.80389719 C16.0554025,2.87371245 16.0807197,2.92990522 16.1381054,2.9724755 C16.2005545,3.01504578 16.3018234,3.03547952 16.4452877,3.03547952 C16.55162,3.03547952 16.6562645,3.02015422 16.7592212,2.9878008 C16.8638657,2.95204177 16.9499443,2.90776867 17.0174569,2.84987309 C17.0647157,2.80730281 17.1001598,2.75111004 17.125477,2.68640321 C17.1423552,2.64383293 17.1642968,2.54677269 17.1913018,2.39522249 L17.1913018,2.39522249 Z M18.5077974,1.56765622 C18.5027339,1.69196145 18.4807923,1.80094137 18.447036,1.9882506 L18.2259323,3.25173655 C18.2073663,3.36241928 18.2478739,3.45096546 18.3508306,3.51226667 L18.3440793,3.55653976 L17.0680913,3.55824257 L17.0579645,3.23641124 C16.8723048,3.3504996 16.6461376,3.44585703 16.4452877,3.50034699 C16.2427499,3.55483695 16.0790319,3.57697349 15.8309231,3.57697349 C15.4190963,3.57697349 15.18449,3.53610602 15.020772,3.38966426 C14.8536783,3.23981687 14.774351,3.14275663 14.7827901,2.93841928 C14.7878535,2.81922249 14.8486149,2.66767229 14.9363812,2.5638008 C15.0241476,2.45482088 15.1456703,2.38670843 15.2773198,2.32370442 C15.4089694,2.2607004 15.5642484,2.2147245 15.7870399,2.17726265 C16.0132071,2.14150361 16.3288285,2.10404177 16.7490943,2.06828273 C16.9685103,2.04955181 17.1035355,2.00017028 17.1659846,1.97462811 C17.2554388,1.94057189 17.2891951,1.90140723 17.3026976,1.82818635 C17.3246392,1.7004755 17.2858194,1.62555181 17.1153502,1.58979277 C16.6528889,1.49784096 15.7937912,1.64939116 15.3498959,1.7600739 L15.5895656,1.10278876 C16.1769251,1.01594538 16.7237771,0.961455422 17.3026976,0.961455422 C18.2225567,0.963158233 18.5162364,1.22879679 18.5077974,1.56765622 L18.5077974,1.56765622 Z"
                  id="Shape"
                />
                <path
                  d="M3.59814776,2.64894137 C3.19982347,2.72897349 2.97703192,2.7596241 2.58377107,2.7596241 C1.99978715,2.7596241 1.53563808,2.46674056 1.5609553,1.93035502 C1.57952126,1.57276466 1.99809933,0.820122088 3.06311047,0.820122088 C3.39898562,0.820122088 3.66059691,0.879720482 4.0133502,1.08235502 L4.16862917,0.20711004 C3.68928976,0.0249092369 3.37198058,-0.00233574297 2.96015377,0.00106987952 C1.56264311,0.0163951807 0.222518162,0.656652209 0.0554244963,1.95930281 C-0.113356984,3.28238715 1.48837926,3.61443534 2.19894929,3.61105556 C2.60908829,3.60932691 3.03610543,3.5922988 3.43442973,3.55143133 L3.59814776,2.64894137 Z"
                  id="Path"
                />
                <path
                  d="M13.5258744,1.019351 L13.6136408,0.505102008 L14.8913166,0.224138153 L14.7546036,1.01764819 L15.3807829,1.01764819 L15.2626358,1.52508594 L14.6550225,1.52338313 L14.2853911,3.55143133 C14.2853911,3.55143133 13.0549741,3.54291727 13.0499106,3.55143133 L13.4178543,1.52849157 L12.955393,1.52849157 L13.0499106,1.01764819 L13.5258744,1.01764819 L13.5258744,1.019351 Z"
                  id="Path"
                />
                <polygon
                  id="Path"
                  points="20.3430096 0.0708151669 19.7488988 3.55306416 18.5049793 3.55476697 19.1311586 0.036758942"
                />
                <path
                  d="M9.57132434,1.623849 C9.41098194,1.623849 9.26582986,1.67323052 9.13755594,1.77029076 C9.00928201,1.86905382 8.9282669,2.01208996 8.89619842,2.20110201 C8.85737868,2.42076466 8.88100809,2.5791261 8.96708664,2.67618635 C9.05485301,2.77324659 9.17975131,2.82262811 9.34515716,2.82262811 C9.45655293,2.82262811 9.57132434,2.80219438 9.65571508,2.7596241 C9.7603596,2.70343133 9.83293564,2.64553574 9.89538478,2.55188112 C9.96289738,2.45482088 10.0067806,2.34584096 10.03041,2.21983293 C10.0658541,2.02060402 10.0354734,1.87245944 9.94433141,1.77369639 C9.85487723,1.67493333 9.73166675,1.623849 9.57132434,1.623849 L9.57132434,1.623849 Z M7.34678443,4.42497349 L7.94258306,1.04659598 L9.01603327,1.04659598 L8.93333035,1.47740723 C9.02616016,1.34799357 9.17468786,1.24241928 9.38060127,1.15727871 C9.58820249,1.07213815 9.82112093,1.00913414 10.0742932,1.00913414 C10.3544704,1.00913414 10.5266275,1.02445944 10.7359166,1.13343936 C10.9435178,1.24412209 11.0903577,1.40418635 11.1679971,1.61192932 C11.2490123,1.81626667 11.2658904,2.047849 11.2220072,2.29816225 C11.1494312,2.715351 10.9485812,3.04229076 10.6177695,3.28408996 C10.2903334,3.52588916 9.99496586,3.58889317 9.57469997,3.59059598 C9.42617227,3.59059598 9.30127397,3.57527068 9.19831727,3.54972851 C9.09704838,3.52248353 9.01603327,3.49013012 8.95527194,3.45096546 C8.89619842,3.4118008 8.83374927,3.35560803 8.75442198,3.26706185 L8.55525983,4.42497349 L7.34678443,4.42497349 Z"
                  id="Shape"
                />
                <path
                  d="M29.5275402,1.93376064 C29.5207889,1.7311261 29.4195201,1.59319839 29.2119188,1.59319839 C28.7106378,1.59319839 28.1772884,2.58423454 28.0861464,2.98269676 C28.9081122,2.98439518 29.547794,2.49057992 29.5275402,1.93376064 L29.5275402,1.93376064 Z M29.6355603,3.4867245 L29.7317658,3.55313414 C29.4870326,4.05546345 28.9891273,4.45902972 28.4051434,4.45902972 C27.9274918,4.45902972 27.5342309,4.13719839 27.5139771,3.53440321 C27.4751574,2.45311807 28.4794072,1.45016225 29.301373,1.45016225 C29.6541263,1.45016225 29.9815624,1.60511807 29.9950649,1.9882506 C30.0254456,2.84817028 28.8287849,3.11040321 28.0405754,3.12913414 C28.0152581,3.21938313 28.0051312,3.30963213 28.0101947,3.44585703 C28.0236972,3.80174458 28.2363619,4.10143936 28.6785694,4.10143936 C29.0600155,4.10143936 29.4414616,3.79152771 29.6355603,3.4867245 L29.6355603,3.4867245 Z"
                  id="Shape"
                />
                <path
                  d="M24.7341462,1.90651566 C24.8185369,1.89459598 24.9552499,1.87927068 25.0143234,1.87927068 C25.1037776,1.87927068 25.1881683,1.8962988 25.1932318,1.98654779 C25.1949196,2.03933494 25.100402,2.42246747 25.0801482,2.51101365 L24.8894251,3.35901365 C24.8117856,3.70638715 24.7290827,4.06908594 24.6548189,4.35686104 L25.1442852,4.35686104 L25.419399,3.01334297 C26.2582429,2.13128675 26.5823034,1.84351165 26.7797777,1.84351165 C26.8692319,1.84351165 26.9299932,1.89119036 26.9350567,1.99846747 C26.9401201,2.15342329 26.8439147,2.50590522 26.8118462,2.6080739 L26.5316689,3.58719036 C26.467532,3.80685301 26.4202731,4.00608193 26.4253366,4.14741526 C26.4337757,4.36196948 26.5620496,4.45732691 26.7544605,4.45732691 C27.1122772,4.45732691 27.3722007,4.08100562 27.5983679,3.71149558 L27.5325431,3.59059598 C27.4414011,3.73874056 27.2287364,4.06397751 27.0616428,4.06397751 C27.0093205,4.06397751 26.9586861,4.02821847 26.9569983,3.93967229 C26.9519348,3.82558394 26.9991936,3.6536 27.0329499,3.53951165 L27.3451957,2.39181687 C27.4295864,2.07509398 27.4684061,1.87245944 27.4667183,1.76347952 C27.4582793,1.5489253 27.3350688,1.44845944 27.1443457,1.44845944 C26.8219731,1.44845944 26.3949559,1.68685301 25.4885994,2.73748755 L25.4750969,2.73748755 L25.5898683,2.22494137 C25.6523174,1.94397751 25.7130787,1.65109398 25.7704645,1.44845944 C25.4565309,1.56254779 25.0075722,1.69025863 24.7307705,1.74474859 L24.7341462,1.90651566 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default CapitalOneIcon;
