import React, { useContext } from 'react';

import ErrorCard from '../../components/ErrorCard/ErrorCard';
import CustomerApi from '../../services/customer/CustomerApi';
import StripeApi from '../../services/stripe/stripe';
import { AppContext } from '../contextStore/AppContext';
import CancelCallbackContext from '../checkout-v2/context/cancel-callback-context';
import { NotificationContext } from '../contextStore/NotificationContext';

import AddPaymentMethodEntry from './AddPaymentMethodEntry';

const AddPaymentMethodStandaloneContainer = () => {
  const { vendorPlatformKey, customerId, setData } =
    useContext(AppContext);
  const { onCancel } = useContext(CancelCallbackContext);
  const { closeNotification } = useContext(NotificationContext);

  const onBeforeSubmit = () => {
    closeNotification();
  };

  const handleOnSuccess = () => {
    // eslint-disable-next-line no-console
    console.debug(
      'AddPaymentMethodStandaloneContainer `onSuccess` callback fired:',
      'setting `isChildWaitingOnGlobalSession` to `true`',
      'BE should be closing session any second...',
    );
    setData({
      isChildWaitingOnGlobalSession: true,
    });
  };

  if (!customerId) {
    reportError(
      new Error('Add payment method requires a customerId.'),
    );

    return <ErrorCard />;
  }

  return (
    <AddPaymentMethodEntry
      customerId={customerId}
      vendorPlatformKey={vendorPlatformKey}
      customerApi={CustomerApi}
      stripeApi={StripeApi}
      onSuccess={handleOnSuccess}
      onCancel={onCancel}
      onBeforeSubmit={onBeforeSubmit}
    />
  );
};

export default AddPaymentMethodStandaloneContainer;
