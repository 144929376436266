import type { PaymentMethodType } from '../../../services/customer/types';

export const findCardPaymentMethodType = (
  paymentTypes: PaymentMethodType[],
) => {
  return paymentTypes.find(
    (paymentType) => paymentType === 'CARD',
  );
};

export const findAchPaymentMethodType = (
  paymentTypes: PaymentMethodType[],
) => {
  return paymentTypes.find(
    (paymentType) => paymentType === 'BANK_ACCOUNT',
  );
};

export const includesCardAndAch = (
  paymentTypes: PaymentMethodType[],
) => {
  return (
    findCardPaymentMethodType(paymentTypes) &&
    findAchPaymentMethodType(paymentTypes)
  );
};
