import type { GenerateEventObjectArgs } from '../../capabilities/checkout-v2/utils/generate-event-object';
import generateEventObject from '../../capabilities/checkout-v2/utils/generate-event-object';
import type { PostMessageFunc } from '../../widget-wrapper/types';

const emitEvent = (
  postMessage: PostMessageFunc,
  data: GenerateEventObjectArgs,
) => {
  postMessage({
    type: 'onEvent',
    data: generateEventObject(data),
  });
};

export default emitEvent;
