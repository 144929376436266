import React, { useContext } from 'react';
import { Box } from '@mui/material';

import ErrorCard from '../../components/ErrorCard/ErrorCard';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { CONTAINER_IDS } from '../../utils/element-ids';
import CancelCallbackContext from '../checkout-v2/context/cancel-callback-context';

import isValidAppViewType from './utils/isValidAppViewType';
import PaymentMethodSelectorContainer from './components/PaymentMethodSelectorContainer/PaymentMethodSelectorContainer';

export const PaymentMethodSelectorRouteContainer = () => {
  const { onCancel } = useContext(CancelCallbackContext);
  const { containerConfig, appEnvironmentType } =
    useEnvironmentContextProvider();

  if (!isValidAppViewType(appEnvironmentType, containerConfig)) {
    return <ErrorCard />;
  }

  return (
    <Box
      data-testid={CONTAINER_IDS.PAYMENT_METHOD_SELECTOR_WRAPPER}
    >
      <PaymentMethodSelectorContainer onCancel={onCancel} />
    </Box>
  );
};
