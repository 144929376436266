import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const VisaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Rectangle"
            fill="#1D33C3"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Group-6"
            transform="translate(5.280000, 6.840000)"
            fill="#FFFFFF"
          >
            <path
              d="M15.0037257,0.468881143 L13.0185303,9.743872 L10.6185897,9.743872 L12.6042663,0.468881143 L15.0037257,0.468881143 Z M25.0976217,6.45766629 L26.3606217,2.97515429 L27.0876286,6.45766629 L25.0976217,6.45766629 Z M27.7747006,9.743872 L29.9942126,9.743872 L28.0576126,0.468881143 L26.0084251,0.468881143 C25.5479714,0.468881143 25.1596891,0.737358857 24.9869589,1.148736 L21.3889726,9.743872 L23.9072743,9.743872 L24.4071817,8.35866171 L27.4855337,8.35866171 L27.7747006,9.743872 Z M21.5121451,6.71411543 C21.5236926,4.26798514 18.126824,4.13470857 18.1513623,3.04010857 C18.1580983,2.70571429 18.4746903,2.35255543 19.1680171,2.26306286 C19.5125154,2.21735429 20.4603669,2.18367429 21.5342777,2.67684571 L21.9552777,0.710896 C21.3774251,0.501117714 20.6345406,0.3 19.7112274,0.3 C17.3396743,0.3 15.6705897,1.56155657 15.6561554,3.36632343 C15.6407589,4.70101371 16.8474651,5.44582286 17.7577874,5.89039886 C18.6921669,6.34507886 19.0044286,6.635208 19.0015417,7.04321714 C18.9952869,7.665816 18.2557703,7.93766171 17.5658114,7.95017143 C16.3591051,7.968936 15.6595234,7.62395657 15.1004354,7.36365829 L14.6659634,9.39744914 C15.2260137,9.65437943 16.2623954,9.87762971 17.335344,9.89062057 C19.8555703,9.89062057 21.5044469,8.642536 21.5121451,6.71411543 L21.5121451,6.71411543 Z M11.5751017,0.468881143 L7.68794857,9.743872 L5.15088229,9.743872 L3.23930171,2.34293257 C3.12190286,1.886328 3.021344,1.72033371 2.66818514,1.52643314 C2.09033257,1.21369029 1.13959429,0.920674286 0.3,0.738321143 L0.359180571,0.468881143 L4.44071543,0.468881143 C4.961312,0.468881143 5.429464,0.816266286 5.54686286,1.414808 L6.557744,6.78291886 L9.05391314,0.468881143 L11.5751017,0.468881143 Z"
              id="Fill-4"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default VisaIcon;
