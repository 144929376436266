import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from 'react';
import type { FC, ReactNode } from 'react';
import type { ModalProps } from '@mui/material';

import noop from '../../../utils/function/noop';
import useDrawerContextProvider from '../../../capabilities/contextStore/DrawerContext/useDrawerContextProvider';
import { CONTAINER_IDS } from '../../../utils/element-ids';
import Drawer from '../../../ui/Drawer/Drawer';
import { AppContext } from '../../../capabilities/contextStore/AppContext';
import useContainerCloseHandler from '../hooks/useContainerCloseHandler';
import { NotificationContext } from '../../../capabilities/contextStore/NotificationContext';

type DrawerContainerProps = {
  children: ReactNode;
  onClose?: () => void; // Define onClose as an optional function prop
};

const DrawerContainer: FC<DrawerContainerProps> = ({
  children,
  onClose = noop,
}) => {
  const [container, setContainer] =
    useState<ModalProps['container']>();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  // TODO: Revisit this useContext usage: remove this loading state logic,
  // instead utilize isLoading from PayAndStoreEntry, AddPaymentMethodEntry.
  const { overlayLoaderConfig } = useContext(AppContext);
  const { closeNotification } = useContext(NotificationContext);
  const isLoading = overlayLoaderConfig.show;

  const {
    title,
    titleTestId,
    showBackButton,
    onBackButtonClick,
    backButtonTestId,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonTestId,
    secondaryButtonTestId,
  } = useDrawerContextProvider();

  useLayoutEffect(() => {
    const matchingElement = document.querySelector(
      `#${CONTAINER_IDS.CHECKOUT_CONTAINER_ID}`,
    );

    if (!matchingElement) {
      console.error(
        `Could not find element with ID ${CONTAINER_IDS.CHECKOUT_CONTAINER_ID}`,
      );
      return;
    }
    setContainer(matchingElement);
  }, []);

  const { shouldCloseContainer } = useContainerCloseHandler();

  useEffect(() => {
    setDrawerOpen(!shouldCloseContainer);
  }, [shouldCloseContainer]);

  useEffect(() => {
    closeNotification();
  }, []);

  return (
    <Drawer
      onClose={() => {
        onClose();
      }}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      title={title}
      titleTestId={titleTestId}
      hasBackButton={isLoading ? false : showBackButton}
      onBack={isLoading ? undefined : onBackButtonClick}
      drawerDataTestIdOnBack={backButtonTestId}
      size="medium"
      id={CONTAINER_IDS.DRAWER_CONTAINER_ID}
      primaryButtonText={isLoading ? '' : primaryButtonText}
      secondaryButtonText={isLoading ? '' : secondaryButtonText}
      onPrimaryAction={
        isLoading ? undefined : onPrimaryButtonClick
      }
      onSecondaryAction={
        isLoading ? undefined : onSecondaryButtonClick
      }
      drawerDataTestIdPrimaryButton={primaryButtonTestId}
      drawerDataTestIdSecondaryButton={secondaryButtonTestId}
      container={container}
    >
      {children}
    </Drawer>
  );
};

export default DrawerContainer;
