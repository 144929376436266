import React from 'react';

import noop from '../../utils/function/noop';

import type { AppContextType } from './AppContextType';
import { initialState } from './initialState';

export const AppContext = React.createContext<AppContextType>({
  ...initialState,
  customerId: 'guest',

  setData: noop,
  setPaymentMethods: noop,
  setAppMetadata: noop,
  setIsAuthorized: noop,
  setSelectedPaymentMethodId: noop,
  setSessionOutcomePollingInterval: noop,
  setInitializeSessionError: noop,
  setInitializeSessionException: noop,
  setContainerConfig: noop,
});
