import type SendEmailFormData from '../../capabilities/add-payment-method/types/SendEmailFormData';
import type SendEmailFormErrors from '../../capabilities/add-payment-method/types/SendEmailFormErrors';

const isInvalidEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !regex.test(email);
};

const validateSendEmailForm = (
  formData: SendEmailFormData,
): SendEmailFormErrors => {
  const errors: SendEmailFormErrors = {};

  const { emailAddress } = formData;

  if (!emailAddress) {
    errors.emailAddress = 'Email address is required.';
  } else if (isInvalidEmail(emailAddress)) {
    errors.emailAddress = 'Please enter a valid email address.';
  }

  return errors;
};

export default validateSendEmailForm;
