import { useParams } from 'react-router';

const useRoutingHelper = () => {
  const params = useParams();
  let isPrimaryRouteInFocus: boolean;

  if (!params?.secondary) {
    isPrimaryRouteInFocus = true;
  } else {
    isPrimaryRouteInFocus = false;
  }

  return {
    isPrimaryRouteInFocus,
  };
};

export default useRoutingHelper;
