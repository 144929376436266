const checkConnectionStatus = async (): Promise<boolean> => {
  if (navigator.onLine) {
    return new Promise((resolve) => {
      (async () => {
        try {
          const online = await fetch('https://icanhazip.com');
          resolve(online.status >= 200 && online.status < 300);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  } else {
    return Promise.resolve(false);
  }
};

export default checkConnectionStatus;
