import { OptumDefault } from '@skyline/themes';
import type { Theme } from '@mui/material';

import getAppTheme from './app-theme';
import getExternalTheme from './external-theme';
import type { getThemeArgs } from './external-theme/types';

const getTheme: (args?: getThemeArgs) => Theme = ({
  theme = OptumDefault,
  appearance,
} = {}) => {
  const appTheme = getAppTheme(theme);
  if (appearance) {
    return getExternalTheme({ theme: appTheme, appearance });
  }
  return appTheme;
};

export default getTheme;
