import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { PaymentMethodChannelType } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import hasMultipleChannels from '../capabilities/back-button/utils/hasMultipleChannels';
import hasMultiplePaymentMethodTypes from '../capabilities/back-button/utils/hasMultiplePaymentMethodTypes';
import { getWebFormChannelByType } from '../capabilities/getWebFormChannelByType';

import { getConfiguredChannelsForAgent } from './getConfiguredChannelsForAgent';
import {
  getChildSessionId,
  getChildSessionStatus,
  getNotificationChannel,
  isChildSessionValid,
} from './selectors';

const getValueForSelectedChannel = (
  session: CheckoutSessionsResponse,
): PaymentMethodChannelType | undefined => {
  let selectedChannel: PaymentMethodChannelType | undefined;
  /**
   *  This is to support our legacy flow.
   *  In the legacy flow the config property is not set and so
   *  if that is the case then we default them to the WEBFORM channel.
   *
   *  This is also an early guard to handle self service flow (authenticated, guest) or
   *  agent with only 1 channel and 1 payment method type to default to webform channel.
   *  This is to prevent user from accessing notification channel selection other than
   *  the agent and also skip the channel selection if there is only 1 channel and 1
   *  payment method type.
   */
  const { agent, config } =
    session.checkoutSession.checkoutRequest;
  const ws2WebformChannel = getWebFormChannelByType(
    session,
    'CARD',
  );
  const isBasicAgentAssistedSession =
    !!agent &&
    !!session.checkoutSession.customerId &&
    !hasMultipleChannels(session) &&
    !hasMultiplePaymentMethodTypes(session) &&
    !!ws2WebformChannel &&
    ws2WebformChannel.enabled;
  const isAuthenticatedUser =
    !!session.checkoutSession.customerId && !agent;
  const isGuestUser =
    !agent && !session.checkoutSession.customerId;
  const isWebFormOrLegacyFlow =
    !config ||
    isBasicAgentAssistedSession ||
    isAuthenticatedUser ||
    isGuestUser;

  if (isWebFormOrLegacyFlow) {
    return 'WEBFORM';
  }

  /** NOTE: By this point of execution, we have established that this session
   *  is an agent assisted session configured for multiple channel types.
   */

  const paymentMethodChannels =
    getConfiguredChannelsForAgent(session);

  if (
    paymentMethodChannels &&
    paymentMethodChannels.length === 1 &&
    paymentMethodChannels[0].enabled
  ) {
    selectedChannel = paymentMethodChannels[0].type;
  }

  const childSessionId = getChildSessionId(session);
  const childSessionStatus = getChildSessionStatus(session);
  if (
    isChildSessionValid(session) &&
    childSessionId &&
    childSessionStatus &&
    [
      'INITIAL',
      'PENDING',
      'NOTIFICATION_CREATED',
      'NOTIFICATION_SENT',
      'LINK_OPENED',
    ].includes(childSessionStatus)
  ) {
    selectedChannel = getNotificationChannel(session);
  }

  return selectedChannel;
};

export default getValueForSelectedChannel;
