import React, { useRef } from 'react';
import type { IMask } from 'react-imask';
import { Box } from '@mui/material';

import StyledIMaskInput from '../styled-components/StyledIMaskInput';

export type MaskedInputFieldProps = {
  id: string;
  mask: IMask.AnyMask;
  lazy?: boolean;
  defaultValue?: number | string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorLabelId?: string;
  required?: boolean;
  unmask?: 'typed' | boolean;
  onChange: (
    value: string,
    maskRef?: IMask.InputMask<IMask.AnyMaskedOptions>,
  ) => void;
};

const MaskedInputField = ({
  id,
  unmask = true,
  lazy = true,
  defaultValue,
  mask,
  error,
  placeholder,
  disabled,
  errorLabelId,
  required,
  onChange,
}: MaskedInputFieldProps) => {
  const ref = useRef(null);
  const inputRef = useRef(null);

  return (
    <Box position="relative">
      <StyledIMaskInput
        id={id}
        data-testid={id}
        className={error ? 'is-invalid' : ''}
        mask={mask}
        lazy={lazy}
        defaultValue={defaultValue}
        unmask={unmask}
        ref={ref}
        inputRef={inputRef}
        onAccept={onChange}
        disabled={disabled}
        placeholder={placeholder}
        aria-describedby={errorLabelId}
        aria-required={required}
      />
    </Box>
  );
};

export default MaskedInputField;
