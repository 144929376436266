import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

import StorageWrapper from '../../../utils/storage/StorageWrapper';

import ServiceContext from './ServiceContext';

const ServiceContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const sessionStorageService = useMemo(
    () => new StorageWrapper(sessionStorage),
    [],
  );

  const serviceContextValue = useMemo(
    () => ({
      sessionStorageService,
    }),
    [sessionStorageService],
  );

  return (
    <ServiceContext.Provider value={serviceContextValue}>
      {children}
    </ServiceContext.Provider>
  );
};

export default ServiceContextProvider;
