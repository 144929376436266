import { styled } from '@mui/material';
import { IMaskInput } from 'react-imask';

import getSharedInputStyles from './shared-input-styles';

const StyledIMaskInput = styled(IMaskInput)(({ theme }) => ({
  ...getSharedInputStyles(theme),
}));

export default StyledIMaskInput;
