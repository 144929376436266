import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { PaymentStatusEvent } from './types';

const EVENT_LOGIN_PAYMENT_STATUS = 'LOGIN_TO_PAY';

export const logLoginPaymentStatusEvent = ({
  checkoutSessionId,
  paymentStatus,
  customerId,
}: PaymentStatusEvent) => {
  appInsights.trackEvent({
    name: EVENT_LOGIN_PAYMENT_STATUS,
    properties: {
      [telemetryKeyPrefix('paymentStatus')]: paymentStatus,
      [telemetryKeyPrefix('customerId')]: customerId,
      [telemetryKeyPrefix('checkoutSessionId')]:
        checkoutSessionId,
    },
  });
};
