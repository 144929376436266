import type {
  CardBrand,
  PaymentMethod,
  PaymentMethodType,
} from '../types/PaymentMethod';

export const getPaymentMethodId = (
  paymentMethod: PaymentMethod<PaymentMethodType>,
): string => paymentMethod.id;

export const getIsDefault = (
  paymentMethod: PaymentMethod<PaymentMethodType>,
): boolean => paymentMethod.default;

export const getNickname = (
  paymentMethod: PaymentMethod<PaymentMethodType>,
) => paymentMethod.nickname;

export const getExpirationYear = (
  paymentMethod: PaymentMethod<'CARD'>,
): number => paymentMethod.paymentMethodDetails.expiryYear;

export const getCardBrand = (
  paymentMethod: PaymentMethod<'CARD'>,
): CardBrand => paymentMethod.paymentMethodDetails.cardBrand;

export const getCardLastFour = (
  paymentMethod: PaymentMethod<'CARD'>,
): string => paymentMethod.paymentMethodDetails.last4;

export const getExpirationMonth = (
  paymentMethod: PaymentMethod<'CARD'>,
): number => paymentMethod.paymentMethodDetails.expiryMonth;

export const getCardHolderName = (
  paymentMethod: PaymentMethod<'CARD'>,
): string => paymentMethod.paymentMethodDetails.nameOnCard;

export const getIsManufacturerCard = (
  paymentMethod: PaymentMethod<'CARD'>,
): boolean =>
  paymentMethod.paymentMethodDetails.manufacturerCard;

export const getIsExpired = (
  paymentMethod: PaymentMethod<'CARD'>,
): boolean =>
  paymentMethod.paymentMethodDetails.status === 'EXPIRED';

export const getIsBankAccountInvalid = (
  paymentMethod: PaymentMethod<'BANK_ACCOUNT'>,
): boolean =>
  paymentMethod.paymentMethodDetails.status === 'INVALIDATED';

export const getBankAccountLast4 = (
  paymentMethod: PaymentMethod<'BANK_ACCOUNT'>,
): string => paymentMethod.paymentMethodDetails.last4;

export const getRoutingNumber = (
  paymentMethod: PaymentMethod<'BANK_ACCOUNT'>,
): string => paymentMethod.paymentMethodDetails.routingNumber;

export const getBankName = (
  paymentMethod: PaymentMethod<'BANK_ACCOUNT'>,
): string => paymentMethod.paymentMethodDetails.bankName;
