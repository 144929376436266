import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import type { FC } from 'react';

const StyledModalDrawerContentContainer = styled(Box)({
  padding: '0 24px 24px 24px',
});

const ModalDrawerContentContainer: FC = ({ children }) => {
  return (
    <StyledModalDrawerContentContainer>
      {children}
    </StyledModalDrawerContentContainer>
  );
};

export default ModalDrawerContentContainer;
