import type UnifiedPaymentFormHeaderConfig from '../../types/UnifiedPaymentFormHeaderConfig';

const headerConfig: UnifiedPaymentFormHeaderConfig = {
  ADD_PAYMENT_METHOD: {
    CARD: {
      customer: {
        title: 'Add a payment method',
        subTitle: 'Card details',
      },
      agent: {
        title: 'Add a payment method via on-screen entry',
        subTitle: 'Card details',
      },
    },
    BANK_ACCOUNT: {
      customer: {
        title: 'Add a payment method',
        subTitle: 'Bank account details',
      },
      agent: {
        title: 'Add a payment method via on-screen entry',
        subTitle: 'Bank account details',
      },
    },
  },
  MAKE_PAYMENT: {
    CARD: {
      customer: {
        title: 'Make a payment',
        subTitle: 'Card details',
      },
      agent: {
        title: 'Make a payment via on-screen entry',
        subTitle: 'Card details',
      },
    },
    BANK_ACCOUNT: {
      customer: {
        title: 'Make a payment',
        subTitle: 'Bank account details',
      },
      agent: {
        title: 'Make a payment via on-screen entry',
        subTitle: 'Bank account details',
      },
    },
  },
};

export default headerConfig;
