import type GenerateAbsoluteRoutePathArgs from '../../../shared/types/routing/GenerateAbsoluteRoutePathArgs';
import type GenerateAbsoluteRoutePathReturnType from '../../../shared/types/routing/GenerateAbsoluteRoutePathReturnType';
import type RouteKeys from '../../../shared/types/routing/RouteKeys';
import type RoutePathOptions from '../../../shared/types/routing/RoutePathOptions';
import type RoutePathsFinal from '../../../shared/types/routing/RoutePathsFinal';
import PrimaryRoutePathsMap from '../constants/PrimaryRoutePathsMaps';

import routeSegmentRequiresReplacement from './utils/routeSegmentRequiresReplacement';
import routeSegmentValueMissing from './utils/routeSegmentValueMissing';

const processRouteSegment = (
  routeSegment: RouteKeys,
  options?: RoutePathOptions,
) => {
  if (
    typeof PrimaryRoutePathsMap[routeSegment] === 'string' &&
    routeSegmentRequiresReplacement(
      PrimaryRoutePathsMap[routeSegment],
    )
  ) {
    if (
      routeSegmentValueMissing(
        PrimaryRoutePathsMap[routeSegment],
        options,
      )
    ) {
      throw new Error(
        `[generateAbsoluteRoutePath] unable to generate route path. Missing value for dynamic segment: ${routeSegment}`,
      );
    }

    return PrimaryRoutePathsMap[routeSegment].replace(
      ':paymentMethodId',
      options?.paymentMethodId as string,
    ) as RoutePathsFinal;
  }

  return PrimaryRoutePathsMap[routeSegment];
};

const generateAbsoluteRoutePath = ({
  routes,
  options,
}: GenerateAbsoluteRoutePathArgs): GenerateAbsoluteRoutePathReturnType => {
  if (routes.length === 0) {
    throw new Error('At least one route is required');
  }

  const paths: RoutePathsFinal[] = [];

  if (routes.length === 1) {
    paths.push(processRouteSegment(routes[0], options));
  } else if (routes.length > 1) {
    routes.forEach((route) => {
      paths.push(processRouteSegment(route, options));
    });
  }

  if (options?.isSecondary) {
    // add 'secondary' after the first path
    paths.splice(1, 0, 'secondary');
  }

  if (paths.length) {
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const finalRoute = `/${paths.join(
      '/',
    )}` as GenerateAbsoluteRoutePathReturnType;

    return finalRoute;
  }

  throw new Error(
    '[generateAbsoluteRoutePath] unable to generate route path.',
  );
};

export default generateAbsoluteRoutePath;
