import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import type {
  Stripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';

import getStripeInstance from '../utils/getStripeInstance';

type StripeElementsLoaderProps = {
  vendorPlatformKey: string;
  options?: StripeElementsOptions;
};

const StripeElementsLoader: FC<StripeElementsLoaderProps> = ({
  vendorPlatformKey,
  options,
  children,
}) => {
  const [stripe, setStripe] = useState<Stripe>();

  useEffect(() => {
    (async () => {
      const stripeInstance = await getStripeInstance({
        vendorPlatformKey,
      });
      if (stripeInstance) {
        setStripe(stripeInstance);
      }
    })();
  }, [vendorPlatformKey]);

  return stripe ? (
    <Elements
      stripe={stripe}
      options={options}
    >
      {children}
    </Elements>
  ) : null;
};

export default StripeElementsLoader;
