import type { SyntheticEvent } from 'react';
import React, { useContext } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import NotificationAlert from '../NotificationAlert/NotificationAlert';
import { NotificationContext } from '../../capabilities/contextStore/NotificationContext';

const NotificationAlertWrapperGrid = styled(Grid)({
  flex: 1,
  position: 'relative',
  paddingTop: '24px',
  boxSizing: 'border-box',
  borderRadius: '12px',
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NotificationWrapperProps {}

const NotificationAlertWrapper: React.FC<
  NotificationWrapperProps
> = () => {
  const {
    isShown,
    severity = 'success',
    title,
    message,
    icon,
    closeable,
    onClose,
    closeNotification,
  } = useContext(NotificationContext);

  let onCloseCallback:
    | ((event: SyntheticEvent) => void)
    | undefined;

  if (onClose) {
    onCloseCallback = (event: SyntheticEvent) => {
      onClose(event);
      closeNotification();
    };
  } else if (closeable) {
    onCloseCallback = () => {
      closeNotification();
    };
  } else {
    onCloseCallback = undefined;
  }

  return (
    <Box>
      <Collapse in={isShown}>
        <NotificationAlertWrapperGrid
          container
          direction="column"
        >
          <NotificationAlert
            severity={severity}
            icon={icon}
            title={title}
            message={message}
            onClose={onCloseCallback}
          />
        </NotificationAlertWrapperGrid>
      </Collapse>
    </Box>
  );
};

export default NotificationAlertWrapper;
