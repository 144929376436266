export function getRequestUrlParams(
  response: InitialPollingResponse,
) {
  let pollingUrl = response.data?.url;
  let pollingServer: string | undefined;
  try {
    const url = new URL(response.data?.url || '');
    pollingUrl = `${url.pathname}${url.search}`;
    pollingServer = url.origin;
  } catch {
    // swallow error, return init values
  }
  return { pollingUrl, pollingServer };
}
