import { IMask } from 'react-imask';

import { PHONE_NUMBER_MASK } from '../../capabilities/add-payment-method/shared/constants';

const formatPhoneNumber = (phoneNumber: string) => {
  const phoneNumberPipe = IMask.createPipe({
    mask: PHONE_NUMBER_MASK,
  });

  return phoneNumberPipe(phoneNumber);
};

export default formatPhoneNumber;
