export function serviceErrorNotification({
  message,
}: {
  message: string;
}) {
  return {
    severity: 'error' as const,
    title: message,
  };
}
