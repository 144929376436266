import { useContext } from 'react';

import { getCheckoutSessionId } from '../../utils/function/getCheckoutSessionId';
import { AppContext } from '../../capabilities/contextStore/AppContext';

const useParseCheckoutSessionId = () => {
  const { checkoutSessionId } = useContext(AppContext);

  const queryParams = new URLSearchParams(
    window.location.search,
  );

  return (
    checkoutSessionId ||
    getCheckoutSessionId({ urlSearchParams: queryParams })
  );
};

export default useParseCheckoutSessionId;
