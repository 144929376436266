import React, { useContext, useState } from 'react';
import type { FC } from 'react';

import StripeExpressCheckoutElementWrapper from '../../components/stripe/stripe-elements-wrapper/StripeExpressCheckoutElementWrapper';
import type { PaymentMethodError } from '../../services/stripe/types';
import processCardPaymentStripe from '../../utils/capabilities/processCardPaymentStripe';
import type { PayAndStoreStripeFormSubmitData } from '../pay-and-store/types';
import { getExpressCheckoutMerchantConfig } from '../../utils/session/getExpressCheckoutMerchantConfig';
import { AppContext } from '../contextStore/AppContext';
import noop from '../../utils/function/noop';
import { CCGThemeProviderContext } from '../contextStore/CCGThemeProviderContext';
import { getMerchantName } from '../../utils/session/selectors';

import type { ExpressCheckoutWalletContainerProps } from './types/ExpressCheckoutWalletContainerProps';
import type { ExpressCheckoutWalletLoadCompleteEvent } from './types/ExpressCheckoutWalletProps';
import ExpressCheckoutWallet from './ExpressCheckoutWallet';
import SectionDivider from './SectionDivider';
import { getStripeExpressCheckoutElementStyleConfig } from './utils/getStripeExpressCheckoutElementStyleConfig';

const ExpressCheckoutWalletContainer: FC<
  ExpressCheckoutWalletContainerProps
> = ({
  vendorPlatformKey,
  amount,
  merchantTransactionId,
  paymentDescription,
  statementDescriptorSuffix,
  authorizeCard,
  sectionDivider,
  paymentApi,
  stripeApi,
  onBeforeSubmit = noop,
  onSuccess = noop,
  onError = noop,
}) => {
  const { originalCheckoutSessionResponse, setData } =
    useContext(AppContext);
  const [
    expressCheckoutWalletAvailable,
    setExpressCheckoutWalletAvailable,
  ] = useState(false);

  const merchantName = getMerchantName(
    originalCheckoutSessionResponse,
  );

  const { isGooglePayButtonEnabled, isApplePayButtonEnabled } =
    getExpressCheckoutMerchantConfig(
      originalCheckoutSessionResponse,
    );

  const { appearance } = useContext(CCGThemeProviderContext);
  const styleConfig =
    getStripeExpressCheckoutElementStyleConfig(appearance);

  const handleOnLoadComplete = (
    options: ExpressCheckoutWalletLoadCompleteEvent,
  ) => {
    if (
      options.googlePayAvailable ||
      options.applePayAvailable
    ) {
      setExpressCheckoutWalletAvailable(true);
    }
  };

  const onProcessingComplete = () => {
    setData({ overlayLoaderConfig: { show: false } });
  };

  const handlePaymentMethodError = ({
    paymentMethodError,
  }: {
    paymentMethodError: PaymentMethodError;
  }) => {
    const { errorType, message } = paymentMethodError;

    if (errorType !== 'validation_error') {
      console.error(message);
      onError(message);
    }

    onProcessingComplete();
  };

  const onSubmit = (data: PayAndStoreStripeFormSubmitData) => {
    onBeforeSubmit();
    setData({ overlayLoaderConfig: { show: true } });

    processCardPaymentStripe({
      elements: data.elements,
      stripe: data.stripe,
      formData: data.formData,
      amount,
      merchantTransactionId,
      paymentDescription,
      statementDescriptorSuffix,
      handlePaymentMethodError,
      authorizeCard,
      originalCheckoutSessionResponse,
      vendorPlatformKey,
      paymentApi,
      stripeApi,
      onProcessingComplete,
      onSuccess,
    }).catch(() => {
      // ignore
    });
  };

  return isGooglePayButtonEnabled || isApplePayButtonEnabled ? (
    <StripeExpressCheckoutElementWrapper
      vendorPlatformKey={vendorPlatformKey}
      amount={amount}
      styleConfig={styleConfig}
    >
      <ExpressCheckoutWallet
        onLoadComplete={handleOnLoadComplete}
        onSubmit={onSubmit}
        googlePayButtonEnabled={isGooglePayButtonEnabled}
        applePayButtonEnabled={isApplePayButtonEnabled}
        merchantName={merchantName}
        styleConfig={styleConfig}
      />
      <SectionDivider
        show={sectionDivider && expressCheckoutWalletAvailable}
      />
    </StripeExpressCheckoutElementWrapper>
  ) : null;
};

export default ExpressCheckoutWalletContainer;
