import React from 'react';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import styled from '@emotion/styled';

import Title from '../Title/Title';

const StyledPageTitleContainer = styled(Box)({
  marginTop: '24px',
  marginBottom: '24px',
});

// TODO: Research if we need this custom styling or maybe we can use one of the variants from Typography. Also, move both StyledSubtitleLabel and StyledPageTitleContainer to separate files.
const StyledSubtitleLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
});

export type PageTitleProps = {
  title?: string;
  subTitle?: string;
  titleId?: string;
  subTitleId?: string;
};

const PageTitle: FC<PageTitleProps> = ({
  title,
  subTitle,
  titleId = 'defaultPageTitleId',
  subTitleId,
}) => {
  return (
    <StyledPageTitleContainer>
      {title ? (
        <Title
          title={title}
          id={titleId}
        />
      ) : null}
      {subTitle ? (
        <Box sx={{ paddingTop: '4px' }}>
          <StyledSubtitleLabel data-testid={subTitleId}>
            {subTitle}
          </StyledSubtitleLabel>
        </Box>
      ) : null}
    </StyledPageTitleContainer>
  );
};

export default PageTitle;
