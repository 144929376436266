import useCcgNavigate from '../../../hooks/useCcgNavigate/useCcgNavigate';

const useOnSuccessHandler = () => {
  const { ccgNavigate } = useCcgNavigate();

  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const onSuccessHandler = (
    showUpdateNotificationAfterNav = false,
  ) => {
    ccgNavigate({
      routes: ['WALLET'],
      state: { showUpdateNotificationAfterNav },
    });
  };

  return onSuccessHandler;
};

export default useOnSuccessHandler;
