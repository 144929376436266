import React, { useContext, useEffect } from 'react';

import {
  getAgent,
  getAssistedByAgent,
  getCustomerId,
  getExpiresAtUtc,
  getIIASPaymentDetails,
  getMetadata,
  getPartialAuthorization,
} from '../../../../utils/session/selectors';
import useFindBankNameWithRoutingNumber from '../../../add-payment-method/components/AddPaymentMethodAchContainer/useFindBankNameWithRoutingNumber';
import { AppContext } from '../../../contextStore/AppContext';
import PaymentApi from '../../../../services/payment/PaymentApi';
import type {
  PayAndStoreAchContainerProps,
  PayAndStoreAchFormData,
} from '../../types';
import PayAndStoreAchForm from '../PayAndStoreAchForm';
import { createConsentObject } from '../../../checkout-v2/utils/createPaymentConsentObject';
import useSessionExpirationReminder from '../../../../hooks/useSessionExpirationReminder';
import shouldTriggerReminder from '../../../../utils/capabilities/shouldTriggerReminder';
import { getTimeDifference } from '../../../../utils/date/getTimeDifference';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import noop from '../../../../utils/function/noop';

const PayAndStoreAchContainer = ({
  amount,
  formTitle,
  showSaveFeatureBlock,
  isInFocus,
  onCancel,
  onSuccess,
  onBackClick,
  onBeforeSubmit,
  onLoadComplete = noop,
}: PayAndStoreAchContainerProps) => {
  const {
    paymentDescription,
    merchantTransactionId,
    authorizeCard,
    statementDescriptorSuffix,
    originalCheckoutSessionResponse,
    setData,
  } = useContext(AppContext);
  const { notify } = useContext(NotificationContext);
  const expiresAt = getExpiresAtUtc(
    originalCheckoutSessionResponse,
  );
  const timeDifference = getTimeDifference(
    new Date(expiresAt),
    new Date(new Date().toISOString()),
  );

  useSessionExpirationReminder({
    expiresAt,
    notify,
    timeDifference,
    shouldTriggerReminder: shouldTriggerReminder(
      originalCheckoutSessionResponse,
    ),
  });

  const [bankName, onRoutingNumberInput] =
    useFindBankNameWithRoutingNumber();

  const agent = getAgent(originalCheckoutSessionResponse);
  const assistedByAgent = getAssistedByAgent(
    originalCheckoutSessionResponse,
  );

  const showFieldsForAuthenticatedUser = getCustomerId(
    originalCheckoutSessionResponse,
  );

  const onSubmitAchForm = (data: PayAndStoreAchFormData) => {
    const consentResponse = createConsentObject(
      originalCheckoutSessionResponse,
    );
    PaymentApi.payAndStore({
      paymentMethod: {
        type: 'BANK_ACCOUNT',
        vendor: 'STRIPE',
        nickname: data.nickname,
        default: data.default,
        savePaymentMethod: data.savePaymentMethod,
        paymentMethodDetails: {
          type: 'BANK_ACCOUNT',
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          accountType: data.accountType,
          nameOnAccount: data.nameOnAccount,
        },
      },
      paymentDescription,
      paymentDetails: getIIASPaymentDetails(
        originalCheckoutSessionResponse,
      ),
      amount,
      merchantTransactionId,
      authorizeCard,
      partialAuthorization: getPartialAuthorization(
        originalCheckoutSessionResponse,
      ),
      statementDescriptorSuffix,
      metadata: getMetadata(originalCheckoutSessionResponse),
      consent: consentResponse,
      agent,
      assistedByAgent,
    })
      .then(() => {
        onSuccess?.();
      })
      .catch((err: unknown) => {
        // We don't call onError because that calls notify which alerts the user with a non user friendly message.
        // The error from the backend is shown to the user via axios interceptor.
        console.error(err);
      })
      .finally(() => {
        setData({ overlayLoaderConfig: { show: false } });
      });
  };

  const handleOnSubmit = (data: PayAndStoreAchFormData) => {
    onBeforeSubmit?.();
    setData({ overlayLoaderConfig: { show: true } });
    onSubmitAchForm(data);
  };

  useEffect(() => {
    onLoadComplete();
  }, []);

  return (
    <PayAndStoreAchForm
      showFieldsForAuthenticatedUser={
        !!showFieldsForAuthenticatedUser
      }
      formTitle={formTitle}
      bankName={bankName}
      amount={amount}
      actionType="make_payment"
      showSaveFeatureBlock={showSaveFeatureBlock}
      isInFocus={isInFocus}
      onCancel={onCancel}
      onSubmit={handleOnSubmit}
      onRoutingNumberInput={onRoutingNumberInput}
      onBackClick={onBackClick}
    />
  );
};

export default PayAndStoreAchContainer;
