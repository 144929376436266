import React from 'react';
import { MemoryRouter as CcgMemoryRouter } from 'react-router';

import { routes } from '../routes';
import type { RootPathNameValues } from '../types/PathNameValues';
import type { UiRouteDescriptor } from '../types/UiRouteDescriptor';

const CcgPrimaryRouter = ({
  initialUiRoute,
}: {
  initialUiRoute: UiRouteDescriptor<RootPathNameValues>;
}) => {
  return (
    <CcgMemoryRouter
      initialEntries={[initialUiRoute]}
      initialIndex={1}
    >
      {routes}
    </CcgMemoryRouter>
  );
};

export default CcgPrimaryRouter;
