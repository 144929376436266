import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { DeprecatedSuccessPayloadObject } from '../../types';

export const getPayload = (
  checkoutSessionResponse: CheckoutSessionsResponse,
): DeprecatedSuccessPayloadObject => {
  const { checkoutSession } = checkoutSessionResponse;
  const {
    id,
    checkoutRequest,
    merchantId,
    checkoutSessionStatus,
    customerId,
    vendorMerchantId,
    paymentId,
  } = checkoutSession;

  const {
    paymentType,
    authorizeCard,
    merchantTransactionId,
    amount,
    cashier,
    statementDescriptorSuffix,
    consent,
  } = checkoutRequest;

  return {
    checkoutSession: {
      id,
      checkoutRequest: {
        paymentType,
        authorizeCard,
        merchantTransactionId,
        amount,
        cashier,
        statementDescriptorSuffix,
        consent,
      },
      merchantId,
      checkoutSessionStatus,
      customerId,
      vendorMerchantId,
      paymentId,
    },
  };
};
