import React from 'react';
import type { ElementType, ReactNode } from 'react';
import { styled, FormLabel } from '@mui/material';

type FormLabelVisuallyHiddenProps = {
  component: ElementType;
  children: ReactNode;
};

const StyledFormLabelVisuallyHidden = styled(FormLabel)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: '0',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: '0',
}) as typeof FormLabel;

const FormLabelVisuallyHidden = ({
  children,
  component,
}: FormLabelVisuallyHiddenProps) => {
  return (
    <StyledFormLabelVisuallyHidden component={component}>
      {children}
    </StyledFormLabelVisuallyHidden>
  );
};

export default FormLabelVisuallyHidden;
