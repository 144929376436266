import React from 'react';
import type { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import WarningIcon from '../../icons/warning/warning';
import {
  ICON_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../utils/element-ids';

export type WarningCardProps = {
  title?: string;
  description?: string;
};

const WarningCard: FC<WarningCardProps> = ({
  title = 'Warning',
  description,
}) => {
  const titleStyles = {
    marginBottom: '8px',
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box style={{ margin: '60px 0 34px' }}>
        <WarningIcon
          id={ICON_IDS.WARNING_CARD_ICON}
          data-testid={ICON_IDS.WARNING_CARD_ICON}
        />
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          component="h1"
          variant="h4"
          id={TITLE_IDS.WARNING_CARD_TITLE}
          data-testid={TITLE_IDS.WARNING_CARD_TITLE}
          style={titleStyles}
        >
          {title}
        </Typography>
      </Box>
      {description ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="subtitle1"
            id={TEXT_CONTENT_IDS.WARNING_CARD_DESCRIPTION}
            data-testid={
              TEXT_CONTENT_IDS.WARNING_CARD_DESCRIPTION
            }
          >
            {description}
          </Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default WarningCard;
