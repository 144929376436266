import type { FC } from 'react';
import React, { useContext, useEffect } from 'react';

import { httpClientService } from '../../services/settingsApi/httpClientService';
import onErrorHandler from '../../services/shared/onErrorHandler';
import { settingsApi } from '../../services/settingsApi/settingsApi';
import { appInsights } from '../../services/AppInsights/app-insights';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import generateErrorObject from '../../capabilities/checkout-v2/utils/generate-error-object';
import useNotificationContextProvider from '../../capabilities/contextStore/NotificationContext/useNotificationContextProvider';
import type { AppEnv } from '../../shared/types/app/AppEnv';

type WidgetSettingsLoaderProps = {
  appEnv: AppEnv;
  checkoutSessionId: string;
};

const WidgetSettingsLoader: FC<WidgetSettingsLoaderProps> = ({
  appEnv,
  checkoutSessionId,
  children,
}) => {
  const { setIsAuthorized, setData } = useContext(AppContext);
  const { notify } = useNotificationContextProvider();
  const { postMessage } = useEnvironmentContextProvider();

  const handleWidgetInitializerError = (error: Error) => {
    if (postMessage) {
      postMessage({ type: 'hideModal' });
      postMessage({
        type: 'onError',
        data: generateErrorObject({
          title: 'INITIALIZATION_ERROR',
          detail: `${error.message} - on mount`,
        }),
      });
    } else {
      console.error(
        "[WidgetSettingsLoader] Widget setup error: can't proceed due to undefined postMessage.",
        error,
      );
    }
  };

  useEffect(() => {
    (async () => {
      httpClientService.init({
        appEnv,
        onUnAuthorizedCallback: () => {
          setIsAuthorized(false);
        },
        onError: onErrorHandler({ notify, setData }),
      });

      if (checkoutSessionId) {
        setData({ appEnv });
      }

      const aiConfig = await settingsApi.getAIConfig();
      appInsights.init(aiConfig);
    })().catch((error) => {
      handleWidgetInitializerError(error as Error);
    });
  }, []);

  return <>{children}</>;
};

export default WidgetSettingsLoader;
