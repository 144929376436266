import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import { Box, Grid } from '@mui/material';

import { AmountLine } from '../components/AmountLine/AmountLine';
import { SubmitCancelButtons } from '../components/SubmitCancelButtons/SubmitCancelButtons';
import { AppContext } from '../contextStore/AppContext';
import { NotificationContext } from '../contextStore/NotificationContext';
import type { CloseableNotificationType } from '../../ui/NotificationAlert/NotificationAlert';
import noop from '../../utils/function/noop';
import { logPaymentStatusEvent } from '../../services/AppInsights/Events/log-payment-status-event';
import { SUCCESSFUL_PAYMENT_SUBMISSION_STATUSES } from '../../utils/constants';
import {
  CONTAINER_IDS,
  TEXT_CONTENT_IDS,
} from '../../utils/element-ids';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import Divider from '../../ui/Divider/Divider';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import AchAuthorizationConsent from '../../components/AchAuthorizationConsent/AchAuthorizationConsent';
import { ACH_AUTHORIZATION_AGREEMENT } from '../pay-and-store/constants';
import {
  getAgent,
  getMerchantConsentText,
  getMetadata,
  getIIASPaymentDetails,
  getAssistedByAgent,
  getPartialAuthorization,
} from '../../utils/session/selectors';
import PaymentMethodTypes from '../../services/commonCheckout/utils/PaymentMethodTypes';
import validateWalletPaymentForm from '../../utils/capabilities/validateWalletPaymentForm';
import { createConsentObject } from '../checkout-v2/utils/createPaymentConsentObject';
import ContentSpacer from '../../ui/ContentSpacer/ContentSpacer';
import useShowAddPaymentMethodWarning from '../../shared/hooks/add-payment-method/useShowAddPaymentMethodWarning';
import isAuthRequiredForPayment from '../../utils/capabilities/isAuthRequiredForPayment';
import authorizeAndConfirmPayment from '../../utils/capabilities/authorizeAndConfirmCardPayment';
import CancelCallbackContext from '../checkout-v2/context/cancel-callback-context';
import useFormConfigManager from '../../hooks/useFormConfigManager/useFormConfigManager';
import { isConsentTextAvailableAndValid } from '../../utils/capabilities/isConsentTextAvailableAndValid';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import isSecondaryTypeDrawer from '../../utils/container-config/isSecondaryTypeDrawer';
import isDrawerType from '../../utils/container-config/isDrawerType';

import { Wallet } from './components/Wallet';
import type { FormattedPaymentMethod } from './components/Wallet/types';
import type {
  CheckoutProps,
  WalletPaymentFormData,
  WalletPaymentFormErrors,
  WalletPaymentFormKeys,
  WalletPaymentFormValues,
} from './types';
import validatePaymentMethod from './components/Wallet/utils/validatePaymentMethod';
import useManagementWalletClickHandler from './hooks/useManageWalletClickHandler';

const invalidPaymentMethodError: CloseableNotificationType = {
  severity: 'error',
  title: '',
  closeable: true,
};

const SUBMIT_BUTTON_LABEL = 'Submit payment';
const CANCEL_BUTTON_LABEL = 'Cancel';

export const Checkout: React.FC<CheckoutProps> = ({
  paymentApi,
  formattedPaymentMethods,
  onFailure = noop,
}) => {
  const {
    customerId,
    checkoutSessionId,
    amount,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    authorizeCard,
    paymentDescription,
    statementDescriptorSuffix,
    merchantTransactionId,
    setData,
    vendorPlatformKey,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);
  const { notify, closeNotification } = useContext(
    NotificationContext,
  );

  // TODO: move useContext(CancelCallbackContext) back to PaymentWithWalletRouteContainer.tsx
  // hint: loading react component as declarative vs function is messing up the loading order of components for primary-secondary container types
  const { onCancel } = useContext(CancelCallbackContext);

  const { containerConfig } = useEnvironmentContextProvider();

  useShowAddPaymentMethodWarning();

  const manageWalletClickHandler =
    useManagementWalletClickHandler();

  const [formData, setFormData] =
    useState<WalletPaymentFormData>({
      achAuthorization: false,
    });

  const [formErrors, setFormErrors] = useState<
    Partial<WalletPaymentFormErrors>
  >({});

  const handleOnChange = (
    key: WalletPaymentFormKeys,
    value: WalletPaymentFormValues,
  ) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const noPaymentMethodAvailable =
    formattedPaymentMethods.length === 0;
  const userSelectedPaymentMethod = formattedPaymentMethods.find(
    (paymentMethod) =>
      paymentMethod.id === selectedPaymentMethodId,
  );

  const consentText = getMerchantConsentText(
    originalCheckoutSessionResponse,
  );
  const showAchAuthorization =
    isConsentTextAvailableAndValid(consentText) &&
    userSelectedPaymentMethod?.type ===
      PaymentMethodTypes.BANK_ACCOUNT;

  const resetForm = () => {
    setFormData({
      achAuthorization: false,
    });
    setFormErrors({});
    closeNotification();
  };

  // on confirming payment - pay button click
  const onPaymentMethodSelected = (
    paymentMethod:
      | FormattedPaymentMethod<PaymentMethodType>
      | undefined,
  ) => {
    if (paymentMethod?.paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethod.paymentMethodId);
      resetForm();
    }
  };

  const validatePayment = () => {
    // validate if chosen payment method is valid
    const paymentMethodError = validatePaymentMethod({
      formattedPaymentMethod: userSelectedPaymentMethod,
      options: {
        consentText,
      },
      noPaymentMethodAvailable,
    });

    if (paymentMethodError) {
      const { errorTitle, errorMessage } = paymentMethodError;
      invalidPaymentMethodError.title = errorTitle;
      invalidPaymentMethodError.message = errorMessage;
      notify(invalidPaymentMethodError);
      return false;
    }

    // validate if payment form is valid
    const errors = validateWalletPaymentForm({
      formData,
      options: {
        paymentMethodType:
          // userSelectedPaymentMethod being undefined, handled via 'validatePaymentMethod' method
          (
            userSelectedPaymentMethod as FormattedPaymentMethod<PaymentMethodType>
          ).type,
      },
    });

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }

    return true;
  };

  // on confirming payment - pay button click
  const handleSubmit = async () => {
    closeNotification();

    if (!validatePayment()) {
      return;
    }

    setData({ overlayLoaderConfig: { show: true } });

    try {
      // This is already checked in validatePayment but to make TypeScript
      // happy, we are checking again to have it in scope
      if (!userSelectedPaymentMethod) {
        return;
      }

      let authRequired = true;
      let consent;

      if (
        userSelectedPaymentMethod.type ===
        PaymentMethodTypes.BANK_ACCOUNT
      ) {
        authRequired = false;
        consent = createConsentObject(
          originalCheckoutSessionResponse,
        );
      }

      let paymentResponse = await paymentApi.payAndStore({
        authRequired,
        agent: getAgent(originalCheckoutSessionResponse),
        assistedByAgent: getAssistedByAgent(
          originalCheckoutSessionResponse,
        ),
        paymentDescription,
        paymentDetails: getIIASPaymentDetails(
          originalCheckoutSessionResponse,
        ),
        amount,
        merchantTransactionId,
        authorizeCard,
        partialAuthorization: getPartialAuthorization(
          originalCheckoutSessionResponse,
        ),
        statementDescriptorSuffix,
        paymentMethodId: userSelectedPaymentMethod.id,
        paymentMethodType: userSelectedPaymentMethod.type,
        metadata: getMetadata(originalCheckoutSessionResponse),
        consent,
      });

      if (!paymentResponse) {
        throw new Error('paymentResponse unavailable');
      }

      const {
        status,
        id: paymentId,
        vendorMerchantId,
        vendorPaymentSecret,
      } = paymentResponse.data;

      if (isAuthRequiredForPayment(status)) {
        if (vendorPaymentSecret) {
          paymentResponse = await authorizeAndConfirmPayment({
            paymentId,
            vendorPlatformKey,
            vendorMerchantId,
            vendorPaymentSecret,
            paymentApi,
          });
        } else {
          throw new Error(
            'vendorPaymentSecret is required to perform payment authorization',
          );
        }
      }
      if (
        paymentResponse &&
        SUCCESSFUL_PAYMENT_SUBMISSION_STATUSES.includes(
          paymentResponse?.data.status,
        )
      ) {
        setData({ isChildWaitingOnGlobalSession: true });
      } else {
        onFailure();
        logPaymentStatusEvent({
          checkoutSessionId,
          checkoutType: 'hsid',
          paymentStatus: 'Failed Attempt',
          customerId,
        });
      }
    } catch (ex) {
      console.error(
        `Payment failed. Error: ${JSON.stringify(ex.message)}`,
      );
    } finally {
      setData({ overlayLoaderConfig: { show: false } });
    }
  };

  const handleSubmitSync = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit();
  }, [
    selectedPaymentMethodId,
    originalCheckoutSessionResponse,
    formData,
  ]);

  useEffect(() => {
    if (!selectedPaymentMethodId) {
      const selectedCard =
        formattedPaymentMethods?.length === 1
          ? formattedPaymentMethods[0]
          : formattedPaymentMethods.find((c) => c.isDefault);
      if (selectedCard) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedPaymentMethodId(selectedCard.id);
      }
    }
  }, [formattedPaymentMethods]);

  const { showActionsOnForm } = useFormConfigManager({
    title: '',
    titleTestId: '',
    processHeaderConfig: false,
    isInFocus: isDrawerType(containerConfig),
    shouldOverrideFormConfigTitleAndActionsOnForm:
      isSecondaryTypeDrawer(containerConfig),
    primaryActionConfig: {
      testId: TEXT_CONTENT_IDS.WALLET_SUBMIT_PAYMENT_BUTTON,
      label: SUBMIT_BUTTON_LABEL,
      handler: handleSubmitSync,
    },
    secondaryActionConfig: {
      testId: TEXT_CONTENT_IDS.WALLET_CANCEL_BUTTON,
      label: CANCEL_BUTTON_LABEL,
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <form data-testid={CONTAINER_IDS.WALLET_FORM}>
      <Box>
        <Box>
          <Wallet
            formattedPaymentMethods={formattedPaymentMethods}
            onSelectPayment={(item) => {
              onPaymentMethodSelected(item);
            }}
            onManageWalletClick={() => {
              closeNotification();
              manageWalletClickHandler();
            }}
          />
        </Box>
        <Grid
          item
          style={{ padding: '24px 0 0' }}
        >
          <Divider />
        </Grid>
      </Box>

      <AmountLine
        amount={amount}
        testId={TEXT_CONTENT_IDS.WALLET_AMOUNTLINE_LABEL}
      />

      {showAchAuthorization && (
        <>
          <AchAuthorizationConsent
            title={ACH_AUTHORIZATION_AGREEMENT}
            consentText={getMerchantConsentText(
              originalCheckoutSessionResponse,
            )}
            consentLabel={
              getAgent(originalCheckoutSessionResponse)
                ? 'The customer agrees to the ACH authorization agreement'
                : 'I agree to the ACH authorization agreement'
            }
            consentValue={formData.achAuthorization}
            onChange={(value) => {
              handleOnChange('achAuthorization', value);
            }}
            hasNotConsented={
              !!formErrors?.achAuthorization &&
              !formData.achAuthorization
            }
            errorMessage={formErrors.achAuthorization}
          />
          <ContentSpacer bottom={24} />
        </>
      )}

      <Disclaimer actionType="make_payment" />

      {showActionsOnForm ? (
        <SubmitCancelButtons
          submitLabel={SUBMIT_BUTTON_LABEL}
          cancelLabel={CANCEL_BUTTON_LABEL}
          onCancel={onCancel}
          handleSubmit={handleSubmit}
        />
      ) : null}
    </form>
  );
};
