class StorageWrapper {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  // Method to save data to storage
  setItem<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      this.storage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error saving data to storage: ${error}`);
    }
  }

  // Method to retrieve data from storage
  getItem<T>(key: string): T | null {
    try {
      const serializedValue = this.storage.getItem(key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue) as T;
    } catch (error) {
      console.error(
        `Error retrieving data from storage: ${error}`,
      );
      return null;
    }
  }

  // Method to remove data from storage
  removeItem(key: string): void {
    try {
      this.storage.removeItem(key);
    } catch (error) {
      console.error(
        `Error removing data from storage: ${error}`,
      );
    }
  }

  // Method to clear all data from storage
  clear(): void {
    try {
      this.storage.clear();
    } catch (error) {
      console.error(`Error clearing storage: ${error}`);
    }
  }
}

export default StorageWrapper;
