import type { EnvironmentContextProviderType } from '../../../capabilities/contextStore/EnvironmentContext/EnvironmentContext';
import type { SessionInitializerErrorArgs } from '../../../shared/types/session/SessionInitializerErrorFunc';
import {
  SESSION_ALREADY_PROCESSED_HTTP_STATUS,
  SESSSION_ID_INVALID_STATUS_CODE,
} from '../../constants';
import emitError from '../../widget/emitError';

type HandleSessionInitializerErrorArgs = {
  postMessage: EnvironmentContextProviderType['postMessage'];
  containerConfig: EnvironmentContextProviderType['containerConfig'];
  setEnvironmentState: EnvironmentContextProviderType['setEnvironmentState'];
  appEnvironmentType: EnvironmentContextProviderType['appEnvironmentType'];
};

const handleSessionInitializerError = ({
  initializeSessionError,
  initializeSessionException,
  postMessage,
  setEnvironmentState,
  appEnvironmentType,
}: SessionInitializerErrorArgs &
  HandleSessionInitializerErrorArgs) => {
  // const { containerConfig } = environmentContextState;
  if (appEnvironmentType === 'embedded') {
    if (postMessage) {
      if (
        initializeSessionError?.status ===
        SESSION_ALREADY_PROCESSED_HTTP_STATUS
      ) {
        emitError(postMessage, {
          title: 'SESSION_ALREADY_PROCESSED',
          detail: 'session is already processed',
        });
      } else {
        const parsedTitle =
          initializeSessionError?.title ||
          'INITIALIZATION_ERROR';

        const parsedStatus = initializeSessionError?.status;

        let parsedDetails =
          'Failed to retrieve checkout session details';
        if (initializeSessionError?.detail) {
          parsedDetails = initializeSessionError.detail;
        } else if (initializeSessionException?.message) {
          parsedDetails = `${initializeSessionException.message} - check docs for more details on widget initialization`;
        }

        // Let the host know that the session initialization failed
        /** @note: QA requested to remove status code on invalid sessions scenario */
        emitError(postMessage, {
          title: parsedTitle,
          detail: parsedDetails,
          status:
            parsedStatus === SESSSION_ID_INVALID_STATUS_CODE
              ? undefined
              : parsedStatus,
        });
      }

      // hide the widget in embedded mode
      setEnvironmentState({
        showPaymentContainer: false,
        showWidgetContainer: false,
      });
    } else {
      console.error(
        '[handleSessionInitializerError] postMessage is not defined',
      );
    }
  } else if (appEnvironmentType === 'hosted') {
    // Don't need to do anything, session outcome will take care of session initializer errors
  } else {
    console.error(
      '[handleSessionInitializerError] Unknown appEnvironmentType',
      appEnvironmentType,
    );
  }
};

export default handleSessionInitializerError;
