import type {
  PaymentMethod,
  PaymentMethodType,
} from '../../../../../../services/commonCheckout/types/PaymentMethod';
import { isBankAccountPaymentMethod } from '../../../../../../services/commonCheckout/utils/isBankAccountPaymentMethod';
import { isCardPaymentMethod } from '../../../../../../services/commonCheckout/utils/isCardPaymentMethod';
import type { FormattedPaymentMethod } from '../../types';

import { formatBankAccountPaymentMethod } from './formatBankAccountPaymentMethod';
import { formatCardPaymentMethod } from './formatCardPaymentMethod';

export function formatPaymentMethods(
  paymentMethods: PaymentMethod<PaymentMethodType>[],
): FormattedPaymentMethod<PaymentMethodType>[] {
  return paymentMethods.map((paymentMethod) => {
    if (isCardPaymentMethod(paymentMethod)) {
      return formatCardPaymentMethod(paymentMethod);
    }

    if (isBankAccountPaymentMethod(paymentMethod)) {
      return formatBankAccountPaymentMethod(paymentMethod);
    }

    throw new Error(
      'Invalid (or) Unhandled paymentMethod.paymentMethodDetails.type',
    );
  });
}
