import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const ChaseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 5</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Group">
            <rect
              id="Rectangle"
              fill="#117ACA"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="logo"
              transform="translate(14.400000, 4.800000)"
              fill="#FFFFFF"
            >
              <path
                d="M5.14517013,0.0207448725 C4.86482598,0.0207448725 4.63774329,0.247832265 4.63774329,0.527848111 L4.63774329,4.08443209 L14.0325373,4.08443209 L9.75418489,0.0210737051 L5.14517013,0.0207448725"
                id="logo-ne"
              />
              <path
                d="M14.3762693,5.16039113 C14.3762693,4.87971736 14.1508177,4.65361202 13.8688425,4.65361202 L10.3132395,4.65361202 L10.3132395,14.0487338 L14.374633,9.76842488 L14.3762693,5.16039113"
                id="logo-se"
              />
              <path
                d="M9.23597558,14.3888717 C9.51566625,14.3888717 9.74274894,14.1614596 9.74274894,13.8807913 L9.74274894,10.3255196 L0.347625563,10.3255196 L4.62662885,14.3882182 L9.23597558,14.3888717"
                id="logo-sw"
              />
              <path
                d="M0.00422292326,9.24988412 C0.00422292326,9.53022906 0.23130561,9.7586229 0.512303249,9.7586229 L4.06725012,9.7586229 L4.06725012,0.362847081 L0.00487640614,4.64185063 L0.00422292326,9.24988412"
                id="logo-nw"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default ChaseIcon;
