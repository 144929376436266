import React, { useState } from 'react';
import CardIcon from '@mui/icons-material/CreditCardOutlined';
import BankIcon from '@mui/icons-material/AccountBalanceOutlined';

import Tabs from '../../ui/Tabs/Tabs';
import type { PaymentMethodType } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodConfig';

const tabsConfig = [
  {
    id: 'cardTab',
    value: 'CARD',
    label: 'Card',
    icon: <CardIcon fontSize="large" />,
  },
  {
    id: 'bankAccountTab',
    value: 'BANK_ACCOUNT',
    label: 'Bank',
    icon: <BankIcon fontSize="large" />,
  },
];

type PaymentMethodTabSelectorProps = {
  availablePaymentMethodTypes: PaymentMethodType[];
  onTabSelectionChange: (
    selectedPaymentMethodType: PaymentMethodType,
  ) => void;
};

const PaymentMethodTabSelector = ({
  availablePaymentMethodTypes,
  onTabSelectionChange,
}: PaymentMethodTabSelectorProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const filteredTabsConfig = tabsConfig.filter((tab) =>
    availablePaymentMethodTypes?.includes(
      tab.value as PaymentMethodType,
    ),
  );

  const handleTabSelectionChange = (index: number) => {
    setSelectedIndex(index);
    onTabSelectionChange(
      filteredTabsConfig[index].value as PaymentMethodType,
    );
  };

  return (
    <Tabs
      tabsConfig={filteredTabsConfig}
      onTabSelectionChange={handleTabSelectionChange}
      selectedIndex={selectedIndex}
    />
  );
};

export default PaymentMethodTabSelector;
