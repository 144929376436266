import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const CITIIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 12</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Rectangle"
            fill="#003B70"
            fillRule="nonzero"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Citi"
            transform="translate(10.800000, 5.400000)"
          >
            <path
              d="M6.21166898,10.6462755 L6.17517963,10.6816841 C5.5870172,11.2825764 4.90626399,11.6010433 4.20480382,11.6010433 C2.7406056,11.6010433 1.67774659,10.4995764 1.67774659,8.9797892 C1.67774659,7.46274538 2.7406056,6.35971687 4.20480382,6.35971687 C4.90626399,6.35971687 5.5870172,6.67949218 6.17517963,7.28198804 L6.21166898,7.31845191 L7.15331797,6.17668072 L7.1281501,6.14570334 C6.34504178,5.21773469 5.40541292,4.76598938 4.25025766,4.76598938 C3.09110412,4.76598938 2.03148572,5.15717286 1.26782153,5.86264516 C0.438081075,6.62661129 2.65587269e-09,7.70402227 2.65587269e-09,8.9797892 C2.65587269e-09,10.2549233 0.438081075,11.334993 1.26782153,12.0991703 C2.03148572,12.8078922 3.09110412,13.1969233 4.25025766,13.1969233 C5.40541292,13.1969233 6.34504178,12.7454311 7.1281501,11.8170826 L7.15331797,11.7882577 L6.21166898,10.6462755 Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <polygon
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
              points="8.10485755 13.0415302 9.76837879 13.0415302 9.76837879 4.89829701 8.10485755 4.89829701"
            />
            <path
              d="M16.2845032,11.2708438 C15.8406984,11.5418319 15.4276591,11.6780646 15.0567485,11.6780646 C14.5193005,11.6780646 14.2764178,11.3936136 14.2764178,10.7600138 L14.2764178,6.43918588 L15.9706202,6.43918588 L15.9706202,4.90572486 L14.2764178,4.90572486 L14.2764178,2.37191625 L12.6459347,3.24691965 L12.6459347,4.90572486 L11.2393531,4.90572486 L11.2393531,6.43918588 L12.6459347,6.43918588 L12.6459347,11.0356443 C12.6459347,12.2879462 13.3856936,13.1434515 14.489966,13.1656927 C15.2397836,13.1804211 15.6917954,12.9564483 15.9663274,12.792699 L15.9824041,12.7807137 L16.3829019,11.2105778 L16.2845029,11.2708443 L16.2845032,11.2708438 Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <polygon
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
              points="17.6622982 13.0415302 19.326913 13.0415302 19.326913 4.89829701 17.6622982 4.89829701"
            />
            <path
              d="M20.3233173,3.4533787 C18.8005334,1.29045379 16.2716248,-2.64593776e-09 13.6986507,-2.64593776e-09 C11.1267289,-2.64593776e-09 8.59731469,1.29045379 7.07705684,3.4533787 L6.99907012,3.56466923 L8.91645865,3.56466923 L8.93771237,3.54158396 C10.2427387,2.19090583 11.9463264,1.47661309 13.6986507,1.47661309 C15.4511855,1.47661309 17.1544373,2.19090583 18.4619879,3.54158396 L18.4831995,3.56466923 L20.3999998,3.56466923 L20.3233173,3.4533787 Z"
              id="Path"
              fill="#D9261C"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default CITIIcon;
