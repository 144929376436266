import { useEffect } from 'react';

import type { CloseableNotificationType } from '../ui/NotificationAlert/NotificationAlert';
import { getMinutesRemaining } from '../utils/date/getMinutesRemaining';
import { getTimeDifference } from '../utils/date/getTimeDifference';

type SessionExpirationReminderProps = {
  expiresAt: string;
  notify: (args: CloseableNotificationType) => void;
  timeDifference: number;
  shouldTriggerReminder: boolean;
};

export default function useSessionExpirationReminder({
  notify,
  timeDifference,
  expiresAt,
  shouldTriggerReminder,
}: SessionExpirationReminderProps) {
  useEffect(() => {
    // NOTE: need to use ReturnType instead of NodeJS.Timeout because jest assumes that setTimeout
    // returns a number and is not able to assign it to NodeJS.Timeout
    let timeoutId: ReturnType<typeof setTimeout>;

    if (shouldTriggerReminder) {
      timeoutId = setTimeout(() => {
        const curentTimeDifference = getTimeDifference(
          new Date(expiresAt),
          new Date(new Date().toISOString()),
        );
        const minutesRemaining = getMinutesRemaining(
          curentTimeDifference,
        );
        notify({
          severity: 'warning',
          title: `Your session will expire in ${minutesRemaining} ${
            minutesRemaining === 1 ? 'minute' : 'minutes'
          }`,
        });
      }, Math.max(timeDifference - 300000, 0));
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
}
