import type { EditPaymentMethodCardFormData } from '../types';

const formLabelMap: Record<
  keyof EditPaymentMethodCardFormData,
  string
> = {
  nameOnCard: 'Name on card',
  expiration: 'Expiration',
  lastFour: 'Card number',
  zipCode: 'ZIP',
  nickname: 'Card nickname',
  isDefault: 'Set as default payment method',
  isManufacturerCard: 'Is this a manufacturer card?',
  agent: '',
};

export default formLabelMap;
