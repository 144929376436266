import type { ContainerConfig } from '../../../components/container-types/types';
import type { AppEnvironmentType } from '../../../shared/types/app/AppEnvironmentType';

const isValidAppViewType = (
  appEnvironmentType: AppEnvironmentType,
  containerConfig?: ContainerConfig,
) => {
  return (
    containerConfig?.type === 'inline' ||
    appEnvironmentType === 'hosted'
  );
};

export default isValidAppViewType;
