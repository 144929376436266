import React, { useCallback, useEffect } from 'react';

import { PaymentMethodSelector } from '../../PaymentMethodSelector';
import communicateSelectionWithConsumer from '../../utils/communicateSelectionWithConsumer';
import useEnvironmentContextProvider from '../../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import type PaymentMethodSelectorEmbeddedContainerProps from '../../../../shared/types/capabilities/paymentMethodSelector/PaymentMethodSelectorEmbeddedContainerProps';
import type OnSelectionChangeFunc from '../../../../shared/types/capabilities/paymentMethodSelector/OnSelectionChangeFunc';
import findPaymentMethod from '../../../../utils/paymentMethod/findPaymentMethod';
import { transformPaymentMethodToPaymentMethodSessionOutcome } from '../../../../utils/paymentMethod/transformPaymentMethod';

const PaymentMethodSelectorEmbeddedContainer = ({
  isLoading,
  formattedPaymentMethods,
  paymentMethods,
}: PaymentMethodSelectorEmbeddedContainerProps) => {
  const { postMessage } = useEnvironmentContextProvider();

  const handleSelectionChange = useCallback(
    (paymentMethodId?: string) => {
      const paymentMethod = findPaymentMethod(
        paymentMethods,
        paymentMethodId,
      );

      let transformedPaymentMethod = null;

      if (paymentMethod) {
        transformedPaymentMethod =
          transformPaymentMethodToPaymentMethodSessionOutcome(
            paymentMethod,
          );
      }

      communicateSelectionWithConsumer(
        transformedPaymentMethod,
        postMessage,
      );
    },
    [paymentMethods],
  );

  const onSelectionChange: OnSelectionChangeFunc = useCallback(
    (paymentMethod) => {
      handleSelectionChange(paymentMethod?.id);
    },
    [paymentMethods],
  );

  useEffect(() => {
    return () => {
      communicateSelectionWithConsumer(null, postMessage);
    };
  }, []);

  return (
    <PaymentMethodSelector
      isLoading={isLoading}
      formattedPaymentMethods={formattedPaymentMethods}
      showHostedExperienceActions={false}
      paymentMethods={paymentMethods}
      onSelectionChange={onSelectionChange}
    />
  );
};

export default PaymentMethodSelectorEmbeddedContainer;
