import React, { useContext, useEffect, useState } from 'react';
import type { TooltipProps } from '@mui/material';
import {
  Box,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { TEXT_CONTENT_IDS } from '../../utils/element-ids';
import { settingsApi } from '../../services/settingsApi/settingsApi';
import { getPrivacyPolicyUrl } from '../../utils/session/selectors';
import { AppContext } from '../../capabilities/contextStore/AppContext';

import ExternalLinkIcon from './ExternalLinkIcon';

export type DisclaimerProps = {
  actionType: 'make_payment' | 'add_payment_method';
  privacyUrlTestId?: string;
  termsAndConditionTestId?: string;
};

const DisclaimerLabel = styled(Typography)({
  fontSize: '12.64px !important',
  fontWeight: 400,
  lineHeight: '16px',
});

const LinkText = styled('a')({
  color: '#0C55B8',
  fontSize: '12.64px',
  lineHeight: '16px',
  fontWeight: 700,
  textDecorationLine: 'underline',
});

const CustomToolTip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
    />
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    backgroundColor: '#4b4d4feb',
    color: 'white',
    fontSize: '11px',
    opacity: '1',
    overflowWrap: 'break-word',
    padding: '4px 8px',
    transformOrigin: '20.9625px 23.2px',
    transitionBehavior: 'normal, normal',
    transitionTimingFunction:
      'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '0.2s, 0.133s',
    transitionProperty: 'opacity, transform',
  },
});

const Disclaimer = ({
  actionType,
  privacyUrlTestId,
  termsAndConditionTestId,
}: DisclaimerProps) => {
  const [actionText, setActionText] = useState<string>('');
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] =
    useState<string>('');
  const {
    originalCheckoutSessionResponse,
    checkoutSessionId,
    appEnv,
  } = useContext(AppContext);
  const privacyPolicyUrl = getPrivacyPolicyUrl(
    originalCheckoutSessionResponse,
  );

  useEffect(() => {
    setActionText(
      // eslint-disable-next-line no-nested-ternary
      actionType === 'add_payment_method'
        ? 'saving this payment method'
        : 'submitting your payment',
    );
  }, [actionType]);

  useEffect(() => {
    const fetchEnvData = async () => {
      const baseUrl = await settingsApi.getWalletUrl();
      setTermsAndConditionsUrl(
        `${baseUrl}/payment-terms-and-conditions?checkoutSessionId=${checkoutSessionId}&appEnv=${appEnv}`,
      );
    };
    fetchEnvData().catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <Box>
      <DisclaimerLabel
        data-testid={TEXT_CONTENT_IDS.DISCLAIMER_TEXT}
      >
        By {actionText}, you acknowledge that you have read and
        agree to the{' '}
        <LinkText
          href={termsAndConditionsUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={
            termsAndConditionTestId ??
            TEXT_CONTENT_IDS.TERMS_AND_CONDITIONS_URL
          }
        >
          Payment Service Terms and Conditions{' '}
          <CustomToolTip
            title="Opens in a new window"
            placement="top"
          >
            <ExternalLinkIcon
              id={
                termsAndConditionTestId
                  ? `${termsAndConditionTestId}-icon`
                  : TEXT_CONTENT_IDS.TERMS_AND_CONDITIONS_ICON
              }
              dataTestId={
                termsAndConditionTestId ??
                TEXT_CONTENT_IDS.TERMS_AND_CONDITIONS_URL
              }
            />
          </CustomToolTip>
        </LinkText>
        &nbsp;and that your use of these services is subject to
        the{' '}
        <LinkText
          data-testid={
            privacyUrlTestId ??
            TEXT_CONTENT_IDS.GENERIC_ID_PRIVACY_POLICY_URL
          }
          href={
            privacyPolicyUrl ||
            'https://www.optum.com/privacy-policy.html'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy{' '}
          <CustomToolTip
            title="Opens in a new window"
            placement="top"
          >
            <ExternalLinkIcon
              id={
                privacyUrlTestId
                  ? `${privacyUrlTestId}-icon`
                  : TEXT_CONTENT_IDS.GENERIC_ID_PRIVACY_POLICY_ICON
              }
              dataTestId={
                privacyUrlTestId ??
                TEXT_CONTENT_IDS.GENERIC_ID_PRIVACY_POLICY_URL
              }
            />
          </CustomToolTip>
        </LinkText>
      </DisclaimerLabel>
    </Box>
  );
};

export default Disclaimer;
