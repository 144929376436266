import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { APIError } from './types';

export const logApiError = ({
  errorType,
  error,
  metadata = {},
}: APIError) => {
  const {
    url,
    httpMethod,
    checkoutSessionId,
    customerId,
    request,
    response,
    version,
  } = metadata;

  appInsights.traceError(error, {
    [telemetryKeyPrefix('errorType')]: errorType,
    [telemetryKeyPrefix('url')]: url,
    [telemetryKeyPrefix('httpMethod')]: httpMethod,
    [telemetryKeyPrefix('checkoutSessionId')]: checkoutSessionId,
    [telemetryKeyPrefix('customerId')]: customerId,
    [telemetryKeyPrefix('request')]: request,
    [telemetryKeyPrefix('response')]: response,
    [telemetryKeyPrefix('version')]: version,
  });
};
