import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const DiscoverIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 3</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0 35.9993871 0 35.9993871 9.76112263 0 9.76112263"
        />
        <polygon
          id="path-3"
          points="0 0 0 9.76112263 36.0081497 9.76112263 36.0081497 0"
        />
        <path
          d="M0.0108207852,3.04494043 C0.0108207852,3.04494043 0.0108207852,3.04494043 0.0108207852,3.04677446 C0.0108207852,4.7175363 1.36612923,6.07292626 3.03697258,6.07292626 C4.70958883,6.07292626 6.06493803,4.7175363 6.06493803,3.04677446 C6.06493803,3.04494043 6.06493803,3.04494043 6.06493803,3.04494043 C6.06493803,1.37409708 4.70958883,0.0187886327 3.03697258,0.0187886327 C1.36612923,0.0187886327 0.0108207852,1.37409708 0.0108207852,3.04494043"
          id="path-5"
        />
      </defs>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Mask"
            fill="#EA5D2A"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Forms-/-zz-build-/-Elements-/-Credit-Cards-/-Discover-White"
            transform="translate(3.000000, 8.220472)"
          >
            <g
              id="g12"
              transform="translate(18.263364, 5.166914) scale(-1, 1) rotate(-180.000000) translate(-18.263364, -5.166914) translate(0.121632, 0.206284)"
            >
              <g id="g16-Clipped">
                <mask
                  id="mask-2"
                  fill="white"
                >
                  {/* <use xlink:href="#path-1"></use> */}
                  <polygon
                    id="path-1"
                    points="0 0 35.9993871 0 35.9993871 9.76112263 0 9.76112263"
                  />
                </mask>
                <g id="path20" />
                <g
                  id="g16"
                  mask="url(#mask-2)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <g transform="translate(0.040756, 3.749575)">
                    <path
                      d="M2.92640087,1.57516805 C2.57766999,1.26026486 2.12466423,1.12287553 1.40752332,1.12287553 L1.10965216,1.12287553 L1.10965216,4.88557433 L1.40752332,4.88557433 C2.12466423,4.88557433 2.55983913,4.75725327 2.92640087,4.42539548 C3.31024326,4.08359316 3.54116819,3.55398603 3.54116819,3.00887115 C3.54116819,2.46265584 3.31024326,1.91682772 2.92640087,1.57516805 L2.92640087,1.57516805 Z M1.62985275,5.84964312 L0.000636816456,5.84964312 L0.000636816456,0.159601487 L1.62125522,0.159601487 C2.48303397,0.159601487 3.10528006,0.362914056 3.65151575,0.816470019 C4.30057945,1.35363744 4.68442185,2.1632604 4.68442185,3.00067914 C4.68442185,4.67987752 3.42982211,5.84964312 1.62985275,5.84964312 L1.62985275,5.84964312 Z"
                      id="path22"
                    />
                    <polygon
                      id="path24"
                      points="5.19515883 0.159601487 6.30504942 0.159601487 6.30504942 5.84964312 5.19515883 5.84964312"
                    />
                    <path
                      d="M9.0189105,3.66614724 C8.35289219,3.9126003 8.15730293,4.07515662 8.15730293,4.38258104 C8.15730293,4.74093039 8.50572814,5.01326368 8.98412504,5.01326368 C9.31669607,5.01326368 9.58998712,4.87666909 9.87917312,4.55243272 L10.4598682,5.31288327 C9.98265324,5.73030882 9.41167852,5.94372893 8.78782255,5.94372893 C7.78110234,5.94372893 7.01317302,5.24457579 7.01317302,4.31331578 C7.01317302,3.52938963 7.370748,3.12804243 8.41315031,2.75288112 C8.84761197,2.59963761 9.06873502,2.49752282 9.18034602,2.42882815 C9.40212117,2.28402118 9.51316159,2.07903761 9.51316159,1.8400837 C9.51316159,1.37902858 9.14659985,1.03745042 8.65139099,1.03745042 C8.1220284,1.03745042 7.6956161,1.30220304 7.44009477,1.79637261 L6.72302925,1.10598206 C7.23439796,0.355353771 7.84853356,0.0226808549 8.6930235,0.0226808549 C9.84646622,0.0226808549 10.655539,0.789570892 10.655539,1.89111052 C10.655539,2.79514535 10.2814781,3.20437889 9.0189105,3.66614724"
                      id="path26"
                    />
                    <path
                      d="M11.0056963,3.00067914 C11.0056963,1.32816478 12.3189851,0.0312804243 14.0090858,0.0312804243 C14.4868509,0.0312804243 14.896003,0.125223588 15.4006061,0.362914056 L15.4006061,1.66941689 C14.9569132,1.22531637 14.563921,1.04621302 14.0606628,1.04621302 C12.9429022,1.04621302 12.1495002,1.85663073 12.1495002,3.00887115 C12.1495002,4.10134251 12.9679673,4.96311311 14.0090858,4.96311311 C14.5384484,4.96311311 14.9391638,4.77420787 15.4006061,4.32287313 L15.4006061,5.62874423 C14.9134466,5.87591053 14.5128127,5.97826986 14.0352717,5.97826986 C12.3537705,5.97826986 11.0056963,4.65513848 11.0056963,3.00067914"
                      id="path28"
                    />
                    <polyline
                      id="path30"
                      points="24.2008593 2.02762359 22.683504 5.84964312 21.4710055 5.84964312 23.8856097 0.0136125886 24.4828926 0.0136125886 26.941106 5.84964312 25.7379814 5.84964312 24.2008593 2.02762359"
                    />
                    <polyline
                      id="path32"
                      points="27.4430193 0.159601487 30.5906247 0.159601487 30.5906247 1.12287553 28.5519969 1.12287553 28.5519969 2.65879531 30.5154294 2.65879531 30.5154294 3.62261956 28.5519969 3.62261956 28.5519969 4.88557433 30.5906247 4.88557433 30.5906247 5.84964312 27.4430193 5.84964312 27.4430193 0.159601487"
                    />
                    <path
                      d="M32.7584498,3.23001457 L32.4342338,3.23001457 L32.4342338,4.95339274 L32.775975,4.95339274 C33.4672011,4.95339274 33.8429737,4.66379918 33.8429737,4.11002359 C33.8429737,3.5379892 33.4672011,3.23001457 32.7584498,3.23001457 L32.7584498,3.23001457 Z M34.9851677,4.16979264 C34.9851677,5.23503883 34.2513514,5.84964312 32.9714012,5.84964312 L31.3256637,5.84964312 L31.3256637,0.159601487 L32.4342338,0.159601487 L32.4342338,2.44537519 L32.5789185,2.44537519 L34.1152255,0.159601487 L35.4801524,0.159601487 L33.6889151,2.55674165 C34.5248258,2.72669523 34.9851677,3.29854621 34.9851677,4.16979264 L34.9851677,4.16979264 Z"
                      id="path34"
                    />
                    <path
                      d="M35.7570998,5.95605409 L35.6973413,5.95605409 L35.6973413,6.26965879 L35.7607216,6.26965879 C35.9357719,6.26965879 36.0263151,6.21866258 36.0263151,6.11535002 C36.0263151,6.00915274 35.9345646,5.95605409 35.7570998,5.95605409 Z M36.3800375,6.11975046 C36.3800375,6.35903661 36.1766169,6.48987637 35.8192729,6.48987637 L35.343619,6.48987637 L35.343619,5.28987637 L35.6973413,5.28987637 L35.6973413,5.75529624 L36.1120294,5.28987637 L36.543619,5.28987637 L36.0564962,5.7847303 C36.2647457,5.83020151 36.3800375,5.95316936 36.3800375,6.11975046 L36.3800375,6.11975046 Z"
                      id="path36"
                    />
                    <path
                      d="M35.6939722,5.21211622 C35.4291251,5.21211622 35.2125604,5.4309838 35.2125604,5.70405649 C35.2125604,5.97811066 35.4263016,6.19686607 35.6939722,6.19686607 C35.9574075,6.19686607 36.1722781,5.97309115 36.1722781,5.70405649 C36.1722781,5.43328324 35.9574075,5.21211622 35.6939722,5.21211622 Z M35.696231,6.30488373 C35.3596663,6.30488373 35.0925604,6.03814852 35.0925604,5.7049258 C35.0925604,5.37192741 35.3627722,5.10488373 35.696231,5.10488373 C36.0240428,5.10488373 36.2925604,5.37478769 36.2925604,5.7049258 C36.2925604,6.03343747 36.0240428,6.30488373 35.696231,6.30488373 L35.696231,6.30488373 Z"
                      id="path38"
                    />
                  </g>
                </g>
              </g>
              <g id="g68-Clipped">
                <mask
                  id="mask-4"
                  fill="white"
                >
                  {/* <use xlink:href="#path-3"></use> */}
                  <polygon
                    id="path-3"
                    points="0 0 0 9.76112263 36.0081497 9.76112263 36.0081497 0"
                  />
                </mask>
                <g id="path72" />
                <g
                  id="g68"
                  mask="url(#mask-4)"
                >
                  <g
                    transform="translate(15.793048, 3.688441)"
                    id="g74-Clipped"
                  >
                    <mask
                      id="mask-6"
                      fill="white"
                    >
                      {/* <use xlink:href="#path-5"></use> */}
                      <path
                        d="M0.0108207852,3.04494043 C0.0108207852,3.04494043 0.0108207852,3.04494043 0.0108207852,3.04677446 C0.0108207852,4.7175363 1.36612923,6.07292626 3.03697258,6.07292626 C4.70958883,6.07292626 6.06493803,4.7175363 6.06493803,3.04677446 C6.06493803,3.04494043 6.06493803,3.04494043 6.06493803,3.04494043 C6.06493803,1.37409708 4.70958883,0.0187886327 3.03697258,0.0187886327 C1.36612923,0.0187886327 0.0108207852,1.37409708 0.0108207852,3.04494043"
                        id="path-5"
                      />
                    </mask>
                    {/* <use id="path78" fill="#FFFFFF" xlink:href="#path-5"></use> */}
                    <path
                      id="path78"
                      d="M0.0108207852,3.04494043 C0.0108207852,3.04494043 0.0108207852,3.04494043 0.0108207852,3.04677446 C0.0108207852,4.7175363 1.36612923,6.07292626 3.03697258,6.07292626 C4.70958883,6.07292626 6.06493803,4.7175363 6.06493803,3.04677446 C6.06493803,3.04494043 6.06493803,3.04494043 6.06493803,3.04494043 C6.06493803,1.37409708 4.70958883,0.0187886327 3.03697258,0.0187886327 C1.36612923,0.0187886327 0.0108207852,1.37409708 0.0108207852,3.04494043"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default DiscoverIcon;
