import type PartialDynamicSegmentOptions from '../../../../shared/types/routing/PartialDynamicSegmentOptions';
import type RoutePathsWithTemplate from '../../../../shared/types/routing/RoutePathsWithTemplate';

const routeSegmentValueMissing = (
  route: RoutePathsWithTemplate,
  options?: PartialDynamicSegmentOptions,
) => {
  if (typeof route !== 'string') return false;

  let result = false;

  const dynamicSegment = route.split(':')[1];
  if (dynamicSegment && !options?.paymentMethodId) {
    result = true;
  }

  return result;
};

export default routeSegmentValueMissing;
