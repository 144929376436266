import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../contextStore/AppContext';
import usePaymentMethodSelectorActivityTracker from '../../../shared/hooks/payment-method-selector/usePaymentMethodSelectorActivityTracker';
import type { FormattedPaymentMethod } from '../../checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../../services/customer/types';
import { findPaymentMethodIdFromRequest } from '../utils/findPaymentMethodIdFromRequest';

const usePaymentMethodSelectionManager = (
  paymentMethodIdFromRequest: string | null = null,
  formattedPaymentMethods: FormattedPaymentMethod<PaymentMethodType>[],
) => {
  const {
    paymentMethodSelectorActivityStack,
    isPrimaryRouteInFocus,
  } = useContext(AppContext);
  const [
    selectedPaymentMethodChangeObject,
    setSelectedPaymentMethodChangeObject,
  ] = useState<{
    selectedPaymentMethodId: string | null;
  }>({ selectedPaymentMethodId: null });

  const { getSelectedPaymentMethod, addPaymentMethodIdToStack } =
    usePaymentMethodSelectorActivityTracker();

  const wrappedSetSelectedPaymentMethod = (
    paymentMethodId: string,
  ) => {
    if (!isPrimaryRouteInFocus) return;

    addPaymentMethodIdToStack(paymentMethodId);
  };

  const getComputedSelectedPaymentMethodId = () => {
    if (!Array.isArray(formattedPaymentMethods)) {
      return null;
    }

    if (
      selectedPaymentMethodChangeObject.selectedPaymentMethodId
    ) {
      return selectedPaymentMethodChangeObject.selectedPaymentMethodId;
    }

    if (getSelectedPaymentMethod()) {
      return getSelectedPaymentMethod();
    }

    if (
      paymentMethodIdFromRequest &&
      findPaymentMethodIdFromRequest(
        paymentMethodIdFromRequest,
        formattedPaymentMethods,
      )
    ) {
      return paymentMethodIdFromRequest;
    }

    return null;
  };

  useEffect(() => {
    if (!isPrimaryRouteInFocus) return;

    if (paymentMethodSelectorActivityStack?.length) {
      setSelectedPaymentMethodChangeObject({
        selectedPaymentMethodId: getSelectedPaymentMethod(),
      });
    } else {
      setSelectedPaymentMethodChangeObject({
        selectedPaymentMethodId: null,
      });
    }
  }, [
    paymentMethodSelectorActivityStack,
    isPrimaryRouteInFocus,
  ]);

  useEffect(() => {
    setSelectedPaymentMethodChangeObject({
      selectedPaymentMethodId:
        getComputedSelectedPaymentMethodId(),
    });
  }, [paymentMethodIdFromRequest, formattedPaymentMethods]);

  useEffect(() => {
    if (isPrimaryRouteInFocus) {
      setSelectedPaymentMethodChangeObject({
        selectedPaymentMethodId:
          getComputedSelectedPaymentMethodId(),
      });
    } else {
      setSelectedPaymentMethodChangeObject({
        selectedPaymentMethodId: null,
      });
    }
  }, [isPrimaryRouteInFocus]);

  return {
    setSelectedPaymentMethodId: wrappedSetSelectedPaymentMethod,
    selectedPaymentMethodChangeObject,
  };
};

export default usePaymentMethodSelectionManager;
