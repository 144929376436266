import type {
  PaymentMethod,
  PaymentMethodType,
} from '../types/PaymentMethod';

import PaymentMethodTypes from './PaymentMethodTypes';

export function isCardPaymentMethod(
  paymentMethod: PaymentMethod<PaymentMethodType>,
): paymentMethod is PaymentMethod<'CARD'> {
  return (
    paymentMethod.paymentMethodDetails.type ===
    PaymentMethodTypes.CARD
  );
}
