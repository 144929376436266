import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const AllyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 6</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Group">
            <rect
              id="Rectangle"
              fill="#650360"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Ally_Bank_logo"
              transform="translate(15.000000, 6.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M11.8983214,5.97341684 C11.8983214,5.15609684 11.7536271,4.38674526 11.4642771,3.66535789 C11.1749014,2.94398737 10.7716007,2.31855579 10.2543729,1.78906316 C9.73712571,1.25958737 9.11861143,0.838488421 8.39882786,0.52576 C7.67902714,0.213056842 6.88870286,0.0566863158 6.02785286,0.0566863158 C2.28281786,0.0566863158 0.00907928571,2.66321263 0.00907928571,5.97341684 C0.00907928571,9.21417263 2.59889357,11.7483158 5.37315643,11.81552 L8.44244357,8.47906737 L8.39246571,8.39614737 L8.39246571,11.5809832 L11.8983214,11.5809832 L11.8983214,5.97341684 Z M8.37985071,8.40867789 L5.96455071,8.41470105 C4.30953,8.41470105 2.47969714,6.07456842 4.34594143,4.24106526 C6.00178286,2.61427368 8.48290286,3.94853263 8.48290286,6.21240421 L8.37985071,8.40867789 Z"
                id="text3259"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default AllyIcon;
