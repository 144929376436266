import React, { useContext, useEffect } from 'react';
import { Grid, FormControl, RadioGroup } from '@mui/material';

import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import type { FormattedPaymentMethod } from '../checkout/components/Wallet/types';
import PageTitle from '../../ui/PageTitle/PageTitle';
import ContentSpacer from '../../ui/ContentSpacer/ContentSpacer';
import {
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../utils/element-ids';
import CallToActionCard from '../../components/CallToActionCard/CallToActionCard';
import PaymentMethodDetails from '../../components/PaymentMethodButton/PaymentMethodDetails';
import useManagementWalletClickHandler from '../checkout/hooks/useManageWalletClickHandler';
import {
  CANCEL_BUTTON,
  PAYMENT_METHOD_SELECTOR_CONFIRM_BUTTON_LABEL,
} from '../../shared/strings';
import Button from '../../ui/Button/Button';
import type OnConfirmFunc from '../../shared/types/capabilities/paymentMethodSelector/OnConfirmFunc';
import type OnSelectionChangeFunc from '../../shared/types/capabilities/paymentMethodSelector/OnSelectionChangeFunc';
import type PaymentMethodSelectorBaseContainerProps from '../../shared/types/capabilities/paymentMethodSelector/PaymentMethodSelectorBaseContainerProps';
import { getPaymentMethodIdFromRequest } from '../../utils/session/selectors';
import { AppContext } from '../contextStore/AppContext';
import findFormattedPaymentMethod from '../../utils/paymentMethod/findFormattedPaymentMethod';

import {
  FormControlLabelRoot,
  FormControlLabelLabel,
  RadioRoot,
} from './PaymentMethodSelector.styles';
import usePaymentMethodSelectionManager from './hooks/usePaymentMethodSelectionManager';

export type PaymentMethodSelectorProps =
  PaymentMethodSelectorBaseContainerProps & {
    showHostedExperienceActions: boolean;
    onConfirm?: OnConfirmFunc;
    onSelectionChange?: OnSelectionChangeFunc;
    onCancel?: () => void;
  };

export const PaymentMethodSelector = ({
  showHostedExperienceActions = false,
  isLoading,
  formattedPaymentMethods,
  onCancel,
  onConfirm,
  onSelectionChange,
}: PaymentMethodSelectorProps) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);

  const onManageWalletClick = useManagementWalletClickHandler();

  const {
    setSelectedPaymentMethodId,
    selectedPaymentMethodChangeObject,
  } = usePaymentMethodSelectionManager(
    getPaymentMethodIdFromRequest(
      originalCheckoutSessionResponse,
    ),
    formattedPaymentMethods,
  );

  const onPaymentMethodSelected = (
    paymentMethod:
      | FormattedPaymentMethod<PaymentMethodType>
      | undefined,
  ) => {
    if (paymentMethod?.paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethod.paymentMethodId);
    }
  };

  const handleConfirmClick = () => {
    if (onConfirm) {
      onConfirm(
        findFormattedPaymentMethod(
          formattedPaymentMethods,
          selectedPaymentMethodChangeObject.selectedPaymentMethodId,
        ),
      );
    }
  };

  useEffect(() => {
    if (!isLoading && onSelectionChange) {
      onSelectionChange(
        findFormattedPaymentMethod(
          formattedPaymentMethods,
          selectedPaymentMethodChangeObject.selectedPaymentMethodId,
        ),
      );
    }
  }, [
    formattedPaymentMethods,
    isLoading,
    selectedPaymentMethodChangeObject,
  ]);

  if (isLoading) {
    return null;
  }

  const hasPaymentMethods = formattedPaymentMethods.length > 0;

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <PageTitle
          titleId={TITLE_IDS.PAYMENT_METHOD_SELECT_TITLE}
          title="Select payment method"
        />
      </Grid>
      <FormControl component="fieldset">
        <RadioGroup
          name="payment-method-select"
          color="secondary"
          value={
            selectedPaymentMethodChangeObject.selectedPaymentMethodId
          }
          onChange={(event) => {
            const matchingPayment = findFormattedPaymentMethod(
              formattedPaymentMethods,
              event.currentTarget.value,
            );
            if (matchingPayment) {
              onPaymentMethodSelected(matchingPayment);
            }
          }}
          sx={{ rowGap: '16px', marginBottom: 0 }}
        >
          {formattedPaymentMethods.map(
            (formattedPaymentMethod, index) => {
              const paymentMethodInputId = `_ccg_paymentMethodInputId_${formattedPaymentMethod.last4}_${index}`;

              return (
                <FormControlLabelRoot
                  data-testid={`paymentMethodSelect-${
                    index + 1
                  }`}
                  htmlFor={paymentMethodInputId}
                  value={formattedPaymentMethod.paymentMethodId}
                  aria-label={`${formattedPaymentMethod.ariaDescription} options`}
                  control={
                    <RadioRoot
                      inputProps={
                        {
                          'data-testid': paymentMethodInputId,
                          id: paymentMethodInputId,
                        } as Record<string, unknown>
                      }
                      id={formattedPaymentMethod.id}
                    />
                  }
                  key={formattedPaymentMethod.paymentMethodId}
                  label={
                    <FormControlLabelLabel>
                      <PaymentMethodDetails
                        paymentMethod={formattedPaymentMethod}
                        showCaIcon={false}
                      />
                    </FormControlLabelLabel>
                  }
                />
              );
            },
          )}
        </RadioGroup>
      </FormControl>

      {hasPaymentMethods ? <ContentSpacer top={24} /> : null}

      <CallToActionCard
        title="Manage wallet"
        buttonLabel="Manage"
        description="Add, edit, or remove a payment method."
        titleId={TEXT_CONTENT_IDS.CTA_MANAGE_WALLET_TITLE}
        descriptionId={
          TEXT_CONTENT_IDS.CTA_MANAGE_WALLET_DESCRIPTION
        }
        buttonId={ELEMENT_IDS.CTA_MANAGE_WALLET_BUTTON}
        onClick={onManageWalletClick}
      />

      {showHostedExperienceActions ? (
        <>
          <ContentSpacer top={24} />

          <Grid
            container
            direction="column"
            style={{ gap: '16px' }}
          >
            {hasPaymentMethods ? (
              <Button
                id={
                  ELEMENT_IDS.PAYMENT_METHOD_SELECTOR_CONFIRM_BUTTON
                }
                isLoading={false}
                fullWidth
                color="primary"
                variant="contained"
                className="loadingButton"
                onClick={handleConfirmClick}
              >
                {PAYMENT_METHOD_SELECTOR_CONFIRM_BUTTON_LABEL}
              </Button>
            ) : null}
            <Button
              id={
                ELEMENT_IDS.PAYMENT_METHOD_SELECTOR_CANCEL_BUTTON
              }
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              {CANCEL_BUTTON}
            </Button>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
