import React, { useState } from 'react';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

import Button from '../../../ui/Button/Button';
import { TEXT_CONTENT_IDS } from '../../../utils/element-ids';

const StyledSubmitButtonsContainer = styled(Grid)({
  loadingButton: {
    width: '100%',
    margin: '8px 0px',
  },
  '& button': {
    width: '100%',
    margin: '8px 0px',
  },
});

export const SubmitCancelButtons: React.FC<{
  onCancel?: (() => void) | undefined;
  cancelLabel?: string;
  handleSubmit?: (() => Promise<void>) | undefined;
  submitLabel?: string;
}> = ({ cancelLabel, submitLabel, onCancel, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const doSubmit = async () => {
    setIsLoading(true);
    try {
      if (handleSubmit) {
        await handleSubmit();
      }
    } catch (error) {
      // Handle any errors if necessary
      console.error(
        'An error occurred during submission:',
        error,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledSubmitButtonsContainer
      container
      direction="column"
      style={{ marginTop: '16px' }}
    >
      {handleSubmit && (
        <Button
          className="loadingButton"
          isLoading={isLoading}
          testId={TEXT_CONTENT_IDS.WALLET_SUBMIT_PAYMENT_BUTTON}
          onClick={() => {
            doSubmit().then(
              () => {
                // submit success
              },
              () => {
                // submit failed
              },
            );
          }}
          color="primary"
          variant="contained"
        >
          {submitLabel}
        </Button>
      )}
      {onCancel && (
        <Grid>
          <Button
            color="secondary"
            variant="contained"
            testId={TEXT_CONTENT_IDS.WALLET_CANCEL_BUTTON}
            onClick={() => {
              onCancel();
            }}
          >
            {cancelLabel}
          </Button>
        </Grid>
      )}
    </StyledSubmitButtonsContainer>
  );
};
