import React, { useContext } from 'react';
import { useParams } from 'react-router';

import { AppContext } from '../../contextStore/AppContext';
import CustomerApi from '../../../services/customer/CustomerApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { DOCUMENT_TITLE } from '../../../utils/constants';
import { isCardPaymentMethod } from '../../../services/commonCheckout/utils/isCardPaymentMethod';
import EditPaymentMethodAchContainer from '../bankAccount/EditPaymentMethodAchContainer';
import { isBankAccountPaymentMethod } from '../../../services/commonCheckout/utils/isBankAccountPaymentMethod';
import useBackButtonHandler from '../hooks/useBackButtonHandler';
import ErrorCard from '../../../components/ErrorCard/ErrorCard';
import useOnSuccessHandler from '../hooks/useOnSuccessHandler';
import { NotificationContext } from '../../contextStore/NotificationContext';
import usePaymentMethodSelectorActivityTracker from '../../../shared/hooks/payment-method-selector/usePaymentMethodSelectorActivityTracker';
import useCcgNavigate from '../../../hooks/useCcgNavigate/useCcgNavigate';
import isPrimarySecondaryContainer from '../../../utils/container-config/isPrimarySecondaryContainer';
import useEnvironmentContextProvider from '../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { getConfigMode } from '../../../utils/session/selectors';

import EditPaymentMethodCardContainer from './EditPaymentMethodCardContainer';

const EditPaymentMethodRouteContainer = () => {
  const { paymentMethods, originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { containerConfig } = useEnvironmentContextProvider();
  const { closeNotification } = useContext(NotificationContext);
  const { addPaymentMethodIdToStack } =
    usePaymentMethodSelectorActivityTracker();

  const backButtonHandler = useBackButtonHandler();
  const onSuccessHandler = useOnSuccessHandler();

  const { ccgNavigate } = useCcgNavigate();

  const { paymentMethodId = '' } = useParams();
  useDocumentTitle(DOCUMENT_TITLE.EDIT_PAYMENT);

  const matchingPaymentMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.id === paymentMethodId,
  );

  const handleUpdateSuccess = () => {
    addPaymentMethodIdToStack(paymentMethodId);

    onSuccessHandler(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleUpdateFailure = () => {
    // TODO: handle this
  };

  const handleBackClick = () => {
    closeNotification();
    backButtonHandler();
  };

  const handleRemoveClick = () => {
    closeNotification();
    ccgNavigate({
      routes: ['REMOVE_PAYMENT_METHOD'],
      options: { paymentMethodId },
    });
  };

  const handleCancelClick = () => {
    closeNotification();
    backButtonHandler();
  };

  const configMode = getConfigMode(
    originalCheckoutSessionResponse,
  );
  const showBackButton = containerConfig
    ? !(
        configMode === 'WALLET' &&
        isPrimarySecondaryContainer(containerConfig)
      )
    : true;

  if (
    matchingPaymentMethod &&
    isCardPaymentMethod(matchingPaymentMethod)
  ) {
    return (
      <EditPaymentMethodCardContainer
        customerApi={CustomerApi}
        paymentMethod={matchingPaymentMethod}
        onUpdateFailure={handleUpdateFailure}
        onUpdateSuccess={handleUpdateSuccess}
        onBackClick={handleBackClick}
        onRemoveClick={handleRemoveClick}
        onCancelClick={handleCancelClick}
        showBackButton={showBackButton}
      />
    );
  }
  if (
    matchingPaymentMethod &&
    isBankAccountPaymentMethod(matchingPaymentMethod)
  ) {
    return (
      <EditPaymentMethodAchContainer
        customerApi={CustomerApi}
        paymentMethod={matchingPaymentMethod}
        onUpdateFailure={handleUpdateFailure}
        onUpdateSuccess={handleUpdateSuccess}
        onBackClick={handleBackClick}
        onRemoveClick={handleRemoveClick}
        onCancelClick={handleCancelClick}
        showBackButton={showBackButton}
      />
    );
  }

  return <ErrorCard />;
};

export default EditPaymentMethodRouteContainer;
