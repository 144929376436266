import { Paper, styled } from '@mui/material';

const StyledPaperContainer = styled(Paper)(({ theme }) => ({
  // Need to use !important to override the default Paper styles
  width: '100% !important',
  maxWidth: '768px !important',
  borderRadius: `${theme.shape.borderRadius}px !important`,
  overflowX: 'auto',
  [theme.breakpoints.up('sm')]: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '768px',
  },
}));

export default StyledPaperContainer;
