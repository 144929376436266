import { useNavigate } from 'react-router';
import { useContext } from 'react';

import generateAbsoluteRoutePath from '../../utils/routing/generateAbsoluteRoutePath/generateAbsoluteRoutePath';
import type CcgNavigateFunc from '../../shared/types/routing/CcgNavigateFunc';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import isPrimarySecondaryContainer from '../../utils/container-config/isPrimarySecondaryContainer';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import { getConfigMode } from '../../utils/session/selectors';
import type { ConfigModes } from '../../services/commonCheckout/types/SessionConfig/Modes';

const getPrimaryRouteKey = (mode: ConfigModes) => {
  if (mode === 'WALLET') {
    return 'WALLET';
  } else if (mode === 'PAYMENT_WITH_WALLET') {
    return 'PAYMENT_WITH_WALLET';
  } else if (mode === 'PAYMENT_METHOD_SELECT') {
    return 'PAYMENT_METHOD_SELECT';
  }

  return '';
};

const useCcgNavigate = () => {
  const navigate = useNavigate();
  const { containerConfig } = useEnvironmentContextProvider();
  const { initialSessionData } = useContext(AppContext);

  const configMode = getConfigMode(initialSessionData);

  const ccgNavigate: CcgNavigateFunc = ({
    routes,
    options,
    state,
  }) => {
    const parsedRoutes = [...routes];
    let parsedOptions = { ...options };

    if (
      containerConfig &&
      isPrimarySecondaryContainer(containerConfig)
    ) {
      // if the container is primary-secondary, we need to prepend the primary route to the path
      const primaryRoute = getPrimaryRouteKey(configMode);

      if (
        primaryRoute &&
        parsedRoutes.length === 1 &&
        parsedRoutes[0] !== primaryRoute
      ) {
        parsedRoutes.unshift(primaryRoute);
        parsedOptions = Object.assign(parsedOptions, {
          isSecondary: true,
        });
      }
    }

    const path = generateAbsoluteRoutePath({
      routes: parsedRoutes,
      options: parsedOptions,
    });

    navigate(path, { state });
  };

  const ccgNavigateBack = () => {
    navigate(-1);
  };

  return {
    ccgNavigate,
    ccgNavigateBack,
  };
};

export default useCcgNavigate;
