import { getRequestUrlParams } from './getRequestUrlParams';

export function getPollingRequest(
  response: InitialPollingResponse,
  request: RequestParams,
): RequestParams {
  const { pollingUrl, pollingServer } =
    getRequestUrlParams(response);
  if (!pollingUrl) {
    throw Error('known polling url');
  }

  return {
    method: 'GET' as Methods,
    url: pollingUrl,
    headers: request.headers,
    baseURL: pollingServer,
  };
}
