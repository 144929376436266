import type { PaymentMethod } from '../../services/commonCheckout/types/PaymentMethod';
import type { PaymentMethodType } from '../../services/customer/types';

const findPaymentMethod = (
  paymentMethods: PaymentMethod<PaymentMethodType>[],
  paymentMethodId?: string | null,
) => {
  return paymentMethodId
    ? paymentMethods.find(
        (paymentMethod) => paymentMethod.id === paymentMethodId,
      ) || null
    : null;
};

export default findPaymentMethod;
