import type { GenerateErrorObjectArgs } from '../../capabilities/checkout-v2/utils/generate-error-object';
import generateErrorObject from '../../capabilities/checkout-v2/utils/generate-error-object';
import type { PostMessageFunc } from '../../widget-wrapper/types';

const emitError = (
  postMessage: PostMessageFunc,
  data: GenerateErrorObjectArgs,
) => {
  postMessage({
    type: 'onError',
    data: generateErrorObject(data),
  });
};

export default emitError;
