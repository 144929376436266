import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const FifthThirdIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 15</title>
      <defs>
        <polygon
          id="path-1"
          points="0.000114545457 14.399963 16.6970239 14.399963 16.6970239 0.0380419615 0.000114545457 0.0380419615"
        />
      </defs>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Rectangle"
            fill="#1C4094"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Fifth_Third_Bank"
            transform="translate(12.600000, 4.800000)"
          >
            <g id="Fill-1-Clipped">
              <mask
                id="mask-2"
                fill="white"
              >
                {/* <use xlink:href="#path-1"></use> */}
                <polygon
                  id="path-1"
                  points="0.000114545457 14.399963 16.6970239 14.399963 16.6970239 0.0380419615 0.000114545457 0.0380419615"
                />
              </mask>
              <g id="path-1-1" />
              <path
                d="M16.6970239,4.33413528 C16.6970239,1.96098838 14.7100421,0.0376317051 12.2587693,0.0376317051 L0.000114545457,0.0376317051 L0.000114545457,10.1038324 C0.000114545457,12.4766063 1.9870964,14.399963 4.43875099,14.399963 L16.6970239,14.399963 L16.6970239,9.0397764 L16.6970239,4.33413528 Z"
                id="Fill-1"
                fill="#5B8F22"
                fillRule="nonzero"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M12.2547544,0.872727273 L0.743362832,0.872727273 L0.743362832,10.2723673 C0.743362832,12.2098641 2.39979686,13.8181818 4.39657303,13.8181818 L15.9079646,13.8181818 L15.9079646,4.41816598 C15.9079646,2.43933395 14.2515306,0.872727273 12.2547544,0.872727273"
              id="Fill-4"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <path
              d="M12.1645227,1.30909091 L1.18938053,1.30909091 L1.18938053,10.1825063 C1.18938053,11.8745795 2.59307055,13.2363636 4.33721207,13.2363636 L15.3123542,13.2363636 L15.3123542,4.40470459 C15.3550067,2.71225521 13.9090485,1.30909091 12.1645227,1.30909091"
              id="Fill-6"
              fill="#1C4094"
              fillRule="nonzero"
            />
            <path
              d="M6.7895805,2.82558683 L6.74744602,4.89360329 L2.67610619,4.89360329 L2.67610619,2.82558683 L6.7895805,2.82558683 Z M7.04391954,8.1623062 C7.29864162,7.91417439 7.38329362,7.37605268 7.2561241,6.96237396 C7.12895458,6.54869525 6.91675002,6.21760144 6.53524146,5.96984673 C6.02618034,5.59727192 5.55963674,5.59727192 5.55963674,5.59727192 L2.67610619,5.59727192 C3.90604995,6.01095063 4.16038899,7.00385496 4.16038899,7.00385496 C4.45762859,8.45229611 2.80327571,8.69967372 2.80327571,8.69967372 L5.94152834,8.69967372 C6.57775898,8.61708882 6.95926754,8.24526821 7.04391954,8.1623062 L7.04391954,8.1623062 Z M12.5991591,8.69967372 C13.2771412,8.57636202 13.5743808,8.24526821 13.6594158,8.1208252 C13.9137549,7.87269339 14.0405414,7.37605268 13.9137549,7.00385496 C13.7865854,6.59017625 13.5743808,6.34166734 13.2353898,6.09391263 C12.7688462,5.76244172 12.3023026,5.76244172 12.3023026,5.76244172 L9.63097657,5.76244172 C10.7755022,6.13501653 10.9877068,7.08681697 10.9877068,7.08681697 C10.9877068,7.08681697 11.4121159,8.36895701 9.67311105,8.69967372 C9.67311105,8.69967372 9.63097657,8.69967372 9.67311105,8.69967372 C9.84279809,8.74153182 10.2246897,8.94818263 10.5636807,9.27927644 C10.8605373,9.56888925 10.9877068,10.024049 10.9877068,10.024049 C11.2845634,11.3484242 9.63097657,11.6791409 9.63097657,11.6791409 L12.2172675,11.6791409 C13.1082202,11.6791409 13.5322463,11.1821231 13.6172814,11.0995382 C13.8287198,10.8514064 14.0405414,10.3962467 13.9562724,10.024049 C13.8716204,9.56888925 13.6172814,9.32075744 13.4468282,9.15521054 C13.1503547,8.90670163 12.5991591,8.69967372 12.5991591,8.69967372 L12.5991591,8.69967372 Z M7.46794562,11.8857917 L10.0124851,2.61818182 L9.41877201,2.61818182 L6.91675002,11.9272727 L7.46794562,11.8857917 Z"
              id="Fill-8"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default FifthThirdIcon;
