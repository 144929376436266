import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { StripeError } from './types';

export const logStripeError = ({
  errorType,
  error,
  metadata = {},
}: StripeError) => {
  const { elementType, type, code, message } = metadata;

  appInsights.traceError(error, {
    [telemetryKeyPrefix('errorType')]: errorType,
    [telemetryKeyPrefix('elementType')]: elementType,
    [telemetryKeyPrefix('stripeErrorType')]: type,
    [telemetryKeyPrefix('stripeErrorCode')]: code,
    [telemetryKeyPrefix('stripeErrorMessage')]: message,
  });
};
