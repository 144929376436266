import type { ExpressPaymentType } from '@stripe/stripe-js';

import type { ExpressCheckoutWalletType } from '../types/ExpressCheckoutWalletType';

export const getPaymentType = (
  type: ExpressPaymentType,
): ExpressCheckoutWalletType => {
  if (type === 'google_pay') {
    return 'GOOGLE_PAY';
  }

  if (type === 'apple_pay') {
    return 'APPLE_PAY';
  }

  throw new Error(
    `CCG Error: ExpressPaymentType(${type}) not implemented.`,
  );
};
