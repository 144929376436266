import React from 'react';
import { Button, Box, styled } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import type { FC } from 'react';

import noop from '../../utils/function/noop';

export type BackButtonProps = {
  onClick: () => void;
  label?: string;
  testId?: string;
};

const BackButton: FC<BackButtonProps> = ({
  label = 'Back',
  onClick = noop,
  testId = 'defaultBackButtonTestId',
}) => {
  const StyledButton = styled(Button)({
    color: '#0C55B8',
    marginTop: '24px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: '6px 8px !important',
    '&:hover': {
      backgroundColor: '#4b4d4f0a',
      borderColor: 'transparent',
      color: '#0C55B8',
    },
  });

  return (
    <Box sx={{ display: 'inline-block' }}>
      <StyledButton
        size="medium"
        startIcon={<ArrowBackIos sx={{ color: '#0C55B8' }} />}
        onClick={onClick}
        data-testid={testId}
      >
        {label}
      </StyledButton>
    </Box>
  );
};

export default BackButton;
