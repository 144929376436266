import type { UserJourney } from '../../../../../../shared/types/UserJourney';

// TODO: Move these to a constants file when we determine a good place for them
export const SEND_TEXT_TITLE = 'Send a link via text';
export const SEND_TEXT_SUBTITLE_ADD_PAYMENT_METHOD =
  'Send a text message with a link for the customer to add their payment method to their wallet.';
export const SEND_TEXT_SUBTITLE_PAYMENT =
  'Send a text message with a link for the customer to make a payment.';

export default function getSendTextFormHeading(
  userJourney: UserJourney,
) {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    return {
      title: SEND_TEXT_TITLE,
      subTitle: SEND_TEXT_SUBTITLE_ADD_PAYMENT_METHOD,
    };
  }

  if (userJourney === 'MAKE_PAYMENT') {
    return {
      title: SEND_TEXT_TITLE,
      subTitle: SEND_TEXT_SUBTITLE_PAYMENT,
    };
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`${userJourney} not handled.`);
}
