import type { ComponentPropsWithoutRef } from 'react';
import React, { useContext } from 'react';
import { styled } from '@mui/material';
import { OptumDefault } from '@skyline/themes';

import PaymentMethodDetails from '../PaymentMethodButton/PaymentMethodDetails';
import { getPaymentMethodDisplayDetails } from '../../utils/session/selectors';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import formatPaymentMethodForDisplay from '../../utils/function/formatPaymentMethodForDisplay';
import { TEXT_CONTENT_IDS } from '../../utils/element-ids';
import mapPaymentMethodSessionOutcomeToPaymentMethod from '../../capabilities/checkout-v2/utils/mapPaymentMethodSessionOutcomeToPaymentMethod';

export type PaymentMethodDisplayProps =
  ComponentPropsWithoutRef<'div'>;

const PaymentMethodDisplayRoot = (
  props: PaymentMethodDisplayProps,
) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const paymentMethodData = getPaymentMethodDisplayDetails(
    originalCheckoutSessionResponse,
  );

  let content = (
    <div
      data-testid={
        TEXT_CONTENT_IDS.PAYMENT_METHOD_DISPLAY_NOT_AVAILABLE
      }
      className="not-available"
    >
      Payment method not available.
    </div>
  );

  const transformedPaymentMethodData =
    mapPaymentMethodSessionOutcomeToPaymentMethod(
      paymentMethodData,
    );

  const formattedPaymentMethod = formatPaymentMethodForDisplay(
    transformedPaymentMethodData,
  );

  if (formattedPaymentMethod) {
    content = (
      <PaymentMethodDetails
        paymentMethod={formattedPaymentMethod}
        showCaIcon={false}
      />
    );
  }

  return <div {...props}>{content}</div>;
};

const PaymentMethodDisplay = styled(PaymentMethodDisplayRoot)(
  ({ theme = OptumDefault }) => ({
    padding: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    maxHeight: 'fit-content',
    gap: '24px',
    '.not-available': {
      textAlign: 'center',
      ...{
        ...theme.typography.h5,
        lineHeight: '26px',
        fontSize: '16px',
      },
    },
  }),
);

export default PaymentMethodDisplay;
