import React, { useRef } from 'react';
import type { DialogProps, Theme } from '@mui/material';
import {
  Box,
  Button,
  Drawer as MuiDrawer,
  DialogTitle as Title,
  DialogContent as Content,
  DialogActions as Footer,
  IconButton,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useMobile } from '@skyline/react-core';

const drawerSizes = {
  medium: 596,
};
type DrawerSize = keyof typeof drawerSizes;

const drawerMaxWidthMediaQuery = (
  theme: Theme,
  size: DrawerSize,
) => theme.breakpoints.down(drawerSizes[size] + 1);

export interface DrawerProps {
  onClose: () => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  title?: string;
  titleTestId?: string;
  hasBackButton?: boolean;
  onBack?: () => void;
  drawerDataTestIdOnBack?: string;
  headerDetails?: React.ReactNode;
  footer?: React.ReactNode;
  size?: DrawerSize;
  id?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  drawerDataTestIdPrimaryButton?: string;
  drawerDataTestIdSecondaryButton?: string;
  formId?: string;
  'data-role'?: string;
  container?: DialogProps['container'];
}

const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = (
  props,
) => {
  const {
    onClose,
    drawerOpen,
    setDrawerOpen,
    title = '',
    titleTestId = '',
    children,
    hasBackButton,
    onBack,
    drawerDataTestIdOnBack,
    headerDetails,
    footer,
    size = 'medium',
    id = 'drawerDialog',
    primaryButtonText,
    secondaryButtonText,
    onPrimaryAction,
    onSecondaryAction,
    drawerDataTestIdPrimaryButton,
    drawerDataTestIdSecondaryButton,
    formId,
    'data-role': dataRole,
    container,
  } = props;

  const theme = useTheme();
  const isMobile = useMobile('sm');
  const isDrawerMaxWidth = useMediaQuery(
    drawerMaxWidthMediaQuery(theme, size),
  );
  const titleId = titleTestId;
  const maxWidthMediaQuery = drawerMaxWidthMediaQuery(
    theme,
    'medium',
  );
  const contentRef = useRef<HTMLDivElement>();

  const titleContainer = (
    <Title
      component="div"
      sx={{
        borderBottom: '1px solid rgb(177,178,180)',
        p: '24px !important',
      }}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
      >
        <div>
          {hasBackButton && (
            <IconButton
              autoFocus
              size="small"
              onClick={onBack}
              disableRipple
              color="primary"
              className="iconButtonGhost"
              data-testid={
                drawerDataTestIdOnBack || 'drawer_close_button'
              }
            >
              <ChevronLeftIcon titleAccess="backButton" />
            </IconButton>
          )}
        </div>
        <Typography
          variant="subtitle1"
          component="h2"
          id={titleId}
          data-testid={titleId}
          style={{ alignSelf: 'center' }}
        >
          <strong>{title}</strong>
        </Typography>
        <IconButton
          autoFocus={!hasBackButton}
          aria-label="close"
          onClick={onClose}
          size="small"
          disableRipple
          color="primary"
          className="iconButtonGhost"
          data-testid="drawer_close_button"
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Title>
  );

  const footerContainer = (footer ||
    primaryButtonText ||
    secondaryButtonText) && (
    <Footer
      sx={{
        zIndex: 9999,
        borderTop: '1px solid rgb(177,178,180)',
        p: '24px',
      }}
    >
      {footer ||
        ((primaryButtonText || secondaryButtonText) && (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            sx={{ width: '100%' }}
            justifyContent="center"
          >
            {primaryButtonText && (
              <Button
                data-testid={
                  drawerDataTestIdPrimaryButton ||
                  'drawer-primary-button'
                }
                onClick={onPrimaryAction}
                fullWidth={isMobile}
                variant="contained"
                color="primary"
                size="medium"
                form={formId}
                type={
                  typeof formId !== 'undefined'
                    ? 'submit'
                    : 'button'
                }
                data-role={dataRole}
              >
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button
                data-testid={
                  drawerDataTestIdSecondaryButton ||
                  'drawer-secondary-button'
                }
                onClick={onSecondaryAction}
                fullWidth={isMobile}
                variant="contained"
                color="secondary"
                size="medium"
                data-role={dataRole}
              >
                {secondaryButtonText}
              </Button>
            )}
          </Stack>
        ))}
    </Footer>
  );

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          width: `${drawerSizes[size]}px`,
          [maxWidthMediaQuery]: {
            height: '100%',
            width: '100%',
          },
          borderRadius: isDrawerMaxWidth
            ? '0 !important'
            : '12px 0 0 12px',
        },
      }}
      id={id}
      anchor={isDrawerMaxWidth ? 'bottom' : 'right'}
      open={drawerOpen}
      container={container}
      onClose={() => {
        onClose();
        setDrawerOpen(false);
      }}
      transitionDuration={400}
      role="dialog"
      aria-modal="true"
      aria-labelledby={titleId}
    >
      {!isMobile && titleContainer}
      <Content
        ref={contentRef}
        id={`drawer-content-${id}`}
        tabIndex={0}
      >
        {isMobile && titleContainer}
        <Box
          flexGrow="2"
          sx={{ [maxWidthMediaQuery]: { my: 1 } }}
        >
          {headerDetails && (
            <Box sx={{ mb: 2 }}>{headerDetails}</Box>
          )}
          {children}
        </Box>
        {isMobile && footerContainer}
      </Content>
      {!isMobile && footerContainer}
    </MuiDrawer>
  );
};

export default Drawer;
