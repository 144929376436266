import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const HuntingtonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 16</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#66BD29"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Huntington_Bancshares_Inc._logo"
              transform="translate(13.800000, 4.200000)"
              fill="#FFFFFF"
            >
              <path
                d="M6.85195876,4.83536585 L6.85195876,0 L5.03092784,1.01463415 L5.03092784,5.85 L6.85195876,4.83536585 Z M7.53484536,10.7646341 L7.53484536,15.6 L9.36907216,14.5853659 L9.36907216,9.75 L7.53484536,10.7646341 Z M5.03092784,9.36317073 L5.03092784,14.5853659 L6.85195876,15.6 L6.85195876,10.4158537 L10.0486598,8.6402439 L10.0486598,14.1953659 L11.8828866,13.1934146 L11.8828866,2.40341463 L10.0486598,1.40146341 L10.0486598,6.57292683 L5.03092784,9.36317073 Z M4.33484536,1.40146341 L2.51381443,2.41609756 L2.51381443,13.1934146 L4.33484536,14.1953659 L4.33484536,9.02707317 L9.3657732,6.23682927 L9.3657732,1.01463415 L7.53154639,0 L7.53154639,5.18414634 L4.33484536,6.9597561 L4.33484536,1.40146341 Z M1.8342268,2.7902439 L0.280412371,3.65268293 C0.131958763,3.74146341 0,3.92219512 0,4.07756098 L0,11.5256098 C0,11.6682927 0.122061856,11.8585366 0.280412371,11.9504878 L1.8342268,12.8129268 L1.8342268,2.7902439 Z M12.5657732,12.8097561 L14.1195876,11.9473171 C14.2680412,11.8585366 14.4,11.6778049 14.4,11.522439 L14.4,4.07756098 C14.4,3.93487805 14.2779381,3.74463415 14.1195876,3.65268293 L12.5657732,2.7902439 L12.5657732,12.8097561 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default HuntingtonIcon;
