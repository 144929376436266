import type RouteKeys from '../../../shared/types/routing/RouteKeys';
import type RoutePathsWithTemplate from '../../../shared/types/routing/RoutePathsWithTemplate';
import PrimaryRoutePathsMap from '../constants/PrimaryRoutePathsMaps';

const generatePathForRouter = (
  routeKey: RouteKeys,
): RoutePathsWithTemplate => {
  return PrimaryRoutePathsMap[routeKey];
};

export default generatePathForRouter;
