import React, { useContext, useState } from 'react';
import type { FC } from 'react';

import type CustomerApi from '../../../services/customer/CustomerApi';
import type {
  PaymentMethod,
  PaymentMethodType,
} from '../../../services/commonCheckout/types/PaymentMethod';
import noop from '../../../utils/function/noop';
import { getAgent } from '../../../utils/session/selectors';
import {
  getFullYearFromFormExpirationField,
  getMonthFromFormExpirationField,
  getShortCombinedExpirationDate,
} from '../utils/selectors';
import { AppContext } from '../../contextStore/AppContext';
import type {
  EditPaymentMethodCardFormData,
  EditPaymentMethodCardFormProps,
} from '../types';

import EditPaymentMethodCardForm from './EditPaymentMethodCardForm';

export type EditPaymentMethodCardContainerProps = {
  customerApi: typeof CustomerApi;
  paymentMethod: PaymentMethod<'CARD'>;
  onUpdateSuccess?: ({
    paymentMethodId,
    isDefault,
  }: {
    paymentMethodId: string;
    isDefault: boolean;
  }) => void;
  onUpdateFailure?: () => void;
  onBackClick?: () => void;
  onRemoveClick?: () => void;
  onCancelClick: () => void;
  showBackButton?: boolean;
};

const EditPaymentMethodCardContainer: FC<
  EditPaymentMethodCardContainerProps
> = ({
  paymentMethod,
  customerApi,
  onUpdateSuccess = noop,
  onUpdateFailure = noop,
  onBackClick = noop,
  onRemoveClick,
  onCancelClick,
  showBackButton = true,
}) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const updatePaymentMethod = async ({
    nameOnCard,
    expiration,
    zipCode,
    nickname,
    isDefault,
    isManufacturerCard,
    agent,
  }: EditPaymentMethodCardFormData) => {
    setIsLoading(true);

    let expiryMonth = 0;
    let expiryYear = 0;

    if (expiration) {
      expiryMonth = getMonthFromFormExpirationField(expiration);
      expiryYear =
        getFullYearFromFormExpirationField(expiration);
    }

    const paymentMethodCardDetails: {
      type: Extract<PaymentMethodType, 'CARD'>;
      nameOnCard: string;
      expiryYear: number;
      expiryMonth: number;
      zipCode: string;
      manufacturerCard: boolean;
    } = {
      type: 'CARD',
      nameOnCard,
      expiryYear,
      expiryMonth,
      zipCode,
      manufacturerCard: isManufacturerCard,
    };

    const result = await customerApi
      .updatePaymentMethod(
        {
          customerId: paymentMethod.customerId,
          paymentMethodId: paymentMethod.id,
        },
        {
          isDefault,
          nickname,
          agent,
          paymentMethodDetails: paymentMethodCardDetails,
        },
      )
      .finally(() => {
        setIsLoading(false);
      });

    if (
      !result?.errors ||
      (result.errors && result.errors.length === 0)
    ) {
      onUpdateSuccess({
        paymentMethodId: paymentMethod.id,
        isDefault,
      });
    } else {
      onUpdateFailure();
    }
  };

  const handleOnSubmit:
    | EditPaymentMethodCardFormProps['onSubmit'] = (
    formData,
  ) => {
    updatePaymentMethod(formData).catch((error) => {
      console.error(
        'Error while updating payment method: ',
        error,
      );
    });
  };

  return (
    <EditPaymentMethodCardForm
      expiration={getShortCombinedExpirationDate(
        paymentMethod.paymentMethodDetails.expiryMonth,
        paymentMethod.paymentMethodDetails.expiryYear,
      )}
      lastFour={paymentMethod.paymentMethodDetails.last4}
      nameOnCard={paymentMethod.paymentMethodDetails.nameOnCard}
      zipCode={paymentMethod.zipCode}
      nickname={paymentMethod.nickname}
      isDefault={paymentMethod.default}
      isManufacturerCard={
        paymentMethod.paymentMethodDetails.manufacturerCard
      }
      isLoading={isLoading}
      onSubmit={handleOnSubmit}
      onBackClick={onBackClick}
      agent={getAgent(originalCheckoutSessionResponse)}
      onRemoveClick={onRemoveClick}
      onCancelClick={onCancelClick}
      showBackButton={showBackButton}
    />
  );
};

export default EditPaymentMethodCardContainer;
