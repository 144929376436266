export const getFullYearFromFormExpirationField = (
  expiration: string,
): number => {
  const expirationParts = expiration.split(' / ');

  if (
    expirationParts.length < 2 ||
    expirationParts[1]?.length < 2
  ) {
    return 0;
  }

  return parseInt(`20${expirationParts[1]}`, 10);
};

export const getMonthFromFormExpirationField = (
  expiration: string,
): number => {
  const expirationParts = expiration.split(' / ');

  if (
    expirationParts.length < 2 ||
    expirationParts[0]?.length < 2
  ) {
    return 0;
  }

  return parseInt(expirationParts[0], 10);
};

export const getShortCombinedExpirationDate = (
  expirationMonth: number,
  expirationYear: number,
): string => {
  const transformedMonth =
    `0${expirationMonth.toString()}`.slice(-2);
  return `${transformedMonth}${expirationYear
    .toString()
    .slice(-2)}`;
};
