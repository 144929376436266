import getCookie from './getCookie';

export const getCheckoutSessionId = ({
  urlSearchParams,
}: {
  urlSearchParams: URLSearchParams;
}): string | undefined =>
  urlSearchParams.get('checkoutSessionId') ||
  urlSearchParams.get('checkoutId') ||
  getCookie('ccg-checkout-id') ||
  undefined;
