import type { CheckoutContainersSessionStatus } from '../../checkout-container/types/CheckoutContainersSessionStatus';

export const isConcludedSessionStatus = (
  sessionStatus: CheckoutContainersSessionStatus,
) => {
  return (
    sessionStatus === 'PROCESSING_SUCCEEDED' ||
    sessionStatus === 'PROCESSING_FAILED' ||
    sessionStatus === 'ALREADY_PROCESSED'
  );
};
