import React, { useCallback, useState } from 'react';
import type { FC, Dispatch } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { Stripe, StripeElements } from '@stripe/stripe-js';

import PageTitle from '../../../ui/PageTitle/PageTitle';
import BackButton from '../../../ui/BackButton/BackButton';
import FormField from '../../../ui/FormField/FormField';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import { SETUP_PAYMENT_METHOD_BUTTON_TEXT } from '../dev';
import { CANCEL_BUTTON } from '../../../shared/strings';
import noop from '../../../utils/function/noop';
import { isInvalidNickname } from '../../../utils/function/nicknameUtils';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import Disclaimer from '../../../components/Disclaimer/Disclaimer';
import {
  NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  PAYMENT_METHOD_SETTINGS_TITLE,
} from '../../pay-and-store/constants';
import type { Agent } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import Button from '../../../ui/Button/Button';
import Divider from '../../../ui/Divider/Divider';
import fieldHasCardNumber from '../../../utils/function/fieldHasCardNumber';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

type AddPaymentMethodFormData = {
  nameOnCard: string;
  isDefault: boolean;
  nickname: string;
  isManufacturerCard: boolean;
};
type AddPaymentMethodFormKeys = keyof AddPaymentMethodFormData;
type AddPaymentMethodFormValues =
  AddPaymentMethodFormData[keyof AddPaymentMethodFormData];
type AddPaymentMethodFormErrors = Partial<
  Record<keyof AddPaymentMethodFormData, string>
>;

const formLabelMap: Record<
  keyof AddPaymentMethodFormData,
  string
> = {
  nameOnCard: 'Name on card',
  isDefault: 'Set as default payment method',
  nickname: 'Card nickname',
  isManufacturerCard: 'Is this a manufacturer card?',
};

const validate = ({
  nameOnCard,
  nickname,
}: AddPaymentMethodFormData): AddPaymentMethodFormErrors => {
  const formErrors: AddPaymentMethodFormErrors = {};
  if (!nameOnCard) {
    formErrors.nameOnCard = `${formLabelMap.nameOnCard} is required.`;
  }

  if (fieldHasCardNumber(nameOnCard)) {
    formErrors.nameOnCard =
      NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  if (isInvalidNickname(nickname)) {
    formErrors.nickname = 'Please enter a valid card nickname.';
  }
  if (fieldHasCardNumber(nickname)) {
    formErrors.nickname =
      NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  return formErrors;
};

export type AddPaymentMethodFormSubmitData = {
  formData: AddPaymentMethodFormData;
  stripe: Stripe;
  elements: StripeElements;
};

export type AddPaymentMethodFormStripeProps = {
  isCreatingNewPaymentMethod: boolean;
  formTitle: string;
  agent: Agent | null;
  isInFocus?: boolean;

  onSubmit: (data: AddPaymentMethodFormSubmitData) => void;
  onCancel: () => void;
  onBackClick?: () => void;
  onLoadComplete?: () => void;
  setIsCreatingNewPaymentMethod: Dispatch<
    React.SetStateAction<boolean>
  >;
};

const AddPaymentMethodFormStripe: FC<
  AddPaymentMethodFormStripeProps
> = ({
  onSubmit,
  onBackClick,
  onCancel,
  onLoadComplete = noop,
  isCreatingNewPaymentMethod,
  setIsCreatingNewPaymentMethod,
  formTitle,
  agent,
  isInFocus,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [formData, setFormData] =
    useState<AddPaymentMethodFormData>({
      nameOnCard: '',
      isDefault: false,
      nickname: '',
      isManufacturerCard: false,
    });
  const [formErrors, setFormErrors] =
    useState<AddPaymentMethodFormErrors>({});

  const setFormFields = (
    fields: Partial<AddPaymentMethodFormData>,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      ...fields,
    }));
  };

  const handleOnChange = (
    key: AddPaymentMethodFormKeys,
    value: AddPaymentMethodFormValues,
  ) => {
    setIsCreatingNewPaymentMethod(false);
    setFormFields({ [key]: value });

    const isUpdatedToManufacturerCard =
      key === 'isManufacturerCard' && value;

    if (isUpdatedToManufacturerCard) {
      setFormFields({ isDefault: false });
    }
  };

  const handleOnBlur = (
    key: AddPaymentMethodFormKeys,
    value: AddPaymentMethodFormValues,
  ) => {
    const newFormValues = { ...formData, [key]: value };

    const validationResult = validate(newFormValues);
    setFormErrors(validationResult);

    setFormFields(newFormValues);
  };

  const handleOnSubmit = useCallback(() => {
    const validations = validate(formData);
    setFormErrors(validations);

    if (
      !elements ||
      !stripe ||
      Object.keys(validations).length
    ) {
      return;
    }

    onSubmit({ elements, stripe, formData });
  }, [formData]);

  const { title, showActionsOnForm } = useFormConfigManager({
    title: formTitle,
    titleTestId: TITLE_IDS.MAIN_PAGE_TITLE,
    isInFocus,
    processHeaderConfig: false,
    backActionConfig: {
      label: 'Back',
      testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON,
      handler: onBackClick,
    },
    primaryActionConfig: {
      label: SETUP_PAYMENT_METHOD_BUTTON_TEXT,
      testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_SAVE_BUTTON,
      handler: handleOnSubmit,
    },
    secondaryActionConfig: {
      label: CANCEL_BUTTON,
      testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_CANCEL_BUTTON,
      handler: useCallback(() => {
        onCancel();
      }, []),
    },
  });

  return (
    <Box>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON
          }
        />
      ) : null}

      <PageTitle
        title={title}
        titleId={TITLE_IDS.MAIN_PAGE_TITLE}
      />

      <Box sx={{ mb: '24px' }}>
        <Typography
          variant="body2"
          data-testid={
            TEXT_CONTENT_IDS.ADD_PAYMENT_ALL_FIELDS_REQUIRED_TEXT
          }
        >
          All fields are required unless marked optional.
        </Typography>
      </Box>

      <FormField
        id="nameOnCard"
        label="Name on card"
        placeholder="Name on card"
        value={formData.nameOnCard}
        errorMessage={formErrors.nameOnCard}
        required
        autocomplete="name"
        onChange={(value) => {
          handleOnChange('nameOnCard', value);
        }}
        onBlur={(value) => {
          handleOnBlur('nameOnCard', value);
        }}
      />

      <Grid
        container
        direction="column"
        style={{ gap: '12px' }}
      >
        <PaymentElement
          id="paymentElement"
          options={{
            terms: { card: 'never' },
            wallets: { googlePay: 'never', applePay: 'never' },
          }}
          onChange={() => {
            setIsCreatingNewPaymentMethod(false);
          }}
          onReady={onLoadComplete}
        />
      </Grid>

      <Grid
        container
        direction="column"
        sx={{
          gap: '16px',
          marginTop: '16px',
        }}
      >
        {agent ? (
          <Grid>
            <Typography
              id="manufacturerCardLabel"
              style={{
                fontWeight: 700,
              }}
              data-testid={
                TEXT_CONTENT_IDS.MANUFACTURER_CARD_LABEL
              }
            >
              {formLabelMap.isManufacturerCard}
            </Typography>
            <RadioGroup
              row
              value={formData.isManufacturerCard ? 'Yes' : 'No'}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    data-testid={
                      TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_YES
                    }
                    color="primary"
                    onChange={() => {
                      handleOnChange('isManufacturerCard', true);
                    }}
                  />
                }
                label="Yes"
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_YES
                }
              />

              <FormControlLabel
                value="No"
                control={
                  <Radio
                    color="primary"
                    data-testid={
                      TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_NO
                    }
                    onChange={() => {
                      handleOnChange(
                        'isManufacturerCard',
                        false,
                      );
                    }}
                  />
                }
                label="No"
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_NO
                }
              />
            </RadioGroup>
          </Grid>
        ) : null}
        {!formData.isManufacturerCard ? (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography
                style={{ fontWeight: 700 }}
                data-testid={
                  TEXT_CONTENT_IDS.PAYMENT_METHOD_SETTINGS_LABEL
                }
              >
                {PAYMENT_METHOD_SETTINGS_TITLE}
              </Typography>
            </FormLabel>
            <Checkbox
              id="isDefault"
              value={formData.isDefault}
              label="Set as default payment method"
              testId={
                TEXT_CONTENT_IDS.ADD_PAYMENT_SET_AS_DEFAULT_CHECKBOX
              }
              onChange={(value) => {
                handleOnChange('isDefault', value);
              }}
            />
          </FormControl>
        ) : null}
      </Grid>
      <Grid
        container
        direction="column"
        sx={{
          marginTop: '16px',
        }}
      >
        <FormField
          id="nickname"
          label={formLabelMap.nickname}
          placeholder="Card nickname"
          hintText="(Optional)"
          helperText="Card nickname must be 30 characters or less and can only contain letters and numbers."
          value={formData.nickname}
          errorMessage={formErrors.nickname}
          maxLength={30}
          onChange={(value) => {
            handleOnChange('nickname', value);
          }}
          onBlur={(value) => {
            handleOnBlur('nickname', value);
          }}
        />
      </Grid>
      <Grid
        item
        style={{ paddingTop: '12px' }}
      >
        <Divider />
      </Grid>
      <Grid
        item
        style={{ margin: '24px 0 0' }}
      >
        <Disclaimer actionType="add_payment_method" />
      </Grid>

      {showActionsOnForm ? (
        <Grid
          container
          direction="column"
          sx={{
            gap: '16px',
            marginTop: '24px',
          }}
        >
          <Button
            isLoading={false}
            fullWidth
            color="primary"
            variant="contained"
            className="loadingButton"
            onClick={handleOnSubmit}
            disabled={isCreatingNewPaymentMethod}
            testId={
              TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_SAVE_BUTTON
            }
          >
            {SETUP_PAYMENT_METHOD_BUTTON_TEXT}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            testId={
              TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_CANCEL_BUTTON
            }
          >
            {CANCEL_BUTTON}
          </Button>
        </Grid>
      ) : null}
    </Box>
  );
};

export default AddPaymentMethodFormStripe;
