import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export interface AppInsightsTrackEvent {
  name: string;
  properties?: Record<string, any>;
}

export type AppInsightsArgs = {
  connectionString: string;
  role: string;
};

class AppInsights {
  private appInsightsInstance: ApplicationInsights;

  private initialized = false;

  init({
    connectionString,
    role,
  }: AppInsightsArgs): ApplicationInsights {
    if (!connectionString || !role) {
      throw new Error('AppInsights configuration missing');
    }

    try {
      if (!this.initialized) {
        this.initialize(connectionString);
        this.configTelemetryInitializer({ role });
        this.startMonitoring();
        this.initialized = true;
      }

      return this.appInsightsInstance;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private initialize(
    connectionString: AppInsightsArgs['connectionString'],
  ) {
    this.appInsightsInstance = new ApplicationInsights({
      config: {
        connectionString,
      },
    });
  }

  private configTelemetryInitializer({
    role,
  }: {
    role: AppInsightsArgs['role'];
  }) {
    const telemetryInitializer = (envelope: ITelemetryItem) => {
      if (!envelope.tags) {
        // eslint-disable-next-line no-param-reassign
        envelope.tags = [];
      }
      envelope.tags.push({ 'ai.cloud.role': role });
      envelope.tags.push({
        'ai.cloud.roleInstance': `${role}-instance`,
      });
    };
    this.appInsightsInstance.addTelemetryInitializer(
      telemetryInitializer,
    );
  }

  private startMonitoring() {
    this.appInsightsInstance.loadAppInsights();
    this.appInsightsInstance.trackPageView();
  }

  getAppInsights(): ApplicationInsights {
    if (!this.initialized) {
      throw new Error('AppInsight needs to be initialized');
    }
    return this.appInsightsInstance;
  }

  trackEvent(event: AppInsightsTrackEvent): void {
    if (!this.initialized) {
      throw new Error('AppInsight needs to be initialized');
    }
    this.appInsightsInstance.trackEvent(event);
  }

  traceError(
    error: Error,
    metadata: Record<string, unknown> = {},
  ): void {
    if (!this.initialized) {
      throw new Error('AppInsight needs to be initialized');
    }
    this.appInsightsInstance.trackException({
      exception: error,
      properties: metadata,
    });
  }

  pushTelemetryData() {
    this.appInsightsInstance.flush();
  }
}

export const appInsights = new AppInsights();
