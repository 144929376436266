import type { ExtendedAxiosError } from '../../services/settingsApi/types';
import { HttpStatus } from '../../utils/constants';

type HttpStatusType = typeof HttpStatus;
type HttpStatusValues = HttpStatusType[keyof HttpStatusType];

const retryableHttpStatusCodes: HttpStatusValues[] = [
  HttpStatus.BAD_GATEWAY,
  HttpStatus.SERVICE_UNAVAILABLE,
  HttpStatus.GATEWAY_TIMEOUT,
];

export const isRetryAllowed = (error: ExtendedAxiosError) => {
  if (
    error.config.retryAttempts &&
    error.config.retryCondition
  ) {
    return (
      error.config.retryAttempts > 0 &&
      error.config.retryCondition(error)
    );
  }
  return false;
};

export const isNetworkError = (error: ExtendedAxiosError) => {
  return (
    !error.response && // related to network and not server error
    Boolean(error.code)
  );
};

export const isRetryStatusCodes = ({
  error,
  httpStatusCodes = retryableHttpStatusCodes,
}: {
  error: ExtendedAxiosError;
  httpStatusCodes?: HttpStatusValues[];
}) => {
  if (error?.response?.status) {
    return httpStatusCodes.includes(
      error.response.status as HttpStatusValues,
    );
  }
  return false;
};

export const isGetRequest = (error: ExtendedAxiosError) => {
  return error.config.method === 'get';
};

export const isRetriable = (error: ExtendedAxiosError) => {
  return (
    isGetRequest(error) &&
    (isNetworkError(error) || isRetryStatusCodes({ error }))
  );
};
