import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const MastercardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 2</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Mask"
            fill="#000000"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Forms-/-zz-build-/-Elements-/-Credit-Cards-/-Master-Card-Icon"
            transform="translate(8.741562, 4.556250)"
            fillRule="nonzero"
          >
            <g id="Mastercard_2019_logo">
              <rect
                id="rect887"
                fill="#FF5F00"
                x="8.43122362"
                y="1.62366514"
                width="7.14058163"
                height="11.6626763"
              />
              <path
                d="M9.16670775,7.4560335 C9.16491487,5.18056572 10.20949,3.03064029 11.9994544,1.62572599 C8.95858643,-0.764071445 4.59177808,-0.415976802 1.96790489,2.42537835 C-0.655968296,5.26673351 -0.655968296,9.64739385 1.96790489,12.488749 C4.59177808,15.3301042 8.95858643,15.6781988 11.9994544,13.2884014 C10.2089203,11.8830409 9.16428137,9.7322243 9.16670775,7.4560335 L9.16670775,7.4560335 Z"
                id="path889"
                fill="#EB001B"
              />
              <path
                d="M23.4251466,12.9638685 L23.4251466,11.9695835 L23.6251462,11.9695835 L23.6251462,11.7638685 L23.149146,11.7638685 L23.149146,11.9695835 L23.3371466,11.9695835 L23.3371466,12.9638685 L23.4251466,12.9638685 Z M24.349146,12.9638685 L24.349146,11.7638685 L24.2051455,11.7638685 L24.0371456,12.6210107 L23.8691457,11.7638685 L23.7251459,11.7638685 L23.7251459,12.9638685 L23.8291458,12.9638685 L23.8291458,12.0552976 L23.985146,12.8352974 L24.093146,12.8352974 L24.2491455,12.0552976 L24.2491455,12.9638685 L24.349146,12.9638685 Z"
                id="path891"
                fill="#F79E1B"
              />
              <path
                d="M24,7.4560335 C24,10.2956574 22.3785196,12.8859769 19.8243779,14.1268731 C17.2702361,15.3677694 14.2317802,15.0413867 11.9994544,13.2863411 C13.7886378,11.8802341 14.8332915,9.73059444 14.8332915,7.45500347 C14.8332915,5.17941249 13.7886378,3.02977289 11.9994544,1.62366581 C14.2317802,-0.131379776 17.270236,-0.457762459 19.8243778,0.783133764 C22.3785195,2.02402999 24,4.6143494 24,7.45397325 L24,7.4560335 Z"
                id="path893"
                fill="#F79E1B"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default MastercardIcon;
