import React, { useCallback, useContext, useState } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Grid, Typography } from '@mui/material';

import BackButton from '../../../../../ui/BackButton/BackButton';
import type SendTextFormData from '../../../types/SendTextFormData';
import type SendTextFormErrors from '../../../types/SendTextFormErrors';
import validateSendTextForm from '../../../../../utils/capabilities/validateSendTextForm';
import { NotificationContext } from '../../../../contextStore/NotificationContext';
import MaskedFormField from '../../../../../ui/FormField/MaskedFormField/MaskedFormField';
import { PHONE_NUMBER_MASK } from '../../../shared/constants';
import type {
  ChildSessionPayload,
  ChildSessionModes,
} from '../../../../../services/session/types';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../../../utils/element-ids';
import Button from '../../../../../ui/Button/Button';
import type { UserJourney } from '../../../../../shared/types/UserJourney';
import useFormConfigManager from '../../../../../hooks/useFormConfigManager/useFormConfigManager';
import PageTitle from '../../../../../ui/PageTitle/PageTitle';
import Dropdown from '../../../../../ui/Dropdown/Dropdown';

import getSendTextFormHeading from './utils/getSendTextFormHeading';

export type SendTextFormProps = {
  phoneNumber?: string;
  userJourney: UserJourney;
  childSessionMode: Extract<
    ChildSessionModes,
    'TEXT_TO_ADD' | 'TEXT_TO_PAY'
  >;
  onBackClick?: () => void;
  onSubmit: (
    formData: ChildSessionPayload<'TEXT_TO_ADD' | 'TEXT_TO_PAY'>,
  ) => void;
  onCancel: (() => void) | undefined;
};

const SendTextForm = ({
  phoneNumber = '',
  userJourney = 'ADD_PAYMENT_METHOD',
  childSessionMode = 'TEXT_TO_ADD',
  onBackClick,
  onSubmit,
  onCancel,
}: SendTextFormProps) => {
  const { notify, closeNotification } = useContext(
    NotificationContext,
  );

  const [formData, setFormData] = useState<SendTextFormData>({
    phoneNumber,
  });
  const [formErrors, setFormErrors] =
    useState<SendTextFormErrors>({});

  const [language, setLanguage] = useState<string>('en');

  const { title, subTitle } =
    getSendTextFormHeading(userJourney);

  const handleOnSubmit = useCallback(() => {
    const errors = validateSendTextForm(formData);
    setFormErrors(errors);
    closeNotification();

    if (Object.keys(errors).length) {
      notify({
        title: 'You have fields that need to be corrected.',
        message: 'See errors below.',
        severity: 'error',
      });
      return;
    }

    onSubmit({
      lang: language,
      childSessionMode,
      customer: {
        phone: {
          countryCode: '1',
          number: formData.phoneNumber,
        },
      },
    });
  }, [formData, language]);

  const handleOnChange = (val: string) => {
    if (formErrors) {
      closeNotification();
      setFormErrors({ phoneNumber: '' });
    }
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: val,
    }));
  };

  const { title: titleFromConfigManager, showActionsOnForm } =
    useFormConfigManager({
      title,
      titleTestId: TITLE_IDS.SEND_TEXT_FORM_TITLE,
      backActionConfig: {
        testId: TEXT_CONTENT_IDS.SEND_TEXT_FORM_BACK_BUTTON,
        handler: onBackClick,
      },
      primaryActionConfig: {
        label: 'Send text',
        handler: handleOnSubmit,
        testId: TEXT_CONTENT_IDS.SEND_TEXT_FORM_SUBMIT,
      },
      secondaryActionConfig: {
        label: 'Cancel',
        handler: useCallback(() => onCancel?.(), []),
        testId: TEXT_CONTENT_IDS.SEND_TEXT_FORM_CANCEL,
      },
    });

  const onLanguageChange = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
  };

  return (
    <Grid
      container
      direction="column"
    >
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={TEXT_CONTENT_IDS.SEND_TEXT_FORM_BACK_BUTTON}
        />
      ) : null}

      <PageTitle
        title={titleFromConfigManager}
        subTitle={subTitle}
        titleId={TITLE_IDS.SEND_TEXT_FORM_TITLE}
        subTitleId={TITLE_IDS.SEND_TEXT_FORM_SUBTITLE}
      />
      <Grid
        container
        direction="column"
        style={{ gap: '24px' }}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '24px',
          }}
          data-testid={
            TEXT_CONTENT_IDS.SEND_TEXT_CUSTOMER_INFORMATION_TITLE
          }
        >
          <b>Customer information</b>
        </Typography>

        <Typography
          variant="body2"
          data-testid={
            TEXT_CONTENT_IDS.SEND_TEXT_FIELDS_REQUIRED_SUBTITLE
          }
        >
          All fields are required unless marked optional.
        </Typography>

        <MaskedFormField
          required
          id="phoneNumber"
          mask={PHONE_NUMBER_MASK}
          label="Phone number"
          defaultValue={formData.phoneNumber}
          errorMessage={formErrors.phoneNumber}
          onChange={handleOnChange}
        />

        <Dropdown
          value={language}
          onChange={onLanguageChange}
          testid="sendTextForm"
          isUsedByChildSession
        />

        <Typography
          data-testid={TEXT_CONTENT_IDS.SEND_TEXT_FORM_NOTE}
        >
          <b>Note:</b> Link expires after 15 minutes.
        </Typography>

        {showActionsOnForm ? (
          <>
            <Button
              isLoading={false}
              disabled={false}
              fullWidth
              color="primary"
              variant="contained"
              className="loadingButton"
              onClick={handleOnSubmit}
              testId={TEXT_CONTENT_IDS.SEND_TEXT_FORM_SUBMIT}
            >
              Send text
            </Button>
            <Button
              isLoading={false}
              disabled={false}
              fullWidth
              color="secondary"
              variant="contained"
              className="loadingButton"
              onClick={onCancel}
              testId={TEXT_CONTENT_IDS.SEND_TEXT_FORM_CANCEL}
            >
              Cancel
            </Button>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default SendTextForm;
