import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type {
  PaymentMethod,
  PaymentMethodType,
} from '../../services/commonCheckout/types/PaymentMethod';
import { DEFAULT_PAYMENT_DESCRIPTION } from '../../utils/constants';

import type { AppContextData } from './AppContextType';

const baseInitialState = {
  originalCheckoutSessionResponse:
    {} as CheckoutSessionsResponse,
  initialSessionData: {} as CheckoutSessionsResponse,
  /** Session related properties */
  checkoutSessionId: '',
  appEnv: '',
  merchantId: '',
  customerId: '',
  hcpToken: '',
  amount: 0,
  isAuthorized: true,
  authorizeCard: false,
  statementDescriptorSuffix: '',
  paymentDescription: DEFAULT_PAYMENT_DESCRIPTION,
  merchantTransactionId: '',
  metadata: {},

  /** Stripe related properties */
  vendorPlatformKey: '',
  vendorMerchantId: '',

  /** UI related properties */
  // Without this casting, we have to mark 3 `paymentMethods` array types throughout the codebase as `readonly ` arrays
  paymentMethods: [] as PaymentMethod<PaymentMethodType>[],
  selectedPaymentMethodId: '',
  isInitializing: false,
  backdropContent: null,
  overlayLoaderConfig: { show: false, description: '' },
  containerConfig: undefined,
  headerTitleId: undefined,

  /** SessionOutcome related properties */
  isChildWaitingOnGlobalSession: false,
  isSessionOutcomeShowingDeadEndScreen: undefined,
  sessionOutcomePollingInterval: undefined,
  initializeSessionError: undefined,
  initializeSessionException: undefined,
  hasInitialSessionDataLoaded: false,
  walletWarningMessage: undefined,

  /** PaymentMethodSelector related properties */
  paymentMethodSelectorActivityStack: [] as string[],
  isPrimaryRouteInFocus: true,
} as const;

export const initialState =
  baseInitialState satisfies AppContextData;
