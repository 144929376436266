import { useContext, useEffect } from 'react';

import { AppContext } from '../../../capabilities/contextStore/AppContext';
import { NotificationContext } from '../../../capabilities/contextStore/NotificationContext';

const useShowAddPaymentMethodWarning = () => {
  const { addPaymentMethodWarning, setData } =
    useContext(AppContext);
  const { notify } = useContext(NotificationContext);

  useEffect(() => {
    if (addPaymentMethodWarning) {
      notify({
        title: addPaymentMethodWarning.title,
        message: addPaymentMethodWarning.description,
        severity: 'warning',
      });

      // reset the warning message so we don't keep showing it
      setData({ addPaymentMethodWarning: undefined });
    }
  }, [addPaymentMethodWarning]);
};

export default useShowAddPaymentMethodWarning;
