import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import type { FC } from 'react';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import FormField from '../../../ui/FormField/FormField';
import BackButton from '../../../ui/BackButton/BackButton';
import expirationInputFormatter from '../formatters/expirationInputFormatter';
import validate from '../validators/validations';
import type {
  EditPaymentMethodCardFormData,
  EditPaymentMethodFormErrors,
  EditPaymentMethodCardFormKeys,
  EditPaymentMethodCardFormProps,
  EditPaymentMethodFormValueTypes,
} from '../types';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import {
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import { PAYMENT_METHOD_SETTINGS_TITLE } from '../../pay-and-store/constants';
import Button from '../../../ui/Button/Button';
import CallToActionCard from '../../../components/CallToActionCard/CallToActionCard';
import ContentSpacer from '../../../ui/ContentSpacer/ContentSpacer';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

const EditPaymentMethodCardForm: FC<
  EditPaymentMethodCardFormProps
> = ({
  nameOnCard,
  expiration,
  lastFour,
  zipCode,
  nickname,
  isDefault,
  isManufacturerCard,
  isLoading,
  agent,
  onSubmit,
  onBackClick,
  onRemoveClick,
  onCancelClick,
  showBackButton = true,
}) => {
  const [formData, setFormData] =
    useState<EditPaymentMethodCardFormData>({
      expiration: expirationInputFormatter(expiration),
      nameOnCard,
      lastFour: `••••    ••••    ••••    ${lastFour}`,
      zipCode,
      nickname,
      isDefault,
      isManufacturerCard,
      agent,
    });
  const primaryButtonText = 'Save';
  const secondaryButtonText = 'Cancel';
  const [formErrors, setFormErrors] =
    useState<EditPaymentMethodFormErrors>({});

  const setFormFields = (
    fields: Partial<EditPaymentMethodCardFormData>,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      ...fields,
    }));
  };

  const handleOnChange = (
    key: EditPaymentMethodCardFormKeys,
    value: EditPaymentMethodFormValueTypes,
  ): void => {
    let transformedValue = value;

    if (key === 'expiration' && typeof value === 'string') {
      transformedValue = expirationInputFormatter(value);
    }

    if (key === 'zipCode' && typeof value === 'string') {
      transformedValue = value.slice(0, 5);
    }

    setFormFields({ [key]: transformedValue });

    const isUpdatedToManufacturerCard =
      key === 'isManufacturerCard' && value;

    if (isUpdatedToManufacturerCard) {
      setFormFields({ isDefault: false });
    }
  };

  const formLabelMap: Record<
    keyof EditPaymentMethodCardFormData,
    string
  > = {
    nameOnCard: 'Name on card',
    isDefault: 'Set as default payment method',
    nickname: 'Card nickname',
    isManufacturerCard: 'Is this a manufacturer card?',
    expiration: '',
    lastFour: '',
    zipCode: '',
    agent: '',
  };
  const handleOnBlur = (
    key: EditPaymentMethodCardFormKeys,
    value: EditPaymentMethodFormValueTypes,
  ): void => {
    const newFormValues = { ...formData, [key]: value };
    const validationResult = validate(newFormValues);
    setFormErrors(validationResult);
    setFormFields(newFormValues);
  };

  const handleOnSubmit = useCallback(() => {
    const validationResult = validate(formData);
    setFormErrors(validationResult);

    if (Object.keys(formErrors).length) {
      return;
    }

    onSubmit({ ...formData, lastFour });
  }, [formData]);

  useEffect(() => {
    const validationResult = validate(formData);
    setFormErrors(validationResult);
  }, []);

  const { title, showActionsOnForm } = useFormConfigManager({
    title: 'Edit card',
    titleTestId: TITLE_IDS.EDIT_PAYMENT_METHOD_FORM_TITLE,
    backActionConfig: {
      label: 'Back',
      handler: onBackClick,
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_METHOD_BACK_BUTTON,
    },
    showBackButton,
    primaryActionConfig: {
      label: primaryButtonText,
      handler: handleOnSubmit,
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_SAVE_BUTTON,
    },
    secondaryActionConfig: {
      label: secondaryButtonText,
      handler: useCallback(() => {
        onCancelClick();
      }, []),
      testId: TEXT_CONTENT_IDS.EDIT_PAYMENT_CANCEL_BUTTON,
    },
  });

  return (
    <>
      <Box>
        {showBackButton && showActionsOnForm && (
          <BackButton
            onClick={onBackClick}
            label="Back"
            testId={
              TEXT_CONTENT_IDS.EDIT_PAYMENT_METHOD_BACK_BUTTON
            }
          />
        )}

        <PageTitle
          title={title}
          titleId={TITLE_IDS.EDIT_PAYMENT_METHOD_FORM_TITLE}
        />

        <Box sx={{ my: '24px' }}>
          <Typography
            variant="body2"
            data-testid={
              TEXT_CONTENT_IDS.EDIT_PAYMENT_ALL_FIELDS_REQUIRED_TEXT
            }
          >
            All fields are required unless marked optional.
          </Typography>
        </Box>

        <FormField
          id="nameOnCard"
          label="Name on card"
          placeholder="Jane Smith"
          value={formData.nameOnCard}
          errorMessage={formErrors.nameOnCard}
          required
          autocomplete="name"
          onChange={(value) => {
            handleOnChange('nameOnCard', value);
          }}
          onBlur={(value) => {
            handleOnBlur('nameOnCard', value);
          }}
        />
        <FormField
          id="lastFour"
          label="Card number"
          value={formData.lastFour}
          disabled
          onChange={(value) => {
            handleOnChange('lastFour', value);
          }}
          onBlur={(value) => {
            handleOnBlur('lastFour', value);
          }}
        />

        <Grid
          container
          className="gridCell"
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <FormField
              id="expiration"
              label="Expiration"
              inputMode="numeric"
              hintText="(MM / YY)"
              placeholder="MM / YY"
              value={formData.expiration}
              errorMessage={formErrors.expiration}
              required
              onChange={(value) => {
                handleOnChange('expiration', value);
              }}
              onBlur={(value) => {
                handleOnBlur('expiration', value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormField
              id="zipCode"
              type="number"
              inputMode="numeric"
              label="ZIP"
              placeholder="12345"
              value={formData.zipCode}
              errorMessage={formErrors.zipCode}
              required
              onChange={(value) => {
                handleOnChange('zipCode', value);
              }}
              onBlur={(value) => {
                handleOnBlur('zipCode', value);
              }}
            />
          </Grid>
        </Grid>
        <FormField
          id="nickname"
          label="Card nickname"
          placeholder="Card nickname"
          hintText="(Optional)"
          helperText="Card nickname must be 30 characters or less and can only contain letters and numbers."
          value={formData.nickname || ''}
          errorMessage={formErrors.nickname}
          maxLength={30}
          onChange={(value) => {
            handleOnChange('nickname', value);
          }}
          onBlur={(value) => {
            handleOnBlur('nickname', value);
          }}
        />
        <Grid
          container
          direction="column"
          sx={{
            gap: '16px',
            marginTop: '16px',
          }}
        >
          {!agent && isManufacturerCard ? (
            <Grid
              container
              style={{
                gap: '6px',
              }}
            >
              <LocalAtmIcon fontSize="small" />

              <Typography
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_LABEL_NON_AGENT
                }
              >
                Manufacturer Card
              </Typography>
            </Grid>
          ) : null}

          {agent ? (
            <Grid>
              <Grid
                container
                style={{
                  gap: '4px',
                }}
              >
                <Typography
                  data-testid={
                    TEXT_CONTENT_IDS.MANUFACTURER_CARD_LABEL
                  }
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {formLabelMap.isManufacturerCard}
                </Typography>
              </Grid>
              <RadioGroup
                row
                value={
                  formData.isManufacturerCard ? 'Yes' : 'No'
                }
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      color="primary"
                      onChange={() => {
                        handleOnChange(
                          'isManufacturerCard',
                          true,
                        );
                      }}
                    />
                  }
                  label="Yes"
                  data-testid={
                    TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_YES
                  }
                />

                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      color="primary"
                      onChange={() => {
                        handleOnChange(
                          'isManufacturerCard',
                          false,
                        );
                      }}
                    />
                  }
                  label="No"
                  data-testid={
                    TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_NO
                  }
                />
              </RadioGroup>
            </Grid>
          ) : null}
          {!formData.isManufacturerCard ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography
                  style={{ fontWeight: 700 }}
                  data-testid={
                    TEXT_CONTENT_IDS.PAYMENT_METHOD_SETTINGS_LABEL
                  }
                >
                  {PAYMENT_METHOD_SETTINGS_TITLE}
                </Typography>
              </FormLabel>
              <Checkbox
                id="isDefault"
                value={formData.isDefault}
                label="Set as default payment method"
                testId={
                  TEXT_CONTENT_IDS.EDIT_PAYMENT_SET_AS_DEFAULT_CHECKBOX
                }
                onChange={(value) => {
                  handleOnChange('isDefault', value);
                }}
              />
            </FormControl>
          ) : null}
        </Grid>
      </Box>

      {onRemoveClick ? (
        <>
          <ContentSpacer top={24} />

          <CallToActionCard
            title="Remove payment method"
            titleId={
              TEXT_CONTENT_IDS.CTA_REMOVE_PAYMENT_METHOD_TITLE
            }
            description="If you remove this payment method, it will no longer be saved to your wallet."
            descriptionId={
              TEXT_CONTENT_IDS.CTA_REMOVE_PAYMENT_METHOD_DESCRIPTION
            }
            buttonLabel="Remove"
            buttonId={
              ELEMENT_IDS.CTA_REMOVE_PAYMENT_METHOD_BUTTON
            }
            onClick={onRemoveClick}
          />
        </>
      ) : null}
      {showActionsOnForm ? (
        <Grid
          container
          sx={{
            marginTop: '40px',
          }}
          direction="column"
          justifyContent="center"
        >
          <Button
            isLoading={isLoading}
            fullWidth
            color="primary"
            variant="contained"
            className="loadingButton"
            onClick={handleOnSubmit}
            id={TEXT_CONTENT_IDS.EDIT_PAYMENT_SAVE_BUTTON}
          >
            {primaryButtonText}
          </Button>

          <ContentSpacer bottom={16} />

          <Button
            color="secondary"
            variant="contained"
            onClick={onCancelClick}
            id={TEXT_CONTENT_IDS.EDIT_PAYMENT_CANCEL_BUTTON}
          >
            {secondaryButtonText}
          </Button>
        </Grid>
      ) : null}
    </>
  );
};

export default EditPaymentMethodCardForm;
