import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import {
  getAgent,
  getCollectionDetailsTel,
  getMerchantConsentID,
  getMerchantConsentText,
} from '../../../utils/session/selectors';
import type { MerchantConsentText } from '../../add-payment-method/types/MerchantConsentTextType';
import { getCurrentTimeStamp } from '../../../utils/date/getCurrentTimeStamp';
import { getUserAgent } from '../../../utils/browser/getUserAgent';

export type HowConsentCollected = 'TEL' | 'WEB';

export type ConsentObject = {
  merchantConsentId: string | undefined | null;
  merchantConsentText: MerchantConsentText;
  collectionTimestamp: string;
  collectionDetails:
    | {
        type: 'WEB';
        web: {
          // We're not going to send the ip address and instead rely on the backend to get it from the request.
          // In the future we will pass through ip address sent by the merchant in the session via this property.
          ipAddress: string | null;
          userAgent: string;
        };
      }
    | {
        type: 'TEL';
        tel: {
          inboundPhoneNumber: {
            countryCode: string;
            number: string;
          };
        };
      };
};

export const createConsentCollectionDetails = (
  session: CheckoutSessionsResponse,
): ConsentObject['collectionDetails'] => {
  const consentCollectionType = getAgent(session)
    ? 'tel'
    : 'web';
  if (consentCollectionType === 'tel') {
    const collectionDetailsTel =
      getCollectionDetailsTel(session);
    return {
      type: 'TEL',
      tel: {
        inboundPhoneNumber: {
          countryCode:
            collectionDetailsTel?.tel.inboundPhoneNumber
              .countryCode || '',
          number:
            collectionDetailsTel?.tel.inboundPhoneNumber
              .number || '',
        },
      },
    };
  }
  return {
    type: 'WEB',
    web: {
      ipAddress: null,
      userAgent: getUserAgent(),
    },
  };
};

export const createConsentObject = (
  session: CheckoutSessionsResponse,
): ConsentObject => {
  return {
    merchantConsentId: getMerchantConsentID(session),
    merchantConsentText: getMerchantConsentText(session),
    collectionTimestamp: getCurrentTimeStamp(),
    collectionDetails: createConsentCollectionDetails(session),
  };
};
