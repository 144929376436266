import React, { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';

import SessionApi from '../../../../services/session/SessionApi';
import { AppContext } from '../../../contextStore/AppContext';

type CancelChildSessionProps = {
  childSessionId?: string;
  /**
   * We probably only want to cancel the child session if the agent clicks cancel.
   * If the child session is already invalid we probably don't want to update its
   * status and leave it as is, this flag will be false by default to prevent
   * accidental cancellation
   */
  shouldCancelChildSession?: boolean;
  setIsChildStatusCanceled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onError: () => void;
  onDone: () => void;
};

const CancelChildSession: FC<CancelChildSessionProps> = ({
  childSessionId,
  shouldCancelChildSession = false,
  onError,
  setIsChildStatusCanceled,
  onDone,
  children,
}) => {
  const { setData } = useContext(AppContext);

  const [haveSentCancelRequest, setHaveSentCancelRequest] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      childSessionId &&
      shouldCancelChildSession &&
      !haveSentCancelRequest
    ) {
      setData({ overlayLoaderConfig: { show: true } });
      setHaveSentCancelRequest(true);
      SessionApi.cancelSession(childSessionId)
        .then(() => {
          setIsChildStatusCanceled(true);
        })
        .catch(onError)
        .finally(() => {
          onDone();
          setData({ overlayLoaderConfig: { show: false } });
        });
    }
  }, [
    childSessionId,
    shouldCancelChildSession,
    haveSentCancelRequest,
    setData,
    onError,
    onDone,
  ]);

  useEffect(() => {
    return () => {
      if (haveSentCancelRequest) {
        setData({ overlayLoaderConfig: { show: false } });
      }
    };
  }, []);

  return <>{children}</>;
};

export default CancelChildSession;
