import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { PaymentMethodType } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodConfig';
import {
  getConfigMode,
  getMerchantConsentText,
  getPaymentMethods,
} from '../session/selectors';

import { isConsentTextAvailableAndValid } from './isConsentTextAvailableAndValid';

/**
 * @description: This function discards ach as payment method type
 * because the session request did not contain a valid consent object.
 * It also filters out webform channel if it is not enabled for card
 * payment only. At the time of writing, the webform channel for card
 * is the only one that has a requirement of ws2 configuration. All
 * other channels are enabled based on session request and merchant
 * configuration.
 *
 * There are scenarios where webform channel is not available any of
 * the payment types. In such cases, the function will return only
 * those payment types that have at least one channel.
 *
 * We are also filtering out express checkout payments here.
 */

export const getFilteredPaymentTypes = ({
  session,
  isForWebFormView = false,
}: {
  session: CheckoutSessionsResponse;
  isForWebFormView?: boolean;
}) => {
  const paymentMethods = getPaymentMethods(session);

  if (!paymentMethods) {
    return [];
  }

  const paymentMethodTypes = paymentMethods.reduce<
    PaymentMethodType[]
  >((accountTypes, currentItem) => {
    const { type, channels } = currentItem;
    if (
      isForWebFormView &&
      channels.some(
        (channel) =>
          channel.type === 'WEBFORM' && channel.enabled,
      )
    ) {
      accountTypes.push(type);
    }

    if (
      !isForWebFormView &&
      channels.some((channel) =>
        ['TEXT', 'EMAIL', 'WEBFORM'].includes(channel.type),
      )
    ) {
      accountTypes.push(type);
    }

    return accountTypes;
  }, []);

  const configMode = getConfigMode(session);
  const isPaymentMode = ['PAYMENT'].includes(configMode);
  const isConsentObjectValid = isConsentTextAvailableAndValid(
    getMerchantConsentText(session),
  );

  return paymentMethodTypes.filter((paymentMethodType) => {
    const isBankAccount = paymentMethodType === 'BANK_ACCOUNT';
    /**
     * We only need to check for consent object validity for PAYMENT mode.
     * For PAYMENT_WITH_WALLET, for now, users are only able to add a payment method
     * and and not make one time payment so we don't need to check for consent object validity.
     */
    if (isPaymentMode && isBankAccount) {
      return isConsentObjectValid;
    }
    return true;
  });
};
