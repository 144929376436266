const containsInvalidCharacters = (consentText: string) => {
  const regex = /^[^\\[\]{}+=*^%#~`<>?|]*$/;
  return regex.test(consentText);
};

export const isConsentTextAvailableAndValid = (
  consentText?: string,
) => {
  return !(
    !consentText || !containsInvalidCharacters(consentText)
  );
};
