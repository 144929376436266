import React from 'react';
import { Typography, styled } from '@mui/material';

import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';

const StyledHelperText = styled(Typography)(({ theme }) => ({
  color: '#6d6e78', // will need to align with design tokens in the future
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  display: 'inline',
  component: 'span',
}));

export type HelperTextProps = {
  id: string;
  helperText?: string;
};

const HelperText = ({
  id,
  helperText = '',
}: HelperTextProps) => {
  return (
    <StyledHelperText
      data-testid={`${id}_${FORM_FIELD_SUFFIXES_IDS.HELPER_TEXT}`}
    >
      {helperText}
    </StyledHelperText>
  );
};

export default HelperText;
