import type { FC } from 'react';
import React, { useEffect, useContext, useState } from 'react';
import { Box } from '@mui/system';

import { AppContext } from '../../../contextStore/AppContext';
import noop from '../../../../utils/function/noop';
import type { PaymentMethodError } from '../../../../services/stripe/types';
import {
  getCustomerId,
  getAgent,
  getExpiresAtUtc,
} from '../../../../utils/session/selectors';
import PayAndStoreStripeForm from '../PayAndStoreStripeForm';
import type {
  PayAndStoreCardContainerProps,
  PayAndStoreStripeFormSubmitData,
} from '../../types';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import { getTimeDifference } from '../../../../utils/date/getTimeDifference';
import useSessionExpirationReminder from '../../../../hooks/useSessionExpirationReminder';
import shouldTriggerReminder from '../../../../utils/capabilities/shouldTriggerReminder';
import StripePaymentElementWrapper from '../../../../components/stripe/stripe-elements-wrapper/StripePaymentElementWrapper';
import processCardPaymentStripe from '../../../../utils/capabilities/processCardPaymentStripe';

const PayAndStoreCardContainer: FC<
  PayAndStoreCardContainerProps
> = ({
  vendorPlatformKey,
  merchantTransactionId,
  amount,
  paymentDescription,
  authorizeCard,
  formTitle,
  statementDescriptorSuffix,
  showSaveFeatureBlock,
  isInFocus,
  stripeApi,
  paymentApi,
  onSuccess = noop,
  onCancel = noop,
  onError = noop,
  onBeforeSubmit = noop,
  onLoad = noop,
  onLoadComplete = noop,
  onBackClick,
}) => {
  const { setData, originalCheckoutSessionResponse } =
    useContext(AppContext);
  const [isMakingPayment, setIsMakingPayment] = useState(false);

  const { notify } = useContext(NotificationContext);
  const expiresAt = getExpiresAtUtc(
    originalCheckoutSessionResponse,
  );
  const timeDifference = getTimeDifference(
    new Date(expiresAt),
    new Date(new Date().toISOString()),
  );

  useSessionExpirationReminder({
    expiresAt,
    notify,
    timeDifference,
    shouldTriggerReminder: shouldTriggerReminder(
      originalCheckoutSessionResponse,
    ),
  });

  const customerId = getCustomerId(
    originalCheckoutSessionResponse,
  );
  const agent = getAgent(originalCheckoutSessionResponse);

  const onProcessingComplete = () => {
    setIsMakingPayment(false);
    setData({ overlayLoaderConfig: { show: false } });
  };

  const handlePaymentMethodError = ({
    paymentMethodError,
  }: {
    paymentMethodError: PaymentMethodError;
  }) => {
    const { errorType, message } = paymentMethodError;

    if (errorType !== 'validation_error') {
      console.error(message);
      onError(message);
    }
    onProcessingComplete();
  };

  const onSubmit = ({
    elements,
    formData,
    stripe,
  }: PayAndStoreStripeFormSubmitData) => {
    onBeforeSubmit();
    setIsMakingPayment(true);
    setData({ overlayLoaderConfig: { show: true } });

    processCardPaymentStripe({
      elements,
      formData,
      stripe,
      originalCheckoutSessionResponse,
      amount,
      merchantTransactionId,
      authorizeCard,
      statementDescriptorSuffix,
      paymentDescription,
      vendorPlatformKey,
      stripeApi,
      paymentApi,
      onSuccess,
      onProcessingComplete,
      handlePaymentMethodError,
    }).catch(() => {
      // ignore
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return vendorPlatformKey ? (
    <Box>
      <StripePaymentElementWrapper
        vendorPlatformKey={vendorPlatformKey}
      >
        <PayAndStoreStripeForm
          isGuestUser={!customerId}
          amount={amount}
          isMakingPayment={isMakingPayment}
          agent={agent}
          showSaveFeatureBlock={showSaveFeatureBlock}
          formTitle={formTitle}
          isInFocus={isInFocus}
          onBackClick={onBackClick}
          setIsMakingPayment={setIsMakingPayment}
          onLoadComplete={onLoadComplete}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </StripePaymentElementWrapper>
    </Box>
  ) : null;
};

export default PayAndStoreCardContainer;
