import { Radio, styled } from '@mui/material';

const StyledRadioRoot = styled(Radio)({
  marginRight: '12px',
  color: '#000000',
  '&.ccg-Mui-checked': {
    color: '#316BBE',
  },
});

export default StyledRadioRoot;
