import React, { useContext } from 'react';
import {
  CheckCircleRounded,
  ErrorRounded,
} from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import type { AlertProps } from '@mui/lab';
import type { TypographyProps } from '@mui/material';
import {
  Alert,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import WarningNotificationIcon from '../../icons/notification/warning_notification';
import {
  ICON_IDS,
  TEXT_CONTENT_IDS,
} from '../../utils/element-ids';
import { CCGThemeProviderContext } from '../../capabilities/contextStore/CCGThemeProviderContext';

import { ALERT_ELEMENT } from './NotificationAlertStyleConstant';

export interface NotificationAlertProps extends AlertProps {
  title: string;
  message?: string;
  inlineMessage?: boolean;
}
export interface CloseableNotificationType
  extends NotificationAlertProps {
  closeable?: boolean;
}

/**
 * CC-918 - Needed to remove aria-hidden=true from icons and reading through the
 * implementation (https://github.com/mui/material-ui/blob/acc033cd56c5b14dcb0cd398292cb4483d0267bd/packages/mui-material/src/SvgIcon/SvgIcon.js)
 * it seems like if you pass "titleAccess" prop to the SVG icons then
 * it won't add the aria-hidden attribute
 */
const iconDefaults = {
  success: (
    <CheckCircleRounded
      aria-label="Success"
      id={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-success`}
      data-testid={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-success`}
      titleAccess="Success"
      sx={{ color: ALERT_ELEMENT.success.iconColor }}
    />
  ),
  error: (
    <ErrorRounded
      aria-label="Error"
      id={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-error`}
      data-testid={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-error`}
      titleAccess="Error"
      sx={{ color: ALERT_ELEMENT.error.iconColor }}
    />
  ),
  warning: (
    <WarningNotificationIcon
      aria-label="Warning"
      id={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-warning`}
      data-testid={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-warning`}
      fill={ALERT_ELEMENT.warning.iconColor}
    />
  ),
  info: (
    <FlagIcon
      aria-label="Info"
      id={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-info`}
      data-testid={`${ICON_IDS.NOTIFICATION_ALERT_ICON}-info`}
      titleAccess="Info"
      sx={{ color: ALERT_ELEMENT.info.iconColor }}
    />
  ),
};

interface StyledMessageProps extends TypographyProps {
  displayInline?: boolean;
}

const NotificationAlert: React.FC<NotificationAlertProps> = ({
  severity = 'success',
  icon,
  onClose,
  title,
  message,
  inlineMessage = false,
}: NotificationAlertProps) => {
  const { appearance } = useContext(CCGThemeProviderContext);

  const StyledTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    lineHeight: '26px',
    fontWeight: theme.typography.fontWeightMedium,
    color: ALERT_ELEMENT?.[severity]?.titleColor,
    '@media (max-width:539px)': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  const StyledMessage = styled(Typography)<StyledMessageProps>(
    ({ displayInline, theme }) => ({
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      fontWeight: theme.typography.fontWeightLight,
      color: ALERT_ELEMENT?.[severity]?.msgColor,
      ...(displayInline && {
        marginLeft: theme.spacing(2),
      }),
    }),
  );

  const StyledAlert = styled(Alert)({
    borderRadius: '12px',
    padding: '14px 12px 14px 16px',
    columnGap: '4px',
    bgcolor: ALERT_ELEMENT?.[severity]?.backgroundColor,
    borderColor: ALERT_ELEMENT?.[severity]?.borderColor,
    ...(appearance?.border?.alertBoxBorderRadius && {
      borderRadius: `${appearance.border.alertBoxBorderRadius} !important`,
    }),
    '& [class$=MuiAlert-icon]': {
      padding: 0,
    },
    '& [class$=MuiAlert-message]': {
      padding: 0,
    },
    '& [class$=MuiAlert-action]': {
      alignItems: 'baseline',
      padding: 0,
    },
    '& [class$=MuiAlert-action] button': {
      padding: 0,
      color: '#424242',
      '&:hover': {
        background: 'transparent',
      },
    },
  });

  const alertMessage = message && (
    <StyledMessage
      id={TEXT_CONTENT_IDS.NOTIFICATION_ALERT_MESSAGE}
      data-testid={TEXT_CONTENT_IDS.NOTIFICATION_ALERT_MESSAGE}
      variant="caption"
      displayInline={inlineMessage}
    >
      <span dangerouslySetInnerHTML={{ __html: message }} />
    </StyledMessage>
  );
  const alertTitle = title && (
    <StyledTitle>
      <span
        id={TEXT_CONTENT_IDS.NOTIFICATION_ALERT_TITLE}
        data-testid={TEXT_CONTENT_IDS.NOTIFICATION_ALERT_TITLE}
      >
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </span>
      {inlineMessage && alertMessage}
    </StyledTitle>
  );

  return (
    <StyledAlert
      severity={severity}
      icon={icon || iconDefaults[severity]}
      onClose={onClose}
      action={
        onClose && (
          <IconButton
            id={
              TEXT_CONTENT_IDS.NOTIFICATION_ALERT_CLOSE_ICON_BUTTON
            }
            data-testid={
              TEXT_CONTENT_IDS.NOTIFICATION_ALERT_CLOSE_ICON_BUTTON
            }
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
    >
      <div style={{ overflow: 'hidden', marginRight: '12px' }}>
        {alertTitle}
        {!inlineMessage && alertMessage}
      </div>
    </StyledAlert>
  );
};

export default NotificationAlert;
