import { Typography, styled } from '@mui/material';

const StyledAmountValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '18px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    color: theme.palette.text.secondary,
    fontSize: '18px',
    fontWeight: 700,
  },
}));

export default StyledAmountValue;
