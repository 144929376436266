import React, { useContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import axios from 'axios';

import { AppContext } from '../../capabilities/contextStore/AppContext';
import { getCheckoutSessionSettingsFromCommonCheckoutApi } from '../../services/commonCheckout/getCheckoutSessionSettingsFromCommonCheckoutApi';
import { createDefaultHeader, setHeaders } from '../../api/apis';
import { settingsApi } from '../../services/settingsApi/settingsApi';
import {
  GUEST,
  SESSION_ALREADY_PROCESSED_HTTP_STATUS,
  SESSSION_ID_INVALID_STATUS_CODE,
} from '../../utils/constants';
import { normalizeUiModesFromServer } from '../../unified-router/utils/normalizeUiModesFromServer';
import { CCGThemeProviderContext } from '../../capabilities/contextStore/CCGThemeProviderContext';
import { isSessionComplete } from '../../capabilities/checkout-v2/checkout/components/checkout-sessions-polling/utils/isSessionComplete';
import { ensureIsInstanceOfError } from '../../utils/function/ensureIsInstanceOfError';
import { isHttpErrorResponse } from '../../utils/function/isHttpErrorResponse';
import { logWidgetUsageStatsEvents } from '../../services/AppInsights/Events/log-widget-usage-stats';
import {
  getDistributionType,
  getVersion,
} from '../../build-info';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import handleSessionInitializerError from '../../utils/session/handleSessionInitializerError/handleSessionInitializerError';
import { getConfigMode } from '../../utils/session/selectors';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import { ROOT_PATHS } from '../../unified-router/constants';

import useParseCheckoutSessionId from './useParseCheckoutSessionId';

type InitializeSessionDataProps = {
  checkoutSessionId?: string;
  children?: ReactNode;
  validateCheckoutSessionId?: boolean;
};

const InitializeSessionData: FC<InitializeSessionDataProps> = ({
  checkoutSessionId: sessionId,
  children,
  validateCheckoutSessionId = true,
}) => {
  const appContext = useContext(AppContext);
  const parseCheckoutSessionId = useParseCheckoutSessionId();
  const { setAppearance } = useContext(CCGThemeProviderContext);
  const environmentContextState =
    useEnvironmentContextProvider();

  const {
    initializeSessionError,
    initializeSessionException,
    setData,
    setInitializeSessionException,
    setInitializeSessionError,
  } = appContext;
  const [isLoading, setIsLoading] = useState(true);
  const checkoutSessionId = sessionId || parseCheckoutSessionId;

  const {
    containerConfig,
    postMessage,
    setEnvironmentState,
    appEnvironmentType,
  } = environmentContextState;

  useEffect(() => {
    let isMounted = true;
    if (checkoutSessionId) {
      getCheckoutSessionSettingsFromCommonCheckoutApi({
        checkoutSessionId,
        fetchConfig: true,
      })
        .then(async (data) => {
          const checkoutSessionData =
            data.originalCheckoutSessionResponse;
          const configMode = getConfigMode(checkoutSessionData);

          // TODO: Move this to CcgElementsRouteGroupContainer or remove this if not needed anymore
          /** @note: this is just a temporary fix - we need to find a better approach
           *  that will throw an error for all invalid modes that are not supported in
           *  hosted experience. Otherwise, we will have to add a check for each mode.
           */
          if (
            (!containerConfig ||
              containerConfig?.type === 'modal' ||
              containerConfig?.type === 'drawer') &&
            configMode === 'PAYMENT_METHOD_DISPLAY'
          ) {
            throw new Error(
              'Invalid configuration for this environment',
            );
          }

          if (
            isSessionComplete(
              data.originalCheckoutSessionResponse,
            ) &&
            Object.keys(ROOT_PATHS).includes(configMode)
          ) {
            setInitializeSessionError({
              title: 'SESSION_ALREADY_PROCESSED',
              detail: 'Session already processed',
              status: SESSION_ALREADY_PROCESSED_HTTP_STATUS,
            });
            return;
          }

          const serverUiModes =
            data.originalCheckoutSessionResponse.checkoutSession
              .checkoutRequest.config?.modes;

          const {
            customerId,
            merchantId,
            hcpToken,
            vendorPlatformKey,
            appearance,
          } = data;

          const upstreamEnv = await settingsApi.getUpstreamEnv();
          const headers = createDefaultHeader({
            upstreamEnv,
            merchantId,
            customerId: customerId || GUEST,
            checkoutSessionId,
            hcpToken,
          });

          setHeaders(headers);
          setData({
            ...data,
            vendorPlatformKey,
            uiModes: normalizeUiModesFromServer(serverUiModes),
            initialSessionData:
              data.originalCheckoutSessionResponse,
          });

          if (appearance) {
            setAppearance(appearance);
          }
          logWidgetUsageStatsEvents({
            appVersion: getVersion(),
            distributionType: getDistributionType(),
            merchantId,
          });
        })
        .catch((e: unknown) => {
          if (
            axios.isAxiosError(e) &&
            e.response?.data &&
            isHttpErrorResponse(e.response.data)
          ) {
            setInitializeSessionError(e.response.data);
          } else {
            setInitializeSessionException(
              ensureIsInstanceOfError(e),
            );
          }
        })
        .finally(() => {
          if (isMounted) {
            // This should happen once all other state is set, and requests are completed:
            setData({
              checkoutSessionId,
              hasInitialSessionDataLoaded: true,
              overlayLoaderConfig: { show: false },
            });
            setIsLoading(false);
          }
        });
    } else if (
      !checkoutSessionId &&
      containerConfig &&
      !validateCheckoutSessionId
    ) {
      /** @note: restrict this for embedded only */
      console.warn('Missing `checkoutSessionId`');
      setIsLoading(false);
      setData({ overlayLoaderConfig: { show: false } });
      /** @note: status code will be remove later when emitting error to callbacks
       *  this is to fulfill QA ask to remove status on invalid session errors in
       *  embedded and to work around typing errors related to status code.
       */
      setInitializeSessionError({
        title: 'INVALID_SESSION',
        detail: 'session id is invalid',
        status: SESSSION_ID_INVALID_STATUS_CODE,
      });
    }

    return () => {
      isMounted = false;
    };
  }, [checkoutSessionId, containerConfig]);

  /** @note: this useEffect handles embedded flow initialization failures */
  useEffect(() => {
    if (
      (initializeSessionError || initializeSessionException) &&
      containerConfig
    ) {
      /** @note: passing environmentContextState as a whole causes infinite
       *  loop. Need to separate the values and pass them individually.
       */
      handleSessionInitializerError({
        initializeSessionError,
        initializeSessionException,
        postMessage,
        containerConfig,
        setEnvironmentState,
        appEnvironmentType,
      });
    }
  }, [
    initializeSessionError,
    initializeSessionException,
    containerConfig,
  ]);

  /** @note: this code block handles hosted flow invalid checkout session failures */
  if (!checkoutSessionId && validateCheckoutSessionId) {
    console.warn('Missing `checkoutSessionId`');
    return <ErrorCard />;
  }

  return (
    <>{checkoutSessionId && (!isLoading ? children : null)}</>
  );
};

export default InitializeSessionData;
