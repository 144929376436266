import { httpClientService } from '../settingsApi/httpClientService';

export async function getBankNameFromCommonCheckoutApi(
  routingNumber: string,
) {
  const httpClient = httpClientService.getHttpClient({
    api: 'CUSTOMER',
  });
  const response = await httpClient.request<{
    bankName: string;
  }>({
    url: `/banks/routing/${routingNumber}`,
    method: 'GET',
  });
  return response.data?.bankName;
}
