import type { Theme } from '@mui/material';
import type { Appearance } from '@stripe/stripe-js';

const stripeAppearance = (theme: Theme): Appearance => {
  return {
    variables: {
      borderRadius: `${theme.shape.borderRadius}px`,
      fontFamily: theme.typography.fontFamily,
      colorTextPlaceholder: theme.palette.grey['600'],
      colorDanger: theme.palette.error.main,
      colorText: theme.palette.text.primary,
    },
    // TODO: try reducing usage of `rules` and just use `variables` instead: https://stripe.com/docs/elements/appearance-api?platform=web#commonly-used-variables
    rules: {
      '.Label': {
        fontWeight: 'bold',
        marginBottom: '8px',
      },
      '.Input': {
        outline: '1px solid #929496',
        boxShadow: 'none',
        border: '0',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.grey['900'],
      },
      '.Input:focus': {
        outline: '2px solid #316BBE',
        boxShadow: 'none',
        border: '0',
      },
      '.Input--invalid': {
        outline: `2px solid ${theme.palette.error.main}`,
        // Without this line, stripe changes text color to red, which is hard to read against a white background.
        color: theme.palette.grey['900'],
      },
    },
  };
};

export default stripeAppearance;
