import { httpClientService } from '../settingsApi/httpClientService';

import { commonCheckoutClientWrapper } from './commonCheckoutClientWrapper';
import type { Agent } from './types/CheckoutSessionsResponse';
import type {
  PaymentMethod,
  PaymentMethodType,
} from './types/PaymentMethod';

export async function removePaymentMethodService({
  paymentMethodId,
  data,
  headers,
}: {
  paymentMethodId: string;
  data: {
    agent: Agent | null;
  };
  headers?: Record<string, string>;
}) {
  const httpClient = httpClientService.getHttpClient({
    api: 'CUSTOMER',
  });

  const request = {
    method: 'DELETE',
    url: `/payment-methods/${paymentMethodId}`,
    headers,
    data,
  } as const;
  const response = await commonCheckoutClientWrapper<
    PaymentMethod<PaymentMethodType>
  >({
    httpClient,
    request,
  });
  return response.status === 204;
}
