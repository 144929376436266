import React from 'react';
import type { FC } from 'react';
import '../../../ui/utils/MuiClassNameSetup';

import { AppContext } from '../../contextStore/AppContext';
import { useAppContext } from '../../contextStore/useAppContext';
import { NotificationContext } from '../../contextStore/NotificationContext';
import { useNotificationContext } from '../../contextStore/useNotificationContext';
import type { getThemeArgs } from '../../../style/external-theme/types';
import { CCGThemeProvider } from '../../../style/CCGThemeProvider/CCGThemeProvider';
import EnvironmentContextProvider from '../../contextStore/EnvironmentContext/EnvironmentContextProvider';
import ContainerSelector from '../../../components/container-types/container-selector/ContainerSelector';
import ConditionalWidgetContainerVisibility from '../../../components/ConditionalWidgetContainerWidget/ConditionalWidgetContainerVisibility';
import type { AppEnv } from '../../../shared/types/app/AppEnv';
import { SharedEmbeddedHostedRouter } from '../../../unified-router/SessionRouter/SharedEmbeddedHostedRouter';
import WidgetLoaderManager from '../../../components/WidgetLoaderManager/WidgetLoaderManager';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { DrawerContext } from '../../contextStore/DrawerContext/DrawerContext';
import { useDrawerContext } from '../../contextStore/DrawerContext/useDrawerContext';
import ServiceContextProvider from '../../contextStore/ServiceContext/ServiceContextProvider';

import type { CheckoutContainerProps } from './components/checkout-container/types/CheckoutContainerProps';

export type CheckoutContainerWrapperProps = Omit<
  CheckoutContainerProps,
  'children'
> &
  // TODO: allow passing just `theme` in addition to `appearance`:
  Pick<getThemeArgs, 'appearance'> & {
    appEnv: AppEnv;
  };

const CheckoutContainerWrapper: FC<
  CheckoutContainerWrapperProps
> = ({
  appEnv,
  checkoutSessionId,
  appearance,
  showPaymentContainer,
  postMessage,
  containerConfig = {
    type: 'modal',
  } /** @note: default should be modal as per docs */,
}) => {
  const appContextValue = useAppContext();
  const notificationContextValue = useNotificationContext();
  const drawerContextValue = useDrawerContext();

  return (
    <ServiceContextProvider>
      <CCGThemeProvider appearance={appearance}>
        <AppContext.Provider value={appContextValue}>
          <EnvironmentContextProvider
            showPaymentContainer={showPaymentContainer}
            postMessage={postMessage}
            containerConfig={containerConfig}
          >
            <NotificationContext.Provider
              value={notificationContextValue}
            >
              <DrawerContext.Provider value={drawerContextValue}>
                <ConditionalWidgetContainerVisibility>
                  <ContainerSelector>
                    <WidgetLoaderManager
                      appEnv={appEnv}
                      checkoutSessionId={checkoutSessionId}
                    >
                      <LanguageSelector>
                        <SharedEmbeddedHostedRouter />
                      </LanguageSelector>
                    </WidgetLoaderManager>
                  </ContainerSelector>
                </ConditionalWidgetContainerVisibility>
              </DrawerContext.Provider>
            </NotificationContext.Provider>
          </EnvironmentContextProvider>
        </AppContext.Provider>
      </CCGThemeProvider>
    </ServiceContextProvider>
  );
};

export default CheckoutContainerWrapper;
