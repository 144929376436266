import { useContext } from 'react';

import noop from '../../../utils/function/noop';

import type { SecondaryContainerCloseContextProviderType } from './SecondaryContainerCloseContext';
import SecondaryContainerCloseContext from './SecondaryContainerCloseContext';

const useSecondaryContainerCloseContext =
  (): SecondaryContainerCloseContextProviderType => {
    const context = useContext(SecondaryContainerCloseContext);
    if (!context) {
      return {
        registerBeforeCloseTask: noop,
        clearBeforeCloseTask: noop,
        runBeforeCloseTask: async () => Promise.resolve(),
      };
    }
    return context;
  };

export default useSecondaryContainerCloseContext;
