import React, { useContext } from 'react';

import type PaymentMethodSelectorContainerProps from '../../../../shared/types/capabilities/paymentMethodSelector/PaymentMethodSelectorContainerProps';
import useEnvironmentContextProvider from '../../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import ErrorCard from '../../../../components/ErrorCard/ErrorCard';
import PaymentMethodSelectorEmbeddedContainer from '../PaymentMethodSelectorEmbeddedContainer/PaymentMethodSelectorEmbeddedContainer';
import PaymentMethodSelectorHostedContainer from '../PaymentMethodSelectorHostedContainer/PaymentMethodSelectorHostedContainer';
import usePaymentSelectorComponentData from '../../hooks/usePaymentSelectorComponentData';
import { AppContext } from '../../../contextStore/AppContext';

const PaymentMethodSelectorContainer = ({
  onCancel,
}: PaymentMethodSelectorContainerProps) => {
  const { setData } = useContext(AppContext);
  const { appEnvironmentType } = useEnvironmentContextProvider();

  const { isLoading, formattedPaymentMethods, paymentMethods } =
    usePaymentSelectorComponentData();

  const onSuccess = () => {
    setData({ isChildWaitingOnGlobalSession: true });
  };

  if (appEnvironmentType === 'embedded') {
    return (
      <PaymentMethodSelectorEmbeddedContainer
        isLoading={isLoading}
        formattedPaymentMethods={formattedPaymentMethods}
        paymentMethods={paymentMethods}
      />
    );
  }

  if (appEnvironmentType === 'hosted') {
    return (
      <PaymentMethodSelectorHostedContainer
        isLoading={isLoading}
        formattedPaymentMethods={formattedPaymentMethods}
        paymentMethods={paymentMethods}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    );
  }

  reportError(
    new Error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `Invalid appEnvironmentType: ${appEnvironmentType}`,
    ),
  );
  return <ErrorCard />;
};

export default PaymentMethodSelectorContainer;
