import React, { useContext, useEffect } from 'react';

import { PaymentMethodSelector } from '../../PaymentMethodSelector';
import type PaymentMethodSelectorHostedContainerProps from '../../../../shared/types/capabilities/paymentMethodSelector/PaymentMethodSelectorHostedContainerProps';
import type OnConfirmFunc from '../../../../shared/types/capabilities/paymentMethodSelector/OnConfirmFunc';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import { SELECT_PAYMENT_METHOD_ERROR } from '../../../../shared/strings';
import PaymentMethodSelectorApi from '../../../../services/paymentMethodSelector/PaymentMethodSelectorApi';
import { AppContext } from '../../../contextStore/AppContext';

const PaymentMethodSelectorHostedContainer = ({
  isLoading,
  formattedPaymentMethods,
  paymentMethods,
  onSuccess,
  onCancel,
}: PaymentMethodSelectorHostedContainerProps) => {
  const { checkoutSessionId, setData } = useContext(AppContext);
  const { notify, closeNotification } = useContext(
    NotificationContext,
  );

  const handleConfirmClick: OnConfirmFunc = (
    selectedPaymentMethod,
  ) => {
    if (!selectedPaymentMethod) {
      notify({
        title: SELECT_PAYMENT_METHOD_ERROR,
        severity: 'error',
      });

      return;
    }

    closeNotification();
    setData({ overlayLoaderConfig: { show: true } });

    const paymentMethod = paymentMethods.find(
      (method) => method.id === selectedPaymentMethod.id,
    );

    if (!paymentMethod) {
      return;
    }

    PaymentMethodSelectorApi.confirmPaymentMethod({
      sessionId: checkoutSessionId,
      paymentMethod,
    })
      .then(onSuccess)
      .catch(() => {
        setData({ overlayLoaderConfig: { show: false } });
      });
  };

  useEffect(() => {
    return () => {
      closeNotification();
    };
  }, []);

  return (
    <PaymentMethodSelector
      isLoading={isLoading}
      formattedPaymentMethods={formattedPaymentMethods}
      paymentMethods={paymentMethods}
      showHostedExperienceActions
      onConfirm={handleConfirmClick}
      onCancel={onCancel}
    />
  );
};

export default PaymentMethodSelectorHostedContainer;
