/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Box } from '@mui/material';

import {
  getEmailAddress,
  getPhoneNumber,
  getSessionId,
} from '../../utils/session/selectors';
import useComponentEntry from '../../hooks/useComponentEntry';
import CancelChildSessionWrapper from '../../shared/CancelChildSessionWrapper/CancelChildSessionWrapper';
import PaymentMethodChannelSelector from '../components/PaymentMethodChannelSelector/PaymentMethodChannelSelector';
import AwaitingUserResponseContainer from '../components/AwaitingUserResponse/AwaitingUserResponseContainer';
import UnifiedPaymentFormEntry from '../UnifiedPaymentForm/UnifiedPaymentFormEntry';
import paymentMethodEntryViewState from '../../utils/capabilities/paymentMethodEntryViewState';
import { getBackButtonHandler } from '../../utils/capabilities/back-button/getBackButtonHandler';
import getBackButtonDetails from '../../utils/capabilities/back-button/getBackButtonDetails';
import { NotificationContext } from '../contextStore/NotificationContext';
import { getConfiguredChannelsForAgent } from '../../utils/session/getConfiguredChannelsForAgent';
import usePaymentMethodEntryLoader from '../../shared/hooks/payment-method-entry/usePaymentMethodEntryLoader';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';

import type { AddPaymentMethodBaseProps } from './types/AddPaymentMethodBaseProps';
import AddPaymentMethodContainer from './AddPaymentMethodContainer';
import NotificationsMethodsContainer from './components/NotificationMethods/NotificationsMethodsContainer';
import AddPaymentMethodAchContainer from './components/AddPaymentMethodAchContainer/AddPaymentMethodAchContainer';
import AddPaymentMethodEntryErrorHandler from './components/AddPaymentMethodEntryErrorHandler/AddPaymentMethodEntryErrorHandler';

const AddPaymentMethodEntry = ({
  customerApi,
  customerId,
  onCancel,
  onSuccess,
  stripeApi,
  vendorPlatformKey,
  onBackClick,
  onBeforeSubmit,
  onError,
}: AddPaymentMethodBaseProps) => {
  const {
    localData,
    originalCheckoutSessionResponse,
    setLocalData,
    setShouldCancelChildSession,
    setIsChildStatusCanceled,
    isChildStatusCanceled,
    shouldCancelChildSession,
    errorType,
    setErrorType,
    isAgentAssistedSession,
    isInitializing,
  } = useComponentEntry();
  
  const { closeNotification } = useContext(NotificationContext);

  const { containerConfig } = useEnvironmentContextProvider();

  const viewState = paymentMethodEntryViewState({
    errorType,
    componentEntryLocalData: localData,
    checkoutSessionDetails: originalCheckoutSessionResponse,
  });

  const backButtonHandler = getBackButtonHandler({
    backButtonDetails: getBackButtonDetails({
      componentEntryLocalData: localData,
      sessionDetails: originalCheckoutSessionResponse,
      containerConfig,
    }),
    closeNotification,
    componentEntryLocalData: localData,
    onBackButtonParentHandler: onBackClick,
    setComponentEntryLocalData: setLocalData,
  });

  const {
    isLoading,
    onAchFormReady,
    onCardFormReady,
    onManagedComponentReady,
    reset,
  } = usePaymentMethodEntryLoader(viewState, isInitializing);

  const renderAddPaymentMethodCardContainer = ({
    formTitle,
    onBackClickLocal,
    selectedPaymentMethod,
  }: {
    formTitle: string;
    onBackClickLocal?: () => void;
    selectedPaymentMethod: string;
  }) => (
    <AddPaymentMethodContainer
      customerApi={customerApi}
      vendorPlatformKey={vendorPlatformKey}
      formTitle={formTitle}
      stripeApi={stripeApi}
      customerId={customerId}
      onSuccess={onSuccess}
      onBeforeSubmit={onBeforeSubmit}
      onBackClick={onBackClickLocal}
      onCancel={onCancel}
      onLoadComplete={onCardFormReady}
      onError={onError}
      isInFocus={selectedPaymentMethod === 'CARD'}
    />
  );

  const renderAddPaymentMethodAchContainer = ({
    formTitle,
    onBackClickLocal,
    selectedPaymentMethod,
  }: {
    formTitle: string;
    onBackClickLocal?: () => void;
    selectedPaymentMethod: string;
  }) => (
    <AddPaymentMethodAchContainer
      customerId={customerId}
      onCancel={onCancel}
      customerApi={customerApi}
      onSuccess={onSuccess}
      formTitle={formTitle}
      onBackClick={onBackClickLocal}
      onBeforeSubmit={onBeforeSubmit}
      onLoadComplete={onAchFormReady}
      onError={onError}
      isInFocus={selectedPaymentMethod === 'BANK_ACCOUNT'}
    />
  );

  const handleErrorNotificationsMethods = useCallback(() => {
    setErrorType('error');
  }, [setErrorType]);

  useEffect(() => {
    if (isInitializing) {
      return;
    }

    reset();
  }, [viewState, isInitializing, reset]);

  if (isInitializing) {
    return null;
  }

  switch (viewState) {
    case 'cancel_child_session':
      return (
        <CancelChildSessionWrapper
          errorType={errorType!}
          isChildStatusCanceled={isChildStatusCanceled}
          onCancel={onCancel}
          originalCheckoutSessionResponse={
            originalCheckoutSessionResponse
          }
          setErrorType={setErrorType}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          setShouldCancelChildSession={
            setShouldCancelChildSession
          }
          shouldCancelChildSession={shouldCancelChildSession}
          setLocalData={setLocalData}
          childSessionId={localData.childSessionId}
          selectedChannelType={localData.selectedChannelType}
        />
      );
    case 'channel_selector':
      return (
        <PaymentMethodChannelSelector
          userJourney="ADD_PAYMENT_METHOD"
          paymentMethodChannels={
            getConfiguredChannelsForAgent(
              originalCheckoutSessionResponse,
            ) ?? []
          }
          onDone={(userSelectedChannel) => {
            setLocalData((prevState) => ({
              ...prevState,
              selectedChannelType: userSelectedChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onCancel={onCancel}
          onBackClick={backButtonHandler}
          onLoadComplete={onManagedComponentReady}
        />
      );
    case 'awaiting_user_response':
      return (
        <AwaitingUserResponseContainer
          onCancel={() => {
            setShouldCancelChildSession(true);
            setErrorType('cancel');
          }}
          onError={(_errorType) => {
            setErrorType(_errorType);
          }}
          onDone={({ childSessionId, paymentMethodId }) => {
            setLocalData((prevState) => ({
              ...prevState,
              childSessionHistory:
                prevState.childSessionHistory && childSessionId
                  ? [
                      ...new Set([
                        ...prevState.childSessionHistory,
                        childSessionId,
                      ]),
                    ]
                  : [],
            }));
            onSuccess({ paymentMethodId });
          }}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          destination={localData.notificationDestination!}
          paymentMethodChannel={localData.selectedChannelType!}
          userJourney="ADD_PAYMENT_METHOD"
          onLoadComplete={onManagedComponentReady}
          childSessionHistory={
            localData.childSessionHistory ?? []
          }
        />
      );
    case 'notifications_methods': {
      const defaultDestination =
        localData.selectedChannelType === 'TEXT'
          ? getPhoneNumber(originalCheckoutSessionResponse)
          : getEmailAddress(originalCheckoutSessionResponse);

      return (
        <NotificationsMethodsContainer
          userJourney="ADD_PAYMENT_METHOD"
          checkoutSessionId={getSessionId(
            originalCheckoutSessionResponse,
          )}
          paymentMethodChannel={localData.selectedChannelType!}
          destination={defaultDestination}
          onDone={({
            childSessionId,
            notificationDestination,
            notificationChannel,
          }) => {
            setLocalData((prevState) => ({
              ...prevState,
              childSessionId,
              notificationDestination,
              selectedChannelType: notificationChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onError={handleErrorNotificationsMethods}
          onBackClick={backButtonHandler}
          onCancel={onCancel}
          onLoadComplete={onManagedComponentReady}
        />
      );
    }
    case 'unified_payment_method_form': {
      return (
        <Box
          style={{
            display: isLoading ? 'none' : 'block',
          }}
        >
          <UnifiedPaymentFormEntry
            userJourney="ADD_PAYMENT_METHOD"
            backButtonLabel="Back"
            onBackClick={backButtonHandler}
            isAgentAssistedSession={isAgentAssistedSession}
          >
            {(selectedPaymentMethod) => {
              return (
                <>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'CARD'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderAddPaymentMethodCardContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'BANK_ACCOUNT'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderAddPaymentMethodAchContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                </>
              );
            }}
          </UnifiedPaymentFormEntry>
        </Box>
      );
    }
    case 'view_state_error':
    default:
      return (
        <AddPaymentMethodEntryErrorHandler
          onBackClick={onBackClick}
        />
      );
  }
};

export default AddPaymentMethodEntry;
