import { useEffect } from 'react';

import usePopulateFormConfigForDrawer from '../usePopulateFormConfigForDrawer/usePopulateFormConfigForDrawer';
import useDrawerContextProvider from '../../capabilities/contextStore/DrawerContext/useDrawerContextProvider';

const useDrawerCleanup = () => {
  const populateFormConfigForDrawer =
    usePopulateFormConfigForDrawer();
  const { resetDrawerConfig } = useDrawerContextProvider();

  useEffect(() => {
    return () => {
      if (populateFormConfigForDrawer) {
        resetDrawerConfig();
      }
    };
  }, []);
};

export default useDrawerCleanup;
