import type { FC } from 'react';
import React, { useContext } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';

const PrimarySecondaryConditionalAppContentVisibility: FC<{
  isPrimaryRouteInFocus?: boolean;
}> = ({ children, isPrimaryRouteInFocus = true }) => {
  const { overlayLoaderConfig } = useContext(AppContext);
  let hideContent = overlayLoaderConfig.show;

  /**
   * This is sort of hacky, but if isPrimaryRouteInFocus is a
   * boolean that means that this component is being used in
   * the PrimaryRouteWrappers component and if it is false
   * then we should always show the content.
   */
  if (
    typeof isPrimaryRouteInFocus === 'boolean' &&
    !isPrimaryRouteInFocus
  ) {
    hideContent = false;
  }

  return (
    <div
      style={{
        display: hideContent ? 'none' : 'block',
      }}
    >
      {children}
    </div>
  );
};

export default PrimarySecondaryConditionalAppContentVisibility;
