import type {
  CheckoutSessionsResponse,
  DeterminedSessionResponse,
} from '../../../../../../services/commonCheckout/types/CheckoutSessionsResponse';

import { validateCheckoutSessionStatus } from './validateCheckoutSessionStatus';

/**
 * Just checks if `checkoutSession.checkoutSessionStatus === 'COMPLETED'`.
 *
 * Also has some type-narrowing as a bonus
 */
export const isSessionComplete = (
  checkoutSessionResponse?: CheckoutSessionsResponse,
): checkoutSessionResponse is DeterminedSessionResponse<'COMPLETED'> => {
  if (!checkoutSessionResponse) {
    // Must be initializing, therefore it's not complete (yet)
    return false;
  }

  if (!checkoutSessionResponse.checkoutSession) {
    console.warn(
      '`checkoutSession` is falsey. `checkoutSessionResponse`:',
      checkoutSessionResponse,
    );
    return false;
  }

  if (
    !(
      'checkoutSessionStatus' in
      checkoutSessionResponse.checkoutSession
    )
  ) {
    reportError(
      new Error(
        `checkoutSession has no \`checkoutSessionResponse\`: ${JSON.stringify(
          checkoutSessionResponse,
        )}`,
      ),
    );
    return false;
  }

  const { checkoutSessionStatus } =
    checkoutSessionResponse.checkoutSession;

  // TODO: remove this check once we are validating all server responses with zod
  /** @note: need to limit to modes that deals with making payments and
   *  managing payment methods.
   */
  validateCheckoutSessionStatus(checkoutSessionStatus);
  return checkoutSessionStatus === 'COMPLETED';
};
