import { useContext, useEffect, useState } from 'react';

import { getWalletFromCommonCheckoutApi } from '../../../services/commonCheckout/getWalletFromCommonCheckoutApi';
import { AppContext } from '../../contextStore/AppContext';
import type { PaymentMethodType } from '../../../services/customer/types';
import type { FormattedPaymentMethod } from '../components/Wallet/types';
import { formatPaymentMethods } from '../components/Wallet/utils';

const usePaymentWithWalletComponentData = () => {
  const {
    amount,
    customerId,
    setData,
    checkoutSessionId,
    isPrimaryRouteInFocus,
  } = useContext(AppContext);
  const [componentDataReady, setComponentDataReady] =
    useState<boolean>(false);
  const [formattedPaymentMethods, setFormattedPaymentMethods] =
    useState<FormattedPaymentMethod<PaymentMethodType>[]>([]);

  useEffect(() => {
    if (!isPrimaryRouteInFocus) {
      return;
    }

    setData({
      overlayLoaderConfig: { show: true },
    });

    (async () => {
      const { paymentMethods: fetchedPaymentMethods } =
        await getWalletFromCommonCheckoutApi({
          customerId,
          amount,
        });

      setData({
        paymentMethods: fetchedPaymentMethods,
      });

      setFormattedPaymentMethods(
        formatPaymentMethods(fetchedPaymentMethods),
      );

      setComponentDataReady(true);
    })()
      .catch((error) => {
        console.error(
          'Error in payment with wallet initialization.',
          error,
        );
      })
      .finally(() => {
        setData({
          overlayLoaderConfig: { show: false },
        });
      });
  }, [isPrimaryRouteInFocus]);

  return {
    componentDataReady,
    checkoutSessionId,
    formattedPaymentMethods,
  };
};

export default usePaymentWithWalletComponentData;
