import type { ContainerConfig } from '../../components/container-types/types';

const isPrimarySecondaryContainer = (
  config: ContainerConfig,
) => {
  let result = false;

  if (
    config &&
    config.type === 'inline' &&
    config.secondaryContainer?.type
  ) {
    result = true;
  }

  return result;
};

export default isPrimarySecondaryContainer;
