import React from 'react';
import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { TEXT_CONTENT_IDS } from '../../../../utils/element-ids';

import type { PaymentMethodChannelOptionProps } from './PaymentMethodChannelOptionProps';

const PaymentMethodChannelOption: FC<
  PaymentMethodChannelOptionProps
> = ({ type, title, icon, onClick, testId, enabled }) => {
  return (
    <Button
      onClick={() => {
        onClick(type);
      }}
      variant="outlined"
      sx={{
        width: '100%',
        minHeight: '74px',
        border: '1px solid #929496',
        borderRadius: '6px',
        backgroundColor: 'transparent',
        '@media (min-width: 0px)': {
          padding: 4,
        },
        marginBottom: 2,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          border: '1px solid #929496',
        },
      }}
      data-testid={testId}
      disabled={!enabled}
    >
      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              style={{ marginTop: 2 }}
            >
              <Typography style={{ color: '#4B4D4F' }}>
                {icon}
              </Typography>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'left' }}
              xs={12}
            >
              <Typography
                data-testid={`${type}_${TEXT_CONTENT_IDS.PAYMENT_METHOD_CHANNEL_TITLE}`}
              >
                <strong>{title}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {enabled ? (
          <NavigateNextIcon style={{ color: '#4B4D4F' }} />
        ) : (
          <Typography
            style={{ color: '#C40000' }}
            data-testid={`${type}_${TEXT_CONTENT_IDS.PAYMENT_METHOD_CHANNEL_NOT_AVAILABLE}`}
          >
            Not available
          </Typography>
        )}
      </Grid>
    </Button>
  );
};

export default PaymentMethodChannelOption;
