import React, { useCallback, useMemo, useRef } from 'react';

import type { BeforeCloseTaskFunc } from './SecondaryContainerCloseContext';
import SecondaryContainerCloseContext from './SecondaryContainerCloseContext';

const SecondaryContainerCloseContextProvider: React.FC = ({
  children,
}) => {
  const beforeCloseTaskRef = useRef<BeforeCloseTaskFunc | null>(
    null,
  );

  const registerBeforeCloseTask = useCallback(
    (task: BeforeCloseTaskFunc) => {
      beforeCloseTaskRef.current = task;
    },
    [],
  );

  const clearBeforeCloseTask = useCallback(() => {
    beforeCloseTaskRef.current = null;
  }, []);

  const runBeforeCloseTask = useCallback(async () => {
    const task = beforeCloseTaskRef.current;
    if (task) {
      await Promise.resolve(task());
    }
  }, []);

  const value = useMemo(() => {
    return {
      registerBeforeCloseTask,
      clearBeforeCloseTask,
      runBeforeCloseTask,
    };
  }, [
    registerBeforeCloseTask,
    clearBeforeCloseTask,
    runBeforeCloseTask,
  ]);

  return (
    <SecondaryContainerCloseContext.Provider value={value}>
      {children}
    </SecondaryContainerCloseContext.Provider>
  );
};

export default SecondaryContainerCloseContextProvider;
