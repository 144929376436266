import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { FC } from 'react';

import SessionCanceledScreen from '../session-invalid-screen/SessionCanceledScreen';
import type { CancelCallbackContextProps } from '../../capabilities/checkout-v2/context/cancel-callback-context';
import CancelCallbackContext from '../../capabilities/checkout-v2/context/cancel-callback-context';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import useServiceContextProvider from '../../capabilities/contextStore/ServiceContext/useServiceContextProvider';

import { cancelSessionHelper } from './utils/cancelSession';
import type CancelSessionHandlerArgs from './types/CancelSessionHandlerArgs';
import { isMobileBrowser } from './utils/isMobileBrowser';

type CancelSessionHandlerProps = {
  sessionId?: string;
  onCancelEnvironmentCallback?: CancelSessionHandlerArgs['onCancelEnvironmentCallback'];
};

const CancelSessionHandler: FC<CancelSessionHandlerProps> = ({
  sessionId,
  onCancelEnvironmentCallback,
  children,
}) => {
  const { triggerUnload, appEnvironmentType } =
    useEnvironmentContextProvider();
  const { sessionStorageService: sessionStorageWrapper } =
    useServiceContextProvider();

  const [showFinalScreen, setShowFinalScreen] = useState(false);
  const unloadAlreadyTriggeredRef = useRef<boolean>();
  const isMountedRef = useRef<boolean>(true);

  const updateUiCallback = () => {
    setShowFinalScreen(true);
  };

  /**
   *  NOTE: will need to handle page unload event on its own
   * because of a race condition that is happening in safari mobile browsers
   * during page reload as per QA. Calling onCancelEnvironmentCallback
   * during page unload results in an intermittent behavior
   * of closing the window automatically and cancelling the session in mobile browsers.
   */
  const handleUnload = () => {
    const shouldCancelSession =
      sessionStorageWrapper.getItem(
        'reloadBecauseOfLanguageChange',
      ) !== 'true';

    cancelSessionHelper({
      sessionId,
      updateUiCallback: isMountedRef.current
        ? updateUiCallback
        : undefined,
      shouldCancelSession,
    });
  };

  const shouldCallEnvironmentCallback =
    !isMobileBrowser() || appEnvironmentType === 'embedded';

  // NOTE: the onCancelEnvironmentCallback will need to be check if call is desktop or mobile
  // android browsers closes the browser when clicking the cancel button that results into
  // cancelling the fetch api call and ends up not cancelling the child session for android users.
  // It was also said by business that they want to see the "Your session is no longer valid"
  // in both ios and android.
  const cancelCallbackContextValue: CancelCallbackContextProps =
    useMemo(
      () => ({
        onCancel: () => {
          cancelSessionHelper({
            sessionId,
            onCancelEnvironmentCallback:
              shouldCallEnvironmentCallback
                ? onCancelEnvironmentCallback
                : undefined,
            updateUiCallback: isMountedRef.current
              ? updateUiCallback
              : undefined,
          });
        },
      }),
      [],
    );

  useEffect(() => {
    if (triggerUnload && !unloadAlreadyTriggeredRef.current) {
      cancelSessionHelper({
        sessionId,
        onCancelEnvironmentCallback,
        updateUiCallback: isMountedRef.current
          ? updateUiCallback
          : undefined,
      });

      unloadAlreadyTriggeredRef.current = true;
    }
  }, [triggerUnload, unloadAlreadyTriggeredRef.current]);

  useEffect(() => {
    if (sessionId) {
      window.addEventListener('unload', handleUnload);
    }

    return () => {
      window.removeEventListener('unload', handleUnload);
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showFinalScreen ? (
    <SessionCanceledScreen />
  ) : (
    <CancelCallbackContext.Provider
      value={cancelCallbackContextValue}
    >
      {children}
    </CancelCallbackContext.Provider>
  );
};

export default CancelSessionHandler;
