import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { FC } from 'react';

import type { InitializerProps } from '../../../widget-wrapper/types';
import getAppEnvironmentType from '../../../utils/getAppEnvironmentType';
import type { ContainerConfig } from '../../../components/container-types/types';

import EnvironmentContext from './EnvironmentContext';
import type { EnvironmentLocalStateContextType } from './EnvironmentContext';

type EnvironmentContextProviderProps =
  Partial<InitializerProps> & {
    containerConfig?: ContainerConfig;
  };

const EnvironmentContextProvider: FC<
  EnvironmentContextProviderProps
> = ({
  children,
  postMessage,
  showPaymentContainer,
  containerConfig,
}) => {
  const [environmentState, setEnvironmentStateLocal] =
    useState<EnvironmentLocalStateContextType>({
      appEnvironmentType: getAppEnvironmentType(),
      postMessage,
      showPaymentContainer,
      showWidgetContainer: false,
      onDoneClick: undefined,
      triggerUnload: false,
      containerConfig,
    });

  /** @note: showPaymentContainer changes value during initialization
   *  and will need to be updated via useEffect.
   */

  useEffect(() => {
    setEnvironmentStateLocal((prev) => ({
      ...prev,
      showPaymentContainer,
    }));
  }, [showPaymentContainer]);

  /** @note: removed setEnvironmentState outside of the local state
   *  to avoid infinite loop of updates.
   */
  const setEnvironmentState = useCallback(
    (newData: Partial<EnvironmentLocalStateContextType>) => {
      setEnvironmentStateLocal((prev) => ({
        ...prev,
        ...newData,
      }));
    },
    [],
  );

  const environmentValue = useMemo(() => {
    return {
      ...{
        ...environmentState,
        setEnvironmentState,
      },
    };
  }, [environmentState]);

  return (
    <EnvironmentContext.Provider value={environmentValue}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentContextProvider;
