import React, { useState, useEffect, useContext } from 'react';

import { getInitialUiRouteFromSession } from '../utils/getInitialUiRouteFromSession';
import type { UiRouteDescriptor } from '../types/UiRouteDescriptor';
import isPrimaryCcgRouteMode from '../../utils/function/isPrimaryCcgRouteMode';
import type {
  RootPathNameValues,
  SecondaryCcgPathNameValues,
} from '../types/PathNameValues';
import isValidPaymentMethodDisplayMode from '../../utils/function/isValidPaymentMethodDisplayMode';
import { getConfigMode } from '../../utils/session/selectors';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import isPrimarySecondaryContainer from '../../utils/container-config/isPrimarySecondaryContainer';

import CcgElementsRouteGroupContainer from './RouteGroupContainers/CcgElementsRouteGroupContainer';
import CcgEmbeddedRouteGroupContainer from './RouteGroupContainers/CcgEmbeddedRouteGroupContainer';
import CcgPrimaryRouter from './CcgPrimaryRouter';
import CcgPrimarySecondaryRouteGroupContainer from './RouteGroupContainers/CcgPrimarySecondaryRouteGroupContainer/CcgPrimarySecondaryRouteGroupContainer';

export const SharedEmbeddedHostedRouter = (_: {
  // We should avoid passing an `isHosted` prop here:
  //   Both hosted and embedded should pass unique props for their given contexts
}) => {
  const { originalCheckoutSessionResponse, checkoutSessionId } =
    useContext(AppContext);
  const configMode = getConfigMode(
    originalCheckoutSessionResponse,
  );
  const { postMessage, containerConfig, showPaymentContainer } =
    useEnvironmentContextProvider();

  const [initialUiRoute, setInitialUiRoute] =
    useState<
      UiRouteDescriptor<
        RootPathNameValues | SecondaryCcgPathNameValues
      >
    >();

  useEffect(() => {
    if (Object.keys(originalCheckoutSessionResponse).length) {
      setInitialUiRoute(
        getInitialUiRouteFromSession({
          originalCheckoutSessionResponse,
        }),
      );
    }
  }, [originalCheckoutSessionResponse]);

  /** @note: path for embedded */
  if (
    initialUiRoute &&
    containerConfig &&
    postMessage &&
    showPaymentContainer &&
    isPrimaryCcgRouteMode(initialUiRoute)
  ) {
    if (isPrimarySecondaryContainer(containerConfig)) {
      return (
        <CcgPrimarySecondaryRouteGroupContainer
          initialUiRoute={initialUiRoute}
        />
      );
    } else {
      return (
        <CcgEmbeddedRouteGroupContainer
          initialUiRoute={initialUiRoute}
          showPaymentContainer={showPaymentContainer}
          postMessage={postMessage}
          containerConfig={containerConfig}
          checkoutSessionId={checkoutSessionId}
        />
      );
    }
  }

  /** @note: path for payment method display */
  if (
    initialUiRoute &&
    isValidPaymentMethodDisplayMode({
      configMode,
      containerConfig,
      initialUiRoute,
    })
  ) {
    return (
      <CcgElementsRouteGroupContainer
        initialUiRoute={initialUiRoute}
      />
    );
  }

  /** @note: path for hosted */
  if (
    initialUiRoute &&
    isPrimaryCcgRouteMode(initialUiRoute) &&
    !containerConfig
  ) {
    return <CcgPrimaryRouter initialUiRoute={initialUiRoute} />;
  }

  /** error scenarios should be handled for each routes instead of here
   *  because there would random moments where the initialUiRoute is
   *  not yet set while loading and it would cause the error to be shown.
   */
  return null;
};
