import React, { useEffect } from 'react';
import { useParams, Outlet } from 'react-router';

import primarySecondaryRouteConfigMap from '../constants/primarySecondaryRouteConfigMap';
import ErrorCard from '../../../../../components/ErrorCard/ErrorCard';
import useNotificationContextProvider from '../../../../../capabilities/contextStore/NotificationContext/useNotificationContextProvider';
import SecondaryContainerCloseContextProvider from '../../../../../capabilities/contextStore/SecondaryContainerCloseContext/SecondaryContainerCloseContextProvider';

import SecondaryRouteContainerSelector from './SecondaryRouteContainerSelector';

const SecondaryRouteContainer = () => {
  const params = useParams();
  const { closeNotification } = useNotificationContextProvider();

  if (!params?.parentRoute) {
    return null;
  }

  const childRouteComponents =
    primarySecondaryRouteConfigMap[
      params.parentRoute as keyof typeof primarySecondaryRouteConfigMap
    ].secondary;

  useEffect(() => {
    return () => {
      closeNotification();
    };
  }, []);

  if (!childRouteComponents) {
    return <ErrorCard />;
  }

  return (
    <SecondaryContainerCloseContextProvider>
      <SecondaryRouteContainerSelector>
        <>
          {childRouteComponents()}
          <Outlet />
        </>
      </SecondaryRouteContainerSelector>
    </SecondaryContainerCloseContextProvider>
  );
};

export default SecondaryRouteContainer;
