export const PAGE_TITLE = 'Make a payment';
export const PAYMENT_BUTTON_TEXT = 'Submit payment';
export const CANCEL_BUTTON_TEXT = 'Cancel';
export const PAYMENT_METHOD_SETTINGS_TITLE =
  'Payment method settings';
export const NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR =
  "Please make sure that the name field doesn't contain a card number.";
export const NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR =
  "Please make sure that the card nickname field doesn't contain a card number.";
export const ACH_AUTHORIZATION_AGREEMENT =
  'ACH authorization agreement';
export const SEND_TEXT_SUBTITLE_PAYMENT =
  'Send a text message with a link for the customer to make a payment.';