import type { FormattedPaymentMethod } from '../types';

import type {
  PaymentMethodError,
  ValidateBankAccountPaymentMethodProp,
} from './validatePaymentMethod';

const validateCardPaymentMethod = ({
  formattedPaymentMethod,
}: ValidateBankAccountPaymentMethodProp & {
  formattedPaymentMethod: FormattedPaymentMethod<'CARD'>;
}): undefined | PaymentMethodError => {
  if (formattedPaymentMethod.isExpired) {
    return {
      errorTitle: 'Expired ',
      errorMessage: 'Please use a different payment method.',
    };
  }

  return undefined;
};

export default validateCardPaymentMethod;
