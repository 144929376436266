import type { ContainerConfig } from '../../components/container-types/types';

const isSecondaryTypeModal = (
  containerConfig?: ContainerConfig,
) => {
  let result = false;

  if (
    containerConfig?.type === 'inline' &&
    containerConfig?.secondaryContainer?.type === 'modal'
  ) {
    result = true;
  }

  return result;
};

export default isSecondaryTypeModal;
