import { useContext } from 'react';

import ServiceContext from './ServiceContext';

const useServiceContextProvider = () => {
  const context = useContext(ServiceContext);
  if (context === undefined) {
    throw new Error(
      'useServices must be used within a ServiceProvider',
    );
  }
  return context;
};

export default useServiceContextProvider;
