import type { AppEnv } from '../shared/types/app/AppEnv';

import { DEFAULT_ENV } from './constants';
import getCookie from './function/getCookie';

/**
 * NOTE: This should only be used in our hosted environment since
 * we can't rely on getting appEnv from URL in embedded
 * @returns AppEnv (dev, test, stage, prod), default: stage
 */
const getAppEnvOrDefaultForHosted = (): AppEnv => {
  const queryParams = new URLSearchParams(
    window.location.search,
  );
  return (
    (queryParams.get('appEnv') as AppEnv) ||
    (getCookie('ccg-app-env') as AppEnv) ||
    DEFAULT_ENV
  );
};

export default getAppEnvOrDefaultForHosted;
