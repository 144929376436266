import { Typography, styled } from '@mui/material';

const StyledErrorTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: '14.88px',
    color: theme.palette.error.main,

    [theme.breakpoints.down('sm')]: {
      fontSize: '14.88px',
    },
  }),
);

export default StyledErrorTypography;
