import type { PaymentMethodType } from '../../../../../services/commonCheckout/types/PaymentMethod';
import type { FormattedPaymentMethod } from '../types';

import { isFormattedBankAccountPaymentMethod } from './isFormattedBankAccountPaymentMethod';
import { isFormattedCardPaymentMethod } from './isFormattedCardPaymentMethod';
import validateCardPaymentMethod from './validateCardPaymentMethod';
import validateBankAccountPaymentMethod from './validateBankAccountPaymentMethod';

export type ValidateBankAccountPaymentMethodProp = {
  formattedPaymentMethod?: FormattedPaymentMethod<PaymentMethodType>;
  options?: Partial<{
    consentText: string;
  }>;
  noPaymentMethodAvailable?: boolean;
};

export type PaymentMethodError = {
  errorTitle: string;
  errorMessage?: string;
};

const validatePaymentMethod = ({
  formattedPaymentMethod,
  options,
  noPaymentMethodAvailable,
}: ValidateBankAccountPaymentMethodProp):
  | undefined
  | PaymentMethodError => {
  if (noPaymentMethodAvailable) {
    return {
      errorTitle: 'Please add a payment method.',
    };
  }
  if (!formattedPaymentMethod) {
    return {
      errorTitle: 'Please select a payment method.',
    };
  }

  if (isFormattedCardPaymentMethod(formattedPaymentMethod)) {
    return validateCardPaymentMethod({ formattedPaymentMethod });
  }

  if (
    isFormattedBankAccountPaymentMethod(formattedPaymentMethod)
  ) {
    return validateBankAccountPaymentMethod({
      formattedPaymentMethod,
      options,
    });
  }
  throw new Error(
    'Invalid (or) Unhandled formattedPaymentMethod.type',
  );
};

export default validatePaymentMethod;
