import { createTheme } from '@mui/material';
import type { Theme, ThemeOptions } from '@mui/material';

const getAppTheme = (theme: ThemeOptions): Theme => {
  const customThemeOptions: ThemeOptions = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            html: {
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:focus, &:focus-within': {
              outline: `2px solid #316BBE`,
            },
          },
          containedSecondary: {
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: '#002677',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:focus, &:focus-within': {
              outline: `2px solid #316BBE`,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            display: 'inline-block',
            '&:focus, &:focus-within, &:focus-visible': {
              outline: `2px auto #316BBE`,
              outlineOffset: '3px',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.ccg-Mui-checked': {
              color: '#196DCD',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 0,
          },
        },
      },
    },
  };

  return createTheme(theme, customThemeOptions);
};

export default getAppTheme;
