import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const USAAIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 23</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#1A3258"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="USAA_logo"
              transform="translate(13.800000, 4.800000)"
              fill="#FFFFFF"
            >
              <g
                id="g56805"
                transform="translate(13.470968, 11.029787) scale(-1, 1) rotate(-180.000000) translate(-13.470968, -11.029787) translate(12.541935, 10.263830)"
              >
                <path
                  d="M0.929032262,1.45896657 C0.490219357,1.45896657 0.134895484,1.14849849 0.134895484,0.765373863 C0.134895484,0.382832828 0.490219357,0.072364742 0.929032262,0.072364742 C1.36725678,0.072364742 1.72304517,0.382832828 1.72304517,0.765373863 C1.72304517,1.14849849 1.36725678,1.45896657 0.929032262,1.45896657 Z M0.929032262,0.197048025 C0.56931097,0.197048025 0.277780646,0.451929485 0.277780646,0.765373863 C0.277780646,1.07954773 0.56931097,1.33428329 0.929032262,1.33428329 C1.28890839,1.33428329 1.57985033,1.07954773 1.57985033,0.765373863 C1.57985033,0.451929485 1.28890839,0.197048025 0.929032262,0.197048025 L0.929032262,0.197048025 Z"
                  id="path56807"
                />
              </g>
              <g
                id="g56809"
                transform="translate(1.625806, 12.408511) scale(-1, 1) rotate(-180.000000) translate(-1.625806, -12.408511) translate(0.154839, 10.417021)"
              >
                <path
                  d="M0,3.98297874 C0,3.98297874 0,1.6557549 0,1.6431932 C0,0.336102129 0.80090045,0.119182979 1.47184279,0.119182979 C2.46368239,0.119182979 2.92337867,0.772391493 2.92337867,1.6557549 L2.92337867,3.98297874 L2.2667085,3.98297874 L2.2667085,1.57704511 C2.2667085,1.27369532 2.16725599,0.706212769 1.47184279,0.717855322 C0.839221046,0.728272344 0.723112658,1.30776511 0.723112658,1.68014299 L0.723112658,3.98297874 L0,3.98297874"
                  id="path56811"
                />
              </g>
              <g
                id="g56813"
                transform="translate(4.722581, 12.255319) scale(-1, 1) rotate(-180.000000) translate(-4.722581, -12.255319) translate(3.406452, 10.263830)"
              >
                <path
                  d="M2.47741937,3.75319151 C2.47741937,3.75319151 2.04724646,3.96036768 1.55897807,3.96036768 C0.94990452,3.96036768 0.185094194,3.65741619 0.185094194,2.82776172 C0.185094194,2.25268086 0.620717422,1.98131746 0.917852907,1.84084086 C1.07541678,1.76648171 1.26729291,1.68314554 1.4253213,1.60057533 C1.50667355,1.55749788 1.83041033,1.45158128 1.83041033,1.11152681 C1.83041033,0.746808515 1.42018065,0.623182982 1.23582968,0.623182982 C0.579003873,0.623182982 0.131705807,0.913603409 0.131705807,0.913603409 L0.200670969,0.183523406 C0.200670969,0.183523406 0.469223228,0.0514723416 1.27243355,0.0496340438 C2.03860646,0.0520851076 2.59128775,0.530348939 2.59128775,1.18873533 C2.59128775,1.69558469 2.23503485,1.99565618 1.9312413,2.13928852 C1.69189162,2.25268086 1.55337291,2.31490725 1.34533162,2.41037618 C1.13725936,2.50477278 0.922529036,2.66933108 0.922529036,2.92378214 C0.922529036,3.1971064 1.17990194,3.39325278 1.55897807,3.39325278 C2.11680001,3.39325278 2.34103743,3.14820767 2.47741937,3.07204087 L2.47741937,3.75319151"
                  id="path56815"
                />
              </g>
              <g
                id="g56817"
                transform="translate(9.367742, 12.331915) scale(-1, 1) rotate(-180.000000) translate(-9.367742, -12.331915) translate(6.038710, 10.417021)"
              >
                <path
                  d="M5.20400447,3.82978725 L4.40537346,3.82978725 L4.53287157,3.51338555 L3.29211445,0.260425534 L2.01211302,3.82978725 L1.21253304,3.82978725 L1.33926586,3.51338555 L0.0174487209,0.0453446817 L0.739274753,0.0453446817 L0.970087657,0.705906387 L2.33742453,0.705906387 L2.56762519,0.0453446817 L3.93199272,0.0453446817 L4.16381581,0.705906387 L5.53103024,0.705906387 L5.76107784,0.0453446817 L6.5613619,0.0453446817 L5.20400447,3.82978725 Z M1.15039111,1.21836256 L1.68150568,2.7003064 L2.15491703,1.21836256 L1.15039111,1.21836256 Z M4.34408865,1.21836256 L4.87321346,2.7003064 L5.34794111,1.21836256 L4.34408865,1.21836256 L4.34408865,1.21836256 Z"
                  id="path56819"
                />
              </g>
              <g
                id="g56821"
                transform="translate(13.761290, 11.170213) scale(-1, 1) rotate(-180.000000) translate(-13.761290, -11.170213) translate(13.161290, 10.570213)"
              >
                <path
                  d="M0.711622214,0.603239902 C0.711622214,0.603239902 0.960746728,0.68999831 0.960746728,0.92018997 C0.960746728,1.15137943 0.733217545,1.28244 0.454666961,1.28244 C0.175338168,1.28244 0.00675840106,1.21688477 0.00675840106,1.21688477 L0.00675840106,0.0824400016 L0.337546339,0.0824400016 L0.337546339,0.496824902 L0.436816767,0.496824902 L0.78594128,0.0873292047 L1.2067584,0.0873292047 L0.711622214,0.603239902 Z M0.490270074,0.68999831 L0.337546339,0.68999831 L0.337546339,1.06781397 C0.336622214,1.06781397 0.611379024,1.0912123 0.611379024,0.905322802 C0.611379024,0.716539698 0.490270074,0.68999831 0.490270074,0.68999831 L0.490270074,0.68999831 Z"
                  id="path56823"
                />
              </g>
              <g
                id="g56825"
                transform="translate(2.322581, 7.429787) scale(-1, 1) rotate(-180.000000) translate(-2.322581, -7.429787) translate(0.000000, 5.821277)"
              >
                <path
                  d="M0,2.8425532 L0.0346219356,1.6863755 C0.0346219356,1.6863755 0.33327484,1.89613754 0.667757422,1.89613754 C1.00282839,1.89613754 1.3803871,1.66723269 1.3803871,1.66723269 L4.37636131,0.00492391557 L4.62998712,1.04936035 L1.3803871,2.85267882 C1.3803871,2.85267882 1.00388129,3.0796664 0.667757422,3.0796664 C0.331602582,3.0796664 0,2.8425532 0,2.8425532"
                  id="path56827"
                />
              </g>
              <g
                id="g56829"
                transform="translate(2.477419, 5.974468) scale(-1, 1) rotate(-180.000000) translate(-2.477419, -5.974468) translate(0.000000, 4.289362)"
              >
                <path
                  d="M-2.57858251e-16,3.07063831 L0.0346219356,1.91416103 C0.0346219356,1.91416103 0.33327484,2.12392307 0.667757422,2.12392307 C1.00282839,2.12392307 1.3803871,1.89513805 1.3803871,1.89513805 L4.69966454,0.0533242565 L4.95205163,1.09853958 L1.3803871,3.08073397 C1.3803871,3.08073397 1.00388129,3.30790129 0.667757422,3.30790129 C0.331602582,3.30790129 -2.57858251e-16,3.07063831 -2.57858251e-16,3.07063831"
                  id="path56831"
                />
              </g>
              <g
                id="g56833"
                transform="translate(2.787097, 4.595745) scale(-1, 1) rotate(-180.000000) translate(-2.787097, -4.595745) translate(0.154839, 2.757447)"
              >
                <path
                  d="M0,3.37021278 L0.0341201684,2.18732937 C0.0341201684,2.18732937 0.32844477,2.40228767 0.658079778,2.40228767 C0.988294647,2.40228767 1.36038149,2.16830299 1.36038149,2.16830299 L4.95047632,0.101106383 L5.19951082,1.16946383 L1.36038149,3.38053789 C1.36038149,3.38053789 0.98933229,3.61256172 0.658079778,3.61256172 C0.326796748,3.61256172 0,3.37021278 0,3.37021278"
                  id="path56835"
                />
              </g>
              <g
                id="g56837"
                transform="translate(6.270968, 2.757447) scale(-1, 1) rotate(-180.000000) translate(-6.270968, -2.757447) "
              >
                <path
                  d="M6.2329619,4.28936172 L6.56464782,2.89403235 L11.0992892,0.306076597 C11.0992892,0.306076597 11.4557223,0.0729191493 11.8059993,0.0729191493 C12.1562764,0.0729191493 12.4705408,0.316493618 12.4705408,0.316493618 L12.4360672,1.49888681 C12.4360672,1.49888681 12.1516594,1.28435745 11.8059993,1.28435745 C11.4600315,1.28435745 11.0992892,1.51788256 11.0992892,1.51788256 L7.59085498,3.5207081 L7.34873904,4.41905364 C7.34873904,4.45692257 7.38315114,4.47472342 7.39638657,4.47741959 L7.99019546,4.65901279 C8.07028517,4.65901279 8.11642448,4.59200683 8.11642448,4.53529534 L8.14738922,4.47083236 C8.1694893,4.46004768 8.25890537,4.50269619 8.26506139,4.50612768 L8.26506139,4.85963236 C8.26718521,5.09496513 8.07508686,5.30290726 7.81388728,5.30290726 C7.64736717,5.30290726 7.21964747,5.30290726 7.21964747,5.30290726 C7.21964747,5.30290726 7.13352486,5.50171917 6.88131306,5.50171917 C6.700511,5.50171917 6.05139034,5.50171917 5.75380873,5.50171917 C5.45638102,5.50171917 5.38500207,5.2276596 5.38500207,5.2276596 L4.60211127,2.13420256 L1.39864587,3.96321704 C1.39864587,3.96321704 1.05206241,4.19704853 0.69039672,4.19704853 C0.32934663,4.19704853 0.0267786521,3.95411746 0.0267786521,3.95411746 L0.061252318,2.77077448 C0.061252318,2.77077448 0.328423228,2.9854264 0.69039672,2.9854264 C1.05237021,2.9854264 1.39864587,2.75101278 1.39864587,2.75101278 L5.33975538,0.502161704 L6.2329619,4.28936172"
                  id="path56839"
                />
              </g>
              <g
                id="g56841"
                transform="translate(9.600000, 4.978723) scale(-1, 1) rotate(-180.000000) translate(-9.600000, -4.978723) translate(6.658065, 2.910638)"
              >
                <path
                  d="M5.50097602e-16,4.05957449 L0.332810324,2.65765788 L4.47298066,0.309446811 C4.47298066,0.309446811 4.84366454,0.0756765971 5.18400002,0.0756765971 C5.52464519,0.0756765971 5.85259357,0.319251066 5.85259357,0.319251066 L5.81821938,1.50185873 C5.81821938,1.50185873 5.55313551,1.28732937 5.18400002,1.28732937 C4.81486454,1.28732937 4.47298066,1.52054809 4.47298066,1.52054809 L5.50097602e-16,4.05957449"
                  id="path56843"
                />
              </g>
              <g
                id="g56845"
                transform="translate(9.909677, 6.510638) scale(-1, 1) rotate(-180.000000) translate(-9.909677, -6.510638) translate(7.122581, 4.595745)"
              >
                <path
                  d="M5.50097602e-16,3.67959952 L0.333739356,2.3048711 L4.0487226,0.239098876 C4.0487226,0.239098876 4.41940647,0.0099123915 4.75974196,0.0099123915 C5.10038712,0.0099123915 5.42833551,0.249011266 5.42833551,0.249011266 L5.39365164,1.40846058 C5.39365164,1.40846058 5.12918712,1.19756696 4.75974196,1.19756696 C4.39060647,1.19756696 4.0487226,1.42618274 4.0487226,1.42618274 L5.50097602e-16,3.67959952"
                  id="path56847"
                />
              </g>
              <g
                id="g56849"
                transform="translate(9.987097, 8.348936) scale(-1, 1) rotate(-180.000000) translate(-9.987097, -8.348936) translate(7.432258, 6.587234)"
              >
                <path
                  d="M0,3.52340427 L0.332500647,2.12344852 L3.62384518,0.256442555 C3.62384518,0.256442555 3.99421937,0.0226723412 4.33486453,0.0226723412 C4.67520002,0.0226723412 5.00345809,0.26624681 5.00345809,0.26624681 L4.96877421,1.44814979 C4.96877421,1.44814979 4.70400002,1.23408001 4.33486453,1.23408001 C3.96572905,1.23408001 3.62384518,1.46760511 3.62384518,1.46760511 L0,3.52340427"
                  id="path56851"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default USAAIcon;
