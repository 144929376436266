import type { SVGProps } from 'react';
import React from 'react';

const WarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.3335 71.6668H76.6668L40.0002 8.3335L3.3335 71.6668ZM43.3335 61.6668H36.6668V55.0002H43.3335V61.6668ZM43.3335 48.3335H36.6668V35.0002H43.3335V48.3335Z"
        fill="#C24E14"
      />
    </svg>
  );
};
export default WarningIcon;
