export const ALERT_ELEMENT = {
  success: {
    titleColor: '#007000',
    msgColor: '#4B4D4F',
    backgroundColor: '#EFF6EF',
    borderColor: '#007000',
    iconColor: '#007000',
  },
  warning: {
    titleColor: '#323334',
    msgColor: '#4B4D4F',
    backgroundColor: '#FEF9EA',
    borderColor: '#F5B700',
    iconColor: '#C9730D',
  },
  error: {
    titleColor: '#C40000',
    msgColor: '#4B4D4F',
    backgroundColor: '#FCF0F0',
    borderColor: '#C40000',
    iconColor: '#C40000',
  },
  info: {
    titleColor: '#002677',
    msgColor: '#4B4D4F',
    backgroundColor: '#E5F8FB',
    borderColor: '#002677',
    iconColor: '#002677',
  },
};
