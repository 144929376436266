import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const GenericCardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Generic Card</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Generic-Card">
          <g
            id="Icons/Payment-Chip-Copy-25"
            fill="#E0E0E0"
            fillRule="nonzero"
          >
            <g id="Brand">
              <rect
                id="Rectangle"
                x="0"
                y="0"
                width="42"
                height="24"
                rx="2"
              />
            </g>
          </g>
          <g
            id="credit_card_black_24dp"
            transform="translate(12.000000, 3.000000)"
          >
            <polygon
              id="Path"
              points="0 0 18 0 18 18 0 18"
            />
            <path
              d="M15,3 L3,3 C2.1675,3 1.5075,3.6675 1.5075,4.5 L1.5,13.5 C1.5,14.3325 2.1675,15 3,15 L15,15 C15.8325,15 16.5,14.3325 16.5,13.5 L16.5,4.5 C16.5,3.6675 15.8325,3 15,3 Z M15,13.5 L3,13.5 L3,9 L15,9 L15,13.5 Z M15,6 L3,6 L3,4.5 L15,4.5 L15,6 Z"
              id="Shape"
              fill="#636363"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default GenericCardIcon;
