import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const RegionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 19</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#6EC72E"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Forms-/-zz-build-/-Elements-/-Banks-/-Regions"
              transform="translate(12.000000, 6.000000)"
              fill="#FFFFFF"
            >
              <g
                id="g36-Clipped"
                transform="translate(0.093327, 0.091598)"
              >
                <g
                  id="g36"
                  transform="translate(-0.000000, -0.000000)"
                >
                  <polygon
                    id="path68"
                    points="6.86194789 2.69923862 4.61122304 5.6644386 8.1105383 11.7226772 6.86194789 2.69923862"
                  />
                  <polygon
                    id="path70"
                    points="13.2399078 5.92012042 13.1900729 5.85531133 10.4745244 11.7306658 17.6516558 11.7306658 13.2399078 5.92012042"
                  />
                  <polygon
                    id="path72"
                    points="8.81728788 11.7306658 10.5732881 2.40715908 8.82816072 0.110461363 7.05494478 2.44533408 8.80097817 11.7306658 8.81728719 11.7306658"
                  />
                  <polygon
                    id="path74"
                    points="4.41732013 5.92012042 0.0055730412 11.7306658 7.10477972 11.7306658 4.46896714 5.85087269 4.41732013 5.92012042"
                  />
                  <polygon
                    id="path76"
                    points="9.69438195 11.7306658 13.0133855 5.62271133 10.7735337 2.67171816 9.6545138 11.7306658 9.69438195 11.7306658"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default RegionsIcon;
