import React from 'react';
import type { FC } from 'react';
import type { StripeElementsOptions } from '@stripe/stripe-js';

import StripeElementsLoader from '../stripe-elements-loader/StripeElementsLoader';
// import { isStripeElementLocale } from '../utils/isStripeElementLocale';
import useRenderedLanguage from '../../../capabilities/components/LanguageSelector/utils/useRenderedLanguage';

import type { ExpressCheckoutElementStyleConfig } from './types/ExpressCheckoutElementStyleConfig';

export type ExpressCheckoutElementWrapperProps = {
  vendorPlatformKey?: string;
  amount: number;
  styleConfig: ExpressCheckoutElementStyleConfig;
};

const StripeExpressCheckoutElementWrapper: FC<
  ExpressCheckoutElementWrapperProps
> = ({ vendorPlatformKey, amount, styleConfig, children }) => {
  const { renderedLanguage } = useRenderedLanguage();

  const options: StripeElementsOptions = {
    appearance: {
      variables: {
        borderRadius: styleConfig.borderRadius,
      },
    },
    mode: 'payment',
    currency: 'usd',
    amount,
    paymentMethodCreation: 'manual',
    locale: renderedLanguage,
  };

  return vendorPlatformKey ? (
    <StripeElementsLoader
      vendorPlatformKey={vendorPlatformKey}
      options={options}
    >
      {children}
    </StripeElementsLoader>
  ) : null;
};

export default StripeExpressCheckoutElementWrapper;
