import AllyIcon from '../../../../../icons/payment/ally';
import AmexIcon from '../../../../../icons/payment/amex';
import BankOfAmericaIcon from '../../../../../icons/payment/bank_of_america';
import BankOfTheWestIcon from '../../../../../icons/payment/bank_of_the_west';
import BBAndTIcon from '../../../../../icons/payment/bb&t';
import CapitalOneIcon from '../../../../../icons/payment/capital_one';
import CharlesSchwabIcon from '../../../../../icons/payment/charles_schwab';
import ChaseIcon from '../../../../../icons/payment/chase';
import CITIIcon from '../../../../../icons/payment/citi';
import CitizensBankIcon from '../../../../../icons/payment/citizens_bank';
import DiscoverIcon from '../../../../../icons/payment/discover';
import EasyPayIcon from '../../../../../icons/payment/easypay';
import FidelityIcon from '../../../../../icons/payment/fidelity';
import FifthThirdIcon from '../../../../../icons/payment/fifth_third';
import GenericBankIcon from '../../../../../icons/payment/Generic Bank';
import GenericCardIcon from '../../../../../icons/payment/Generic Card';
import HuntingtonIcon from '../../../../../icons/payment/huntington';
import MastercardIcon from '../../../../../icons/payment/mastercard';
import NavyFederalIcon from '../../../../../icons/payment/navy_federal';
import NoProviderIcon from '../../../../../icons/payment/no_provider';
import PaymentChipsIcon from '../../../../../icons/payment/payment_chips';
import PNCIcon from '../../../../../icons/payment/pnc';
import RegionsIcon from '../../../../../icons/payment/regions';
import SunTrustIcon from '../../../../../icons/payment/suntrust';
import TDAmeritradeIcon from '../../../../../icons/payment/td_ameritrade';
import USAAIcon from '../../../../../icons/payment/usaa';
import USBankIcon from '../../../../../icons/payment/us_bank';
import VisaIcon from '../../../../../icons/payment/visa';
import WellsFargoIcon from '../../../../../icons/payment/wells_fargo';

export function stringToIcon(image: string) {
  switch (image.toLowerCase()) {
    case 'ally':
      return AllyIcon;
    case 'amex':
      return AmexIcon;
    case 'bankofamerica':
      return BankOfAmericaIcon;
    case 'bankofthewest':
      return BankOfTheWestIcon;
    case 'bbandT':
      return BBAndTIcon;
    case 'capitalone':
      return CapitalOneIcon;
    case 'charlesschwab':
      return CharlesSchwabIcon;
    case 'chase':
      return ChaseIcon;
    case 'citibank':
      return CITIIcon;
    case 'citizenbank':
      return CitizensBankIcon;
    case 'dis':
    case 'discover':
      return DiscoverIcon;
    case 'easypay':
      return EasyPayIcon;
    case 'fidelity':
      return FidelityIcon;
    case 'fifththirdbank':
      return FifthThirdIcon;
    case 'genericbank':
      return GenericBankIcon;
    case 'huntingtonnationalbank':
      return HuntingtonIcon;
    case 'mc':
    case 'mastercard':
      return MastercardIcon;
    case 'navyfederal':
      return NavyFederalIcon;
    case 'noprovider':
      return NoProviderIcon;
    case 'paymentchips':
      return PaymentChipsIcon;
    case 'pnc':
      return PNCIcon;
    case 'regions':
      return RegionsIcon;
    case 'suntrust':
      return SunTrustIcon;
    case 'tdameritrade':
      return TDAmeritradeIcon;
    case 'usbank':
      return USBankIcon;
    case 'usaa':
      return USAAIcon;
    case 'visa':
      return VisaIcon;
    case 'wellsfargo':
      return WellsFargoIcon;
    case 'genericcard':
    default:
      return GenericCardIcon;
  }
}

export function getCardSvg(image: string, altImage: string) {
  return (
    stringToIcon(image) ||
    stringToIcon(altImage) ||
    stringToIcon('genericcard')
  );
}
