import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { SuccessCallbackArgs } from '../../types';

export const getData = (
  checkoutSessionResponse: CheckoutSessionsResponse,
): SuccessCallbackArgs['data'] => {
  const { checkoutSession } = checkoutSessionResponse;
  const { agent } = checkoutSession.checkoutRequest;
  const {
    id: sessionId,
    checkoutSessionStatus: status,
    payment,
    paymentMethod,
  } = checkoutSession;

  return {
    sessionId,
    status,
    payment,
    paymentMethod,
    agent,
  };
};
