type PollProps<T> = {
  /**
   * Any errors thrown inside `fn` will be caught and converted to a rejected promise.
   */
  fn: () => Promise<T>;
  until: (fnResult: T) => boolean;
  isSessionOutcomePolling?: boolean;
  maxAttempts?: number;
  interval?: number;
};

export default async <T>({
  fn,
  until,
  isSessionOutcomePolling = false,
  maxAttempts = 0,
  interval = 1000,
}: PollProps<T>): Promise<T> => {
  let attempts = 0;
  let sessionOutcomeIntervalVal: number | undefined;
  let pollingTimeoutId: number | undefined;
  let executePollResolve: (value: T) => void;
  let executePollReject: (reason: any) => void;

  const executePoll = (
    resolve: (value: T) => void,
    reject: (reason: any) => void,
  ) => {
    pollingTimeoutId = undefined; // set undefined to eliminate double polling with slow internet
    executePollResolve = resolve;
    executePollReject = reject;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, consistent-return
    (async () => {
      try {
        const result: T = await fn();
        attempts += 1;

        if (until(result)) {
          resolve(result);
        } else if (maxAttempts && attempts === maxAttempts) {
          reject(new Error('Exceeded max attempts'));
        } else {
          pollingTimeoutId = setTimeout(
            executePoll,
            isSessionOutcomePolling && sessionOutcomeIntervalVal
              ? sessionOutcomeIntervalVal
              : interval,
            resolve,
            reject,
          );
        }
      } catch (error) {
        reject(error);
      }
    })();
    if (isSessionOutcomePolling) {
      document.addEventListener(
        'ccg_pollingIntervalChange',
        (event: Event) => {
          const customEvent = event as CustomEvent;
          sessionOutcomeIntervalVal = customEvent.detail;
          if (pollingTimeoutId) {
            // to eliminate double polling when switching pages
            clearInterval(pollingTimeoutId);
            pollingTimeoutId = undefined;
            executePoll(executePollResolve, executePollReject);
          }
        },
      );
    }
  };

  return new Promise(executePoll);
};
