import getStripeInstance from '../../components/stripe/utils/getStripeInstance';

const authorizePayment = async ({
  vendorPlatformKey,
  vendorMerchantId,
  vendorPaymentSecret,
}: {
  vendorPlatformKey: string;
  vendorMerchantId: string;
  vendorPaymentSecret?: string;
}) => {
  if (!vendorPaymentSecret || !vendorMerchantId) {
    throw new Error(
      'Failed to start authorization due to missing vendorPaymentSecret/vendorMerchantId',
    );
  }

  /**
   * Instantiate stripe instance bound to connect account as (3DS) authorization for payments are bound to connect account
   */
  const stripe = await getStripeInstance({
    vendorPlatformKey,
    vendorMerchantId,
  });

  if (stripe) {
    /**
     * This launches Stripe's authorization screen, when attempting to make 3DS card payment
     * - Note: Card authorization was introduced starting v2.9.0
     */
    return stripe.handleNextAction({
      clientSecret: vendorPaymentSecret,
    });
  }

  throw new Error('Unable to create stripe instance');
};

export default authorizePayment;
