import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import type { PaymentMethodType } from '../../../services/commonCheckout/types/PaymentMethod';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import { TEXT_CONTENT_IDS } from '../../../utils/element-ids';
import PaymentMethodTabSelector from '../../../components/PaymentMethodTabSelector/PaymentMethodTabSelector';
import BackButton from '../../../ui/BackButton/BackButton';
import noop from '../../../utils/function/noop';

type UnifiedPaymentFormHeader = {
  title: string;
  subTitle: string;
  availablePaymentMethodTypes: PaymentMethodType[];
  backButtonLabel?: string;
  onTabSelectionChange: (
    selectedPaymentMethodType: PaymentMethodType,
  ) => void;
  onBackClick?: () => void;
  renderExpressCheckout?: () => React.ReactNode;
};

const UnifiedPaymentFormHeader = ({
  title,
  subTitle,
  availablePaymentMethodTypes,
  backButtonLabel = 'Back',
  onBackClick,
  onTabSelectionChange,
  renderExpressCheckout = noop,
}: UnifiedPaymentFormHeader) => {
  const moreThanOnePaymentMethodTypeAvailable =
    availablePaymentMethodTypes?.length > 1;

  return (
    <Grid
      container
      direction="column"
      sx={{ flexWrap: 'nowrap' }}
    >
      {onBackClick && backButtonLabel ? (
        <BackButton
          label={backButtonLabel}
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_BACK_BUTTON
          }
        />
      ) : null}
      <PageTitle
        title={title}
        titleId={
          TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_TITLE
        }
      />

      {renderExpressCheckout()}

      {moreThanOnePaymentMethodTypeAvailable ? (
        <>
          <PaymentMethodTabSelector
            availablePaymentMethodTypes={
              availablePaymentMethodTypes
            }
            onTabSelectionChange={onTabSelectionChange}
          />
          <Box sx={{ marginBottom: '24px' }} />
        </>
      ) : null}

      <Typography
        variant="h6"
        component="h3"
        data-testid={
          TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_SUB_TITLE
        }
      >
        {subTitle}
      </Typography>
    </Grid>
  );
};

export default UnifiedPaymentFormHeader;

