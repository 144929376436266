import type { HttpErrorResponse } from '../../services/commonCheckout/types/HttpErrorResponse';

/**
 * If you catch an error, and want to check if it's an `HttpErrorResponse`,
 * you can do:
 * @example
 * } catch (e: unknown) {
 *   if (
 *     / Convert `unknown` to well-typed object,
 *     / pass to respective callbacks:
 *     /   I believe we should apply this pattern across all new code,
 *     /   because throwing and catching causes typescript types to be lost.
 *     axios.isAxiosError(e) &&
 *     e.response?.data &&
 *     isHttpErrorResponse(e.response.data)
 *   ) {
 *     handleHttpErrorResponse(e);
 *   }
 * }
 */
export const isHttpErrorResponse = (
  caughtError: unknown,
): caughtError is HttpErrorResponse => {
  return !!(
    caughtError &&
    typeof caughtError === 'object' &&
    'title' in caughtError &&
    'detail' in caughtError &&
    'status' in caughtError &&
    typeof caughtError.status === 'number'
  );
};

// TS test:
// const handleHttpErrorResponse = (
//   // eslint-disable-next-line @typescript-eslint/consistent-type-imports
//   _: HttpErrorResponse,
// ) => undefined;
// export const test = (e: unknown) => {
//   if (isHttpErrorResponse(e)) {
//     handleHttpErrorResponse(e);
//   }
// };
