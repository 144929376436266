import React from 'react';

import noop from '../../utils/function/noop';

import type { CCGThemeProviderContextType } from './CCGThemeProviderContextType';

export const CCGThemeProviderContext =
  /**
   * NOTE:
   * data from `/checkout-sessions` is set in `AppContext`
   * while `appearance` configuration, is set in `CCGThemeProviderContext`,
   * to separate application logic from UI/Styling state &
   * `appearance` object can probably grow over time and have its own logic
   */
  React.createContext<CCGThemeProviderContextType>({
    setAppearance: noop,
    appearance: undefined,
  });
