import type {
  PrimarySecondaryRouteConfigMapKey,
  PrimarySecondaryValueType,
} from '../../../../../shared/types/routing/PrimarySecondaryRouteConfig';
import primarySecondaryRouteConfigMap from '../constants/primarySecondaryRouteConfigMap';

const getPrimaryRouteComponent = (
  primaryRoutePath: PrimarySecondaryRouteConfigMapKey,
): PrimarySecondaryValueType => {
  return primarySecondaryRouteConfigMap[primaryRoutePath]
    ?.primary;
};

export default getPrimaryRouteComponent;
