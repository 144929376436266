import type { FC, Ref } from 'react';
import React, { useContext } from 'react';
import { Grid, Box, FormControl } from '@mui/material';
import {
  CustomAlert as ErrorNotification,
  useMobile,
} from '@skyline/react-core';

import type { PaymentMethodType } from '../../../../services/commonCheckout/types/PaymentMethod';
import {
  ELEMENT_IDS,
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../../utils/element-ids';
import { AppContext } from '../../../contextStore/AppContext';
import CallToActionCard from '../../../../components/CallToActionCard/CallToActionCard';
import PaymentMethodDetails from '../../../../components/PaymentMethodButton/PaymentMethodDetails';
import PageTitle from '../../../../ui/PageTitle/PageTitle';
import useFormConfigManager from '../../../../hooks/useFormConfigManager/useFormConfigManager';
import isSecondaryTypeDrawer from '../../../../utils/container-config/isSecondaryTypeDrawer';
import useEnvironmentContextProvider from '../../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import FormLabelVisuallyHidden from '../../../../ui/FormLabelVisuallyHidden/FormLabelVisuallyHidden';

import type { FormattedPaymentMethod } from './types';
import StyledFormControlLabel from './styled-components/StyledFormControlLabel';
import StyledRadioRoot from './styled-components/StyledRadioRoot';
import StyledRadioGroup from './styled-components/StyledRadioGroup';

export type WalletProps = {
  header?: string;
  paymentRequired?: string;
  formattedPaymentMethods: FormattedPaymentMethod<PaymentMethodType>[];
  showPaymentRequiredError?: boolean;
  errorSummaryRef?: Ref<HTMLDivElement>;
  onSelectPayment?: (
    paymentMethod: FormattedPaymentMethod<PaymentMethodType>,
  ) => void;
  onManageWalletClick: () => void;
};

export const Wallet: FC<WalletProps> = (props) => {
  const {
    header = 'Your wallet',
    paymentRequired = 'A Payment is Required',
    formattedPaymentMethods,
    showPaymentRequiredError = false,
    errorSummaryRef,
    onSelectPayment,
    onManageWalletClick,
  } = props;
  const { selectedPaymentMethodId } = useContext(AppContext);
  const isMobile = useMobile('sm');
  const { containerConfig } = useEnvironmentContextProvider();

  function findDefaultPaymentMethodId() {
    return formattedPaymentMethods.find(
      (formattedPaymentMethod) =>
        formattedPaymentMethod.isDefault,
    )?.id;
  }

  function getSelectedId() {
    // TODO: need to clean up logic of this func
    if (!Array.isArray(formattedPaymentMethods)) {
      return '';
    }

    if (selectedPaymentMethodId) {
      return selectedPaymentMethodId;
    } else if (formattedPaymentMethods.length === 1) {
      return formattedPaymentMethods[0].id;
    }

    return findDefaultPaymentMethodId();
  }

  const { title } = useFormConfigManager({
    title: header,
    titleTestId: TITLE_IDS.MAIN_PAGE_TITLE,
    processFooterConfig: false,
    shouldOverrideFormConfigTitleAndActionsOnForm:
      isSecondaryTypeDrawer(containerConfig),
  });

  return (
    <Grid
      container
      direction="column"
    >
      <Grid
        container
        item
        direction="column"
      >
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Grid item>
            <PageTitle
              titleId={TITLE_IDS.MAIN_PAGE_TITLE}
              title={title}
              subTitle={
                formattedPaymentMethods &&
                formattedPaymentMethods.length !== 0
                  ? 'Select payment method'
                  : ''
              }
              subTitleId={TEXT_CONTENT_IDS.WALLET_FORM_TEXT}
            />
          </Grid>
        </Grid>
        {showPaymentRequiredError && (
          <Grid item>
            <Box pb={2}>
              <ErrorNotification
                title={paymentRequired}
                ref={errorSummaryRef}
                severity="error"
              />
            </Box>
          </Grid>
        )}
        <FormControl
          component="fieldset"
          aria-labelledby={TEXT_CONTENT_IDS.WALLET_FORM_TEXT}
        >
          {formattedPaymentMethods &&
            formattedPaymentMethods.length !== 0 && (
              <FormLabelVisuallyHidden component="legend">
                Select payment method
              </FormLabelVisuallyHidden>
            )}
          <StyledRadioGroup
            name="payment-method"
            color="secondary"
            value={getSelectedId() ?? null}
            onChange={(event) => {
              const matchingPayment =
                formattedPaymentMethods.find(
                  (formattedPaymentMethod) =>
                    formattedPaymentMethod.id ===
                    event.currentTarget.value,
                );
              if (onSelectPayment && matchingPayment) {
                onSelectPayment(matchingPayment);
              }
            }}
          >
            {formattedPaymentMethods.map(
              (formattedPaymentMethod, index) => {
                const paymentInputId = `_ccg_paymentInputId_${formattedPaymentMethod.last4}_${index}`;

                return (
                  <StyledFormControlLabel
                    data-testid={`paymentMethod-${index + 1}`}
                    htmlFor={paymentInputId}
                    value={
                      formattedPaymentMethod.paymentMethodId
                    }
                    aria-label={`${formattedPaymentMethod.ariaDescription} options`}
                    control={
                      <StyledRadioRoot
                        inputProps={{
                          // @ts-expect-error need to pass down data-testid to inputProps
                          'data-testid': paymentInputId,
                        }}
                        id={formattedPaymentMethod.id}
                      />
                    }
                    key={formattedPaymentMethod.paymentMethodId}
                    label={
                      <PaymentMethodDetails
                        paymentMethod={formattedPaymentMethod}
                        showCaIcon={false}
                      />
                    }
                  />
                );
              },
            )}
          </StyledRadioGroup>
        </FormControl>
      </Grid>
      <Grid
        item
        style={{ padding: '24px 0 0' }}
      >
        <CallToActionCard
          title="Manage wallet"
          buttonLabel="Manage"
          description="Add, edit, or remove a payment method."
          titleId={TEXT_CONTENT_IDS.CTA_MANAGE_WALLET_TITLE}
          descriptionId={
            TEXT_CONTENT_IDS.CTA_MANAGE_WALLET_DESCRIPTION
          }
          buttonId={ELEMENT_IDS.CTA_MANAGE_WALLET_BUTTON}
          onClick={onManageWalletClick}
        />
      </Grid>
    </Grid>
  );
};
