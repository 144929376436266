import { useContext } from 'react';

import { AppContext } from '../../contextStore/AppContext';
import { getConfigMode } from '../../../utils/session/selectors';
import useCcgNavigate from '../../../hooks/useCcgNavigate/useCcgNavigate';
import useEnvironmentContextProvider from '../../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import isPrimarySecondaryContainer from '../../../utils/container-config/isPrimarySecondaryContainer';

const useBackButtonForWalletManagement = () => {
  const { ccgNavigate } = useCcgNavigate();
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { containerConfig } = useEnvironmentContextProvider();

  let backButtonHandler;
  let showBackButton = false;

  if (
    (containerConfig &&
      isPrimarySecondaryContainer(containerConfig)) ||
    getConfigMode(originalCheckoutSessionResponse) === 'WALLET'
  ) {
    showBackButton = false;
  } else {
    showBackButton = true;
  }

  if (
    getConfigMode(originalCheckoutSessionResponse) ===
    'PAYMENT_WITH_WALLET'
  ) {
    backButtonHandler = () => {
      ccgNavigate({ routes: ['PAYMENT_WITH_WALLET'] });
    };
  }

  if (
    getConfigMode(originalCheckoutSessionResponse) ===
    'PAYMENT_METHOD_SELECT'
  ) {
    backButtonHandler = () => {
      ccgNavigate({ routes: ['PAYMENT_METHOD_SELECT'] });
    };
  }

  return {
    backButtonHandler,
    showBackButton,
  };
};

export default useBackButtonForWalletManagement;
