const IS_NUMBER_REGEX = /\d/;
const SHOULD_PAD_NUMBER_REGEX = /[2-9]/;
const VALID_SECOND_MONTH_DIGIT_AFTER_1_REGEX = /[0-2]$/;
const VALID_SECOND_MONTH_DIGIT_AFTER_0_REGEX = /[1-9]$/;

const expirationInputFormatter = (
  expirationInput?: string,
): string => {
  if (!expirationInput) {
    return '';
  }

  let result = '';

  const cleanedUpInput = expirationInput.replace(/[^\d]/g, '');
  const firstInputCharacter = cleanedUpInput.slice(0, 1);
  const lastInputCharacter = cleanedUpInput.slice(-1);
  const restOfInput = cleanedUpInput.slice(0, -1);

  if (IS_NUMBER_REGEX.test(lastInputCharacter)) {
    if (
      cleanedUpInput.length === 1 &&
      SHOULD_PAD_NUMBER_REGEX.test(cleanedUpInput)
    ) {
      result = `0${cleanedUpInput} / `;
    } else if (cleanedUpInput.length === 1) {
      result = cleanedUpInput;
    } else if (
      cleanedUpInput.length === 2 &&
      firstInputCharacter === '1' &&
      !VALID_SECOND_MONTH_DIGIT_AFTER_1_REGEX.test(
        lastInputCharacter,
      )
    ) {
      result = restOfInput;
    } else if (
      cleanedUpInput.length === 2 &&
      firstInputCharacter === '0' &&
      !VALID_SECOND_MONTH_DIGIT_AFTER_0_REGEX.test(
        lastInputCharacter,
      )
    ) {
      result = restOfInput;
    } else if (
      cleanedUpInput.length === 2 &&
      expirationInput.includes('/')
    ) {
      result = cleanedUpInput;
    } else if (cleanedUpInput.length === 2) {
      result = `${cleanedUpInput} / `;
    } else {
      result = `${cleanedUpInput.slice(
        0,
        2,
      )} / ${cleanedUpInput.slice(2, 4)}`;
    }
  } else {
    result = restOfInput;
  }

  return result.slice(0, 7);
};

export default expirationInputFormatter;
