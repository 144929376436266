import { isConsentTextAvailableAndValid } from '../../../../../utils/capabilities/isConsentTextAvailableAndValid';
import type { FormattedPaymentMethod } from '../types';

import type {
  PaymentMethodError,
  ValidateBankAccountPaymentMethodProp,
} from './validatePaymentMethod';

const validateBankAccountPaymentMethod = ({
  formattedPaymentMethod,
  options,
}: ValidateBankAccountPaymentMethodProp & {
  formattedPaymentMethod: FormattedPaymentMethod<'BANK_ACCOUNT'>;
}): undefined | PaymentMethodError => {
  if (formattedPaymentMethod.isInvalid) {
    return {
      errorTitle:
        'Payment method is no longer valid. Please try a different payment method.',
    };
  }

  if (!isConsentTextAvailableAndValid(options?.consentText)) {
    return {
      errorTitle:
        'Consent is not available. Please try another payment method type.',
    };
  }

  return undefined;
};

export default validateBankAccountPaymentMethod;
