import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { PaymentMethodChannelType } from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import {
  getChildSessionId,
  getChildSessionStatus,
  getPaymentMethodId,
  isChildSessionValid,
} from '../../../utils/session/selectors';
import getStatusMessage from '../../add-payment-method/utils/getStatusMessage';
import { AppContext } from '../../contextStore/AppContext';
import generateCountdownString from '../../../utils/date/generateCountdownString';
import getAwaitingUserResponseTitle from '../../../utils/capabilities/getAwaitingUserResponseTitle';
import noop from '../../../utils/function/noop';
import type { UserJourney } from '../../../shared/types/UserJourney';
import useSecondaryContainerCloseContext from '../../contextStore/SecondaryContainerCloseContext/useSecondaryContainerCloseContext';
import SessionApi from '../../../services/session/SessionApi';

import AwaitingUserResponse from './AwaitingUserResponse';

type AwaitingUserResponseContainerProps = {
  onCancel: () => void;
  onDone: ({
    childSessionId,
    paymentMethodId,
  }: {
    childSessionId: string;
    paymentMethodId: string;
  }) => void;
  onError: (errorType: 'cancel' | 'error') => void;
  setIsChildStatusCanceled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  destination: string;
  paymentMethodChannel: PaymentMethodChannelType;
  userJourney: UserJourney;
  onLoadComplete?: () => void;
  childSessionHistory?: string[];
};

const AwaitingUserResponseContainer = ({
  onCancel,
  onDone,
  destination,
  onError,
  setIsChildStatusCanceled,
  paymentMethodChannel = 'TEXT',
  userJourney,
  onLoadComplete = noop,
  childSessionHistory = [],
}: AwaitingUserResponseContainerProps) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);

  const { registerBeforeCloseTask, clearBeforeCloseTask } =
    useSecondaryContainerCloseContext();

  const paymentMethodId = getPaymentMethodId(
    originalCheckoutSessionResponse,
  );

  const childSessionStatus = getChildSessionStatus(
    originalCheckoutSessionResponse,
  );

  const isChildSessionInvalid = !isChildSessionValid(
    originalCheckoutSessionResponse,
  );

  const [haveFiredOnDone, setHaveFiredOnDone] =
    useState<boolean>(false);
  const [haveFiredOnError, setHaveFiredOnError] =
    useState<boolean>(false);
  const [expiresAtMessage, setExpiresAtMessage] =
    useState<string>();

  const errorType = ['CANCELED', 'EXPIRED'].includes(
    getChildSessionStatus(originalCheckoutSessionResponse),
  )
    ? 'cancel'
    : 'error';

  const expiresAt =
    originalCheckoutSessionResponse.checkoutSession.childSession
      ?.expiresAt;
  const description = getAwaitingUserResponseTitle(userJourney);
  const childSessionId = getChildSessionId(
    originalCheckoutSessionResponse,
  );

  useEffect(() => {
    if (
      childSessionId &&
      childSessionStatus === 'COMPLETED' &&
      paymentMethodId &&
      !childSessionHistory.includes(childSessionId)
    ) {
      if (!haveFiredOnDone && !haveFiredOnError) {
        onDone({ childSessionId, paymentMethodId });
        setHaveFiredOnDone(true);
      }
      return;
    }
    if (
      isChildSessionInvalid &&
      !haveFiredOnError &&
      !haveFiredOnDone
    ) {
      setIsChildStatusCanceled(
        originalCheckoutSessionResponse.checkoutSession
          .childSession?.status === 'CANCELED',
      );
      onError(errorType);
      setHaveFiredOnError(true);
    }

    // on component unmount - we should not call `onCancel` -
    // Agents will (predictably) reload the page to ensure they aren't stuck on the loading screen.
  }, [
    // onDone:
    paymentMethodId,
    onDone,
    haveFiredOnDone,
    setHaveFiredOnDone,
    childSessionStatus,

    // onError:
    isChildSessionInvalid,
    haveFiredOnError,
    onError,
    errorType,
    setHaveFiredOnError,
  ]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (expiresAt) {
      // set countdown message for initial load (expiresAt should not change)
      setExpiresAtMessage(generateCountdownString(expiresAt));

      interval = setInterval(() => {
        /**
         * we need to call `generateCountdownString(expiresAt)` inside the interval
         * so that we're always checking against "now" timestamp.
         */
        setExpiresAtMessage(generateCountdownString(expiresAt));
      }, 15000); // run every 15 seconds
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [expiresAt]);

  const cancelSession = useCallback(() => {
    if (childSessionId) {
      SessionApi.cancelSessionUsingBeacon(childSessionId).catch(
        () => {
          // ignore error
        },
      );
    }
  }, [childSessionId]);

  useEffect(() => {
    registerBeforeCloseTask(() => {
      cancelSession();
    });

    return () => {
      clearBeforeCloseTask();
    };
  }, [cancelSession]);

  useEffect(() => {
    onLoadComplete();
  }, []);

  const statusMessage = getStatusMessage(
    paymentMethodChannel,
    childSessionStatus,
  );

  return (
    <AwaitingUserResponse
      status={statusMessage}
      destination={destination}
      expiresAtMessage={expiresAtMessage}
      onCancel={onCancel}
      paymentMethodChannel={paymentMethodChannel}
      description={description}
    />
  );
};

export default AwaitingUserResponseContainer;
