import type { PropsWithoutRef } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import { OptumDefault } from '@skyline/themes';
import { Typography } from '@mui/material';

import type { ButtonProps } from '../../ui/Button/Button';
import Button from '../../ui/Button/Button';
import ContentSpacer from '../../ui/ContentSpacer/ContentSpacer';

const StyledCallToActionCard = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  min-width: 88px;
  min-height: 74px;
  padding: 16px;
  border: 1px solid #b1b2b4;
  ${({ theme = OptumDefault }) => `
    border-radius: 8px;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.subtitle2.fontSize};
    font-weight: 400;
    line-height: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    
    & > div.titleDescriptions {
      display: flex;
      flex-direction: column;
`}
`;

export type CallToActionCardProps = PropsWithoutRef<{
  title: string;
  titleId: string;
  description?: string;
  descriptionId?: string;
  buttonLabel: string;
  buttonId: string;
  testId?: string
  onClick: ButtonProps['onClick'];
}>;

const CallToActionCard = ({
  title,
  description,
  buttonLabel,
  titleId,
  descriptionId,
  buttonId,
  onClick,
  testId
}: CallToActionCardProps) => {
  return (
    <StyledCallToActionCard>
      <div className="titleDescriptions">
        <Typography
          id={titleId}
          data-testid={titleId}
          // Adding style here because the fontWeight prop is overridden by the theme
          // on lower breakpoints (540px and below)
          style={{ fontWeight: 700 }}
        >
          {title}
        </Typography>

        <ContentSpacer bottom={4} />

        <Typography
          id={descriptionId}
          data-testid={descriptionId}
        >
          {description}
        </Typography>
      </div>

      <ContentSpacer right={24} />

      <div className="buttonPlaceholder">
        <Button
          id={buttonId}
          variant="outlined"
          color="info"
          onClick={onClick}
          data-testid={testId}
        >
          {buttonLabel}
        </Button>
      </div>
    </StyledCallToActionCard>
  );
};

export default CallToActionCard;
