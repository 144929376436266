import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const FidelityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 14</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#408800"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="fidelity-vector-logo"
              transform="translate(5.400000, 8.400000)"
              fill="#FFFFFF"
            >
              <polygon
                id="Path"
                points="1.985 5.59836735 0.005 5.59836735 1.595 0.0489795918 5.655 0.0489795918 5.27 1.36653061 3.195 1.36653061 2.93 2.31183673 5 2.31183673 4.655 3.52163265 2.58 3.52163265"
              />
              <path
                d="M6.575,5.59836735 L4.615,5.59836735 L5.775,1.54285714 L7.735,1.54285714 L6.575,5.59836735 Z M7.84,1.15591837 L5.88,1.15591837 L6.2,0.0489795918 L8.16,0.0489795918 L7.84,1.15591837 Z"
                id="Shape"
              />
              <path
                d="M11.655,5.59836735 L9.735,5.59836735 L9.87,5.14285714 C9.485,5.46122449 9.09,5.69142857 8.46,5.69142857 C7.82,5.69142857 7.42,5.39265306 7.42,4.76081633 C7.42,4.06040816 7.7,3.02204082 8.12,2.2922449 C8.425,1.74857143 8.97,1.4644898 9.615,1.4644898 C10.17,1.4644898 10.58,1.7044898 10.74,1.99836735 L11.285,0.0440816327 L13.245,0.0440816327 L11.655,5.59836735 Z M10.185,2.7477551 C10.01,2.7477551 9.835,2.80163265 9.735,3.03673469 C9.59,3.37959184 9.46,3.71265306 9.46,4.05061224 C9.46,4.20734694 9.575,4.33959184 9.765,4.33959184 C9.915,4.33959184 10.015,4.2955102 10.1,4.24163265 L10.495,2.90938776 C10.425,2.81632653 10.305,2.7477551 10.185,2.7477551 L10.185,2.7477551 Z"
                id="Shape"
              />
              <path
                d="M17.19,3.83020408 L14.485,3.83020408 C14.43,4.04571429 14.38,4.19265306 14.38,4.36408163 C14.38,4.44734694 14.395,4.65306122 14.67,4.65306122 C14.945,4.65306122 15.065,4.55020408 15.18,4.15346939 L17.1,4.15346939 C16.855,5.3044898 15.46,5.70122449 14.6,5.70122449 C13.44,5.70122449 12.535,5.53959184 12.535,4.44244898 C12.535,3.84 12.845,2.84081633 13.255,2.31673469 C13.75,1.68489796 14.605,1.44489796 15.395,1.44489796 C16.61,1.44489796 17.4,1.68979592 17.4,2.70367347 C17.4,2.94857143 17.295,3.4922449 17.19,3.83020408 M15.23,2.46857143 C14.985,2.46857143 14.82,2.63020408 14.715,3.04163265 L15.52,3.04163265 C15.545,2.95836735 15.575,2.83102041 15.575,2.75755102 C15.57,2.5322449 15.435,2.46857143 15.23,2.46857143"
                id="Shape"
              />
              <polygon
                id="Path"
                points="19.16 5.59836735 17.2 5.59836735 18.785 0.0489795918 20.745 0.0489795918"
              />
              <path
                d="M21.745,5.59836735 L19.785,5.59836735 L20.945,1.54285714 L22.905,1.54285714 L21.745,5.59836735 Z M23.015,1.15591837 L21.055,1.15591837 L21.375,0.0489795918 L23.335,0.0489795918 L23.015,1.15591837 Z"
                id="Shape"
              />
              <path
                d="M26.535,1.54285714 L26.705,2.45877551 L25.205,2.45877551 L24.765,3.99673469 C24.725,4.11428571 24.695,4.26612245 24.695,4.35918367 C24.695,4.5355102 24.77,4.59918367 25.035,4.59918367 L25.455,4.59918367 L25.165,5.59836735 L23.42,5.59836735 C22.845,5.59836735 22.625,5.32408163 22.625,4.9077551 C22.625,4.73632653 22.65,4.55020408 22.71,4.3444898 L23.775,0.646530612 L25.735,0.646530612 L25.475,1.5477551 L26.535,1.5477551 L26.535,1.54285714 Z"
                id="Path"
              />
              <path
                d="M29.735,4.78530612 C29.4041589,4.78530612 29.135,5.054465 29.135,5.38530612 C29.135,5.71614724 29.4041589,5.98530612 29.735,5.98530612 C30.0658411,5.98530612 30.335,5.71614724 30.335,5.38530612 C30.335,5.04885752 30.0658411,4.78530612 29.735,4.78530612 M29.735,5.87315659 C29.4658411,5.87315659 29.2415421,5.654465 29.2415421,5.37969865 C29.2415421,5.10493229 29.4602336,4.8862407 29.735,4.8862407 C30.0041589,4.8862407 30.2284579,5.10493229 30.2284579,5.37969865 C30.2284579,5.654465 30.0097664,5.87315659 29.735,5.87315659"
                id="Shape"
              />
              <path
                d="M30.6557317,5.39510204 C30.6557317,5.16601113 30.480122,5.03510204 30.085,5.03510204 L29.485,5.03510204 L29.485,6.23510204 L29.7630488,6.23510204 L29.7630488,5.74419295 L29.982561,5.74419295 L30.3776829,6.23510204 L30.685,6.23510204 L30.2606098,5.73328386 C30.4947561,5.70055659 30.6557317,5.60237477 30.6557317,5.39510204 L30.6557317,5.39510204 Z M29.7630488,5.5587384 L29.7630488,5.22055659 L29.982561,5.22055659 C30.1581707,5.22055659 30.3630488,5.23146568 30.3630488,5.38419295 C30.3630488,5.54782931 30.1581707,5.5587384 29.982561,5.5587384 L29.7630488,5.5587384 Z"
                id="Shape"
              />
              <path
                d="M29.18,1.54285714 L28.115,3.79102041 L28.145,1.54285714 L26.165,1.54285714 L26.545,5.59836735 C26.495,5.83346939 26.415,5.97061224 26.265,6.03428571 C26.1,6.1077551 25.725,6.09306122 25.59,6.08816327 L25.565,6.08816327 L25.25,7.19020408 L26.59,7.19020408 C27.38,7.19020408 27.765,6.80326531 28.33,5.93632653 L31.185,1.53795918 L29.18,1.53795918 L29.18,1.54285714 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default FidelityIcon;
