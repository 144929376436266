import { httpClientService } from '../settingsApi/httpClientService';

import { commonCheckoutClientWrapper } from './commonCheckoutClientWrapper';
import type { CheckoutSessionsResponse } from './types/CheckoutSessionsResponse';

type SimpleSessionStatus = 'PENDING' | 'COMPLETED' | 'FAILED';

export async function getCheckoutSessionStatusFromCommonCheckoutApi({
  updateStatusUrl,
}: {
  updateStatusUrl: string;
}): Promise<{
  status: SimpleSessionStatus;
  response: CheckoutSessionsResponse;
}> {
  const httpClient = httpClientService.getHttpClient({
    api: 'WALLET',
  });

  const statusResponse =
    await commonCheckoutClientWrapper<CheckoutSessionsResponse>({
      httpClient,
      request: {
        url: updateStatusUrl || '',
      },
    });
  if (!statusResponse.data) {
    throw new Error('statusResponse.data is missing');
  }
  const status =
    statusResponse.data.checkoutSession?.checkoutSessionStatus;
  const currentStatus =
    status === 'COMPLETED' || status === 'FAILED'
      ? status
      : 'PENDING';
  return {
    status: currentStatus,
    response: statusResponse.data,
  };
}
