import { IconButton, styled } from '@mui/material';

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  color: theme.palette.grey[800],
}));

export default StyledCloseButton;
