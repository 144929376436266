import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { getConfiguredChannelsForAgent } from '../../../session/getConfiguredChannelsForAgent';

const hasMultipleChannels = (
  session: CheckoutSessionsResponse,
) => {
  const channels = getConfiguredChannelsForAgent(session);

  return channels && channels.length > 1;
};

export default hasMultipleChannels;
