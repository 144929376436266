import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type {
  DeprecatedErrorPayloadObject,
  ErrorCallbackArgs,
  ErrorCode,
} from '../types';

export type GenerateErrorObjectArgs = {
  checkoutSessionResponse?: CheckoutSessionsResponse;
  title?: ErrorCode;
  detail?: string;
  status?: number;
  errorDetails?: string;
};

export const DEFAULT_ERROR_TITLE = 'ERROR';

const generateErrorObject = ({
  checkoutSessionResponse,
  title = DEFAULT_ERROR_TITLE,
  detail = '',
  status,
  errorDetails,
}: GenerateErrorObjectArgs): ErrorCallbackArgs => {
  // TODO: Revisit logic around what's being sent as callback to merchants
  // `CheckoutSessionResponse.checkoutSession` object continues to grow
  // and it doesn't make sense to send as-is to the customer
  // When need to specific in what's being sent
  let payload: undefined | DeprecatedErrorPayloadObject;

  if (checkoutSessionResponse) {
    const { checkoutSession } = checkoutSessionResponse;
    const { paymentId } = checkoutSession;
    const { merchantTransactionId, amount } =
      checkoutSession.checkoutRequest;
    payload = {
      checkoutSession: {
        checkoutRequest: {
          merchantTransactionId,
          amount,
        },
        paymentId,
      },
    };
  }

  return {
    title,
    detail,
    status,
    errorDetails,
    code: title,
    description: detail,
    payload,
  };
};

export default generateErrorObject;
