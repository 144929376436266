import { useState } from 'react';

import type { NotificationContextType } from './NotificationContextType';

export const useNotificationContext = () => {
  const [notificationContext, setNotificationContext] =
    useState<NotificationContextType>({
      title: '',
      isShown: false,
      closeable: true,
      notify: ({
        severity,
        title,
        message = '',
        icon,
        inlineMessage = false,
        closeable = true,
        onClose,
      }) => {
        setNotificationContext((state) => {
          return {
            ...state,
            severity,
            title,
            message,
            icon,
            inlineMessage,
            closeable,
            onClose,
            isShown: true,
          };
        });
      },
      closeNotification: () => {
        setNotificationContext((state) => {
          return {
            ...state,
            isShown: false,
          };
        });
      },
    });

  return notificationContext;
};
