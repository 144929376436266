import React from 'react';
import type { PropsWithoutRef } from 'react';
import {
  Button as BaseButton,
  type ButtonProps,
  buttonClasses,
} from '@mui/base/Button';
import { styled } from '@mui/material/styles';
import { OptumDefault } from '@skyline/themes';

import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';

import PaymentMethodDetails from './PaymentMethodDetails';

export type PaymentMethodButtonProps = PropsWithoutRef<
  ButtonProps & {
    paymentMethod: FormattedPaymentMethod<PaymentMethodType>;
    onClick?: ({
      selectedPaymentMethod,
    }: {
      selectedPaymentMethod: FormattedPaymentMethod<PaymentMethodType>;
    }) => void;
  }
>;

const StyledPaymentMethodButton = styled(BaseButton)(
  ({ theme = OptumDefault }) => `
  width: 100%;
  min-height: 74px;
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px solid #CBCCCD;
  gap: 12px;
  font-family: ${theme.typography.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  box-sizing: border-box;
  background-color: #ffffff;
  &:hover, hover:not(.${buttonClasses.disabled}) {
    background-color: ${theme.palette.grey[100]};
    cursor: pointer;
  }
  &.ccg-${buttonClasses.active} {
    background-color: #83C8FF1A;
    outline: 1px solid #005FCC;
  }
  &.ccg-${buttonClasses.focusVisible} {
    background-color: ${theme.palette.grey[100]};
    outline: 1px solid #005FCC;
  }
  &.ccg-${buttonClasses.disabled},  
  &.ccg-${buttonClasses.disabled}:hover,  
  &:disabled,  
  &:disabled:hover {
    background-color: ${theme.palette.grey[200]};
    color: ${theme.palette.grey[500]};
    border: 0;
    cursor: default;
    box-shadow: none;
    transform: scale(1);
    &:hover {
      background-color: ${theme.palette.grey[200]};
      cursor: default;
      color: ${theme.palette.grey[500]};
    }
  }
`,
);

const PaymentMethodRootButton = ({
  paymentMethod,
  onClick,
  ...props
}: PaymentMethodButtonProps) => {
  return (
    <StyledPaymentMethodButton
      {...props}
      type="button"
      id={`paymentMethodTile-${paymentMethod.id}`}
      data-testid={`paymentMethodTile-${paymentMethod.id}`}
      focusableWhenDisabled
      onClick={() =>
        onClick?.({ selectedPaymentMethod: paymentMethod })
      }
    >
      <PaymentMethodDetails paymentMethod={paymentMethod} />
    </StyledPaymentMethodButton>
  );
};

const PaymentMethodButton = PaymentMethodRootButton;
export default PaymentMethodButton;
