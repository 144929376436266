import React, { useContext } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';

import OverlayLoader from './OverlayLoader';

const ConditionalOverlayLoader = () => {
  const { overlayLoaderConfig } = useContext(AppContext);

  return overlayLoaderConfig.show ? (
    <OverlayLoader
      description={overlayLoaderConfig.description}
    />
  ) : null;
};

export default ConditionalOverlayLoader;
