import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';

import { removeHcpToken } from './removeHcpToken';

/**
 * Compare two checkoutSession responses:
 * while ignoring `hcpToken`, since it changes every single response.
 *
 * Note: we don't care about new `hcpToken`s, we only need the first one.
 */
export function doCheckoutSessionsMatch(
  one: CheckoutSessionsResponse,
  two: CheckoutSessionsResponse,
) {
  return (
    JSON.stringify(removeHcpToken(one)) !==
    JSON.stringify(removeHcpToken(two))
  );
}
