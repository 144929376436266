import type { ValueOf } from 'type-fest';

import type { SessionOutcomeConfig } from './types/SessionOutcomeConfig';

/**
 * Success/error ui is determined by this priority:
 *  1. BE: It makes final determination if a session is a success or failure
 *      - If it provides us a ui config, we always show that first.
 *  2. Fallback based on current config.mode
 */
export const getFinalUiConfig = <
  TDeadEndUiConfig extends ValueOf<SessionOutcomeConfig>,
>({
  explicitServerConfig,
  modeBasedFallbackConfig,
}: {
  explicitServerConfig: TDeadEndUiConfig | undefined;
  modeBasedFallbackConfig: TDeadEndUiConfig | undefined;
}): TDeadEndUiConfig => {
  return {
    ...modeBasedFallbackConfig,
    ...explicitServerConfig,
  } as TDeadEndUiConfig;
};
