import { useState, useEffect } from 'react';

import type { AppContextType } from './AppContextType';
import { initialState } from './initialState';

/** THIS SHOULD ONLY BE CALLED IN THE ROOT OF THE APPLICATION */
// Most application code should call `useContext(AppContext)` to read/write context data
export const useAppContext = () => {
  const [appContext, setAppContext] = useState<AppContextType>({
    ...initialState,

    setData: ({
      vendorPlatformKey,
      checkoutSessionId,
      merchantId,
      customerId,
      hcpToken,
      ...newData
    }) => {
      // If we are stuck showing the OverlayLoader, this is important information for debugging.
      // if (newData.isChildWaitingOnGlobalSession === true) {
      //   console.debug(
      //     new Error(
      //       [
      //         '`isChildWaitingOnGlobalSession` set to `true`.',
      //         'OverlayLoader will be shown until `/checkout-sessions/{checkoutSessionId}`',
      //         'returns `checkoutSessionStatus: COMPLETED` or a 4xx/5xx response.',
      //       ].join('\n'),
      //     ),
      //   );
      // }
      setAppContext((state) => {
        return {
          ...state,
          vendorPlatformKey:
            vendorPlatformKey || state.vendorPlatformKey,
          checkoutSessionId:
            checkoutSessionId || state.checkoutSessionId,
          merchantId: merchantId || state.merchantId,
          customerId: customerId || state.customerId,
          hcpToken: hcpToken || state.hcpToken,
          ...newData,
        };
      });
    },

    setPaymentMethods: (paymentMethods) => {
      setAppContext((state) => {
        return { ...state, paymentMethods };
      });
    },
    setAppMetadata: ({
      vendorPlatformKey,
      checkoutSessionId,
      merchantId,
      customerId,
      hcpToken,
    }) => {
      setAppContext((state) => {
        return {
          ...state,
          vendorPlatformKey:
            vendorPlatformKey || state.vendorPlatformKey,
          checkoutSessionId:
            checkoutSessionId || state.checkoutSessionId,
          merchantId: merchantId || state.merchantId,
          customerId: customerId || state.customerId,
          hcpToken: hcpToken || state.hcpToken,
        };
      });
    },
    setIsAuthorized: (isAuthorized) => {
      setAppContext((state) => {
        return { ...state, isAuthorized };
      });
    },
    setSelectedPaymentMethodId: (selectedPaymentMethodId) => {
      setAppContext((state) => {
        return { ...state, selectedPaymentMethodId };
      });
    },
    setSessionOutcomePollingInterval: (intervalTime) => {
      setAppContext((state) => ({
        ...state,
        sessionOutcomePollingInterval: intervalTime,
      }));
    },
    setInitializeSessionError: (error) => {
      setAppContext((state) => ({
        ...state,
        initializeSessionError: error,
      }));
    },
    setInitializeSessionException: (exception) => {
      setAppContext((state) => ({
        ...state,
        initializeSessionException: exception,
      }));
    },
    setContainerConfig: (value) => {
      setAppContext((state) => ({
        ...state,
        containerConfig: value,
      }));
    },
  });

  useEffect(() => {
    const event = new CustomEvent('ccg_pollingIntervalChange', {
      detail: appContext.sessionOutcomePollingInterval,
    });
    document.dispatchEvent(event);
  }, [appContext.sessionOutcomePollingInterval]);

  return appContext;
};
