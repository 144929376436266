import type { Theme } from '@mui/material';

const getSharedInputStyles = (theme: Theme) =>
  ({
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    padding: '10px 12px',
    border: 0,
    outline: '1px solid #929496',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    fontSize: '16px',
    backgroundColor: '#ffffff',
    color: theme.palette.grey['900'],
    lineHeight: '20px',

    '&.is-invalid': {
      outline: `2px solid ${theme.palette.error.main}`,
    },

    '&:focus': {
      outline: '2px solid #316BBE',
    },
    '&::placeholder': {
      color: theme.palette.grey['600'],
      fontFamily: theme.typography.fontFamily,
    },

    '&:disabled': {
      backgroundColor: '#F2F2F2',
    },
  } as const);

export default getSharedInputStyles;
