import type { SVGProps } from 'react';
import React from 'react';

const WarningNotificationIcon = (
  props: SVGProps<SVGSVGElement> & { fill?: string },
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 21.5H23L12 2.5L1 21.5ZM13 18.5H11V16.5H13V18.5ZM13 14.5H11V10.5H13V14.5Z"
        fill={props.fill || '#C24E14'}
      />
    </svg>
  );
};
export default WarningNotificationIcon;
