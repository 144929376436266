import type { PaymentMethodSessionOutcome } from '../../../services/commonCheckout/types/SessionOutcome/PaymentMethodSessionOutcome';
import type { MessageCallbackArgs, MessageCode } from '../types';

export type GenerateEventObjectArgs = {
  title: MessageCode;
  paymentMethod?: PaymentMethodSessionOutcome | null;
};

const generateEventObject = ({
  title,
  paymentMethod,
}: GenerateEventObjectArgs): MessageCallbackArgs => {
  const result: MessageCallbackArgs = {
    title,
    code: title,
  };

  if (paymentMethod !== undefined) {
    result.data = {
      paymentMethod,
    };
  }
  return result;
};

export default generateEventObject;
