import React from 'react';
import type { FC } from 'react';
import type { Theme } from '@mui/material';
import {
  ThemeProvider as NewV5ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ThemeProvider as OldV4ThemeProvider } from '@mui/styles';

import { CCGThemeProviderContext } from '../../capabilities/contextStore/CCGThemeProviderContext';
import { useCCGThemeProviderContext } from '../../capabilities/contextStore/useCCGThemeProviderContext';
import type { getThemeArgs } from '../external-theme/types';
import { FontLoader } from '../FontLoader';
import defaultFont from '../default-fonts';
import getTheme from '..';

import StyledScopedCssBaselineContainer from './styled-components/StyledScopedCssBaselineContainer';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type CCGThemeProviderProps = getThemeArgs & {
  fullWidthView?: boolean;
};
export const CCGThemeProvider: FC<CCGThemeProviderProps> = ({
  appearance,
  theme,
  children,
  fullWidthView = false,
}) => {
  let appearanceConfig = appearance;
  if (
    !appearance?.font ||
    !Object.keys(appearance.font).length
  ) {
    appearanceConfig = {
      ...appearanceConfig,
      font: defaultFont,
    };
  }

  return (
    <CCGThemeProviderContext.Provider
      value={useCCGThemeProviderContext({
        appearance: appearanceConfig,
      })}
    >
      <CCGThemeProviderContext.Consumer>
        {(context) => {
          return (
            <StyledEngineProvider injectFirst>
              <NewV5ThemeProvider
                theme={getTheme({
                  theme,
                  appearance: context.appearance,
                })}
              >
                <OldV4ThemeProvider
                  theme={getTheme({
                    theme,
                    appearance: context.appearance,
                  })}
                >
                  <FontLoader
                    fontSources={
                      context.appearance?.font?.fontSources
                    }
                  >
                    <StyledScopedCssBaselineContainer
                      fullWidthView={fullWidthView}
                    >
                      {children}
                    </StyledScopedCssBaselineContainer>
                  </FontLoader>
                </OldV4ThemeProvider>
              </NewV5ThemeProvider>
            </StyledEngineProvider>
          );
        }}
      </CCGThemeProviderContext.Consumer>
    </CCGThemeProviderContext.Provider>
  );
};
