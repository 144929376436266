// TODO: Convert return type to `string | undefined` and manually inspect all callers
const getCookie = (key: string): string => {
  if (document.cookie.includes(key)) {
    return (
      document.cookie
        .split(`; ${key}=`)
        .pop()
        ?.split(';')[0]
        .replace(`${key}=`, '') || ''
    );
  }
  return '';
};

export default getCookie;
