export const ALL_FIELDS_REQUIRED =
  'All fields are required unless marked optional.';
export const ADD_BANK_ACCOUNT = 'Add a bank account';
export const ADD_BANK_ACCOUNT_ON_SCREEN_ENTRY =
  'Add a bank account via on-screen entry';
export const BANK_ACCOUNT_NICKNAME_HELPER_TEXT =
  'Bank account nickname must be 30 characters or less and can contain letters and numbers only.';
export const ACCOUNT_NUMBER_HELPER_TEXT =
  'Account numbers can be up to 17-digits';
export const ROUTING_NUMBER_HELPER_TEXT =
  'Routing numbers are 9-digits';
export const SAVE_BUTTON = 'Save';
export const CANCEL_BUTTON = 'Cancel';
export const NAME_ON_ACCOUNT = 'Name on account';
export const ROUTING_NUMBER = 'Routing number';
export const ACCOUNT_NUMBER = 'Account number';
export const BANK_ACCOUNT_NICKNAME = 'Bank account nickname';
export const CHECKING = 'Checking';
export const SAVINGS = 'Savings';
export const PAYMENT_METHOD_SETTINGS = 'Payment method settings';
export const SET_AS_DEFAULT_PAYMENT_METHOD =
  'Set as default payment method';
export const GENERIC_STRIPE_ERROR_MESSAGE =
  'Payment method could not be authorized. Please try a different payment method.';
export const PAY_AND_STORE_ACH = 'Pay with bank account';
export const PAY_AND_STORE_ACH_ON_SCREEN_ENTRY =
  'Pay with bank account via on-screen entry';
export const PAY_AND_STORE_CARD = 'Pay with card';
export const PAY_AND_STORE_CARD_ON_SCREEN_ENTRY =
  'Pay with card via on-screen entry';
export const PAYMENT_METHOD_TYPE_TITLE_ADD_PAYMENT_METHOD =
  'Add a payment method';
export const PAYMENT_METHOD_TYPE_SUBTITLE_ADD_PAYMENT_METHOD =
  'Please select the type of payment method you would like to add.';
export const PAYMENT_METHOD_TYPE_TITLE_PAYMENT =
  'Make a payment';
export const PAYMENT_METHOD_TYPE_SUBTITLE_PAYMENT =
  'Please select the type of payment method you would like to use.';
export const PAYMENT_METHOD_SELECTOR_CONFIRM_BUTTON_LABEL =
  'Confirm payment method';
export const SELECT_PAYMENT_METHOD_ERROR =
  'Please select a payment method.';
