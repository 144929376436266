import type { UserJourney } from '../../shared/types/UserJourney';

const getPaymentMethodChannelSelectorTitle = (
  userJourney: UserJourney,
): string => {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    return 'Add a payment method';
  }

  return 'Make a payment';
};

export default getPaymentMethodChannelSelectorTitle;
