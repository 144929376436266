import { createContext } from 'react';

import type StorageWrapper from '../../../utils/storage/StorageWrapper';

export interface ServiceContextProviderProps {
  sessionStorageService: StorageWrapper;
}

const ServiceContext = createContext<
  ServiceContextProviderProps | undefined
>(undefined);

export default ServiceContext;
