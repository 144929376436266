import { useContext } from 'react';

import { NotificationContext } from '../NotificationContext';

const useNotificationContextProvider = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationContext must be used within an NotificationContext.Provider',
    );
  }

  return context;
};

export default useNotificationContextProvider;
