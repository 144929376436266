import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import PaymentMethodButton from '../PaymentMethodButton/PaymentMethodButton';
import { CONTAINER_IDS } from '../../utils/element-ids';

export type PaymentMethodListProps = {
  paymentMethodsList: FormattedPaymentMethod<PaymentMethodType>[];
  onPaymentMethodClick: (args: {
    selectedPaymentMethod: FormattedPaymentMethod<PaymentMethodType>;
  }) => void;
};

const PaymentMethodList: FC<PaymentMethodListProps> = (
  props,
) => {
  const { paymentMethodsList, onPaymentMethodClick } = props;

  return (
    <Box
      data-testid={
        CONTAINER_IDS.PAYMENT_METHOD_LIST_WRAPPER_ELEMENT
      }
      style={{ display: 'grid', rowGap: '16px' }}
    >
      {paymentMethodsList.map((paymentMethod) => (
        <PaymentMethodButton
          key={paymentMethod.paymentMethodId}
          paymentMethod={paymentMethod}
          onClick={() => {
            onPaymentMethodClick({
              selectedPaymentMethod: paymentMethod,
            });
          }}
        />
      ))}
    </Box>
  );
};

export default PaymentMethodList;
