import type CancelSessionHandlerArgs from '../types/CancelSessionHandlerArgs';
import SessionApi from '../../../services/session/SessionApi';

export const cancelSessionHelper = ({
  sessionId,
  updateUiCallback,
  onCancelEnvironmentCallback,
  shouldCancelSession = true,
}: CancelSessionHandlerArgs) => {
  if (!shouldCancelSession) {
    return;
  }
  /**
   * 1. cancel the session via web service call
   * 2. update UI to show the session no longer valid screen
   * 3. call environment (hosted, embedded) specific callback
   */

  if (sessionId) {
    try {
      SessionApi.cancelSessionUsingBeacon(sessionId).catch(
        () => {
          reportError(new Error('Cancel session call failed'));
        },
      );
    } finally {
      // We just want to fire the cancel session call
    }
  }

  updateUiCallback?.();

  /**
   * Hosted: The callback will attempt to close the window
   * Embedded: We trigger the logic for closing the widget
   */
  onCancelEnvironmentCallback?.();
};
