import type { HttpServiceError } from '../../api/apis';
import type { AppContextData } from '../../capabilities/contextStore/AppContextType';
import type { CloseableNotificationType } from '../../ui/NotificationAlert/NotificationAlert';
import { serviceErrorNotification } from '../../ui/NotificationAlert/serviceErrorNotification';
import connectionChecker from '../../utils/connection/connection-checker/ConnectionChecker';
import { INTERNET_CONNECTIVITY_MESSAGES } from '../../utils/constants';

let flag = false;

// TODO: explore better ways to conditionally call interceptor for each request
const requiresErrorNotification = (error: HttpServiceError) => {
  // Suppress error notification in PAYMENT_WITH_WALLET mode for maximum retries attempts with 'add payment method' flow
  return error.message !== 'Maximum number of retries reached.';
};

const onErrorHandler =
  ({
    notify,
    setData,
  }: {
    notify: (args: CloseableNotificationType) => void;
    setData: (newData: Partial<AppContextData>) => void;
  }) =>
  (error: HttpServiceError) => {
    const hasConnectionProblems =
      error.message ===
      INTERNET_CONNECTIVITY_MESSAGES.NO_CONNECTION;

    if (hasConnectionProblems) {
      if (!flag) {
        connectionChecker.checkConnection({
          connectionRestoredCallback: () => {
            notify({
              severity: 'success',
              title:
                INTERNET_CONNECTIVITY_MESSAGES.CONNECTION_RESTORED,
            });

            flag = false;
          },
        });

        // handle internet connectivity problems
        notify({
          severity: 'warning',
          title: error.message,
        });
        flag = true;
      }
      setData({ overlayLoaderConfig: { show: false } });
    } else if (requiresErrorNotification(error)) {
      notify(serviceErrorNotification(error));
      setData({ overlayLoaderConfig: { show: false } });
    }
  };

export default onErrorHandler;
