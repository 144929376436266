import type { ApiError } from '../../../capabilities/checkout-v2/types';
import type { AppContextType } from '../../../capabilities/contextStore/AppContextType';

const handlePaymentMethodError = ({
  callback,
  setData,
  error,
}: {
  setData: AppContextType['setData'];
  callback: () => void;
  error?: ApiError;
}) => {
  if (error?.title === 'RETRIES_EXHAUSTED') {
    // Warning details should ideally be dictated by API.
    // But for PAYMENT_WITH_WALLET mode, involving 'add payment method' flow
    // with retry attempt exhausted use-case, UI is limited to deal with error object;
    // So, fields are being manually mapped to warning object
    callback();
    setData({
      addPaymentMethodWarning: {
        code: error.title,
        title: error.detail,
        description: 'Please try a different payment method.',
      },
    });
  }
};

export default handlePaymentMethodError;
