import type { Appearance } from './external-theme/appearance/types';

const defaultFont: Appearance['font'] = {
  fontFamily: '"Optum Sans",Arial,sans-serif, Tahoma',
  fontSources: [
    {
      family: 'Optum Sans',
      src: "url('https://walletprod.healthsafepay.com/wallet/assets/fonts/OptumSans-Regular.woff2')",
      style: 'normal',
      weight: '400',
    },
    {
      family: 'Optum Sans',
      src: "url('https://walletprod.healthsafepay.com/wallet/assets/fonts/OptumSans-Bold.woff2')",
      style: 'normal',
      weight: '700',
    },
    {
      family: 'Optum Sans',
      src: "url('https://walletprod.healthsafepay.com/wallet/assets/fonts/OptumSans-RegularItalic.woff2')",
      style: 'italic',
      weight: '400',
    },
    {
      family: 'Optum Sans',
      src: "url('https://walletprod.healthsafepay.com/wallet/assets/fonts/OptumSans-BoldItalic.woff2')",
      style: 'italic',
      weight: '700',
    },
  ],
};

export default defaultFont;
