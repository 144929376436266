import type { MergeDeep } from 'type-fest';

import type { Appearance } from '../../../style/external-theme/appearance/types';

import type { ChildSessionStatus } from './ChildSessionStatus';
import type { HttpErrorResponse } from './HttpErrorResponse';
import type { PaymentSessionOutcome } from './SessionOutcome/PaymentSessionOutcome';
import type { PaymentDetails } from './PaymentDetails';
import type { PaymentType } from './PaymentType';
import type Warning from './Warning';
import type { Consent } from './Consent';
import type { Features } from './Features';
import type { SessionConfig } from './SessionConfig/SessionConfig';
import type { PaymentMethodSessionOutcome } from './SessionOutcome/PaymentMethodSessionOutcome';

export const CheckoutSessionStatuses = [
  'CREATED',
  'CANCELLED',
  'INITIATED',
  'PENDING',
  'COMPLETED',
  'FAILED',
  'UN_INITIATED',
] as const;

export type CheckoutSessionStatus =
  (typeof CheckoutSessionStatuses)[number];

export type SessionNotificationChannels = 'TEXT' | 'EMAIL';

type SessionNotification = {
  id: string;
  destination: string[];
  channel: SessionNotificationChannels;
};

export type ChildSession = {
  id: string;
  createdAt: string;
  expiresAt: string;
  status: ChildSessionStatus;
  notification?: SessionNotification;
};

/**
 * A `CheckoutSessionsResponse` with a specific type of `checkoutSessionStatus`.
 */
export type DeterminedSessionResponse<
  TStatus extends CheckoutSessionStatus,
> = MergeDeep<
  CheckoutSessionsResponse,
  {
    checkoutSession: {
      checkoutSessionStatus: TStatus;
    };
  }
>;

/**
 * A valid `/checkout-sessions` response whose
 * `checkoutSessionStatus` is not `'COMPLETED'`.
 */
export type IncompleteSessionResponse =
  DeterminedSessionResponse<
    Exclude<CheckoutSessionStatus, 'COMPLETED'>
  >;
export type Agent = {
  firstName: string | null;
  lastName: string | null;
  isAccessVerified: boolean | null;
  userId: string;
};

type selectedPaymentMethod = {
  id: string;
  default: boolean;
};

export type CheckoutSessionsResponse = {
  checkoutSession: {
    id: string;
    checkoutSessionStatus: CheckoutSessionStatus;
    /** @deprecated merchantId will be deprecated soon */
    merchantId: string;
    merchant: {
      id: string;
      name: string;
    };
    customerId: string;
    vendorMerchantId: string;
    checkoutRequest: {
      authorizeCard: boolean;
      partialAuthorization?: boolean;
      amount: number;
      authorizedAmount: number;
      capturedAmount: number;
      /** @deprecated cashier will be deprecated soon */
      cashier: string | null;
      agent: Agent | null;
      assistedByAgent: Agent | null;
      merchantTransactionId: string;
      paymentType?: PaymentType;
      customer?: any;
      paymentDetails?: PaymentDetails;
      statementDescriptorSuffix?: string;
      paymentDescription?: string;
      phoneNumber?: string;
      email?: string;
      metadata: {
        orderId?: string;
        merchantTransactionId?: string;
        invoiceNumber?: string;
        mrn?: string;
        locationId?: string;
        amount?: string;
        [x: string]: string | undefined;
      };
      config?: SessionConfig;
      consent?: Consent;
      paymentMethod?: selectedPaymentMethod;
    };
    childSessionId?: string;
    childSession?: ChildSession;
    paymentMethodId?: string;
    paymentMethod?: PaymentMethodSessionOutcome;
    payment?: PaymentSessionOutcome;
    /**
     * error property used only for 1.x and not for 2.x and above
     */
    error?: HttpErrorResponse;
    appearance?: Appearance;
    createdAtUtc: string;
    expiresAtUtc: string;
    warning?: Warning;
    paymentId?: string;
    features: Features | null;
  };
  hcpToken?: string;
};
