import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { WidgetUsageStatsEvent } from './types';

const EVENT_WIDGET_USAGE_STATS = 'WIDGET_USAGE_STATS';

export const logWidgetUsageStatsEvents = ({
  appVersion,
  merchantId,
  distributionType,
}: WidgetUsageStatsEvent) => {
  appInsights.trackEvent({
    name: EVENT_WIDGET_USAGE_STATS,
    properties: {
      [telemetryKeyPrefix('appVersion')]: appVersion,
      [telemetryKeyPrefix('merchantId')]: merchantId,
      [telemetryKeyPrefix('distributionType')]: distributionType,
    },
  });
};
