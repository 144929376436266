import formLabelMap from '../utils/formLabelMap';
import {
  getFullYearFromFormExpirationField,
  getMonthFromFormExpirationField,
} from '../utils/selectors';
import type {
  EditPaymentMethodCardFormData,
  EditPaymentMethodFormErrors,
} from '../types';
import { isInvalidNickname } from '../../../utils/function/nicknameUtils';
import {
  NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
} from '../../pay-and-store/constants';
import fieldHasCardNumber from '../../../utils/function/fieldHasCardNumber';

const expirationDateYearIsInvalid = (year: number) => {
  const now = new Date();

  return (
    year > now.getFullYear() && year - now.getFullYear() > 50
  );
};

const expirationDateYearIsInThePast = (year: number) => {
  const now = new Date();

  return year < now.getFullYear();
};

const expirationDateIsInThePast = (
  month: number,
  year: number,
) => {
  const now = new Date();

  return (
    year === now.getFullYear() && month < now.getMonth() + 1
  );
};

const validate = ({
  nameOnCard,
  expiration,
  zipCode,
  nickname,
}: EditPaymentMethodCardFormData): EditPaymentMethodFormErrors => {
  const formErrors: EditPaymentMethodFormErrors = {};
  if (!nameOnCard) {
    formErrors.nameOnCard = `${formLabelMap.nameOnCard} is required.`;
  }

  if (fieldHasCardNumber(nameOnCard)) {
    formErrors.nameOnCard =
      NAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  if (!expiration) {
    formErrors.expiration = `${formLabelMap.expiration} is required.`;
  } else if (expiration.replace(/[^\d]/g, '').length !== 4) {
    formErrors.expiration = `${formLabelMap.expiration} date is incomplete.`;
  } else if (
    expirationDateYearIsInvalid(
      getFullYearFromFormExpirationField(expiration),
    )
  ) {
    formErrors.expiration = `${formLabelMap.expiration} year is invalid.`;
  } else if (
    expirationDateYearIsInThePast(
      getFullYearFromFormExpirationField(expiration),
    )
  ) {
    formErrors.expiration = `${formLabelMap.expiration} year is in the past.`;
  } else if (
    expirationDateIsInThePast(
      getMonthFromFormExpirationField(expiration),
      getFullYearFromFormExpirationField(expiration),
    )
  ) {
    formErrors.expiration = `${formLabelMap.expiration} date is in the past.`;
  }

  if (!zipCode) {
    formErrors.zipCode = `${formLabelMap.zipCode} is required.`;
  } else if (zipCode.length < 5) {
    formErrors.zipCode = `${formLabelMap.zipCode} is incomplete.`;
  }

  if (nickname && isInvalidNickname(nickname)) {
    formErrors.nickname = 'Please enter a valid card nickname.';
  }
  if (nickname && fieldHasCardNumber(nickname)) {
    formErrors.nickname =
      NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  return formErrors;
};

export default validate;
