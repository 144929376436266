import { DEFAULT_PAYMENT_DESCRIPTION } from '../../utils/constants';
import { httpClientService } from '../settingsApi/httpClientService';

import { commonCheckoutClientWrapper } from './commonCheckoutClientWrapper';
import type { CheckoutSessionsResponse } from './types/CheckoutSessionsResponse';

export async function getCheckoutSessionSettingsFromCommonCheckoutApi({
  checkoutSessionId,
  fetchConfig,
}: {
  checkoutSessionId: string;
  fetchConfig?: boolean;
}) {
  const updateStatusUrl = `/checkout-sessions/${checkoutSessionId}`;

  const httpClient = httpClientService.getHttpClient({
    api: 'WALLET',
  });

  const statusResponse =
    await commonCheckoutClientWrapper<CheckoutSessionsResponse>({
      httpClient,
      request: {
        url: updateStatusUrl || '',
        params: { init: fetchConfig },
        method: 'GET',
      },
    });

  const { headers } = statusResponse;

  /**
   * TODO: Couldn't figure out typing where the CheckoutSessionsResponse is attached to
   * statusResponse.data and statusResponse.data.data
   */
  // @ts-expect-error - see reason above
  const data = (statusResponse.data?.data ||
    statusResponse.data) as CheckoutSessionsResponse;

  const merchantId = data?.checkoutSession.merchantId;
  const customerId = data?.checkoutSession.customerId;
  const vendorMerchantId =
    data?.checkoutSession.vendorMerchantId;
  const amount = data?.checkoutSession.checkoutRequest.amount;
  const merchantTransactionId =
    data?.checkoutSession.checkoutRequest.merchantTransactionId;
  const metadata =
    data?.checkoutSession.checkoutRequest.metadata;
  const authorizeCard =
    data?.checkoutSession.checkoutRequest.authorizeCard;
  const hcpToken = data?.hcpToken as string;
  const statementDescriptorSuffix =
    data?.checkoutSession.checkoutRequest
      .statementDescriptorSuffix ?? '';
  const paymentDescription =
    data?.checkoutSession.checkoutRequest.paymentDescription ||
    DEFAULT_PAYMENT_DESCRIPTION;
  const uiModes =
    data?.checkoutSession.checkoutRequest.config?.modes || [];
  const appearance = data?.checkoutSession?.appearance;

  const vendorPlatformKey =
    headers?.['x-vendor-stripe-public-key'] || '';
  return {
    vendorPlatformKey,
    merchantId,
    amount,
    merchantTransactionId,
    metadata,
    customerId,
    vendorMerchantId,
    hcpToken,
    authorizeCard,
    statementDescriptorSuffix,
    paymentDescription,
    uiModes,
    appearance,
    originalCheckoutSessionResponse: data,
  };
}
