import React from 'react';
import type { FC } from 'react';

import StyledErrorTypography from './styled-components/StyledErrorTypography';

export type InputErrorProps = {
  id: string;
  errorMessage?: string;
};

const InputError: FC<InputErrorProps> = ({
  id,
  errorMessage,
}) => {
  return (
    <StyledErrorTypography
      id={id}
      data-testid={id}
      display="inline"
      role="alert"
    >
      {errorMessage}
    </StyledErrorTypography>
  );
};

export default InputError;
