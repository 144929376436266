import type { SecondaryCcgMode } from '../../services/commonCheckout/types/SessionConfig/Modes';
import type { UiModes } from '../../services/commonCheckout/types/UiModes';
import { TITLE_IDS } from '../../utils/element-ids';

import type { SessionOutcomeConfig } from './types/SessionOutcomeConfig';

export const mapConfigModeToFallbackErrorScreen: Record<
  Exclude<UiModes, SecondaryCcgMode>,
  SessionOutcomeConfig
> = {
  // When anyone is adding a payment method:
  PAYMENT_METHOD_ENTRY: {
    success: {
      title: 'Success',
      description: 'Payment method added.',
      titleId: TITLE_IDS.SESSION_OUTCOME_SUCCESS_TITLE,
    },
    failure: {
      // https://www.figma.com/file/FCVq6UZa9XADJtnhPXK4rC/CC-Add-payment-method-component-(merchant-portal)?type=design&node-id=564%3A30571&mode=design&t=qzHXMSlTmLyRkTp7-1
      title: 'Payment method could not be added.',
      description: 'Please try a different payment method.',
    },
  },

  PAYMENT_WITH_WALLET: {
    success: {
      title: 'Thank You',
      description: 'Payment submitted successfully.',
      titleId: TITLE_IDS.SESSION_OUTCOME_SUCCESS_TITLE,
    },
    failure: {
      // Checked with phil on teams, same text here:
      // https://www.figma.com/file/hZV7HpbbFP6hTw35UDvbPp/CC-Make-a-Payment-Component?type=design&node-id=77%3A9058&mode=design&t=XAdQ54YOOfKskRuN-1
      // UPDATE: This text is too specific, if a user simply goes offline, they may be alarmed to see any suggestion that a payment was being attempted.
      // title: 'Payment could not be authorized.',
      // description: 'Please try a different payment method.',
    },
  },

  // guest
  PAYMENT: {
    success: {
      title: 'Thank You',
      description: 'Payment submitted successfully.',
      titleId: TITLE_IDS.SESSION_OUTCOME_SUCCESS_TITLE,
    },
    failure: {
      // https://www.figma.com/file/hZV7HpbbFP6hTw35UDvbPp/CC-Make-a-Payment-Component?type=design&node-id=77%3A9058&mode=design&t=XAdQ54YOOfKskRuN-1
      title: 'Payment could not be authorized.',
      description: 'Please try a different payment method.',
    },
  },
  // TODO: cc-6797, check if this is correct
  WALLET: {
    success: {
      title: 'Thank You',
      description: '',
      titleId: TITLE_IDS.SESSION_OUTCOME_SUCCESS_TITLE,
    },
    failure: {
      title: 'Something went wrong.',
      description: 'Please try again.',
    },
  },
  PAYMENT_METHOD_SELECT: {
    success: {
      title: 'Done',
      description: 'You may now close this window.',
      titleId: TITLE_IDS.SESSION_OUTCOME_SUCCESS_TITLE,
    },
    failure: {
      title: 'Something went wrong.',
      description: 'Please try again.',
    },
  },
} as const;
