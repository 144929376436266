import { isInvalidNickname } from '../function/nicknameUtils';
import type AddPaymentMethodAchFormData from '../../capabilities/add-payment-method/types/AddPaymentMethodAchFormData';
import type { PayAndStoreAchFormData } from '../../capabilities/pay-and-store/types';

import { isValidAccountNumber } from './validateAccountNumber';
import { isValidRoutingNumber } from './validateRoutingNumber';
import { validateAchAuthorization } from './validateAchAuthorization';

type GenericAchFormData =
  | AddPaymentMethodAchFormData
  | PayAndStoreAchFormData;

const formLabelMap: Record<
  keyof (AddPaymentMethodAchFormData & PayAndStoreAchFormData),
  string | boolean
> = {
  nameOnAccount: 'Name on account',
  routingNumber: 'Routing number',
  accountNumber: 'Account number',
  default: 'Set as default payment method',
  accountType: '',
  nickname: '',
  achAuthorization: false,
  savePaymentMethod: false,
};

const validateNameOnAccount = ({
  nameOnAccount,
  accountNumber,
}: Pick<
  GenericAchFormData,
  'nameOnAccount' | 'accountNumber'
>) => {
  if (!nameOnAccount) {
    return `${formLabelMap.nameOnAccount} is required.`;
  } else if (
    accountNumber &&
    nameOnAccount.includes(accountNumber)
  ) {
    return `Please make sure that the name field doesn't contain a bank account number.`;
  }
  return null;
};

const validateRoutingNumber = (routingNumber?: string) => {
  if (!routingNumber) {
    return `${formLabelMap.routingNumber} is required.`;
  } else if (routingNumber.length < 9) {
    return `${formLabelMap.routingNumber} is incomplete.`;
  } else if (!isValidRoutingNumber(routingNumber)) {
    return `Enter a valid routing number.`;
  }
  return null;
};

const validateAccountNumber = (accountNumber?: string) => {
  if (!accountNumber) {
    return `${formLabelMap.accountNumber} is required.`;
  } else if (!isValidAccountNumber(accountNumber)) {
    return `Enter a valid account number.`;
  }
  return null;
};

const validateBankAccountNickname = ({
  nickname,
  accountNumber,
}: Pick<GenericAchFormData, 'nickname' | 'accountNumber'>) => {
  if (isInvalidNickname(nickname)) {
    return `Enter a valid bank account nickname.`;
  } else if (
    accountNumber &&
    nickname?.includes(accountNumber)
  ) {
    return `Please make sure that the nickname field doesn't contain a bank account number.`;
  }
  return null;
};

const executeValidator = <TFormData extends GenericAchFormData>(
  key: keyof TFormData,
  value: string | boolean | undefined,
  formData: TFormData,
) => {
  switch (key) {
    case 'nameOnAccount':
      return validateNameOnAccount(formData);
    case 'routingNumber':
      return validateRoutingNumber(value as string);
    case 'accountNumber':
      return validateAccountNumber(value as string);
    case 'nickname':
      return validateBankAccountNickname(formData);
    case 'accountType':
      return null;
    case 'achAuthorization':
      return validateAchAuthorization(value as boolean);
    case 'default':
      return null;
    default:
      return null;
  }
};

const validateAchForm = <TFormData extends GenericAchFormData>(
  formData: TFormData,
): Partial<Record<keyof TFormData, string>> => {
  const formErrors: Partial<Record<keyof TFormData, string>> =
    {};

  const keys = Object.keys(formData) as (keyof TFormData)[];
  keys.forEach((key) => {
    const value = formData[key] as unknown as string | boolean;
    const error = executeValidator(key, value, formData);
    if (error) {
      formErrors[key] = error;
    }
  });

  return formErrors;
};

export default validateAchForm;
