import type { Appearance } from '../../../style/external-theme/appearance/types';

const getButtonHeight = (buttonHeight: number) => {
  const minButtonHeight = 40;
  const maxButtonHeight = 55;
  if (buttonHeight < minButtonHeight) {
    return minButtonHeight;
  }
  if (buttonHeight > maxButtonHeight) {
    return maxButtonHeight;
  }
  return buttonHeight;
};

export const getStripeExpressCheckoutElementStyleConfig = (
  appearance?: Appearance,
) => {
  return {
    buttonHeight: getButtonHeight(
      appearance?.height?.button ?? 48,
    ),
    borderRadius:
      appearance?.border?.buttonBorderRadius ?? '8px',
  };
};
