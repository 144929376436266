import type { SvgIconProps } from '@mui/material';
import React from 'react';

const GenericBankIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="37"
      height="24"
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 11H11V18H13V11ZM19 11H17V18H19V11ZM27.5 20H8.5V22H27.5V20ZM25 11H23V18H25V11ZM18 4.26L23.21 7H12.79L18 4.26ZM18 2L8.5 7V9H27.5V7L18 2Z"
        fill="#212223"
      />
    </svg>
  );
};
export default GenericBankIcon;
