import React from 'react';
import { Box } from '@mui/material';
import type { FC } from 'react';

import FieldLabel from '../../FieldLabel/FieldLabel';
import MaskedInputField from '../../InputField/MaskedInputField/MaskedInputField';
import InputError from '../../InputError/InputError';
import type { FieldLabelProps } from '../../FieldLabel/FieldLabel';
import type { MaskedInputFieldProps } from '../../InputField/MaskedInputField/MaskedInputField';
import type { InputErrorProps } from '../../InputError/InputError';
import noop from '../../../utils/function/noop';
import HelperText from '../../HelperText/HelperText';

export type FormFieldProps = MaskedInputFieldProps &
  FieldLabelProps &
  InputErrorProps & { helperText?: string };

const MaskedFormField: FC<FormFieldProps> = ({
  // FieldLabelProps section
  label,
  // End FieldLabelProps section

  // MaskedInputFieldProps section
  mask,
  hintText,
  helperText,
  id: inputId,
  placeholder,
  disabled = false,
  required = false,
  onChange = noop,
  defaultValue,
  unmask,
  lazy,
  // End MaskedInputFieldProps section

  // InputErrorProps section
  errorMessage,
  // End InputErrorProps section
}) => {
  const errorLabelId = `${inputId}-error`;

  return (
    <Box sx={{ marginBottom: '12px' }}>
      <Box sx={{ mb: 1 }}>
        <FieldLabel
          id={inputId}
          label={label}
          hintText={hintText}
        />
      </Box>
      <Box sx={{ mb: '3px' }}>
        <MaskedInputField
          mask={mask}
          unmask={unmask}
          lazy={lazy}
          id={inputId}
          defaultValue={defaultValue}
          errorLabelId={errorLabelId}
          placeholder={placeholder}
          disabled={disabled}
          error={!!errorMessage}
          required={required}
          onChange={onChange}
        />
      </Box>
      {errorMessage ? (
        <Box>
          <InputError
            id={errorLabelId}
            errorMessage={errorMessage}
          />
        </Box>
      ) : null}
      {helperText ? (
        <Box>
          <HelperText
            id={helperText}
            helperText={helperText}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default MaskedFormField;
