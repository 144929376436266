import React, { useContext } from 'react';
import { useParams } from 'react-router';

import { DOCUMENT_TITLE } from '../../../../utils/constants';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { NotificationContext } from '../../../contextStore/NotificationContext';
import usePaymentMethodSelectorActivityTracker from '../../../../shared/hooks/payment-method-selector/usePaymentMethodSelectorActivityTracker';
import useCcgNavigate from '../../../../hooks/useCcgNavigate/useCcgNavigate';

import RemovePaymentMethod from './RemovePaymentMethod';

const RemovePaymentMethodRouteContainer = () => {
  const { closeNotification } = useContext(NotificationContext);
  const { removePaymentMethodIdFromStack } =
    usePaymentMethodSelectorActivityTracker();
  useDocumentTitle(DOCUMENT_TITLE.REMOVE_PAYMENT);

  const { paymentMethodId = '' } = useParams();
  const { ccgNavigate, ccgNavigateBack } = useCcgNavigate();

  const handleBackClick = () => {
    closeNotification();
    ccgNavigateBack();
  };

  const gotoWalletScreen = () => {
    ccgNavigate({
      routes: ['WALLET'],
      state: {
        showRemovePaymentMethodNotificationAfterNav: true,
      },
    });
  };

  const handleOnComplete = () => {
    removePaymentMethodIdFromStack(paymentMethodId);

    gotoWalletScreen();
  };

  return (
    <RemovePaymentMethod
      onCancel={handleBackClick}
      onComplete={handleOnComplete}
      paymentMethodId={paymentMethodId}
      onBackClick={handleBackClick}
    />
  );
};

export default RemovePaymentMethodRouteContainer;
