import type { PaymentMethod } from '../../../../../../services/commonCheckout/types/PaymentMethod';
import {
  getBankAccountLast4,
  getBankName,
  getIsBankAccountInvalid,
  getIsDefault,
  getNickname,
  getPaymentMethodId,
  getRoutingNumber,
} from '../../../../../../services/commonCheckout/utils/paymentMethodSelectors';
import PaymentMethodTypes from '../../../../../../services/commonCheckout/utils/PaymentMethodTypes';
import type { FormattedPaymentMethod } from '../../types';

export function formatBankAccountPaymentMethod(
  bankAccount: PaymentMethod<'BANK_ACCOUNT'>,
): FormattedPaymentMethod<'BANK_ACCOUNT'> {
  const isInvalid = getIsBankAccountInvalid(bankAccount);
  const nickname = getNickname(bankAccount);
  const bankName = getBankName(bankAccount);
  const description = nickname || bankName;
  const isDefault = getIsDefault(bankAccount);

  return {
    type: PaymentMethodTypes.BANK_ACCOUNT,
    id: getPaymentMethodId(bankAccount),
    paymentMethodId: getPaymentMethodId(bankAccount),
    isDefault,
    last4: getBankAccountLast4(bankAccount),
    bankName,
    isInvalid,
    nickname: getNickname(bankAccount),
    routingNumber: getRoutingNumber(bankAccount),
    isAllowedToSetAsDefault: !isDefault && !isInvalid,
    isAllowedToEdit: !isInvalid,
    description,
    ariaDescription: description,
  };
}
