import { doPolling } from './helpers/doPolling';
import { getPollingRequest } from './helpers/getPollingRequest';
import { isPollingResponse } from './helpers/isPollingResponse';

export const commonCheckoutClientWrapper = async <T, I = any>(
  params: RequestWrapperParams<I>,
): Promise<{
  status: number;
  data?: T;
  initial?: I;
  statusUrl?: string;
  headers?: Record<string, string>;
}> => {
  const { httpClient, request, onBuildPollingRequest } = params;

  const response = await httpClient.request<{
    url: string;
    data: any;
  }>(request);
  if (!isPollingResponse(response)) {
    return {
      ...response,
      data: response.data?.data || response.data,
      statusUrl: response.data?.url,
    };
  }

  const pollingRequest = onBuildPollingRequest
    ? onBuildPollingRequest({ initialResponse: response })
    : getPollingRequest(response, request);

  const pollingResult = await doPolling<T>({
    ...params,
    request: pollingRequest,
  });

  return {
    ...pollingResult,
    initial: response.data?.data,
    statusUrl: response.data?.url,
  };
};
