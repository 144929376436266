import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';

import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import type { UserJourney } from '../../shared/types/UserJourney';
import { AppContext } from '../contextStore/AppContext';
import { NotificationContext } from '../contextStore/NotificationContext';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import { getFilteredPaymentTypes } from '../../utils/capabilities/getFilteredPaymentTypes';
import { TEXT_CONTENT_IDS } from '../../utils/element-ids';
import BackButton from '../../ui/BackButton/BackButton';
import useFormConfigManager from '../../hooks/useFormConfigManager/useFormConfigManager';

import UnifiedPaymentFormHeader from './UnifiedPaymentFormHeader/UnifiedPaymentFormHeader';
import headerConfig from './UnifiedPaymentFormHeader/utils/headerConfig';

type UnifiedPaymentFormEntryProps = {
  userJourney: UserJourney;
  onBackClick?: () => void;
  backButtonLabel?: string;
  isAgentAssistedSession: boolean;
  renderExpressCheckout?: () => React.ReactNode;
  children: (
    selectedPaymentMethodType: PaymentMethodType,
  ) => React.ReactNode;
};

const UnifiedPaymentFormEntry: FC<
  UnifiedPaymentFormEntryProps
> = ({
  userJourney,
  backButtonLabel,
  onBackClick,
  isAgentAssistedSession = false,
  renderExpressCheckout,
  children,
}) => {
  const { closeNotification } = useContext(NotificationContext);
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const [isInitializing, setIsInitializing] = useState(true);
  const [
    availablePaymentMethodTypes,
    setAvailablePaymentMethodTypes,
  ] = useState<PaymentMethodType[]>([]);
  const [computedTitle, setComputedTitle] = useState('');
  const [computedSubTitle, setComputedSubTitle] = useState('');

  const [
    selectedPaymentMethodType,
    setSelectedPaymentMethodType,
  ] = useState<PaymentMethodType>();

  const { title, showActionsOnForm } = useFormConfigManager({
    title: computedTitle,
    titleTestId:
      TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_TITLE,
    processFooterConfig: false,
    backActionConfig: {
      testId:
        TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_BACK_BUTTON,
      handler: onBackClick,
    },
  });

  useEffect(() => {
    if (isInitializing || !selectedPaymentMethodType) {
      return;
    }

    const headerConfigForUserJourneyPaymentMethodType =
      headerConfig[userJourney][selectedPaymentMethodType][
        isAgentAssistedSession ? 'agent' : 'customer'
      ];

    setComputedTitle(
      headerConfigForUserJourneyPaymentMethodType.title,
    );
    setComputedSubTitle(
      headerConfigForUserJourneyPaymentMethodType.subTitle,
    );
  }, [availablePaymentMethodTypes, selectedPaymentMethodType]);

  useEffect(() => {
    const filteredPaymentTypes = getFilteredPaymentTypes({
      session: originalCheckoutSessionResponse,
      isForWebFormView: true,
    });

    setAvailablePaymentMethodTypes(filteredPaymentTypes);
    setSelectedPaymentMethodType(filteredPaymentTypes[0]);

    setIsInitializing(false);
  }, []);

  if (isInitializing) {
    return null;
  }

  if (
    !selectedPaymentMethodType ||
    !availablePaymentMethodTypes ||
    availablePaymentMethodTypes.length === 0
  ) {
    console.error('No payment method types available');
    return (
      <>
        {showActionsOnForm && onBackClick && backButtonLabel ? (
          <BackButton
            label={backButtonLabel}
            onClick={onBackClick}
            testId={
              TEXT_CONTENT_IDS.UNIFIED_PAYMENT_METHOD_SELECTOR_BACK_BUTTON
            }
          />
        ) : null}
        <ErrorCard />
      </>
    );
  }

  return (
    <>
      <UnifiedPaymentFormHeader
        title={title}
        subTitle={computedSubTitle}
        availablePaymentMethodTypes={availablePaymentMethodTypes}
        onTabSelectionChange={(newSelectedPaymentMethodType) => {
          closeNotification();

          setSelectedPaymentMethodType(
            newSelectedPaymentMethodType,
          );
        }}
        backButtonLabel={backButtonLabel}
        onBackClick={showActionsOnForm ? onBackClick : undefined}
        renderExpressCheckout={renderExpressCheckout}
      />
      {children(selectedPaymentMethodType)}
    </>
  );
};

export default UnifiedPaymentFormEntry;
