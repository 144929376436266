import React, { useContext } from 'react';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import CustomerApi from '../../services/customer/CustomerApi';
import StripeApi from '../../services/stripe/stripe';
import { DOCUMENT_TITLE } from '../../utils/constants';
import { AppContext } from '../contextStore/AppContext';
import { NotificationContext } from '../contextStore/NotificationContext';
import handlePaymentMethodError from '../../shared/utils/add-payment-method/handlePaymentMethodError';
import type { ApiError } from '../checkout-v2/types';
import usePaymentMethodSelectorActivityTracker from '../../shared/hooks/payment-method-selector/usePaymentMethodSelectorActivityTracker';
import useCcgNavigate from '../../hooks/useCcgNavigate/useCcgNavigate';

import AddPaymentMethodEntry from './AddPaymentMethodEntry';

const AddPaymentMethodRouteContainer = () => {
  const {
    customerId,
    vendorPlatformKey,
    setSelectedPaymentMethodId,
    setData,
  } = useContext(AppContext);
  const { closeNotification } = useContext(NotificationContext);
  const { addPaymentMethodIdToStack } =
    usePaymentMethodSelectorActivityTracker();

  useDocumentTitle(DOCUMENT_TITLE.ADD_PAYMENT);

  const { ccgNavigate } = useCcgNavigate();

  const onSuccess = ({
    paymentMethodId,
    showAddPaymentMethodNotificationAfterNav = true,
  }: {
    paymentMethodId: string;
    showAddPaymentMethodNotificationAfterNav?: boolean;
  }) => {
    addPaymentMethodIdToStack(paymentMethodId);

    setSelectedPaymentMethodId(paymentMethodId);

    ccgNavigate({
      routes: ['WALLET'],
      state: { showAddPaymentMethodNotificationAfterNav },
    });
  };

  const handleBackClick = () => {
    closeNotification();
    ccgNavigate({ routes: ['WALLET'] });
  };

  const onBeforeSubmit = () => {
    closeNotification();
  };

  const handlePaymentMethodErrorLocal = (error: ApiError) => {
    handlePaymentMethodError({
      setData,
      error,
      callback: () => {
        ccgNavigate({ routes: ['WALLET'] });
      },
    });
  };

  return customerId ? (
    <AddPaymentMethodEntry
      customerApi={CustomerApi}
      vendorPlatformKey={vendorPlatformKey}
      stripeApi={StripeApi}
      customerId={customerId}
      onSuccess={onSuccess}
      onBackClick={handleBackClick}
      onBeforeSubmit={onBeforeSubmit}
      onCancel={handleBackClick}
      onError={handlePaymentMethodErrorLocal}
    />
  ) : null;
};

export default AddPaymentMethodRouteContainer;
