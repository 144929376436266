import type {
  RootPathNameValues,
  SecondaryCcgPathNameValues,
} from '../../unified-router/types/PathNameValues';
import type { UiRouteDescriptor } from '../../unified-router/types/UiRouteDescriptor';
import type { ConfigModes } from '../../services/commonCheckout/types/SessionConfig/Modes';
import type { ContainerConfig } from '../../components/container-types/types';

type isValidPaymentMethodDisplayModeProps = {
  containerConfig: ContainerConfig | undefined;
  initialUiRoute:
    | UiRouteDescriptor<
        RootPathNameValues | SecondaryCcgPathNameValues
      >
    | undefined;
  configMode: ConfigModes;
};
export default function isValidPaymentMethodDisplayMode({
  containerConfig,
  initialUiRoute,
  configMode,
}: isValidPaymentMethodDisplayModeProps) {
  return (
    !!containerConfig &&
    containerConfig.type === 'inline' &&
    !!initialUiRoute &&
    initialUiRoute.pathname ===
      '/component-library/payment-method-display' &&
    configMode === 'PAYMENT_METHOD_DISPLAY'
  );
}
