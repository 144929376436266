import type { PaymentMethodChannelType } from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import type { UserJourney } from '../../../shared/types/UserJourney';

/** @description getChildSessionMode will determine whether the type of mode
 *  for the child session is TEXT_TO_PAY, TEXT_TO_ADD, EMAIL_TO_ADD, or
 *  EMAIL_TO_PAY. If a valid combination is not found, an empty string is returned.
 */
export default function getChildSessionMode(
  paymentMethodChannel: PaymentMethodChannelType,
  userJourney: UserJourney,
) {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    if (paymentMethodChannel === 'TEXT') {
      return 'TEXT_TO_ADD';
    } else if (paymentMethodChannel === 'EMAIL') {
      return 'EMAIL_TO_ADD';
    }
  } else if (userJourney === 'MAKE_PAYMENT') {
    if (paymentMethodChannel === 'TEXT') {
      return 'TEXT_TO_PAY';
    } else if (paymentMethodChannel === 'EMAIL') {
      return 'EMAIL_TO_PAY';
    }
  }

  return '';
}
