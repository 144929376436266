import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AppContext } from '../../../capabilities/contextStore/AppContext';
import type PaymentMethodEntryViewStates from '../../types/capabilities/PaymentMethodEntryViewStates';
import shouldHideModal from '../../../utils/capabilities/payment-method-entry/shouldHideModal';
import type PaymentMethodEntryLoaderState from '../../types/capabilities/payment-method-entry/PaymentMethodEntryLoaderState';
import getShowLoaderDefaultValue from '../../../utils/capabilities/payment-method-entry/getShowLoaderDefaultValue';

const usePaymentMethodEntryLoader = (
  viewState: PaymentMethodEntryViewStates,
  isInitializing: boolean,
) => {
  const { originalCheckoutSessionResponse, setData } =
    useContext(AppContext);

  const [state, setState] =
    useState<PaymentMethodEntryLoaderState>({
      showLoader: getShowLoaderDefaultValue(
        viewState,
        isInitializing,
      ),
      isCardFormReady: false,
      isAchFormReady: false,
    });

  const updateLocalState = useCallback(
    (newState: Partial<PaymentMethodEntryLoaderState>) => {
      setState((prevState) => ({
        ...prevState,
        ...newState,
        showLoader: !shouldHideModal(
          viewState,
          originalCheckoutSessionResponse,
          { ...prevState, ...newState },
        ),
      }));
    },
    [viewState],
  );

  const onCardFormReady = () => {
    updateLocalState({
      isCardFormReady: true,
    });
  };

  const onAchFormReady = useCallback(() => {
    setTimeout(() => {
      updateLocalState({
        isAchFormReady: true,
      });
    });
  }, [updateLocalState]);

  const onManagedComponentReady = useCallback(() => {
    setTimeout(() => {
      updateLocalState({
        showLoader: false,
      });
    });
  }, [updateLocalState]);

  const reset = useCallback(() => {
    setState({
      showLoader: getShowLoaderDefaultValue(
        viewState,
        isInitializing,
      ),
      isAchFormReady: false,
      isCardFormReady: false,
    });
  }, [viewState, isInitializing]);

  useEffect(() => {
    setData({ overlayLoaderConfig: { show: state.showLoader } });
  }, [state.showLoader]);

  return {
    reset,
    onCardFormReady,
    onAchFormReady,
    onManagedComponentReady,
    isLoading: state.showLoader,
  };
};

export default usePaymentMethodEntryLoader;
