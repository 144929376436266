import React from 'react';
import { Routes, Route } from 'react-router';

import AddPaymentMethodRouteContainer from '../../../../../capabilities/add-payment-method/AddPaymentMethodRouteContainer';
import RemovePaymentMethodRouteContainer from '../../../../../capabilities/checkout/components/RemovePaymentMethod/remove-payment-method-route-container';
import EditPaymentMethodRouteContainer from '../../../../../capabilities/edit-payment-method/card/EditPaymentMethodRouteContainer';
import { PaymentMethodSelectorRouteContainer } from '../../../../../capabilities/payment-method-selector/PaymentMethodSelectorRouteContainer';
import WalletManagementEntry from '../../../../../capabilities/wallet-management/WalletManagementEntry';
import generatePathForRouter from '../../../../../utils/routing/generatePathForRouter/generatePathForRouter';
import type { PrimarySecondaryRouteConfigMap } from '../../../../../shared/types/routing/PrimarySecondaryRouteConfig';
import PaymentWithWalletRouteContainer from '../../../../../capabilities/checkout/PaymentWithWalletRouteContainer';
import WalletManagementRouteContainer from '../../../../../capabilities/wallet-management/WalletManagementRouteContainer';

const primarySecondaryRouteConfigMap: PrimarySecondaryRouteConfigMap =
  {
    'payment-method-selector': {
      primary: PaymentMethodSelectorRouteContainer,
      secondary: () => (
        <Routes>
          <Route
            path={generatePathForRouter('WALLET')}
            element={<WalletManagementEntry />}
          />
          <Route
            path={generatePathForRouter('EDIT_PAYMENT_METHOD')}
            element={<EditPaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('REMOVE_PAYMENT_METHOD')}
            element={<RemovePaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('ADD_PAYMENT_METHOD')}
            element={<AddPaymentMethodRouteContainer />}
          />
        </Routes>
      ),
    },
    'payment-with-wallet': {
      primary: PaymentWithWalletRouteContainer,
      secondary: () => (
        <Routes>
          <Route
            path={generatePathForRouter('WALLET')}
            element={<WalletManagementEntry />}
          />
          <Route
            path={generatePathForRouter('EDIT_PAYMENT_METHOD')}
            element={<EditPaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('REMOVE_PAYMENT_METHOD')}
            element={<RemovePaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('ADD_PAYMENT_METHOD')}
            element={<AddPaymentMethodRouteContainer />}
          />
        </Routes>
      ),
    },
    'wallet-management': {
      primary: WalletManagementRouteContainer,
      secondary: () => (
        <Routes>
          <Route
            path={generatePathForRouter('EDIT_PAYMENT_METHOD')}
            element={<EditPaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('REMOVE_PAYMENT_METHOD')}
            element={<RemovePaymentMethodRouteContainer />}
          />
          <Route
            path={generatePathForRouter('ADD_PAYMENT_METHOD')}
            element={<AddPaymentMethodRouteContainer />}
          />
        </Routes>
      ),
    },
  };

export default primarySecondaryRouteConfigMap;
