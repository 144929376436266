import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
} from '@mui/material';

import FieldLabel from '../FieldLabel/FieldLabel';

type RadioGroupProps = {
  id: string;
  label: string;
  options: { id: string; label: string }[];
  row?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  isUsedByRadioGroup?: boolean;
  onChange: (value: string) => void;
};

const RadioGroup = ({
  id,
  label,
  options = [],
  row = true,
  defaultValue,
  disabled,
  isUsedByRadioGroup,
  onChange,
}: RadioGroupProps) => {
  return (
    <FormControl>
      <FieldLabel
        id={`${id}-label`}
        label={label}
        isUsedByRadioGroup={isUsedByRadioGroup}
      />
      {disabled ? (
        <Grid
          container
          alignItems="center"
          style={{ height: 40, marginBottom: 12 }}
        >
          <Typography>
            {options.find((option) => option.id === defaultValue)
              ?.label || '-'}
          </Typography>
        </Grid>
      ) : (
        <MuiRadioGroup
          role="group"
          value={defaultValue}
          row={row}
          aria-labelledby={`${id}-label`}
          onChange={(_, value) => {
            onChange(value);
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={
                <Radio
                  inputProps={{
                    // @ts-expect-error - Need to pass in the data-testid to the input
                    'data-testid': `radio-option-value-${option.id}`,
                  }}
                  color="primary"
                />
              }
              label={option.label}
              data-testid={`radio-option-label-${option.id}`}
            />
          ))}
        </MuiRadioGroup>
      )}
    </FormControl>
  );
};

export default RadioGroup;
