import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';

import type GetBackButtonDetailsReturn from './types/GetBackButtonDetailsReturn';

export type GetBackButtonHandlerProps = {
  componentEntryLocalData: ComponentEntryLocalData;
  setComponentEntryLocalData: (
    value: React.SetStateAction<ComponentEntryLocalData>,
  ) => void;
  closeNotification: () => void;
  backButtonDetails: GetBackButtonDetailsReturn;
  onBackButtonParentHandler?: () => void;
};

export const getBackButtonHandler = ({
  backButtonDetails,
  componentEntryLocalData,
  setComponentEntryLocalData,
  closeNotification,
  onBackButtonParentHandler,
}: GetBackButtonHandlerProps) => {
  if (backButtonDetails.showBackButton) {
    const localDataOnBackClick: ComponentEntryLocalData = {
      ...componentEntryLocalData,
      isBackToChannelSelection: false,
    };

    if (
      backButtonDetails.scenario === 'BACK_TO_CHANNEL_SELECTOR'
    ) {
      return () => {
        closeNotification();
        setComponentEntryLocalData({
          ...localDataOnBackClick,
          isBackToChannelSelection: true,
          selectedChannelType: undefined,
        });
      };
    } else if (
      backButtonDetails.scenario === 'BACK_TO_WALLET' ||
      backButtonDetails.scenario === 'BACK_TO_WALLET_MANAGEMENT'
    ) {
      return onBackButtonParentHandler;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
