import React from 'react';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import {
  CircularProgress,
  Button as MuiButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingButtonLoadingIndicator = styled('div')(() => ({
  marginLeft: 8,
  display: 'inherit',
}));

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  /**
   * @deprecated Use `id` instead
   */
  testId?: string;
}

const Button = (props: ButtonProps) => {
  const {
    id,
    isLoading,
    children,
    testId = 'defaultButtonTestId',
    ...rest
  } = props;

  const loadingIndicator = isLoading ? (
    <LoadingButtonLoadingIndicator>
      <CircularProgress
        size={16}
        color="inherit"
      />
    </LoadingButtonLoadingIndicator>
  ) : null;

  return (
    <MuiButton
      {...rest}
      id={id}
      data-testid={id ?? testId}
    >
      <>
        <span>{children}</span>
        {loadingIndicator}
      </>
    </MuiButton>
  );
};

export default Button;
