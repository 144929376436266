import type { CheckoutSessionsResponse } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import {
  getPaymentMethods,
  getConfigMode,
  getMerchantConsentText,
} from '../../../session/selectors';
import { isConsentTextAvailableAndValid } from '../../isConsentTextAvailableAndValid';

/**
 * This is different from `getFilteredPaymentTypes` in that it does not
 * filters out payment method types even if the channels are not enabled.
 * This function has it's own business rules:
 * 1. If mode is PAYMENT and consent is not available, then it filters out
 */
export const getFilteredPaymentTypesForViewState = (
  session: CheckoutSessionsResponse,
) => {
  const paymentMethods = getPaymentMethods(session);

  if (!paymentMethods || paymentMethods.length === 0) {
    return [];
  }

  const configMode = getConfigMode(session);
  const isPaymentMode = ['PAYMENT'].includes(configMode);
  const isConsentObjectValid = isConsentTextAvailableAndValid(
    getMerchantConsentText(session),
  );

  return paymentMethods.filter((paymentMethodType) => {
    const isBankAccount =
      paymentMethodType.type === 'BANK_ACCOUNT';
    if (isPaymentMode && isBankAccount) {
      return isConsentObjectValid;
    }
    return true;
  });
};
