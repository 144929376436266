import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const USBankIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 22</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Brand">
          <rect
            id="Rectangle"
            fill="#00438F"
            x="0"
            y="0"
            width="42"
            height="24"
            rx="2"
          />
          <g
            id="Forms-/-zz-build-/-Elements-/-Banks-/-US-Bank"
            transform="translate(11.400000, 4.800000)"
            fillRule="nonzero"
          >
            <g id="us-bank-2">
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="0 0 0 11.1710915 9.58873995 14.4 19.2 11.1710915 19.2 0"
              />
              <path
                d="M9.57809848,10.7804715 L6.64155002,10.7804715 L6.64155002,9.78681673 C6.05867212,10.5814875 5.22947614,11.0009673 4.04124021,11.0009673 C2.62916633,11.0009673 1.28389058,10.1401795 1.28389058,8.32939316 L1.28389058,2.94132319 L4.33251859,2.94132319 L4.33251859,7.0924654 C4.33251859,7.97603042 4.44459814,8.61600608 5.34155569,8.61600608 C5.83483417,8.61600608 6.52947047,8.37304943 6.52947047,7.0924654 L6.52947047,2.94132319 L9.57809848,2.94132319 L9.57809848,10.7804715 Z M15.2495164,5.23802281 C15.2045562,4.97292167 15.1149567,4.79639848 14.8907976,4.66384791 C14.7112777,4.48700837 14.4646384,4.39874677 14.195519,4.39874677 C13.8146413,4.39874677 13.2988826,4.5094692 13.2988826,5.01721065 C13.2988826,5.2601673 13.4780815,5.37057338 13.6576014,5.43700684 C14.1730389,5.65750266 15.3163146,5.70179163 16.3475107,6.05515437 C17.3112663,6.36454449 18.2079027,6.93840304 18.2079027,8.21930342 C18.2079027,10.4052806 16.1230304,11.0016 14.1727178,11.0016 C12.2898455,11.0016 10.2499335,10.2509019 10.1828142,8.21930342 L13.0750447,8.21930342 C13.120005,8.50654905 13.2320845,8.72704487 13.4784026,8.9260289 C13.6129623,9.03611863 13.9045618,9.14652471 14.2854396,9.14652471 C14.6666385,9.14652471 15.3166357,9.01429049 15.3166357,8.55020532 C15.3166357,8.08675285 15.0253573,7.97634677 13.6129623,7.73339011 C11.304252,7.33605475 10.3623342,6.5850403 10.3623342,5.2601673 C10.3623342,3.27285779 12.5368059,2.7648 14.1283998,2.7648 C15.8767123,2.7648 17.9391045,3.22856882 18.0065449,5.23802281 L15.2495164,5.23802281 L15.2495164,5.23802281 Z"
                id="Shape"
                fill="#00438F"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default USBankIcon;
