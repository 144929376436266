import { styled, SvgIcon } from '@mui/material';
import React from 'react';

import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';

import { generatePaymentMethodStatus } from './utils/generatePaymentMethodStatus';

const StyledPaymentMethodStatus = styled('div')`
  ${({ theme }) => `
  & > .paymentMethodChip {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    text-align: left;
    & > :first-of-type {
      padding-top: 2px;
    }
    & > .paymentMethodChipLabel {
      font-size: ${theme.typography.subtitle2.fontSize};
      color: #4B4D4F;
      text-align: start;
    }  
  }
  `}
`;

const PaymentMethodStatus = ({
  formattedPaymentMethod,
}: {
  formattedPaymentMethod: FormattedPaymentMethod<PaymentMethodType>;
}) => {
  const paymentMethodStatus = generatePaymentMethodStatus(
    formattedPaymentMethod,
  );
  return (
    <StyledPaymentMethodStatus>
      {paymentMethodStatus.map(
        ({ text, icon, chipType }, index) => {
          const idSuffix = `${chipType}-${formattedPaymentMethod.id}`;

          return (
            <div
              className="paymentMethodChip"
              id={`paymentMethodTileChip-${idSuffix}`}
              data-testid={`paymentMethodTileChip-${idSuffix}`}
              key={index}
            >
              <div>
                <SvgIcon
                  id={`paymentMethodTileChipIcon-${idSuffix}`}
                  data-testid={`paymentMethodTileChipIcon-${idSuffix}`}
                  component={icon}
                  sx={{ width: '16px', height: '16px' }}
                />
              </div>
              <div
                className="paymentMethodChipLabel"
                id={`paymentMethodTileChipLabel-${idSuffix}`}
                data-testid={`paymentMethodTileChipLabel-${idSuffix}`}
              >
                {text}
              </div>
            </div>
          );
        },
      )}
    </StyledPaymentMethodStatus>
  );
};

export default PaymentMethodStatus;
