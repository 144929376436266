import React from 'react';
import type { FC } from 'react';
import { Divider } from '@mui/material';

import type { SectionDividerProps } from './types/SectionDividerProps';

const SectionDivider: FC<SectionDividerProps> = ({ show }) => {
  return show ? (
    <Divider style={{ color: '#4B4D4F', padding: '16px 0' }}>
      Or pay with
    </Divider>
  ) : null;
};

export default SectionDivider;
