import CheckIcon from '@mui/icons-material/Check';
import { LocalAtm } from '@mui/icons-material';

import type { FormattedPaymentMethod } from '../../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../../services/commonCheckout/types/PaymentMethod';
import WarningNotificationIcon from '../../../icons/notification/warning_notification';

const statusVariants = {
  defaultPaymentMethod: {
    text: 'Default',
    icon: CheckIcon,
  },
  manufacturerCard: {
    text: 'Manufacturer card',
    icon: LocalAtm,
  },
  expiredCard: {
    text: (expirationMonth: number, expirationYear: number) =>
      `Expired ${expirationMonth}/${expirationYear}`,
    icon: WarningNotificationIcon,
  },
  invalidPaymentMethod: {
    text: 'This payment method is no longer valid.',
    icon: WarningNotificationIcon,
  },
} as const;

export const generatePaymentMethodStatus = (
  paymentMethod: FormattedPaymentMethod<PaymentMethodType>,
) => {
  const status: {
    text: string;
    icon: typeof CheckIcon | typeof WarningNotificationIcon;
    chipType: 'Default' | 'Manufacturer' | 'Expired' | 'Invalid';
  }[] = [];

  if (paymentMethod.isDefault) {
    status.push({
      ...statusVariants.defaultPaymentMethod,
      chipType: 'Default',
    });
  }

  if (paymentMethod.type === 'CARD') {
    if (paymentMethod.isManufacturerCard) {
      status.push({
        text: 'Manufacturer card',
        icon: LocalAtm,
        chipType: 'Manufacturer',
      });
    }
    if (paymentMethod.isExpired) {
      status.push({
        text: statusVariants.expiredCard.text(
          paymentMethod.expirationMonth,
          paymentMethod.expirationYear,
        ),
        icon: statusVariants.expiredCard.icon,
        chipType: 'Expired',
      });
    }
    return status;
  }

  if (paymentMethod.isInvalid) {
    status.push({
      ...statusVariants.invalidPaymentMethod,
      chipType: 'Invalid',
    });
  }

  return status;
};
