import { useContext } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';

const useSecondaryContainerNotificationWrapperVisibility =
  () => {
    const {
      isAuthorized,
      isChildWaitingOnGlobalSession,
      isSessionOutcomeShowingDeadEndScreen,
    } = useContext(AppContext);

    return (
      isAuthorized &&
      !isChildWaitingOnGlobalSession &&
      !isSessionOutcomeShowingDeadEndScreen
    );
  };

export default useSecondaryContainerNotificationWrapperVisibility;
