/**
 *
 * @param showPaymentContainer - is sent by the merchant to the widget
 * @param callback - will be used by different containers to determine if the widget should be shown/hidden
 * @returns
 */
const shouldShowWidget = (
  showPaymentContainer: boolean | undefined,
  callback: () => boolean,
) => {
  return showPaymentContainer && callback();
};

export default shouldShowWidget;
