import type { UiModes } from '../../services/commonCheckout/types/UiModes';

import { uiModeToRouteMap } from './uiModeToRouteMap';

export function normalizeUiModesFromServer(
  serverUiModes: string[] | undefined,
): UiModes[] {
  let foundInvalidUiMode = false;

  serverUiModes?.forEach((uiMode) => {
    if (!(uiMode in uiModeToRouteMap)) {
      foundInvalidUiMode = true;
      // eslint-disable-next-line no-console
      console.log(
        `Unknown uiMode returned from server: ${uiMode}`,
      );
    }
  });

  if (
    !serverUiModes ||
    serverUiModes.length === 0 ||
    foundInvalidUiMode
  ) {
    reportError(
      new Error(
        'checkoutSession.checkoutRequest.config.modes is undefined or empty or has invalid value',
      ),
    );
    throw new Error(
      '"checkoutSession.checkoutRequest.config.modes is undefined or empty or has invalid value',
    );
  }

  return serverUiModes as UiModes[];
}
