import React from 'react';
import type { FC } from 'react';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import { Grid, Typography } from '@mui/material';

import {
  ICON_IDS,
  TEXT_CONTENT_IDS,
} from '../../utils/element-ids';

export type SuccessCardProps = {
  title?: string;
  description?: string;
  titleId?: string;
};

const SuccessCard: FC<SuccessCardProps> = ({
  title = 'Success',
  description,
  titleId = 'defaultSuccessTitle',
}) => {
  const titleStyles = {
    marginBottom: '8px',
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <SuccessIcon
        data-testid={ICON_IDS.SESSION_OUTCOME_SUCCESS_ICON}
        style={{
          color: '#007000',
          fontSize: '60px',
          margin: '60px 0 34px',
        }}
      />
      <Typography
        component="h1"
        variant="h4"
        data-testid={titleId}
        style={titleStyles}
      >
        {title}
      </Typography>
      {description ? (
        <Typography
          data-testid={
            TEXT_CONTENT_IDS.SESSION_OUTCOME_SUCCESS_DESCRIPTION
          }
          variant="subtitle1"
        >
          {description}
        </Typography>
      ) : null}
    </Grid>
  );
};

export default SuccessCard;
