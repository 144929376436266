import type { CheckoutContainersSessionStatus } from '../../checkout-container/types/CheckoutContainersSessionStatus';

import { isConcludedSessionStatus } from './isConcludedSessionStatus';

export const isOngoingSessionStatus = (
  sessionStatus: CheckoutContainersSessionStatus,
) => {
  return (
    sessionStatus !== 'ERROR' &&
    !isConcludedSessionStatus(sessionStatus)
  );
};
