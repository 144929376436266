import React from 'react';
import type { SetStateAction, Dispatch } from 'react';

import CancelChildSession from '../../capabilities/add-payment-method/components/CancelChildSession/CancelChildSession';
import ErrorBoundary from '../../capabilities/add-payment-method/components/ErrorHandler/ErrorHandler';
import type { PaymentMethodChannelType } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import { isChildSessionExpired } from '../../utils/session/selectors';
import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type ComponentEntryLocalData from '../types/component-entry/ComponentEntryLocalData';

type CancelChildSessionWrapperProps = {
  selectedChannelType?: PaymentMethodChannelType;
  childSessionId?: string;
  shouldCancelChildSession: boolean;
  setShouldCancelChildSession: Dispatch<SetStateAction<boolean>>;
  setErrorType: Dispatch<
    SetStateAction<'cancel' | 'error' | undefined>
  >;
  setIsChildStatusCanceled: Dispatch<SetStateAction<boolean>>;
  errorType: 'cancel' | 'error';
  isChildStatusCanceled: boolean;
  originalCheckoutSessionResponse: CheckoutSessionsResponse;
  onCancel:
    | (() => void)
    | ((args: { checkoutSessionId: string }) => void)
    | undefined;
  setLocalData: Dispatch<
    SetStateAction<ComponentEntryLocalData>
  >;
};

const CancelChildSessionWrapper = ({
  childSessionId,
  shouldCancelChildSession,
  selectedChannelType,
  setShouldCancelChildSession,
  setErrorType,
  setIsChildStatusCanceled,
  errorType,
  isChildStatusCanceled,
  originalCheckoutSessionResponse,
  onCancel,
  setLocalData,
}: CancelChildSessionWrapperProps) => {
  return (
    <CancelChildSession
      childSessionId={childSessionId}
      shouldCancelChildSession={shouldCancelChildSession}
      setIsChildStatusCanceled={setIsChildStatusCanceled}
      onDone={() => {
        setShouldCancelChildSession(false);
      }}
      onError={() => {
        setShouldCancelChildSession(false);
        setErrorType('error');
      }}
    >
      <ErrorBoundary
        errorType={errorType}
        isChildStatusCanceled={isChildStatusCanceled}
        isChildSessionExpired={isChildSessionExpired(
          originalCheckoutSessionResponse,
        )}
        retryButtonText={
          selectedChannelType === 'TEXT'
            ? 'Resend text'
            : 'Resend email'
        }
        onCancel={onCancel}
        onRetry={() => {
          setErrorType(undefined);
          setLocalData((prevState) => ({
            ...prevState,
            childSessionId: undefined,
            paymentMethodId: undefined,
          }));
        }}
        onTryAnotherMethod={() => {
          setErrorType(undefined);
          setLocalData((prevState) => ({
            ...prevState,
            childSessionId: undefined,
            paymentMethodId: undefined,
            selectedChannelType: undefined,
            isBackToChannelSelection: true,
          }));
        }}
      />
    </CancelChildSession>
  );
};

export default CancelChildSessionWrapper;
