import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../contextStore/AppContext';
import SessionApi from '../../../../services/session/SessionApi';
import SuccessCard from '../../../../components/success-card/SuccessCard';
import type { PaymentMethodChannelType } from '../../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import type {
  ChildSessionModes,
  ChildSessionPayload,
} from '../../../../services/session/types';
import {
  getFirstDestination,
  getNotificationChannel,
  getSessionId,
} from '../../../../utils/session/selectors';
import type { SessionNotificationChannels } from '../../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { TITLE_IDS } from '../../../../utils/element-ids';
import getChildSessionMode from '../../utils/getChildSessionMode';
import type { UserJourney } from '../../../../shared/types/UserJourney';
import noop from '../../../../utils/function/noop';

import { useNotificationDeliveryHandler } from './useNotificationDeliveryHandler';
import SendTextForm from './SendTextForm/SendTextForm';
import SendEmailForm from './SendEmailForm/SendEmailForm';

const SHOW_TEXT_SENT_MESSAGE_INTERVAL = 3000;

type NotificationsMethodsContainerProps = {
  checkoutSessionId: string;
  paymentMethodChannel: PaymentMethodChannelType;
  destination?: string;
  onDone: (args: {
    childSessionId: string;
    // Notification destination is the actual phone number or email that the notification was sent to and not what's on file
    notificationDestination: string;
    // Notification channel is the channel used by the user to send the notification and not the available channels
    notificationChannel: SessionNotificationChannels;
  }) => void;
  onError: () => void;
  onBackClick: (() => void) | undefined;
  onCancel: () => void;
  onLoadComplete?: () => void;
  userJourney: UserJourney;
};

const NotificationsMethodsContainer = ({
  checkoutSessionId,
  paymentMethodChannel,
  destination: defaultDestination,
  onDone,
  onBackClick,
  onError,
  onCancel,
  userJourney,
  onLoadComplete = noop,
}: NotificationsMethodsContainerProps) => {
  const { originalCheckoutSessionResponse, setData } =
    useContext(AppContext);
  const [showNotificationSent, setShowNotificationSent] =
    useState(false);
  const [childSessionId, setChildSessionId] = useState<string>();

  const destinationFromServer = getFirstDestination(
    originalCheckoutSessionResponse,
  );

  const channelFromServer = getNotificationChannel(
    originalCheckoutSessionResponse,
  );

  const { hasError, hasNotificationDelivered } =
    useNotificationDeliveryHandler({
      childSessionId,
      onError,
    });

  const childSessionMode = getChildSessionMode(
    paymentMethodChannel,
    userJourney,
  );

  const createChildSessionAndSendNotification = async <
    T extends ChildSessionModes,
  >(
    payload: ChildSessionPayload<T>,
  ) => {
    setData({ overlayLoaderConfig: { show: true } });

    // The backend call also sends out the notification after it creates the child session
    const childSessionCreationResponse =
      await SessionApi.createChildSession({
        parentSessionId: checkoutSessionId,
        payload,
      }).catch(onError);

    if (
      !childSessionCreationResponse ||
      !childSessionCreationResponse?.data.checkoutSession.id
    ) {
      onError();
      return;
    }

    /**
     * 1. Success case is handled in a useEffect
     * 2. The response returned for child session is the same
     * as a "parent" session so we use the same selector
     * function to get the ID.
     */
    setChildSessionId(
      getSessionId(childSessionCreationResponse.data),
    );
  };

  const handleSubmit = <T extends ChildSessionModes>(
    payload: ChildSessionPayload<T>,
  ) => {
    createChildSessionAndSendNotification(payload).catch(
      onError,
    );
  };

  useEffect(() => {
    if (
      destinationFromServer &&
      channelFromServer &&
      childSessionId &&
      !hasError &&
      hasNotificationDelivered
    ) {
      // show the Text sent screen
      setShowNotificationSent(true);
      setData({ overlayLoaderConfig: { show: false } });

      setTimeout(() => {
        onDone({
          notificationDestination: destinationFromServer,
          notificationChannel: channelFromServer,
          childSessionId,
        });
      }, SHOW_TEXT_SENT_MESSAGE_INTERVAL);
    }
  }, [
    destinationFromServer,
    childSessionId,
    hasError,
    hasNotificationDelivered,
    channelFromServer,
  ]);

  useEffect(() => {
    onLoadComplete();
  }, []);

  if (showNotificationSent) {
    return (
      <SuccessCard
        title={`${
          paymentMethodChannel === 'TEXT' ? 'Text' : 'Email'
        } sent`}
        titleId={
          TITLE_IDS.NOTIFICATIONS_METHODS_CONTAINER_NOTIFICATION_SUCCESS_TITLE
        }
      />
    );
  }

  /**
   * NOTE: To avoid making changes in other files because we are limiting the
   *  scope of this ticket to text2add and text2pay, we are using the
   *  childSessionMode to type guard the props passed to SendTextForm
   * */
  if (
    childSessionMode === 'TEXT_TO_ADD' ||
    childSessionMode === 'TEXT_TO_PAY'
  ) {
    /** NOTE: QA requested to preserve test-ids for elements that are not changing in send text form;
     *  hence, adding new optional property subtitleId to PageTitle component
     */
    return (
      <SendTextForm
        phoneNumber={defaultDestination}
        childSessionMode={childSessionMode}
        userJourney={userJourney}
        onBackClick={onBackClick}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    );
  }

  if (
    childSessionMode === 'EMAIL_TO_ADD' ||
    childSessionMode === 'EMAIL_TO_PAY'
  ) {
    return (
      <SendEmailForm
        emailAddress={defaultDestination}
        childSessionMode={childSessionMode}
        userJourney={userJourney}
        onBackClick={onBackClick}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    );
  }

  reportError(new Error('Unknown payment method channel.'));
  return null;
};

export default NotificationsMethodsContainer;
