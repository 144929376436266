import type { FC } from 'react';
import React from 'react';

import ConditionalOverlayLoader from '../../ui/OverlayLoader/ConditionalOverlayLoader';
import ContainerContent from '../../capabilities/checkout-v2/checkout/components/ContainerContent';
import NotificationAlertWrapper from '../../ui/NotificationAlertWrapper/NotificationAlertWrapper';
import PrimarySecondaryConditionalAppContentVisibility from '../PrimarySecondaryConditionalAppContentVisibility/PrimarySecondaryConditionalAppContentVisibility';
import useSecondaryContainerNotificationWrapperVisibility from '../../hooks/useSecondaryContainerNotificationWrapperVisibility/useSecondaryContainerNotificationWrapperVisibility';

const SecondaryRouteWrappers: FC = ({ children }) => {
  const isNotificationAlertWrapperVisible =
    useSecondaryContainerNotificationWrapperVisibility();

  return (
    <>
      <ConditionalOverlayLoader />
      <PrimarySecondaryConditionalAppContentVisibility>
        {isNotificationAlertWrapperVisible ? (
          <NotificationAlertWrapper />
        ) : null}
        <ContainerContent>{children}</ContainerContent>
      </PrimarySecondaryConditionalAppContentVisibility>
    </>
  );
};

export default SecondaryRouteWrappers;
