import React from 'react';
import { styled } from '@mui/material/styles';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { SvgIcon } from '@mui/material';

import type { FormattedPaymentMethod } from '../../capabilities/checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../services/commonCheckout/types/PaymentMethod';
import { maskLast4 } from '../../capabilities/checkout/components/Wallet/utils/formatters';
import getPaymentMethodLogo from '../../capabilities/checkout/components/Wallet/utils/getPaymentMethodLogo';
import { TRANSLATION_CLASS_NAMES } from '../../utils/element-ids';

import PaymentMethodStatus from './PaymentMethodStatus';

/** @description: figma specs for icons are:
 *  37x26 for cards
 *  37x24 for ach
 *  24x24 for the caret icon
 *  16x16 for the additional details icon
 */

const StyledPaymentMethod = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  & > .paymentIcon {
    flex: 0 0 37px;
    & .ccg-MuiSvgIcon-root {
      width: 37px;
      height: 26px;
    }
  }

  & > .paymentMethodDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    & > .paymentMethodDescription {
      word-break: break-word;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #4b4d4f;
      width: 100%;
    }
  }

  & > .paymentNavIcon {
    margin-left: auto;
  }
`;

export type PaymentMethodDetailsProps = {
  paymentMethod: FormattedPaymentMethod<PaymentMethodType>;
  showCaIcon?: boolean;
};

const PaymentMethodDetails = ({
  paymentMethod,
  showCaIcon = true,
}: PaymentMethodDetailsProps) => {
  const { description, last4 } = paymentMethod;
  const paymentMethodIcon = getPaymentMethodLogo(paymentMethod);

  const testIdSuffix = `-${paymentMethod.id}`;

  return (
    <StyledPaymentMethod>
      <div className="paymentIcon">
        <SvgIcon
          data-testid={`paymentMethodTileVendorIcon${testIdSuffix}`}
          component={paymentMethodIcon}
          width="37px"
          height="26px"
          viewBox="0 0 42 24"
          preserveAspectRatio="xMidYMid meet"
        />
      </div>
      <div className="paymentMethodDetails">
        <div className="paymentMethodDescription">
          <span
            className={
              TRANSLATION_CLASS_NAMES.NO_TRANSLATION_CLASS_NAME
            }
            style={{ textAlign: 'left' }}
            data-testid={`paymentMethodTileTitleLabel${testIdSuffix}`}
          >
            {description}
          </span>
          &nbsp;
          <span
            data-testid={`paymentMethodTileTitleLast4${testIdSuffix}`}
          >
            {maskLast4(last4)}
          </span>
        </div>
        <PaymentMethodStatus
          formattedPaymentMethod={paymentMethod}
        />
      </div>
      {showCaIcon ? (
        <div className="paymentNavIcon">
          <NavigateNext
            data-testid={`paymentMethodTileCtaIcon${testIdSuffix}`}
            sx={{ width: '24px', height: '24px' }}
          />
        </div>
      ) : null}
    </StyledPaymentMethod>
  );
};

export default PaymentMethodDetails;
