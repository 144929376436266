import React from 'react';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import type { ChangeEventHandler, FC } from 'react';

import noop from '../../utils/function/noop';

export type CheckboxProps = {
  id: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  testId?: string;
  error?: boolean;
};

const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  disabled,
  value = false,
  onChange = noop,
  testId = 'defaultCheckboxTestId',
  error,
}) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    onChange(event.currentTarget.checked);
  };

  // use globally unique id, to avoid clashing with client code.
  const ccgId = `_ccg_CheckboxId${id}`;

  return (
    <div>
      <FormControlLabel
        htmlFor={ccgId}
        label={label}
        disabled={disabled}
        data-testid={`${testId}-label`}
        control={
          <MuiCheckbox
            id={ccgId}
            sx={[
              {
                '&[class*="checked"]': {
                  color: '#316BBE',
                },
                '&[class*="disabled"]': {
                  color: '#B3B3B3',
                },
              },
              error
                ? {
                    color: (theme) => theme.palette.error.main,
                  }
                : {},
            ]}
            onChange={handleOnChange}
            value={value}
            checked={value}
            data-testid={testId}
          />
        }
      />
    </div>
  );
};

export default Checkbox;
