import React, { useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';
import type { HttpErrorResponse } from '../../services/commonCheckout/types/HttpErrorResponse';
import { isSessionComplete } from '../../capabilities/checkout-v2/checkout/components/checkout-sessions-polling/utils/isSessionComplete';
import type { IncompleteSessionResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';

import type { SessionOutcomeProps } from './types/SessionOutcomeProps';
import { startPollingCheckoutSession } from './startPollingCheckoutSession';
import { SessionOutcomeUi } from './SessionOutcomeUi';

export const SessionOutcome = ({
  onNewSessionData,
  onExceptionCallback,
  onHttpErrorResponseCallback,
  onSuccessCallback,
  shouldStartPolling,
  stopPollingRef,
  children,
}: SessionOutcomeProps & {
  children: ReactNode;
}) => {
  const {
    setData,
    overlayLoaderConfig,
    originalCheckoutSessionResponse,
    checkoutSessionId,
    isChildWaitingOnGlobalSession,
    initializeSessionError,
    initializeSessionException,
  } = useContext(AppContext);

  const [basicSessionStatus, setBasicSessionStatus] = useState<
    'success' | 'failure' | 'open'
  >(
    // This component currently assumes higher-level code will verify the session is not already processed.
    // Although, if the session is already processed, 'open' will just flip to 'failure' very quickly.
    'open',
  );

  const [finalCallback, rawSetFinalCallback] = useState<
    (() => void) | undefined
  >(undefined);

  const setFinalCallback = (callback: () => void) => {
    rawSetFinalCallback(
      // This is reacts set state callback:
      () => {
        // Our actual callback is put into react state:
        return callback;
      },
    );
  };

  const [haveStartedPolling, setHaveStartedPolling] =
    useState<boolean>();

  const [shouldTimeoutIfStillOpen, setShouldTimeoutIfStillOpen] =
    useState<boolean>();

  const [haveShownTimeoutError, setHaveShownTimeoutError] =
    useState<boolean>();

  const [errorResponse, setHttpErrorResponse] =
    useState<HttpErrorResponse>();

  const [exception, setException] = useState<Error>();

  useEffect(() => {
    if (stopPollingRef.current) {
      // eslint-disable-next-line no-console
      console.debug(
        'Somehow stopPollingRef.current was synchronously flipped to true... this should never happen.',
      );
      return;
    }
    // Since we have session initializer and session outcome run asynchronously,
    // we can have originalCheckoutSessionResponse not defined initially
    if (
      shouldStartPolling &&
      !haveStartedPolling &&
      originalCheckoutSessionResponse &&
      Object.keys(originalCheckoutSessionResponse).length &&
      !isSessionComplete(originalCheckoutSessionResponse)
    ) {
      if (!checkoutSessionId) {
        // This can happen for hosted HSID flows (/wallet, <BrowserRouter>)
        // eslint-disable-next-line no-console
        console.debug(
          'checkoutSessionId is falsey - cannot start polling yet',
        );
        return;
      }

      setHaveStartedPolling(true);
      startPollingCheckoutSession({
        initialCheckoutSession:
          // May be ok if this is undefined:
          originalCheckoutSessionResponse as IncompleteSessionResponse,
        updateStatusUrl: `/checkout-sessions/${checkoutSessionId}`,
        stopPollingRef,
        onNewSessionData,
        isSessionOutcomePolling: true,
        onFinallyDone: (props) => {
          setData({
            isSessionOutcomeShowingDeadEndScreen: true,
          });
          const { didSucceed, latestCheckoutSession } = props;
          if (didSucceed) {
            setBasicSessionStatus('success');
            setFinalCallback(() =>
              onSuccessCallback?.(latestCheckoutSession),
            );
            return;
          }

          setBasicSessionStatus('failure');
          if ('httpErrorResponse' in props) {
            setFinalCallback(() =>
              onHttpErrorResponseCallback?.(
                props.httpErrorResponse,
                latestCheckoutSession,
              ),
            );
            setHttpErrorResponse(props.httpErrorResponse);
            return;
          }

          if ('exception' in props) {
            setFinalCallback(() =>
              onExceptionCallback?.(
                props.exception,
                latestCheckoutSession,
              ),
            );
            setException(props.exception);
            return;
          }

          if ('pollingStoppedEarly' in props) {
            // eslint-disable-next-line no-console
            console.debug('pollingStoppedEarly', props);
            return;
          }

          console.error(
            'onFinallyDone called with unexpected props:',
            props,
          );
        },
      });
    }

    // Polling is canceled in 'unmount' useEffect below.
    // Returning unmount callback here is risky,
    // Due to all the dependencies,
    // effect cleanup may be called when component is not actually unmounting.
  }, [
    checkoutSessionId,
    shouldStartPolling,
    haveStartedPolling,
    setHaveStartedPolling,
    originalCheckoutSessionResponse,
    stopPollingRef,
    onNewSessionData,
    onExceptionCallback,
    onHttpErrorResponseCallback,
    onSuccessCallback,
    setBasicSessionStatus,
    setHttpErrorResponse,
    setException,
    setData,
  ]);

  // on unmount, stop polling:
  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-console
      console.debug('looks like SessionOutcome is unmounting.');
      // eslint-disable-next-line no-param-reassign
      stopPollingRef.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initializeSessionError || initializeSessionException) {
      setData({
        isSessionOutcomeShowingDeadEndScreen: true,
      });
      setBasicSessionStatus('failure');
      if (initializeSessionError) {
        setFinalCallback(() =>
          onHttpErrorResponseCallback?.(
            initializeSessionError,
            originalCheckoutSessionResponse as IncompleteSessionResponse,
          ),
        );
        setHttpErrorResponse(initializeSessionError);
      }
      if (initializeSessionException) {
        setFinalCallback(() =>
          onExceptionCallback?.(
            initializeSessionException,
            originalCheckoutSessionResponse as IncompleteSessionResponse,
          ),
        );
        setException(initializeSessionException);
      }
    }
  }, [initializeSessionError, initializeSessionException]);

  // Toggle OverlayLoader:
  useEffect(() => {
    if (
      isChildWaitingOnGlobalSession &&
      basicSessionStatus === 'open' &&
      !overlayLoaderConfig.show
    ) {
      setData({ overlayLoaderConfig: { show: true } });

      setTimeout(() => {
        setShouldTimeoutIfStillOpen(true);
      }, 50 * 1000);
    }
    if (
      basicSessionStatus !== 'open' &&
      overlayLoaderConfig.show
    ) {
      setData({ overlayLoaderConfig: { show: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChildWaitingOnGlobalSession,
    basicSessionStatus,
    overlayLoaderConfig.show,
    // Including this causes a `setTimoout` loop:
    // setShouldTimeoutIfStillOpen,
  ]);

  // If some code incorrectly sets `isChildWaitingOnGlobalSession` to `true`,
  // `shouldTimeoutIfStillOpen` will be set to `true` after 50 seconds.
  //
  // The below useEffect handles showing a dead-end screen once `shouldTimeoutIfStillOpen` is set to `true`.
  useEffect(() => {
    if (
      shouldTimeoutIfStillOpen &&
      !haveShownTimeoutError &&
      basicSessionStatus === 'open'
    ) {
      // This timeout logic could potentially just show a warning banner,
      // instead of a dead-end error.
      // This can allow the user to continue their session, since it should still be open.
      //  - Currently I am opting to keep the dead-end screen:
      //    - If we do incorrectly set `isChildWaitingOnGlobalSession` to `true`,
      //      we don't want that mistake to slip into prod.
      //      By showing a dead-end screen, we are forced to fix the mistake.

      // eslint-disable-next-line no-param-reassign
      stopPollingRef.current = true;
      setHaveShownTimeoutError(true);
      setBasicSessionStatus('failure');
      const error = new Error(
        'SessionOutcome timed out waiting for backend to close session.',
      );
      reportError(error);
      onExceptionCallback?.(
        error,
        originalCheckoutSessionResponse,
      );
      setException(error);
      // Use default ErrorCard messages for timeout message.
    }
  }, [
    shouldTimeoutIfStillOpen,
    haveShownTimeoutError,
    basicSessionStatus,
    stopPollingRef,
    setHaveShownTimeoutError,
    setBasicSessionStatus,
    onExceptionCallback,
    originalCheckoutSessionResponse,
    setException,
    setData,
  ]);

  if (basicSessionStatus !== 'open') {
    return (
      <SessionOutcomeUi
        finalCallback={finalCallback}
        basicSessionStatus={basicSessionStatus}
        originalCheckoutSessionResponse={
          originalCheckoutSessionResponse
        }
        errorResponse={errorResponse}
        exception={exception}
      />
    );
  }

  return <>{children}</>;
};
