import React from 'react';

import { TEXT_CONTENT_IDS } from '../../utils/element-ids';
// TODO: convert to emotion styled component

const RichTextBox: React.FC<{
  richText: string | TrustedHTML | undefined;
  minHeight?: string;
}> = ({ richText, minHeight = '80px' }) => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight,
        maxHeight: '144px',
        padding: '16px',
        borderRadius: '4px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
        border: '1px solid #4B4D4F',
        overflow: 'auto',
        wordBreak: 'break-word',
      }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      data-testid={
        TEXT_CONTENT_IDS.MERCHANT_AUTHORIZATION_CONSENT_TEXT
      }
      // for now we are expecting this text to be plain text, waiting for business for more clarity
      // dangerouslySetInnerHTML={{
      //   __html: richText ?? '',
      // }}
    >
      {richText}
    </div>
  );
};

export default RichTextBox;
