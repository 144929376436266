import React from 'react';
import { Route, Routes } from 'react-router';

import ErrorCard from '../components/ErrorCard/ErrorCard';
import PaymentMethodDisplay from '../components/PaymentMethodDisplay/PaymentMethodDisplay';

export const secondaryRoutes = (
  <Routes>
    <Route
      path="/component-library/payment-method-display"
      element={<PaymentMethodDisplay />}
    />
    <Route
      path="*"
      element={<ErrorCard />}
    />
  </Routes>
);
