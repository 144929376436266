import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { StripeElements, Stripe } from '@stripe/stripe-js';

import type { CreateNewPaymentRequest } from '../../services/stripe/types';
import noop from '../../utils/function/noop';
import { AppContext } from '../contextStore/AppContext';
import {
  getAgent,
  getExpiresAtUtc,
} from '../../utils/session/selectors';
import { getTimeDifference } from '../../utils/date/getTimeDifference';
import { NotificationContext } from '../contextStore/NotificationContext';
import useSessionExpirationReminder from '../../hooks/useSessionExpirationReminder';
import { GENERIC_STRIPE_ERROR_MESSAGE } from '../../shared/strings';
import shouldTriggerReminder from '../../utils/capabilities/shouldTriggerReminder';
import StripePaymentElementWrapper from '../../components/stripe/stripe-elements-wrapper/StripePaymentElementWrapper';
import type { ApiError } from '../checkout-v2/types';
import type {
  PaymentMethodStatus,
  SetupPaymentMethodResponse,
} from '../../services/customer/types';

import type { AddPaymentMethodBaseProps } from './types/AddPaymentMethodBaseProps';
import type { AddPaymentMethodFormSubmitData } from './components/AddPaymentMethodStripeForm';
import AddPaymentMethodFormStripe from './components/AddPaymentMethodStripeForm';

export type AddPaymentMethodContainerProps =
  AddPaymentMethodBaseProps & {
    formTitle: string;
    onError?: (error: ApiError) => void;
  };

const AddPaymentMethodContainer: FC<
  AddPaymentMethodContainerProps
> = ({
  stripeApi,
  customerApi,
  customerId,
  vendorPlatformKey,
  formTitle,
  isInFocus,
  onBeforeSubmit = noop,
  onSuccess = noop,
  onCancel = noop,
  onBackClick,
  onLoadComplete = noop,
  onError = noop,
}) => {
  const {
    setData,
    setSessionOutcomePollingInterval,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);
  const { notify } = useContext(NotificationContext);
  const [
    isCreatingNewPaymentMethod,
    setIsCreatingNewPaymentMethod,
  ] = useState(false);
  const [isSubmitDone, setIsSubmitDone] =
    useState<boolean>(false);
  const [isComponentUnmounting, setIsComponentUnmounting] =
    useState<boolean>(false);
  const expiresAt = getExpiresAtUtc(
    originalCheckoutSessionResponse,
  );
  const timeDifference = getTimeDifference(
    new Date(expiresAt),
    new Date(new Date().toISOString()),
  );
  const agent = getAgent(originalCheckoutSessionResponse);

  const handleError = (err: string) => {
    setIsCreatingNewPaymentMethod(false);
    setData({ overlayLoaderConfig: { show: false } });
    console.error(err);
  };

  const handleStripeError = (error: string) => {
    // `error` should not be displayed to the user as it's coming from Stripe.
    notify({
      severity: 'error',
      title: GENERIC_STRIPE_ERROR_MESSAGE,
    });
    handleError(error);
  };

  const isValidCard = async (elements: StripeElements) => {
    const { validationError } =
      await stripeApi.validatePaymentMethod({ elements });

    if (validationError) {
      // Stripe handles showing errors in their UI so we don't need to do anything.
      // We also don't start polling as the Stripe form is not valid.
      handleError(validationError.message);
      return false;
    }
    return true;
  };

  const tokenizeCard = async ({
    elements,
    stripe,
    name,
  }: Pick<
    CreateNewPaymentRequest,
    'elements' | 'stripe' | 'name'
  >) => {
    const {
      paymentMethod: tokenizedCard,
      paymentMethodError: tokenizedCardError,
    } = await stripeApi.createPaymentMethod({
      elements,
      stripe,
      name,
    });

    if (tokenizedCardError) {
      handleStripeError(tokenizedCardError.message);
      return undefined;
    }

    if (!tokenizedCard) {
      throw new Error('Error tokenizing card');
    }

    return tokenizedCard;
  };

  const notifyPaymentMethodWarning = (
    setupIntentResponse?: SetupPaymentMethodResponse,
  ) => {
    if (setupIntentResponse?.data.warning) {
      setData({
        addPaymentMethodWarning:
          setupIntentResponse?.data.warning,
      });
    }
  };

  const isAuthRequired = (status: PaymentMethodStatus) => {
    return status === 'AUTH_REQUIRED';
  };

  const authorizeCard = async ({
    stripe,
    setupPaymentMethodResponse,
    setupPaymentMethodUrl,
  }: {
    stripe: Stripe;
    setupPaymentMethodResponse: SetupPaymentMethodResponse;
    setupPaymentMethodUrl: string;
  }) => {
    const { status, vendorSetupPaymentMethodSecret } =
      setupPaymentMethodResponse.data;

    if (!isAuthRequired(status)) {
      return setupPaymentMethodResponse;
    }

    if (!vendorSetupPaymentMethodSecret) {
      throw new Error(
        'Failed to start authorization due to missing vendorSetupPaymentMethodSecret',
      );
    }

    /**
     * This launches Stripe's authorization screen, when adding a card
     * - stripe.handleNextAction resolves to a confirmed success/failure
     * for the given setupIntent, without the need to confirm it via the API
     * - UI however, relies upon CCG API instead of the resolved value from the promise
     * - Note: Card authorization was introduced starting v2.9.0
     */
    await stripe.handleNextAction({
      clientSecret: vendorSetupPaymentMethodSecret,
    });

    return customerApi.confirmPaymentMethodCreation({
      url: setupPaymentMethodUrl,
      isPaymentMethodAuthorized: true,
    });
  };

  const createPaymentMethod = async ({
    elements,
    stripe,
    name,
    nickname,
    isDefault,
    isManufacturerCard,
  }: CreateNewPaymentRequest & {
    isDefault: boolean;
    nickname: string;
    isManufacturerCard: boolean;
  }) => {
    try {
      if (!(await isValidCard(elements))) {
        return;
      }

      const tokenizedCard = await tokenizeCard({
        elements,
        stripe,
        name,
      });

      if (!tokenizedCard) {
        return;
      }

      let createPaymentMethodResponse =
        await customerApi.createPaymentMethod(customerId, {
          paymentMethod: {
            type: 'CARD',
            vendorPaymentMethodId: tokenizedCard.id,
            vendor: 'STRIPE',
            default: isDefault,
            nickname,
            card: {
              nameOnCard: name,
              manufacturerCard: isManufacturerCard,
            },
            agent,
            authRequired: true,
          },
        });

      if (
        !createPaymentMethodResponse ||
        createPaymentMethodResponse?.errors?.length
      ) {
        throw new Error('Failed to add payment method');
      }

      createPaymentMethodResponse = await authorizeCard({
        stripe,
        setupPaymentMethodResponse: createPaymentMethodResponse,
        setupPaymentMethodUrl: createPaymentMethodResponse.url,
      });

      if (
        createPaymentMethodResponse?.errors?.length ||
        !createPaymentMethodResponse?.data?.paymentMethodId
      ) {
        throw new Error('Failed to add payment method');
      }

      const { paymentMethodId } =
        createPaymentMethodResponse.data;

      setIsCreatingNewPaymentMethod(false);
      onSuccess({
        paymentMethodId,
        showAddPaymentMethodNotificationAfterNav:
          !createPaymentMethodResponse?.data.warning,
      });
      notifyPaymentMethodWarning(createPaymentMethodResponse);
    } catch (e: any) {
      onError(e?.response?.data as ApiError);
      if (e instanceof Error) {
        handleError(e.message);
      }
    } finally {
      setData({ overlayLoaderConfig: { show: false } });
    }
  };

  const onSubmit = ({
    elements,
    formData: {
      nameOnCard: name,
      nickname,
      isDefault,
      isManufacturerCard,
    },
    stripe,
  }: AddPaymentMethodFormSubmitData) => {
    setIsCreatingNewPaymentMethod(true);
    setData({ overlayLoaderConfig: { show: true } });
    setSessionOutcomePollingInterval(undefined);
    onBeforeSubmit();
    createPaymentMethod({
      elements,
      stripe,
      name,
      nickname,
      isDefault,
      isManufacturerCard,
    })
      .catch(() => {
        // errors are already caught and handled inside createPaymentMethod itself
      })
      .finally(() => {
        setIsComponentUnmounting(false);
        setIsSubmitDone(true);
      });
  };

  useSessionExpirationReminder({
    expiresAt,
    notify,
    timeDifference,
    shouldTriggerReminder: shouldTriggerReminder(
      originalCheckoutSessionResponse,
    ),
  });

  useEffect(() => {
    if (isSubmitDone && !isComponentUnmounting) {
      setSessionOutcomePollingInterval(10000);
      setIsComponentUnmounting(false);
      setIsSubmitDone(false);
    }
  }, [isSubmitDone, isComponentUnmounting]);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    setSessionOutcomePollingInterval(10000);

    // eslint-disable-next-line consistent-return
    return () => {
      setIsComponentUnmounting(true);
      setSessionOutcomePollingInterval(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StripePaymentElementWrapper
      vendorPlatformKey={vendorPlatformKey}
    >
      <AddPaymentMethodFormStripe
        onSubmit={onSubmit}
        onBackClick={onBackClick}
        onCancel={onCancel}
        onLoadComplete={onLoadComplete}
        isCreatingNewPaymentMethod={isCreatingNewPaymentMethod}
        setIsCreatingNewPaymentMethod={
          setIsCreatingNewPaymentMethod
        }
        formTitle={formTitle}
        agent={agent}
        isInFocus={isInFocus}
      />
    </StripePaymentElementWrapper>
  );
};

export default AddPaymentMethodContainer;
