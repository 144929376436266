import type { FormattedPaymentMethod } from '../../checkout/components/Wallet/types';
import type { PaymentMethodType } from '../../../services/commonCheckout/types/PaymentMethod';

export const findPaymentMethodIdFromRequest = (
  selectedPaymentMethodId: string | null | undefined,
  paymentMethodList: FormattedPaymentMethod<PaymentMethodType>[],
) => {
  const matchingPaymentMethod = paymentMethodList.find(
    (paymentMethod) =>
      paymentMethod.id === selectedPaymentMethodId,
  );
  if (matchingPaymentMethod) {
    return matchingPaymentMethod.id;
  } else {
    return null;
  }
};
