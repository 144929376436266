import React from 'react';
import { Grid } from '@mui/material';

import getLoaderDimension from '../utils/styles/getLoaderDimension';
import LoaderWithText from '../LoaderWithText/LoaderWithText';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';

interface OverlayLoaderProps {
  description?: string;
}

const OverlayLoader = ({
  description = '',
}: OverlayLoaderProps) => {
  const { containerConfig } = useEnvironmentContextProvider();
  const loaderStyles = getLoaderDimension(containerConfig);

  const gridStylesBase = {
    zIndex: 2,
    gap: '32px',
    ...loaderStyles,
  };

  const innerDivStylesBase = {
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <Grid
      id="loaderContainer"
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={
        containerConfig // TODO: Need to clean up later
          ? gridStylesBase
          : { ...gridStylesBase, position: 'absolute' }
      }
    >
      <div
        style={
          containerConfig // TODO: Need to clean up later
            ? innerDivStylesBase
            : { ...innerDivStylesBase, position: 'absolute' }
        }
      >
        <div
          style={{
            position: 'relative',
            marginTop: '50%',
          }}
        >
          <LoaderWithText description={description} />
        </div>
      </div>
    </Grid>
  );
};

export default OverlayLoader;
