import type { PayAndStoreResponse } from '../types';

/**
 * PayAndStoreResponse type could be one of 'string' | 'PayAndStoreResponse' | 'undefined'
 * This method ensures response type is 'PayAndStoreResponse'
 */
export default function isPayAndStoreResponse(
  response: unknown,
): response is PayAndStoreResponse {
  return (
    response !== null &&
    typeof response === 'object' &&
    'data' in response &&
    response.data != null &&
    typeof response.data === 'object'
  );
}
