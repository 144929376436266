import type { AppEnv } from '../../shared/types/app/AppEnv';
import {
  DEFAULT_ENV,
  WALLET_ORIGIN,
} from '../../utils/constants';

type SettingsApiInitProps = {
  client: HttpClient;
  appEnv?: AppEnv;
  onLoadSettingsFailed?: ((reason?: any) => void) | undefined;
  onUnAuthorizedCallback?: (() => void) | undefined;
};

type SettingsInitData = {
  httpClient: HttpClient;
  settings: Record<string, string>;
};

class SettingsApi {
  // --initPromise is used so get* properites will know to await the initialize call...
  private initPromiseResolve: (props: SettingsInitData) => void;

  private initPromiseFailed: (reason?: any) => void;

  private initPromise = new Promise<SettingsInitData>(
    (resolve, failed) => {
      this.initPromiseResolve = resolve;
      this.initPromiseFailed = failed;
    },
  );

  private async loadSettingsFile(props: SettingsApiInitProps) {
    const { client, appEnv = DEFAULT_ENV } = props;
    const walletOrigin = WALLET_ORIGIN[appEnv];

    return client
      .request<Record<string, string>>({
        baseURL: walletOrigin,
        url: `/settings.json`,
      })
      .then(
        (result) => {
          this.initPromiseResolve({
            httpClient: client,
            settings: result.data || {},
          });
        },
        (reason) => {
          this.initPromiseFailed(reason);
          throw reason;
        },
      );
  }

  private loadSettingsFilePromise: Promise<void>;

  init(props: SettingsApiInitProps) {
    const { onLoadSettingsFailed } = props;
    this.loadSettingsFilePromise =
      this.loadSettingsFilePromise === undefined
        ? this.loadSettingsFile(props)
        : this.loadSettingsFilePromise;
    this.loadSettingsFilePromise.then(
      () => {
        // success
      },
      (reason) => {
        if (onLoadSettingsFailed) {
          onLoadSettingsFailed(reason);
        }
      },
    );
  }

  getPaymentUrl = async () => {
    return this.initPromise.then(
      (data) => data.settings.paymentApiBaseUrl || '',
    );
  };

  getWalletUrl = async () => {
    return this.initPromise.then(
      (data) => data.settings.walletBaseUrl || '',
    );
  };

  getSpanishTranslationKey = async () => {
    return this.initPromise.then(
      (data) => data.settings.spanishTKey || '',
    );
  };

  getCustomerUrl = async () => {
    return this.initPromise.then(
      (data) => data.settings.customerApiBaseUrl || '',
    );
  };

  getApiURL = async ({
    api,
  }: {
    api: 'CUSTOMER' | 'PAYMENT' | 'WALLET';
  }) => {
    return this.initPromise.then((data) => {
      switch (api) {
        case 'CUSTOMER':
          return data.settings.customerApiBaseUrl || '';
        case 'PAYMENT':
          return data.settings.paymentApiBaseUrl || '';
        case 'WALLET':
          return data.settings.walletBaseUrl || '';
        default:
          return '';
      }
    });
  };

  getUpstreamEnv = async () => {
    return this.initPromise.then(
      (data) => data.settings.upstreamEnv || '',
    );
  };

  getAIConfig = async () => {
    return this.initPromise.then((data) => ({
      connectionString: data.settings.aiConnectionString,
      role: data.settings.aiRole,
    }));
  };
}

export const settingsApi = new SettingsApi();
