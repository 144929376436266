import { RadioGroup, styled } from '@mui/material';

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  marginBottom: 0,
});

export default StyledRadioGroup;
