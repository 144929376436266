import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type {
  PaymentMethodChannelType,
  PaymentMethodChannels,
} from '../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';

import { getAgent } from './selectors';

/**
 * @description: this utility function  will return all the notification channels
 * configured for an AGENT session.
 *
 * For AGENT sessions, the API does NOT include
 * express checkout elements including: GOOGLE_PAY &
 * APPLE_PAY.
 */
export const getConfiguredChannelsForAgent = (
  session: CheckoutSessionsResponse,
) => {
  const { config } = session.checkoutSession.checkoutRequest;
  const { paymentMethod } = config ?? {};
  const isNotAgentSession = !getAgent(session);
  if (isNotAgentSession || !config || !paymentMethod?.length) {
    return [];
  }

  if (paymentMethod.length === 1) {
    return paymentMethod[0].channels;
  }

  const [paymentTypeA, paymentTypeB] = paymentMethod;
  const { channels: channelsOfPaymentTypeA } = paymentTypeA;
  const { channels: channelsOfPaymentTypeB } = paymentTypeB;

  const combinedEnabledChannels = [
    ...channelsOfPaymentTypeA,
    ...channelsOfPaymentTypeB,
  ];

  const uniqueEnabledChannelsFromAllPaymentTypesCombinedForAgent =
    [
      ...new Set(
        combinedEnabledChannels.map(({ type }) => type),
      ),
    ];

  const unsortedCombinedChannels =
    uniqueEnabledChannelsFromAllPaymentTypesCombinedForAgent.reduce<PaymentMethodChannels>(
      (availableChannels, currentChannelType, currentIndex) => {
        const restOfChannelProps = {
          enabled:
            (channelsOfPaymentTypeA.find(
              (channelConfig) =>
                channelConfig.type === currentChannelType,
            )?.enabled ??
              false) ||
            (channelsOfPaymentTypeB.find(
              (channelConfig) =>
                channelConfig.type === currentChannelType,
            )?.enabled ??
              false),
          order: currentIndex,
        };
        if (availableChannels.length === 0) {
          return [
            { type: currentChannelType, ...restOfChannelProps },
          ];
        }
        return [
          ...availableChannels,
          { type: currentChannelType, ...restOfChannelProps },
        ];
      },
      [],
    );

  const orderArray: PaymentMethodChannelType[] = [
    'TEXT',
    'EMAIL',
    'WEBFORM',
  ];

  unsortedCombinedChannels.sort((a, b) => {
    const aIndex = orderArray.indexOf(a.type);
    const bIndex = orderArray.indexOf(b.type);

    return aIndex - bIndex;
  });

  return unsortedCombinedChannels;
};
