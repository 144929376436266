import type { FC } from 'react';
import React, { useContext, useEffect } from 'react';

import InitializeSessionData from '../../shared/CheckoutSessionInitializer/InitializeSessionData';
import WidgetSettingsLoader from '../WidgetSettingsLoader/WidgetSettingsLoader';
import type { AppEnv } from '../../shared/types/app/AppEnv';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import connectionChecker from '../../utils/connection/connection-checker/ConnectionChecker';
import { AppContext } from '../../capabilities/contextStore/AppContext';
import emitEvent from '../../utils/widget/emitEvent';

type WidgetLoaderManagerProps = {
  appEnv: AppEnv;
  checkoutSessionId: string;
};

const WidgetLoaderManager: FC<WidgetLoaderManagerProps> = ({
  children,
  appEnv,
  checkoutSessionId,
}) => {
  const { hasInitialSessionDataLoaded, initialSessionData } =
    useContext(AppContext);
  const { setEnvironmentState, postMessage } =
    useEnvironmentContextProvider();

  useEffect(() => {
    if (!postMessage) {
      return;
    }

    if (
      !hasInitialSessionDataLoaded ||
      !Object.keys(initialSessionData).length
    ) {
      return;
    }

    emitEvent(postMessage, { title: 'WIDGET_LOADED' });
  }, [hasInitialSessionDataLoaded, initialSessionData]);

  useEffect(() => {
    setEnvironmentState({
      onDoneClick: () => {
        if (!postMessage) return;

        emitEvent(postMessage, { title: 'WIDGET_CLOSED' });
        postMessage?.({ type: 'hideModal' });
      },
    });

    return () => {
      connectionChecker.stopChecking();
    };
  }, []);

  return (
    <WidgetSettingsLoader
      appEnv={appEnv}
      checkoutSessionId={checkoutSessionId}
    >
      <InitializeSessionData
        checkoutSessionId={checkoutSessionId}
        validateCheckoutSessionId={false}
      >
        {children}
      </InitializeSessionData>
    </WidgetSettingsLoader>
  );
};

export default WidgetLoaderManager;
