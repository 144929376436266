import { createTheme } from '@mui/material';
import type { Theme, ThemeOptions } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

import mutateTypographyWithUpdates from '../utils/mutateTypographyWithUpdates';

import type {
  FontDefinition,
  Palette,
  PaletteColors,
} from './appearance/types';
import {
  transformPrimaryButtonWithTheme,
  transformSecondaryButtonWithTheme,
} from './appearance/transform/button';
import type { getThemeArgs } from './types';

const getExternalTheme: (
  args: Required<getThemeArgs>,
) => Theme = ({ theme, appearance }) => {
  let font: FontDefinition | undefined;
  let palette: Palette | undefined;

  if (appearance) {
    font = appearance.font;
    palette = appearance.palette;
  }

  let typography: TypographyOptions | undefined;

  // set font family
  if (font?.fontFamily) {
    typography = createTheme(theme, {
      typography: {
        fontFamily: font.fontFamily,
        fontSources: font.fontSources,
      },
    }).typography;

    mutateTypographyWithUpdates(typography, {
      fontFamily: font.fontFamily,
    });
  }

  let containedPrimary = {};
  let containedSecondary = {};

  // update theme to reflect palette
  const externalTheme: ThemeOptions = palette
    ? createTheme(theme, { palette })
    : theme;

  // update button styles based on palette theme
  if (palette?.primary) {
    containedPrimary = transformPrimaryButtonWithTheme(
      externalTheme.palette?.primary as PaletteColors,
    );
  }
  if (palette?.secondary) {
    containedSecondary = transformSecondaryButtonWithTheme(
      externalTheme.palette?.secondary as PaletteColors,
    );
  }

  const externalThemeOptions: ThemeOptions = {
    typography: {
      ...(typography || {}),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            ...containedPrimary,
          },
          containedSecondary: {
            ...containedSecondary,
          },
        },
      },
    },
  };

  return createTheme(externalTheme, externalThemeOptions);
};

export default getExternalTheme;
