import React, { useContext } from 'react';
import type { FC } from 'react';
import { Container } from '@mui/system';

import useEnvironmentContextProvider from '../../../../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';
import { ModalContainer } from '../../../../../components/container-types/modal-container';
import { AppContext } from '../../../../../capabilities/contextStore/AppContext';
import useCcgNavigate from '../../../../../hooks/useCcgNavigate/useCcgNavigate';
import { getConfigMode } from '../../../../../utils/session/selectors';
import goBackToPrimaryContainer from '../../../../../utils/routing/goBackToPrimaryContainer/goBackToPrimaryContainer';
import SecondaryRouteWrappers from '../../../../../components/PrimarySecondaryRouteWrappers/SecondaryRouteWrappers';
import DrawerContainer from '../../../../../components/container-types/drawer-container';
import isPrimarySecondaryContainer from '../../../../../utils/container-config/isPrimarySecondaryContainer';
import isSecondaryTypeDrawer from '../../../../../utils/container-config/isSecondaryTypeDrawer';
import useSecondaryContainerCloseContext from '../../../../../capabilities/contextStore/SecondaryContainerCloseContext/useSecondaryContainerCloseContext';

const SecondaryRouteContainerSelector: FC = ({ children }) => {
  const { originalCheckoutSessionResponse } =
    useContext(AppContext);
  const { containerConfig } = useEnvironmentContextProvider();
  const { runBeforeCloseTask } =
    useSecondaryContainerCloseContext();
  const { ccgNavigate } = useCcgNavigate();

  if (!containerConfig) {
    throw new Error('containerConfig is required');
  }

  const handleOnClose = () => {
    runBeforeCloseTask()
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        goBackToPrimaryContainer(
          getConfigMode(originalCheckoutSessionResponse),
          ccgNavigate,
        );
      });
  };

  if (
    containerConfig.type === 'inline' &&
    containerConfig.secondaryContainer?.type === 'modal'
  ) {
    return (
      <ModalContainer onClose={handleOnClose}>
        <Container>
          <SecondaryRouteWrappers>
            {children}
          </SecondaryRouteWrappers>
        </Container>
      </ModalContainer>
    );
  } else if (
    isPrimarySecondaryContainer(containerConfig) &&
    isSecondaryTypeDrawer(containerConfig)
  ) {
    return (
      <DrawerContainer onClose={handleOnClose}>
        <Container>
          <SecondaryRouteWrappers>
            {children}
          </SecondaryRouteWrappers>
        </Container>
      </DrawerContainer>
    );
  }

  throw new Error('Invalid secondary container type');
};

export default SecondaryRouteContainerSelector;
