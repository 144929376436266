import { createContext } from 'react';

import type { PostMessageFunc } from '../../../widget-wrapper/types';
import type { ContainerConfig } from '../../../components/container-types/types';
import type { AppEnvironmentType } from '../../../shared/types/app/AppEnvironmentType';

export type EnvironmentContextData = {
  appEnvironmentType: AppEnvironmentType;
  containerConfig?: ContainerConfig;
  onDoneClick?: () => void;
  /**
   * This is used to trigger cancel session handler. Currently this is only needed/used in CheckoutContainer
   */
  triggerUnload?: boolean;
  /**
   * This is used to show/hide the widget container. The responsibility of setting this value is on the containers (CheckoutContainer). It defaults to true so that we can show the widget container while it's loading the initial settings.
   */
  showWidgetContainer?: boolean;
  /**
   * This is sent from merchants in embedded mode. This is used when computing showWidgetContainer value.
   */
  showPaymentContainer?: boolean;
};

export type EnvironmentContextActions = {
  postMessage?: PostMessageFunc;
};

export type EnvironmentLocalStateContextType =
  EnvironmentContextData & EnvironmentContextActions;

export type EnvironmentContextProviderType =
  EnvironmentLocalStateContextType & {
    setEnvironmentState: (
      newData: Partial<EnvironmentLocalStateContextType>,
    ) => void;
  };

const EnvironmentContext = createContext<
  EnvironmentContextProviderType | undefined
>(undefined);

export default EnvironmentContext;
