import type { UserJourney } from '../../shared/types/UserJourney';

const getAwaitingUserResponseTitle = (
  userJourney: UserJourney,
): string => {
  if (userJourney === 'ADD_PAYMENT_METHOD') {
    return 'Awaiting payment method...';
  }
  if (userJourney === 'MAKE_PAYMENT') {
    return 'Awaiting payment...';
  }
  return '';
};

export default getAwaitingUserResponseTitle;
