import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import React from 'react';

const CharlesSchwabIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42px"
      height="24px"
      viewBox="0 0 42 24"
      {...props}
    >
      <title>Icons/Payment Chip Copy 11</title>
      <g
        id="🎨-UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          fillRule="nonzero"
        >
          <g id="Brand">
            <rect
              id="Rectangle"
              fill="#00A0DF"
              x="0"
              y="0"
              width="42"
              height="24"
              rx="2"
            />
            <g
              id="Forms-/-zz-build-/-Elements-/-Banks-/-Charles-Schwab"
              transform="translate(7.200000, 4.800000)"
              fill="#FFFFFF"
            >
              <g
                id="XMLID_51_"
                transform="translate(0.000000, 0.034951)"
              >
                <g
                  id="XMLID_101_"
                  transform="translate(23.667000, 2.761165)"
                >
                  <path
                    d="M2.8773,3.37631068 C2.8773,2.87650485 2.57025,2.50252427 2.32185,2.12504854 C2.07345,1.87339806 1.8906,1.62524272 1.8906,1.31067961 C1.8906,0.936699029 2.139,0.685048544 2.44605,0.685048544 C2.63235,0.685048544 2.7531,0.747961165 2.8773,0.810873786 C3.0015,0.873786408 3.18435,0.936699029 3.30855,0.936699029 C3.55695,0.936699029 3.7398,0.685048544 3.7398,0.499805825 C3.7398,0.24815534 3.4914,3.97352054e-15 3.06015,3.97352054e-15 C2.07345,3.97352054e-15 1.2075,1.12543689 1.2075,1.9992233 C1.2075,2.49902913 1.51455,2.87300971 1.76295,3.18757282 C1.94925,3.4392233 2.1321,3.68737864 2.1321,3.93902913 C2.1321,4.31300971 1.76295,4.56466019 1.45245,4.56466019 C1.0833,4.56466019 0.897,4.37592233 0.6486,4.19067961 C0.5244,4.06485437 0.4002,3.93902913 0.27945,3.93902913 C0.09315,3.93902913 0.03105,4.31300971 0.03105,4.50174757 C0.03105,4.75339806 0.4623,5.12737864 1.01775,5.12737864 C1.8906,5.0015534 2.8773,4.25009709 2.8773,3.37631068"
                    id="XMLID_21_"
                  />
                </g>
                <g
                  id="XMLID_98_"
                  transform="translate(3.622500, 0.000000)"
                >
                  <path
                    d="M1.8768,0.0104854369 C1.8147,0.0104854369 1.8147,0.0104854369 1.8768,0.0104854369 L1.8147,0.262135922 L1.8147,0.325048544 L2.12175,0.325048544 C2.37015,0.387961165 2.4288,0.387961165 2.4288,0.513786408 C2.4288,0.702524272 2.1804,1.32815534 1.87335,2.20194175 L1.62495,3.01631068 C1.37655,3.70485437 1.0074,4.70446602 0.70035,5.51883495 C0.3312,6.5184466 0.0207,7.33281553 0.0207,7.45864078 C0.0207,7.58446602 0.0828,7.64737864 0.38985,7.64737864 C0.6969,7.64737864 0.8211,7.58446602 0.8832,7.45864078 C1.43865,5.64466019 2.9187,3.58252427 3.66045,3.58252427 C3.90885,3.58252427 3.9675,3.64543689 3.9675,3.89359223 C3.9675,4.26757282 3.66045,5.01902913 3.28785,5.77048544 C2.9808,6.3961165 2.7324,7.02174757 2.7324,7.33281553 C2.7324,7.58446602 2.8566,7.70679612 3.10155,7.70679612 C3.8433,7.70679612 4.46085,7.01825243 4.8921,6.45553398 L4.9542,6.39262136 L4.7679,6.2038835 L4.7058,6.2038835 C4.4574,6.45553398 4.02615,6.89242718 3.90195,6.89242718 L3.83985,6.89242718 L3.83985,6.76660194 C3.83985,6.70368932 3.90195,6.51495146 4.02615,6.26679612 C4.3332,5.57825243 4.83,4.38990291 4.83,3.70485437 C4.83,3.14213592 4.5816,2.83106796 4.08825,2.83106796 C3.16365,2.83106796 2.4219,3.89359223 2.05275,4.45631068 C1.99065,4.58213592 1.86645,4.70796117 1.86645,4.70796117 C1.86645,4.70796117 1.92855,4.58213592 1.99065,4.33398058 C2.11485,4.0823301 2.23905,3.70834951 2.4219,3.20854369 C3.10155,1.33165049 3.4086,0.394951456 3.4086,0.269126214 C3.4086,0.143300971 3.2844,0.143300971 3.2223,0.143300971 L1.863,0.143300971 L1.8768,0.0104854369 Z"
                    id="XMLID_99_"
                  />
                </g>
                <g
                  id="XMLID_95_"
                  transform="translate(0.103500, 2.621359)"
                >
                  <path
                    d="M3.2982,4.01592233 C3.3603,3.95300971 3.3603,3.95300971 3.2982,4.01592233 C3.2982,3.95300971 3.1119,3.82718447 3.1119,3.82718447 C2.80485,4.07883495 2.2494,4.57864078 1.7526,4.57864078 C1.5663,4.57864078 1.38345,4.51572816 1.25925,4.38990291 C1.07295,4.26407767 1.01085,4.01592233 1.01085,3.70135922 C0.94875,2.94990291 1.6284,1.7615534 2.24595,1.07650485 C2.6151,0.702524272 2.9256,0.513786408 3.10845,0.513786408 C3.35685,0.513786408 3.5397,0.702524272 3.726,0.88776699 C3.8502,1.07650485 3.9744,1.19883495 4.15725,1.19883495 C4.21935,1.19883495 4.34355,1.13592233 4.34355,1.07300971 C4.40565,1.01009709 4.46775,0.884271845 4.46775,0.821359223 C4.46775,0.510291262 4.1607,0.0699029126 3.48105,0.00699029126 C2.7393,0.00699029126 1.7526,0.443883495 1.01085,1.19533981 C0.3312,1.8838835 -0.03795,2.7576699 0.02415,3.57203883 C0.02415,4.26058252 0.3933,5.00854369 1.38345,5.00854369 C2.37015,5.14135922 3.174,4.26757282 3.2982,4.01592233 Z"
                    id="XMLID_96_"
                  />
                </g>
                <g
                  id="XMLID_92_"
                  transform="translate(14.248500, 2.691262)"
                >
                  <path
                    d="M1.61805,2.19495146 C1.61805,2.25786408 1.55595,2.3207767 1.55595,2.3207767 L1.55595,2.25786408 C1.55595,2.19495146 1.61805,2.00621359 1.68015,1.75805825 C1.80435,1.25825243 1.92855,0.632621359 1.92855,0.443883495 C1.92855,0.19223301 1.80435,0.0699029126 1.5594,0.0699029126 C1.06605,0.0699029126 0.2622,0.94368932 0.01725,1.38407767 L0.14145,1.57281553 L0.20355,1.50990291 L0.26565,1.44699029 C0.45195,1.19533981 0.8211,0.758446602 1.0074,0.758446602 C1.1316,0.758446602 1.1937,0.884271845 1.1937,1.06951456 C1.1937,1.50640777 0.70035,2.88349515 0.38985,3.82019417 C0.20355,4.32 0.0828,4.69398058 0.0828,4.7568932 C0.0828,4.88271845 0.1449,4.88271845 0.207,4.88271845 L0.63825,4.88271845 C0.70035,4.88271845 0.76245,4.81980583 0.82455,4.69398058 C0.82455,4.69398058 1.01085,4.19417476 1.1316,3.94252427 C1.9941,2.19145631 2.4288,1.3176699 2.79795,0.940194175 C3.04635,0.688543689 3.2292,0.629126214 3.3534,0.629126214 C3.4776,0.629126214 3.4776,0.692038835 3.5397,0.754951456 C3.6018,0.817864078 3.6639,0.94368932 3.7881,0.94368932 C3.8502,0.94368932 3.9744,0.94368932 4.0365,0.880776699 C4.1607,0.754951456 4.2228,0.629126214 4.2228,0.443883495 C4.2228,0.132815534 3.9744,0.00699029126 3.79155,0.00699029126 C2.91525,0.0699029126 2.3598,0.632621359 1.61805,2.19495146 Z"
                    id="XMLID_93_"
                  />
                </g>
                <path
                  d="M13.2135,2.63533981 C13.1514,2.63533981 13.0893,2.69825243 13.0893,2.76116505 C13.0272,2.82407767 13.0272,2.88699029 12.903,2.88699029 C12.8409,2.88699029 12.7788,2.88699029 12.6546,2.82407767 C12.5304,2.82407767 12.4062,2.76116505 12.22335,2.76116505 C10.06365,2.76116505 8.95275,5.70058252 8.95275,6.70019417 C8.95275,7.38873786 9.3219,7.69980583 9.6945,7.69980583 C10.12575,7.69980583 10.7433,7.57398058 11.54715,6.32271845 L11.73345,6.01165049 L11.73345,6.07456311 L11.67135,6.20038835 C11.54715,6.38912621 11.3643,6.82601942 11.3643,7.2 C11.3643,7.38873786 11.4264,7.51106796 11.4885,7.57398058 C11.5506,7.6368932 11.6748,7.69980583 11.79555,7.69980583 C12.5373,7.69980583 13.15485,6.70019417 13.3998,6.32271845 L13.2135,6.13398058 L13.1514,6.13398058 L12.9651,6.32271845 C12.7167,6.57436893 12.47175,6.94834951 12.28545,6.94834951 L12.22335,6.94834951 L12.22335,6.82252427 C12.22335,6.57087379 12.7167,5.38601942 13.0272,4.44582524 C13.2756,3.75728155 13.52055,3.13165049 13.52055,3.00932039 C13.52055,3.00932039 13.52055,2.88349515 13.45845,2.88349515 L13.1514,2.69475728 C13.2756,2.63533981 13.2135,2.63533981 13.2135,2.63533981 Z M9.8187,6.51145631 C9.8187,5.07495146 10.9296,3.13514563 12.0405,3.13514563 C12.40965,3.13514563 12.59595,3.3238835 12.59595,3.69786408 C12.59595,4.26058252 11.1159,6.94834951 10.12575,6.94834951 C9.9429,7.01126214 9.8187,6.94834951 9.8187,6.51145631 Z"
                  id="XMLID_87_"
                />
                <path
                  d="M20.1273,6.38563107 C20.1273,7.2 20.62065,7.76271845 21.3624,7.76271845 C22.2249,7.76271845 23.15295,6.88893204 23.3979,6.63728155 L23.3979,6.57436893 L23.2737,6.38563107 L23.2116,6.38563107 L23.0874,6.44854369 C22.78035,6.70019417 22.28355,7.07417476 21.8523,7.07417476 C21.35895,7.07417476 21.04845,6.7631068 21.04845,6.25980583 C21.04845,5.63417476 21.3555,5.19728155 21.5418,4.82330097 L21.5418,4.76038835 L22.03515,4.76038835 C22.839,4.76038835 24.31905,4.38640777 24.31905,3.44621359 C24.31905,3.07223301 23.9499,2.63184466 23.33235,2.63184466 C22.1007,2.76116505 20.1273,4.63805825 20.1273,6.38563107 Z M23.2737,3.07223301 C23.5221,3.07223301 23.70495,3.26097087 23.70495,3.50912621 C23.70495,4.13475728 23.0253,4.44582524 21.8523,4.44582524 C22.0386,4.01242718 22.71825,3.07223301 23.2737,3.07223301 Z"
                  id="XMLID_82_"
                />
                <g
                  id="XMLID_78_"
                  transform="translate(17.560500, 0.069903)"
                >
                  <path
                    d="M2.1942,6.37864078 C2.1942,6.37864078 2.1942,6.31572816 2.1942,6.37864078 C2.1942,6.31572816 2.0079,6.12699029 2.0079,6.12699029 L1.9458,6.18990291 C1.51455,6.62679612 1.32825,6.94135922 1.0833,6.94135922 L1.0212,6.94135922 C1.0212,6.94135922 0.9591,6.8784466 0.9591,6.81553398 C0.9591,6.5638835 1.39035,5.3161165 2.2563,3.06524272 L2.87385,1.44 C3.1809,0.625631068 3.3672,0.188737864 3.3672,0.125825243 L3.3672,0.0629126214 C3.3672,-3.97352054e-15 3.3051,-3.97352054e-15 3.1809,-3.97352054e-15 L1.76295,-3.97352054e-15 C1.76295,-3.97352054e-15 1.70085,-3.97352054e-15 1.70085,0.0629126214 L1.70085,0.373980583 L2.0079,0.373980583 C2.1942,0.436893204 2.31495,0.499805825 2.31495,0.625631068 C2.31495,0.814368932 1.39035,3.12815534 1.39035,3.12815534 C0.897,4.37941748 0.03105,6.56737864 0.03105,7.06718447 C0.03105,7.31883495 0.09315,7.50407767 0.15525,7.56699029 C0.27945,7.69281553 0.40365,7.75572816 0.5865,7.75572816 C1.14195,7.75572816 1.7595,7.00427184 2.12865,6.50446602 C2.1321,6.50446602 2.1942,6.37864078 2.1942,6.37864078 Z"
                    id="XMLID_79_"
                  />
                </g>
                <g
                  id="XMLID_75_"
                  transform="translate(0.000000, 9.366990)"
                >
                  <path
                    d="M0.18975,1.46097087 C0.18975,2.14951456 0.7452,2.52349515 1.23855,2.77514563 L1.48695,2.90097087 C1.9803,3.15262136 2.2287,3.46368932 2.2287,3.8376699 C2.2287,4.27456311 1.92165,4.65203883 1.48695,4.65203883 C0.8073,4.65203883 0.43815,3.96349515 0.25185,3.52660194 L0.00345,3.58951456 C0.06555,4.02640777 0.18975,4.52621359 0.18975,4.71495146 L0.31395,4.71495146 L0.621,4.8407767 C0.8073,4.90368932 1.05225,4.96660194 1.30065,4.96660194 C2.22525,4.96660194 2.9049,4.4038835 2.9049,3.53009709 C2.9049,2.71572816 2.28735,2.34174757 1.8561,2.15300971 L1.6698,2.09009709 C1.11435,1.77902913 0.86595,1.52737864 0.86595,1.15339806 C0.86595,0.779417476 1.11435,0.401941748 1.5456,0.401941748 C2.10105,0.401941748 2.34945,0.838834951 2.4702,1.27572816 L2.7186,1.21281553 C2.6565,0.838834951 2.5944,0.524271845 2.5944,0.276116505 L2.4081,0.213203883 L2.2839,0.150291262 C2.0976,0.0873786408 1.85265,0.0244660194 1.66635,0.0244660194 C0.8073,0.0838834951 0.18975,0.646601942 0.18975,1.46097087 Z"
                    id="XMLID_76_"
                  />
                </g>
                <g
                  id="XMLID_72_"
                  transform="translate(3.450000, 9.541748)"
                >
                  <path
                    d="M0.0138,2.41165049 C0.0138,3.6 0.81765,4.72543689 2.60475,4.72543689 C3.036,4.72543689 3.65355,4.59961165 3.90195,4.53669903 C4.02615,4.22563107 4.209,3.66291262 4.2711,3.34834951 L4.0227,3.28543689 C3.65355,4.0368932 3.34305,4.41087379 2.54265,4.41087379 C1.55595,4.41087379 0.8763,3.53708738 0.8763,2.22291262 C0.8763,1.28621359 1.30755,0.22368932 2.54265,0.22368932 C3.2844,0.22368932 3.65355,0.534757282 3.83985,1.28621359 L4.08825,1.28621359 L4.02615,0.975145631 L3.90195,0.160776699 L3.77775,0.160776699 C3.52935,0.0978640777 3.1602,0.0349514563 2.66685,0.0349514563 C1.1247,-0.0908737864 0.0138,0.908737864 0.0138,2.41165049 Z"
                    id="XMLID_73_"
                  />
                </g>
                <g
                  id="XMLID_69_"
                  transform="translate(7.900500, 9.576699)"
                >
                  <path
                    d="M5.00595,0 L3.03255,0 L3.03255,0.251650485 C3.5259,0.314563107 3.588,0.314563107 3.588,1.12543689 L3.588,2.06213592 L1.4904,2.06213592 L1.4904,1.12543689 C1.4904,0.373980583 1.5525,0.311067961 2.04585,0.251650485 L2.04585,0 L0.01035,0 L0.01035,0.251650485 C0.6279,0.314563107 0.6279,0.377475728 0.6279,1.12543689 L0.6279,3.62796117 C0.6279,4.37941748 0.5658,4.4423301 0.01035,4.50174757 L0.01035,4.75339806 L2.04585,4.75339806 L2.04585,4.50174757 C1.5525,4.43883495 1.4283,4.43883495 1.4283,3.62796117 L1.4283,2.43961165 L3.5259,2.43961165 L3.5259,3.62796117 C3.5259,4.37941748 3.4638,4.4423301 2.90835,4.50174757 L2.90835,4.75339806 L4.94385,4.75339806 L4.94385,4.50174757 C4.3884,4.43883495 4.3263,4.43883495 4.3263,3.62796117 L4.3263,1.12543689 C4.3263,0.373980583 4.3884,0.311067961 4.94385,0.251650485 C4.94385,0.24815534 5.00595,0 5.00595,0 Z"
                    id="XMLID_70_"
                  />
                </g>
                <g
                  id="XMLID_64_"
                  transform="translate(13.213500, 9.576699)"
                >
                  <path
                    d="M6.6654,0 L5.06115,0 L5.06115,0.251650485 L5.30955,0.251650485 C5.43375,0.251650485 5.49585,0.314563107 5.55795,0.314563107 C5.55795,0.377475728 5.62005,0.377475728 5.62005,0.44038835 C5.62005,0.503300971 5.62005,0.629126214 5.55795,0.751456311 C5.55795,0.814368932 5.1888,2.37669903 4.8783,3.37631068 C4.8162,3.25048544 3.6432,0.0629126214 3.6432,0.0629126214 L3.33615,0.0629126214 C3.33615,0.0629126214 2.28735,3.37631068 2.28735,3.4392233 C2.22525,3.31339806 1.4835,0.688543689 1.4835,0.688543689 C1.4835,0.562718447 1.4214,0.499805825 1.4214,0.436893204 L1.4214,0.311067961 C1.4835,0.24815534 1.5456,0.24815534 1.6698,0.24815534 L1.9182,0.24815534 L1.9182,0.0594174757 L0,0.0594174757 L0,0.311067961 C0.36915,0.373980583 0.49335,0.373980583 0.67965,0.99961165 L1.85265,4.74990291 L2.1597,4.74990291 C2.1597,4.74990291 3.2085,1.49941748 3.2706,1.43650485 C3.3327,1.5623301 4.4436,4.74990291 4.4436,4.74990291 L4.75065,4.74990291 C5.3682,2.56194175 5.92365,1.06252427 5.92365,0.99961165 C6.17205,0.373980583 6.2307,0.311067961 6.6654,0.311067961 C6.6654,0.311067961 6.6654,0 6.6654,0 Z"
                    id="XMLID_65_"
                  />
                </g>
                <path
                  d="M21.114,10.7021359 C21.114,10.7021359 21.60735,12.2015534 21.66945,12.2015534 L20.62065,12.2015534 C20.55855,12.2015534 21.114,10.7021359 21.114,10.7021359 Z M21.9765,10.8279612 L21.48315,9.51378641 L21.1761,9.57669903 L19.75815,13.2640777 C19.50975,13.8897087 19.4511,13.9526214 19.0164,13.9526214 L19.0164,14.2042718 L20.62065,14.2042718 L20.62065,13.9526214 L20.43435,13.9526214 C20.31015,13.9526214 20.18595,13.8897087 20.18595,13.8267961 L20.18595,13.7009709 C20.18595,13.6380583 20.18595,13.5751456 20.24805,13.4493204 L20.31015,13.3234951 C20.37225,13.0718447 20.49645,12.6978641 20.55855,12.5720388 L21.85575,12.5720388 C21.97995,12.9460194 22.1628,13.3864078 22.2249,13.5716505 C22.2249,13.6345631 22.287,13.6974757 22.287,13.7603883 L22.287,13.823301 C22.287,13.8862136 22.2249,13.8862136 22.1007,13.8862136 L21.9144,13.9491262 L21.9144,14.1378641 L23.76705,14.1378641 L23.76705,13.8862136 C23.3979,13.823301 23.2737,13.823301 23.0253,13.1976699 L21.9765,10.8279612 L21.9765,10.8279612 Z"
                  id="XMLID_59_"
                />
                <path
                  d="M25.2471,10.2023301 C25.2471,10.0135922 25.2471,9.89126214 25.3092,9.89126214 L25.61625,9.89126214 C25.80255,9.89126214 26.47875,9.95417476 26.47875,10.7650485 C26.47875,11.327767 26.1096,11.638835 25.49205,11.638835 L25.24365,11.638835 C25.2471,11.638835 25.2471,10.2023301 25.2471,10.2023301 Z M23.95335,9.57669903 L23.95335,9.82834951 C24.4467,9.89126214 24.5088,9.89126214 24.5088,10.7021359 L24.5088,13.2046602 C24.5088,13.9561165 24.4467,14.0190291 23.89125,14.0784466 L23.89125,14.3300971 L25.6197,14.3300971 C26.17515,14.3300971 26.6685,14.2042718 26.979,14.0190291 C27.41025,13.7673786 27.59655,13.3933981 27.59655,12.9565049 C27.59655,12.3308738 27.1653,11.8939806 26.36145,11.7681553 C26.7306,11.7052427 27.34815,11.3941748 27.34815,10.7685437 C27.34815,10.3945631 27.22395,10.1429126 26.979,9.95417476 C26.7306,9.76543689 26.42355,9.70252427 25.8681,9.70252427 L23.95335,9.70252427 L23.95335,9.57669903 Z M25.4955,11.8275728 C26.29935,11.8275728 26.7306,12.2015534 26.7306,12.8900971 C26.7306,13.5786408 26.23725,13.8897087 25.806,13.8897087 C25.6197,13.8897087 25.49895,13.8897087 25.43685,13.8267961 C25.31265,13.7009709 25.31265,13.5157282 25.31265,13.1382524 L25.31265,11.8869903 C25.3092,11.8275728 25.4955,11.8275728 25.4955,11.8275728 Z"
                  id="XMLID_52_"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default CharlesSchwabIcon;
